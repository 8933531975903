/* eslint-disable */

import React, { useState, useEffect, useContext, useRef,useCallback } from 'react';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';
import ControlList from './ControlList';
import ActionPanel from './ActionPanel';
import { getUserTable, updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import { addorupdateValues } from '../../../../api/crud';
import UpdateRecord from '../../../../api/updateRecord';
import cloudrun from '../../../../clients/httpcloudrun';
import { GetSingleDataRow } from '../../../../api/GetData';
import TopNabar from './TopNavbar';
import ShareManager from '../Shares/ShareManager';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import Spinner from '../../../../components/Spinner/Spinner';


const DetailView = (props) => {
  const {
    tableid,
    blockid,
    tableinfo,
    color,
    hide,
    selectedRow,
    role,
    NextPrev,
    id,
    RefreshRow,
    SelectedRowIndex,
    updateTableInfo,
    userTabSettings,
    updateSettingsFromDetailView,
    hasMessages,
    filterCount,
    activeRow,
    isShare = false, //6-3-2022 added isShare,isMobileShare. IsShare is used to not show share/print buttons. IsMobileShare updates UI to reflect Mobile friendly version
    isMobileShare=false,
    canMessage= true,
    canCollaborate=true,
    canViewComments=true,
    shareControl,
    heightInfo,
    nextPrevBool
  } = props;
  const { userInfo } = useContext(AccountContext);

  const [selectedItem, setSelectedItem] = useState();
  const [dataId, setDataId] = useState(id);
  const [currentTableInfo, setCurrentTableInfo] = useState({});
  const [itemArray, setItemArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [layout, setLayout] = useState('horizontal');
  const [index, setIndex] = useState(Date.now()); // we need a way to trigger controlist to update. When changes are complete, we update this value.
  const [changes, setChanges] = useState({}); // We store all control changes in this array. When use clicks the checkmark to submit, we call CRUD method sand update setIndex to refresh the controls
  const [updateGrid, setUpdateGrid] = useState(false);
  const [secondaryInitialSize,setSecondaryInitialSize] = useState(450);
  const [tabsettings,setTabsettings] = useState(null);
  const [width,setWidth] = useState(window.innerWidth*.7)
  const [lastUpdated,setLastUpdated] =useState(new Date())
  const [showShareManager,setShowShareManager] = useState(false);
  const [showActionPanel,setShowActionPanel] = useState(true);
  const [isEditMode,setIsEditMode] =useState(false);



  const ref = useRef(null);
  const divRef=useRef(null);

  const updateSelectedItem = (item) => {
    setSelectedItem(item);
  };

  //11-78-23 Adding method, because if user clicks cancel to exit edit mode, it should remove all
  //changes. Right now if they made changes, click cancel, and then try to exit window, they get "would you like to save changes"
  const toggleEditMode =(mode) =>{
    setIsEditMode(mode);
    if(!mode) {
      setChanges({});
    }
  }


  const saveAndClose = async () =>{
    await submitChanges();
    hide();
  }

  const closeDetailView =() =>{
 
    if(Object.keys(changes).length > 0) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <GBConfirm
              title={'Save changes?'}
              action={saveAndClose}
              buttonLabel="OK"
              message='Would you like to save your changes?'
              divRef={divRef}
              showInput={false}
              confirmAction="Save & close"
              height="250px"
              onClose={onClose}
            />
          );
        },
      });
    } else {
      hide();
    }
  
  }

  // 6-10-2020 In detail view, when drilling into related records, we need to keep array
  // of the path. Starts with single record, click to drill related adds a new record, click to drill to a new related record, adds to array.
  // The topnav bar has breadcrumbs. When you click on item, it should display this record and truncate array
  // if you are drilling into next reocrd, it should display item, but add(push) new item into array.
  // "mode" parameter defines the logic. if mode="add", we push item into array. If "truncate", we truncate array at this selecteditme
  // only two modes, so if not "add", then "truncate"

  //6-7-2022 We need to be able to show detailview as stand-alone page for the Sharing of single records. I will
  //implement this by passing null for the "hide" parameter, which means, there is no way to hide the detailview.

  //6-15-23 added check on messageIndex to also check for email type field with enableEmailMessages
  //Messages now mean both text and email messages.

  useEffect(() => {
    
    const Newtableinfo = structuredClone(tableinfo);
    setIsLoading(true);
    setCurrentTableInfo(Newtableinfo);

    const testarray = [];

    const canViewPrivate =Newtableinfo.security ==undefined || Newtableinfo.security.viewPrivate ==undefined || Newtableinfo.security.viewPrivate <= role;
    const messageIndex = Newtableinfo.columns.findIndex((el) => (el.uitype === 12 || (el.uitype===10 && el.enableEmailMessages) )  && (el.isPrivate===undefined || canViewPrivate));

    //5-1-24 I removed the "await" on this GetSingleDataRow as it casus the page to flicker. 
    //it's workign fine in my local env, but we should thoroughly test this in dev where there
    //is going to be some delay.
    //the controlist is set not to render under isloading if false.. We'll need to test this out 
    //and see if we can keep it.

    const getFullRecord = async ()=>{
      let singleRow = await GetSingleDataRow(id, Newtableinfo, tableid,blockid);
      singleRow.canEdit =props.selectedRow.canEdit;
     

      const TopItem = {
        tableid: tableid,
        id: props.id,
        selectedRow: singleRow,
        tableinfo: Newtableinfo,
        hasMessages: messageIndex !== -1
      };
  
      testarray.push(TopItem);

      setItemArray(testarray);
      setSelectedItem(singleRow)
      setItemIndex(0);
      setDataId(id);

    }
    getFullRecord()


    setWidth(userTabSettings.detailViewWidth !==undefined ? parseFloat(userTabSettings.detailViewWidth)*window.innerWidth : window.innerWidth*.8 )
    setSecondaryInitialSize(userTabSettings.detailViewSecondaryPane !==undefined ? parseFloat(userTabSettings.detailViewSecondaryPane)*window.innerWidth : window.innerWidth*.4 )
    setLayout(userTabSettings.detailViewLayout !==undefined ? userTabSettings.detailViewLayout : 'horizontal' )

    //6-13-2022, param to determine if we should show action panel for Share at all
    //if share has no access to messages, collaboriation, attachments, and we don't have any multiple related records, we hide
    const hasAttachments= tableinfo.columns.findIndex(col=>col.source===undefined && col.uitype===4 || (col.source && col.source.lookupuitype===4)) !==-1
    const hasRelated = tableinfo.columns.findIndex(col=>col.uitype===1 && col.lookup==='multiple') !==-1;

    const showActionPanelTemp = hasRelated || hasAttachments || (hasMessages && canMessage) || canCollaborate || canViewComments
    setShowActionPanel(showActionPanelTemp);

    if (divRef.current) {
      divRef.current.focus();
    }

    setIsLoading(false)

  }, [props.id]);

  // 5-4-2021 Added method in situation where user selected a new related record and we need to
  // update the core record so that the lookups auto-populate on detail view.
  // 2-10-24 we don't execute this when in a share(gets invoked when updating a form from detail view)
  // same for deep link Record. Fow now, we aren't refreshing ..as we don't have refreshrow logic in deep link.
  const updateSelectedRow =(index, newRow) => {
    if(!isShare) {
    const itemArrayTemp =  [...itemArray];
    itemArrayTemp[index].selectedRow=newRow;
    setItemArray(itemArrayTemp);
    setIndex(new Date())
    }
  }

  // 12 For a loadedRelationalRecord, we need to determine if they can view
  // it (block navigation) and also if they can edit it (pass canEdit to remove editing capabilities)
  const checkLoadedRelationalRecordSecurity=(tableid,blockid,tableinfo,dataRow) => {
    
    let canEdit=true;
    let canView=true;

    let blockRole = undefined;
    const idx = userInfo.blocks.findIndex(el=>el.blockid===blockid);
    if(idx !==-1){
      blockRole = userInfo.blocks[idx].role;
    }


    // if they have access to the block && if no security defined, or their role has full access to view/edit, return true.
    if(blockRole !==undefined && (tableinfo.security===undefined || (tableinfo.security.editAnyRecord <=blockRole && tableinfo.security.allRecords <=blockRole ))) {
      return {canView:true,canEdit:true};
    } 

    const userid=userInfo.id;

    /*****Are they either owner or a Collaborator  ************ */
    let isCollaborator = false;

    const collabFields = tableinfo.columns.filter(el=>el.uitype===8 || (el.source && el.source.lookupuitype===8));
      collabFields.forEach(itm=>{
        if(dataRow[itm.data] !==null) {
          const idx=dataRow[itm.data].findIndex(el=>el.userid===userid)
          if(idx !==-1) isCollaborator=true;
      }}) 

   
      // 12-13-2021. We check both the addedby.userid as well as just addedby. This is because if a table
      // does not have the addedby column defined, we are still including it in the result set, however it
      // does not get processed like a full user field which is replaced by full user details. The dataRow
      // just has the userid itself. This was added so that we can implement this view level security
      // even on tables where they have not added the addedby field.

      const isOwner = (dataRow.addedby.userid !==undefined && dataRow.addedby.userid===userid) || dataRow.addedby===userid;

      /****** END Collaborator check ******* */

      canEdit = (blockRole !==undefined && ((isCollaborator && tableinfo.security.editOthersRecord <= blockRole) || isOwner || tableinfo.security.editAnyRecord <= blockRole));
      canView = (blockRole !==undefined && ((isCollaborator && tableinfo.security.myRecords <= blockRole) || isOwner || tableinfo.security.allRecords <= blockRole));

      return {canEdit,canView}
  }

  const toggleShowShare =() =>{
    setShowShareManager(!showShareManager);
  }

  const loadRelatedRecord = async (id, tableid, blockid,mode) => {
    
    //8-17-23 added this check for when they are drilled into a relational record and have it in
    //edit mode and then try to navigate back to root. This throws an error, so for now, we just
    //tell them they must cancel edit mode before they can navigate back. 
   if(isEditMode && itemArray.length>1)
   {
    toast.info(<div style={{margin:'10px'}}>You must close out of Edit mode before navigating back.</div>, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose:7000,
    })
    return false
   }

    // check if this record is already in tree/array. If yes, just return false
    const checkIndex = itemArray.findIndex((itm) => itm.id == id && itm.tableid == tableid);
    if (checkIndex !== -1 && mode === 'add') {
      return false;
    }

      //6-3-2022 Adding check for Share user. Current logic is that they should not be able to 
      //drill into relational records when viewing a share. The share anonymous user has email format
      // of anonymous_<zoneid>@graceblocks.com
      if(userInfo.email.toLowerCase().includes('anonymous_') || isMobileShare) {
        return false;
      }

    if (mode === 'add') {
      
      // const table = await getTableAPI(tableid);
      const table = await getUserTable(tableid, 0,role,userInfo.blocks,false); 

      let singleRow = await GetSingleDataRow(id, table.tableinfo, tableid,blockid);
     
      // 12-9-2021 Logic to include security on this relational record.
      // set Default to true, for "free" plan..only run logic if not free.

      let privs={canEdit:true,canView:true};
      if(userInfo.plan !=='Free'){
      privs = checkLoadedRelationalRecordSecurity(tableid,table.blockid,table.tableinfo,singleRow);
      }
      
      
      
      // if they don't have view privs, immediate return, don't load relational record
      if(!privs.canView) {
        toast.info(<div style={{margin:'10px'}}>🔒 Security applied. Your security access level does not allow access to view this record's details.</div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose:7000,
        })
        return false
      }

      setIsLoading(true);

      singleRow.canEdit=privs.canEdit;

      const relatedTableInfo = table.tableinfo;
      relatedTableInfo.recordname = table.recordname;
      relatedTableInfo.icon = table.icon;

      // 3-29-23 Remove message field if it's private and user does not have access.
      const canViewPrivate =relatedTableInfo.security ==undefined || relatedTableInfo.security.viewPrivate ==undefined || relatedTableInfo.security.viewPrivate <= role;
      const messageIndex =relatedTableInfo.columns.findIndex((el) => (el.uitype === 12 || (el.uitype===10 && el.enableEmailMessages) ) && (el.isPrivate===undefined || canViewPrivate));

      const TopItem = {
        tableid: parseInt(tableid),
        id: id,
        selectedRow: singleRow,
        tableinfo: relatedTableInfo,
        hasMessages: messageIndex !== -1
      };

      const tempArray = [...itemArray];
      tempArray.push(TopItem);

      setItemArray(tempArray);
      setDataId(id);
      setSelectedItem(singleRow);
      setCurrentTableInfo(relatedTableInfo);
      setItemIndex(tempArray.length - 1);
    } else {
     

      const idx = itemArray.findIndex((el) => el.tableid === tableid && el.id === id);
      if (idx !== -1) {

        const tempArray = [...itemArray.slice(0, idx + 1)];

        setItemArray(tempArray);
        setItemIndex(idx);
        setDataId(tempArray[idx].id);

        //3-19-24 If they navigate back to root record, we automatically do a full refresh
        //as they could have updated a related reocrd whose lookup values show on details page.
        if(tempArray.length===1){
          const id = tempArray[idx].id;
          const updatedRow = await RefreshRow(id,'id',idx)
          updateSelectedRow(idx,updatedRow)
        }

        //3-19-24 need to reset the itemarray both before/after refreshing record to have it appear correctly.
        setItemArray(tempArray);
   
      } 
    }
    setIsLoading(false);
  };

  const updateWindowWidth = async () => {

    // 5-17-2021 Since we are saving percentage of width, when it's calculated, it ends up
    // with float number. Instead of forcing round up, instead I just check if the difference
    // is > 1 . If so, update user settings.
    if(Math.abs(width -divRef.current.offsetWidth) > 1){
      const widthPct = (divRef.current.offsetWidth / window.innerWidth).toFixed(2);
      setWidth(window.innerWidth*widthPct)
      userTabSettings.detailViewWidth=widthPct
      await updateSettingsFromDetailView(userTabSettings)
    }
  }

  const updateTable = async (tableid, fullList) => {
    const fields = fullList.filter((itm) => itm.data !== undefined);
    const sections = fullList.filter((itm) => itm.name !== undefined); // we expect sections to have names but column/fields to not have tihs attribute
    const items = [...itemArray];
    const currentTable = items[itemIndex].tableinfo;

    currentTable.columns.forEach((itm) => {
      const rowindex = fields.findIndex((field) => field.data === itm.data);
      itm.detailviewsort = rowindex;
    });

    currentTable.sections = sections;
    items[itemIndex].tableinfo = currentTable;
    setItemArray(items);
    
    // 3-20 added the check on itemIndex, We only need to update the grid tableinfo on Root table. 
    // if they are on related table and make updates, we don't want to update tableinfo in grid.
    if(itemIndex===0){ 
      updateTableInfo(currentTable);  //added 9-15-2020 we update tableinfo in grid, as next/prev send new tableinfo for each record.
    }  
    await updateTableInfoAPI(tableid, currentTable);
  };

  const updateSectionName = (sectionIndex, value) => {
    const tempChanges = { ...changes };
    tempChanges[sectionIndex] = value;
    setChanges(tempChanges);

  };

  const updateValue = (field, value) => {
    // const tempChanges = { ...changes };
    // tempChanges[field] = value;
    // setChanges(tempChanges);

    //6-10-24 changed to setting value directly, as I was seeing the pdf document viewer 
    //get updated with every keystroke. Not sure if it was causing other components to silently re-redner
    //This seems to work fine, as no UI changes are dependant on the change.

    changes[field]=value

  };


  const submitSectionName = async (sectionNumber) => {
    if (Object.keys(changes).length===0) {
      return;
    }

    const temparray = [...itemArray];
    const tableinfo = temparray[itemIndex].tableinfo;

    //12-30-22 Adding check on Section name to be unique, otherwise causes issues.
    const idx =tableinfo.sections.findIndex(el=>el.name.toLowerCase()===Object.values(changes)[0].toLowerCase());
    if(idx !==-1) {
      toast.info(<div style={{margin:'10px'}}>Please use a different name; this section already exists.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:7000,
      })
      setChanges([]);
      return false
    }

    tableinfo.sections[sectionNumber - 1].name = Object.values(changes)[0];
    delete tableinfo.sections[sectionNumber - 1].isEdit;
   
    const tableid = temparray[itemIndex].tableid;
    
    if(itemIndex===0){ 
      updateTableInfo(tableinfo);  //added 9-15-2020 we update tableinfo in grid, as next/prev send new tableinfo for each record.
    }  

    await updateTableInfoAPI(tableid, tableinfo);
    setItemArray(temparray);
    setChanges([]);
    setIndex(Date.now());
  };

  const deleteSection = async (sectionNumber) => {
    const temparray = [...itemArray];
    const tableinfo = temparray[itemIndex].tableinfo;

    tableinfo.sections.splice(sectionNumber - 1, 1);
    const tableid = temparray[itemIndex].tableid;

    if(itemIndex===0){ 
      updateTableInfo(tableinfo);  
    } 
    
    await updateTableInfoAPI(tableid, tableinfo);
    setItemArray(temparray);
    setIndex(Date.now());
  };

  const addSection = async () => {
    const temparray = [...itemArray];
    // const tableinfo = temparray[itemIndex].tableinfo;
    const tableinfo = JSON.parse(JSON.stringify(temparray[itemIndex].tableinfo));
    const section = {
      name: `New section - ${tableinfo.sections.length + 1}`,
      count: 0,
      display: true,
      collapse: false,
      insertAt: tableinfo.columns.length + tableinfo.sections.length,
      sectionNumber: tableinfo.sections.length,
      isEdit:true
    };

    tableinfo.sections.push(section);

    const tableid = temparray[itemIndex].tableid;
    temparray[itemIndex].tableinfo = tableinfo;
    await updateTableInfoAPI(tableid, tableinfo);

    setItemArray(temparray);
    setIndex(Date.now());
  };


  const changeLayout = async (layout) => {
    setLayout(layout);
    userTabSettings.detailViewLayout=layout
    await updateSettingsFromDetailView(userTabSettings)
  };

  const splitWidthHandler = async () => {
    const secondary = (ref.current.state.secondaryPaneSize/window.innerWidth).toFixed(2)
    userTabSettings.detailViewSecondaryPane=secondary
    await updateSettingsFromDetailView(userTabSettings)
    setSecondaryInitialSize(ref.current.state.secondaryPaneSize)
  };

  //11-2-23 Need to add support for when a web form is used to update data in the record.
  //we want to auto-refresh the grid and the current local recrod.


  const submitChanges = async (refreshOnly=false,fetchedData=false) => {

    /*  11-2-2023 We are adding support for the detail view to use updating via the webforms.
    In this use-case we will want to refresh the row/grid with the latest values. this operation
    takes place outside the regular updating of fields. The form does the logic of updating the data
    and then will trigger this call by passing refreshOnly = true
    */

    /*  1-25-24 added new optional param fetchedData. This is part of new logic in which we
     are returning smaller number of lookup items and now show an Icon to fetch remaininig data. When
     clicked, we call this method, which will remove limit and fetch full record, and we want UI
     to remember this so if they click back on record, it won't show reload icon again. 
     So, from ControlList, we'll be calling submitChanges(true,true) to only refresh record
     and to set fetchedData=true.

    */


    if(refreshOnly && itemArray.length===1) {
 
      const id = itemArray[itemIndex].selectedRow.id;
      const updatedRow = await RefreshRow(id,'id',SelectedRowIndex)
      if(fetchedData) {
        updatedRow.fetchedData=true;
      }
      updateSelectedRow(itemIndex,updatedRow)
      return;
    }

    if (changes.isEmpty) {
      return;
    }

    /**** Following code updates the stored data array so updates are refrectec in UI and base grid ****/
   
     const temparray = [...itemArray];
     const tinfo=itemArray[itemIndex].tableinfo;
     const tableid = itemArray[itemIndex].tableid;
     const id = itemArray[itemIndex].selectedRow.id;

     const result =await UpdateRecord(changes,tinfo,tableid,id);

     // 5-8-2023
     //if root record, we will update grid and update current row.
     const hasRelational = tinfo.columns.findIndex(col=>col.uitype===1 && Object.keys(changes).includes(col.data)) !== -1

     if(itemArray.length===1 && hasRelational) {
      const updatedRow = await RefreshRow(id,'id',SelectedRowIndex)
      updateSelectedRow(itemIndex,updatedRow)
     }

      if(result==='success') {
      Object.keys(changes).map(itm => {
        const idx = tinfo.columns.findIndex(col=>col.data===itm)
        if(idx !==-1) {
          if (tinfo.columns[idx].uitype===1 && changes[itm][0].type===-1){
            temparray[itemIndex].selectedRow[itm] = null;
            //6-10-24 added logic to change selectRow, so changes reflect in grid upon closing detail
            //we only do that if we are in root record.
            if(itemArray.length===1) {
              selectedRow[itm] =null;
            }
          } else {
            temparray[itemIndex].selectedRow[itm] = changes[itm]
            if(itemArray.length===1) {
            selectedRow[itm] = changes[itm]
            }
          }
        }
      })

      setItemArray(temparray);
      setChanges({});
    }

      /****  END CODE ON UPDATING GRID  ******/

    setIndex(Date.now());

  };

  // 5-4-2021 Added this method for the use-case where the use has drilled into/loaded
  // a related reocrd, and then they add/remove a relational record. In current logic
  // we only call refreshrow if we are in ROOT record(which returns us the new row and updates grid)
  //In this use-case we still want to update the record so any lookup valies will 
  //appear but need to call the method directly instead of usign RefreshRow.

  const updateLoadedRecord = async (currentDataItem,currentItemIndex) => {
    // const {dataItem,updateSelectedRow,itemIndex} = this.props;
    const filters = [
        {
          field: 'id',
          type: 'table', // 2 possible values ("table", "relation") indicates if id is relational lookup filter or "table" to search by id on table.
          displayfield: 'id',
          uitype: 100, // used if buildsqlfilter to apply filter on just this id.
          value: currentDataItem.id,
          maxID:0
        },
      ];

    let payload = {
        tableinfo: currentDataItem.tableinfo,
        tableid:  currentDataItem.tableid,
        blockid: blockid,
        limit: 1,
        offset: 0,
        filters: filters,
        sortfield: 'id',
        sortorder: 'asc',
      };

      const tabledata = await cloudrun.post('/ExecuteTableQuery', { payload });
      const shapedData = tabledata.rows;
      
      // 11-30-2021 when changing relation record, we also need to keep the canEdit attribute(which is loaded in block)
      // since they chnaged the regard, we know they can edit, and therefore we can hard-code the canEdit=true.
      shapedData[0].canEdit=true;

      updateSelectedRow(currentItemIndex,shapedData[0])
  }

  const getTopPosition =()=>{
    let top=0;
    if(!isMobileShare && hide==null) top = heightInfo.top;
    if(isMobileShare && hide ===null) top= heightInfo.top + 100;
    if(isMobileShare && hide !==null) top=heightInfo.top+50;
    if(!isMobileShare && hide !==null) top=0;
    return top;
  }


  return (
    <div
      onMouseUp={()=>updateWindowWidth()}
      ref={divRef}
      style={{
        display: 'flex',
        position: 'absolute',
        minWidth: '100px',
        maxWidth: !isMobileShare && hide !==null ?  window.innerWidth * 0.9 : null,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        right: 0,
        top: getTopPosition(),
        bottom: !isMobileShare && hide !==null ? 0 : heightInfo.bottom,
        backgroundColor: 'white',
        resize: !isMobileShare && hide !==null ? 'horizontal' : null,
        width: !isMobileShare && hide !==null ? width :'100%',
        margin: '0px',
        padding: '0px',
        overflow: 'hidden',
        transform: 'rotate(180deg)',
        border: !isMobileShare && hide !==null ? '1px solid black' : null,
        height:'100%', // 12-6-23 added to support Record view. need this for page to properly render.
      }}
    >
      {itemArray.length > 0 && !isLoading  && secondaryInitialSize>0? (
        <div
          style={{
            position:'relative',
            display: 'flex',
            width: '100%',
            height: '90%',
            flexDirection: 'column',
            transform: 'rotate(180deg)',
          }}
        >
          <TopNabar
            color={color}
            hide={!isShare ? closeDetailView : hide}
            updateGrid={updateGrid}
            itemArray={itemArray}
            load={loadRelatedRecord}
            NextPrev={NextPrev}
            changeLayout={changeLayout}
            layout={layout}
            filterCount={filterCount}
            SelectedRowIndex={SelectedRowIndex}
            canEdit={selectedRow.canEdit}
            toggleShowShare={toggleShowShare}
            isShare={isShare}
            isMobileShare={isMobileShare}
            showActionPanel={showActionPanel}
            isEditMode={isEditMode}
            nextPrevBool={nextPrevBool}
          />
          <div
            style={{
              display: 'flex',
              // height: '100%'
            }}
          >
            <SplitterLayout
              ref={ref}
              // ref={component => { component.setState({ secondaryPaneSize: 500 }); }}
              // onSecondaryPaneSizeChange={splitWidthHandler}
              onDragEnd={splitWidthHandler}
              primaryMinSize={150}
              // secondaryMinSize={50}
              // secondaryInitialSize={0}
              secondaryInitialSize={showActionPanel ? secondaryInitialSize : 0} 
              vertical={layout === 'vertical'}
            >
              {!isMobileShare || (isMobileShare && shareControl ==='details') ?(
              <div style={{ display: 'flex',overflow:'hidden' }}>
                <ControlList
                  dataItem={itemArray[itemIndex]}
                  isRoot={itemArray.length===1}
                  blockid={blockid}
                  userInfo={userInfo}
                  role={role}
                  color={color}
                  updateValue={updateValue}
                  updateTable={updateTable}
                  updateSectionName={updateSectionName}
                  submitSectionName={submitSectionName}
                  loadRelatedRecord={loadRelatedRecord}
                  deleteSection={deleteSection}
                  addSection={addSection}
                  submitChanges={submitChanges}
                  index={index}
                  lastUpdated={lastUpdated}
                  setIsEditMode={toggleEditMode}
                  isShare={isShare}
                />
              </div>
              ) : null}

              {!isLoading && !isMobileShare && showActionPanel || (isMobileShare && shareControl !=='details' && showActionPanel) ? (
              <div style={{ display: 'flex', height: '100%',width:'100%' }}>
                <ActionPanel
                  SelectedRowIndex={SelectedRowIndex}
                  RefreshRow={RefreshRow}
                  loadRelatedRecord={loadRelatedRecord}
                  selectedRow={selectedRow}
                  updateSelectedRow={updateSelectedRow}
                  updateLoadedRecord={updateLoadedRecord}
                  setUpdateGrid={setUpdateGrid}
                  isRoot={itemArray.length===1}
                  id={dataId}
                  blockid={blockid}
                  color={color}
                  dataItem={itemArray[itemIndex]}
                  itemIndex={itemIndex}
                  hasMessages={hasMessages}
                  secondaryInitialSize={secondaryInitialSize}
                  userTabSettings={userTabSettings}
                  updateSettingsFromDetailView={updateSettingsFromDetailView}
                  role={role}
                  canMessage={canMessage}
                  canCollaborate={canCollaborate}
                  canViewComments={canViewComments}
                  shareControl={shareControl}
                  isMobileShare={isMobileShare}
                  isShare={isShare}
                  layout={layout}
                />
              </div>
               ): null} 
            </SplitterLayout>
          </div>
        </div>
      ) : null}
      {showShareManager ? (
      <div style={{overflow:'auto',position:'absolute', border:'1px solid #757575',  transform: 'rotate(180deg)',top:50, left:20, height:'600px', width:'500px',zIndex:100,backgroundColor:'white'}}>
      <ShareManager
          role={role}
          tablename=''
          tableinfo={tableinfo}
          tableid={tableid}
          color={color}
          close={toggleShowShare}
          sharedRecordIds={[id]}
          RID={selectedRow.CustomID}
        />
      </div>
      ): null}
    </div>
  );
};

export default DetailView;
