/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Popup } from 'semantic-ui-react';
import { Link } from '@reach/router';
import Modal from 'react-modal';
import Icon from '@mdi/react';
import {
  mdiCheckboxBlankOutline,
  mdiPlus,
  mdiHomeOutline,
  mdiChevronUp,
  mdiChevronDown,
  mdiAlert,
} from '@mdi/js';
import BottomBar from './BottomBar';
import {
  getTablesAPI,
  updateTableOrderAPI,
  updateTableAPI,
  updateTableInfoAPI,
  getTableAPI,
  copyTable,
  checkIfNestedTable
} from '../../../../api/tables';
import {getBlockUserSettings,updateBlockUserSettings,updateDefaultTab} from '../../../../api/blocks'
import Newtab from './NewTab';
import styles from './index.module.css';
import { AccountContext } from '../../../../providers/AccountProvider';
import AddTabButton from './AddTabButton';
import cloudrun from '../../../../clients/httpcloudrun';
import Spinner from '../../../../components/Spinner/Spinner';
import { confirmAlert } from 'react-confirm-alert';
import GBButton from '../../../../components/GBButton/GBButton';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import {plans} from '../../../Zone/plans';
import DupliateTabSetup from './duplicateTabSetup';
import Global from '../../../../clients/global'


Modal.setAppElement(document.getElementById('root'));

class TableControls extends Component {
  trigger = (
    <span>
      <div />
    </span>
  );

  options = [
    {
      key: 'user',
      text: (
        <div>
          <strong>Actions</strong>
        </div>
      ),
      disabled: true,
    },
    { key: 'edit', value: 'edit', text: 'Edit tab properties', icon: 'edit' },
    { key: 'copy', value: 'copy', text: 'Duplicate tab', icon: 'copy' },
    { key: 'moveup', value: 'moveup', text: 'Move tab up', icon: 'arrow up' },
    { key: 'movedown', value: 'movedown', text: 'Move tab down', icon: 'arrow down' },
    { key: 'delete', value: 'delete', text: 'Delete tab', icon: 'delete' },
    
  ];

  static contextType = AccountContext;

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      tables: [],
      modalIsOpen: false,
      hoveringKey: 0,
      selectedKey: 0,
      showMore: false,
      isLoading: false,
      isDone: false,
      height: window.innerHeight * 0.8,
      tabMode: 'new',
      currentTabname: '',
      currentIcon: '',
      currentRecordname: 'Record',
      tableinfo: null,
      settings:null,
      modalControl:'showTabSetup',
      isSubTable: false
    };
  }

  async componentDidMount() {
    const { blockid,role } = this.props;
    const { userInfo } = this.context;
    await this.GetTables(blockid);
  }

  async componentDidUpdate(prevProps) {
    const { RELOAD,role } = this.props;
    if (this.props.lastBlockEdited !== prevProps.lastBlockEdited) {
      await RELOAD('home', 'home', '', 'home');
      this.setState({ selectedKey: 'home' });
    }
  }

  componentWillUnmount() {
    // window.removeEventListener("resize", this.getHeight.bind(this));
  }

  getHeight = () => {
    // this.setState({ height: window.innerHeight*.8})
  };

  AnimatedLoadingDelay = (modalIsOpen) => {
    this.setState({ isDone: true });
    setTimeout(() => {
      this.setState({ isLoading: false, modalIsOpen });
    }, 1000);
  };

  confirmAction = (title,message,newaction,actionvalue) => {
    
    const {tables}= this.state;
    const idx = tables.findIndex(itm=>itm.key===actionvalue)
    let name=''
    if(idx !==-1){
      name = tables[idx].text
    }

   const combomessage= <div>You have selected to delete the tab: {name}.<p/><p/>{message}</div>

    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={title}
            action={newaction !=='' ? newaction : onClose}
            actionValue={newaction !=='' ? actionvalue : ''}
            buttonLabel="OK"
            showCancelButton={newaction !==''}
            message={combomessage}
            divRef={this.input}
            confirmAction=""
            confirmWord="delete"
            showInput={newaction !==''}
            height="400px"
            onClose={onClose}
          />
        );
      },
    });
  };

  arraymove = async (arr, fromIndex, toIndex) => {
    const element = arr[fromIndex];
    const element2 = arr[toIndex];

    if(toIndex  < arr.length && toIndex>0) {
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);


    const tblchange = { tbl1: element.key, tbl2: element2.key, from: fromIndex, to: toIndex };
    // eslint-disable-next-line no-console
    this.setState({ tables: arr });
    await updateTableOrderAPI(tblchange);
    }
  };

  GetTables = async (blockid) => {
    const { RELOAD,role } = this.props;
    const settings = await getBlockUserSettings(blockid)
    
    let defaultTab=null;
    if(settings !==null && settings.defaultTab !==undefined) {
      defaultTab = settings.defaultTab
    }

    let tables = await getTablesAPI(blockid,role);

    const homeIcon = { key: 'home', value: 'home', text: 'Home', gbicon: mdiHomeOutline };
    if (tables !== null) {
      tables.unshift(homeIcon);
      const { showMore } = this.state;
      if (tables.length > 6) {
        const expandIcon = {
          key: 2000,
          value: 1000,
          text: showMore ? 'Less' : 'More',
          gbicon: showMore ? mdiChevronUp : mdiChevronDown,
        };
        tables.splice(6, 0, expandIcon);
      }
    } else {
      tables = [];
      tables.push(homeIcon);
    }

    let selectedTab = defaultTab===null ? 'home' : defaultTab;
    const idx = tables.findIndex(itm=>itm.key===defaultTab)
    if(defaultTab !==null && defaultTab !==undefined && defaultTab !==2000)
    {
      let tab=0;
      if(idx !==-1){
        tab = tables[idx];
      } else {
        tab= tables[0];
      }
      await RELOAD(tab.value, tab.text, tab.gbicon, tab.recordname);
    }

    this.setState({ tables, selectedKey: selectedTab,settings,showMore:idx>6 });
  };

  LoadTable = async (tableid, action = 'standard') => {
    let { selectedKey, tables, showMore,settings } = this.state;
    const { RELOAD,blockid } = this.props;

    this.setState({isLoading:true})

    // We reset the defaultTab on every table selection and save this to the DB.
    if(settings !==null){
      settings.defaultTab=tableid
    } else {
      settings = {defaultTab:tableid}
    }

    // update the default tab each time they select a tab to view, excep if it's the more/less button.
    if(tableid !==2000) {

      // await updateBlockUserSettings(blockid,settings)
      try{
      await updateDefaultTab(blockid,tableid)
      } catch(error) {
        console.log(error)
      }
    }

    // When they click on the tab to get list of options, don't reload table.
    if (tableid === selectedKey && action === 'standard') {
      return;
    }

    // the "more/less" Icon is inserted into table list. It has a value of 2000
    // When clicked, we either show full list, or collapse to top 5.
    if (tableid === 2000) {
      const rowindex = tables.findIndex((el) => el.key === 2000);
      if (rowindex !== -1) {
        tables[rowindex].text = !showMore ? 'Less' : 'More';
        tables[rowindex].gbicon = !showMore ? mdiChevronUp : mdiChevronDown;
      }

      this.setState({ showMore: !showMore, tables });
    } else {
      const rowindex = tables.findIndex((el) => el.key === tableid);
      if (rowindex !== -1) {
        const tbl = tables[rowindex];
        await RELOAD(tableid, tbl.text, tbl.gbicon, tbl.recordname);
        this.setState({ selectedKey: tableid});
      }
    }

    //4-4-23 added this 1/2 s delay to fix issue of quick clicking tabs that broke. This
    //slight delay seems to fix it.
    setTimeout(() => {
      this.setState({ isLoading: false }); 
    }, 500); // Delay of 2000 milliseconds (2 seconds)

  };

  // updateTabName = async (name) => {
  //   await this.updateTable(this.state.selectedKey,name,this.state.currentIcon,this.state.currentRecordname)
  //   this.setState({currentTabname: name})
  // }

  // updateTabIcon = async (icon) => {
  //   await this.updateTable(this.state.selectedKey,this.state.currentTabname,icon,this.state.currentRecordname)
  //   this.setState({currentIcon: icon})
  // }

  // updateRecordName = async (record) => {
  //   await this.updateTable(this.state.selectedKey,this.state.currentTabname,this.state.currentIcon,record)
  //   this.setState({currentRecordname: record})
  // }

  addTable = async (name, icon) => {
    const { showMore } = this.state;
    this.setState({ isLoading: true, modalIsOpen: true, isDone: false,modalControl:'showTabSetup' });
    const { blockid, RELOAD,role } = this.props;
    name='New tab'
    const payload = { name, icon, blockid };
    const result = await cloudrun.post('/addTable', { payload });
    const newtableid = result.tableid;
    const finalTabName= result.finalTabName;

    const tables = await getTablesAPI(blockid,role);
    const tblinfo = await getTableAPI(newtableid);
    const homeIcon = { key: 'home', value: 'home', text: 'Home', gbicon: mdiHomeOutline };

    tables.unshift(homeIcon);

    if (tables.length > 6) {
      const expandIcon = {
        key: 2000,
        value: 1000,
        text: 'Less',
        gbicon: mdiChevronUp,
      };
      tables.splice(6, 0, expandIcon);
    }

    this.setState(
      {
        tables,
        selectedKey: parseInt(newtableid),
        tableinfo: tblinfo.tableinfo,
        showMore: true,
        currentTabname: finalTabName,
        currentRecordname: 'Record',
        tabMode: 'add',
        currentIcon: mdiCheckboxBlankOutline,
        isSubTable: false
        // isLoading:false,
      },
      async () => {
        await this.LoadTable(parseInt(newtableid), 'new');
      },
    );

    this.AnimatedLoadingDelay(true);
  };

  copyTableInternal = async (tableid, tabName,includeData,includeBookmarks,includeForms) => {
    const { blockid,role,ShowError} = this.props;
    this.setState({ isLoading: true,modalIsOpen:true });
    const result = await copyTable(blockid,tableid,tabName,includeData,includeBookmarks,includeForms);

    if(result !=='error') {
        const tables = await getTablesAPI(blockid,role);
        const homeIcon = { key: 'home', value: 'home', text: 'Home', gbicon: mdiHomeOutline };

        tables.unshift(homeIcon);

        if (tables.length > 6) {
          const expandIcon = {
            key: 2000,
            value: 1000,
            text: 'Less',
            gbicon: mdiChevronUp,
          };
          tables.splice(6, 0, expandIcon);
        }

        this.setState(
          {
            tables,
          }
        );
        this.AnimatedLoadingDelay(false);
    } else {
      this.setState({ isLoading: false ,modalIsOpen:false });
      ShowError(<div style={{margin:'10px'}}>An error occurred duplicating table. Please notify support.</div>) 
    }
  }

  showDuplicateTabSetup = (key) =>{
    const { tables } = this.state;
    const rowindex = tables.findIndex((el) => el.value == key);
    const tab = tables[rowindex].text;
    this.setState({modalControl:'duplicateTab', modalIsOpen:true,selectedKey:key,currentTabname:tab})
  }

  //  5-10-22 Added the isCsv param to deal with use-case where user is uploading CSV. In this case, the 
  // server is already updating the tableinfo, so we don't want to change it again here.
  updateTable = async (tableid, name, icon, record,isFieldChanged,isSecurityChanged, tableinfo,isCsv=false,isSubTable) => {
    const { tables } = this.state;
    const { RELOAD } = this.props;
    const rowindex = [...tables].findIndex((el) => el.value === tableid);
    tables[rowindex].text = name;
    tables[rowindex].gbicon = icon;
    tables[rowindex].recordname = record;
    let viewRole=0;
    const payload = { tableid, tableinfo };

    if(tableinfo !==null && tableinfo.security !==undefined){
      viewRole=tableinfo.security.myRecords;
    }

    await updateTableAPI(tableid, name, icon, record,viewRole,isSubTable);

    if(isSecurityChanged) {
      await cloudrun.post('/updateTableSecurity',{payload})
    }

    // this.setState({ tables, modalIsOpen: false, isLoading: false });

    // 11-16-2021 , updated logic to specifically pass back if relational identifieer is changed
    // versus older logic which checked if tableinfo was no null.
    if (isFieldChanged) {
      const newtableid = await cloudrun.post('/updateCustomIdView', { payload });
    }
    
    if((isFieldChanged || isSecurityChanged) && !isCsv) {
      await updateTableInfoAPI(tableid, tableinfo);
    }

    await RELOAD(tableid, name, icon, record);
    this.setState(tables);
  };

  // 9-22-202 When adding a new "template" table such as Messaging Template, the node.js fnction
  // does all the logic, but we then need to update local state and call RELOAD so new columns appear.
  updateTemplateTable = async (tableid, name, icon, record) => {
    const { RELOAD } = this.props;
    const { tables } = this.state;
    const rowindex = [...tables].findIndex((el) => el.value === tableid);
    tables[rowindex].text = name;
    tables[rowindex].gbicon = icon;
    tables[rowindex].recordname = record;

    await RELOAD(tableid, name, icon, record);
  };

  PageResults = (offset) => {
    const { NextPage } = this.props;
    NextPage(offset);
  };

  showUpgradeMessage = (role) => {
    const {ShowError} = this.props;
    if (role === 'Zone owner') {
      ShowError(
        <div style={{ margin: '5px' }}>
          You have reached the max number of tabs for this plan. Upgrade now by clicking the option in
          the top right corner of the {` `}
          <Link to="/" style={{color:'white'}}><u><b>Zone dashboard</b></u></Link>
        </div>,
      );
    } else {
      ShowError(
        <div style={{ margin: '5px' }}>
          You have reached the max number of tabs for this plan, consider upgrading. Contact your Zone
          owner.
        </div>,
      );
    }
  }

  TabAction = async (action, key, index) => {
    const { tables } = this.state;

    switch (action) {
      case 'delete':
        const message =<div>
        Are you sure you want to delete this tab? Any data associated with the tab will be
        abolished. </div>
        this.confirmAction('Delete tab',message,this.deleteTable,key)
        break;
      case 'moveup':
        this.arraymove(tables, index, index - 1);
        break;
      case 'movedown':
        this.arraymove(tables, index, index + 1);
        break;
      case 'edit':
        const tbl = tables.filter((el) => el.value === key);
        const tbldata = await getTableAPI(key);
        this.setState({
          modalControl:'showTabSetup',
          currentTabname: tbl[0].text,
          currentRecordname: tbl[0].recordname,
          tabMode: 'edit',
          currentIcon: tbl[0].gbicon,
          modalIsOpen: true,
          isLoading: false,
          tableinfo: tbldata.tableinfo,
          isSubTable: tbldata.isSubTable
        });
        break;
      case 'copy' :
        this.showDuplicateTabSetup(key)
      // const { blockid} = this.props;
        // await this.copyTableInternal(key)
        break;
      default:
    }
  };

  displayTables = () => {
    let tablist = [];
    const { hoveringKey, selectedKey, tables } = this.state;
    const { color, isExpanded,role } = this.props;
    // eslint-disable-next-line
    {
      this.state.showMore
        ? (tablist = this.state.tables)
        : (tablist = this.state.tables.slice(0, 7));
    }

    return tablist.map((el, index) => (
      <div key={el.key} className={styles.noSelect} title={!isExpanded ? el.text : ''}>
        {hoveringKey === el.key || selectedKey === el.key ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onMouseOver={() => this.setState({ hoveringKey: el.key })}
            onMouseOut={() => this.setState({ hoveringKey: null })}
            style={{
              userSelect: 'none', 
              display: 'flex',
              justifyContent: selectedKey === el.key && isExpanded ? 'space-between' : null,
              color: selectedKey === el.key ? 'white' : null,
              backgroundColor: selectedKey === el.key ? color : `${color}1A`,
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
              alignItems: 'center',
              padding: '5px',
              margin:'5px',
              cursor: 'pointer',
            }}
            onClick={(e) => {
              !this.state.isLoading ? this.LoadTable(el.key, e) : null;
            }}
            onFocus={() => this.setState({ hoveringKey: el.key })}
            onBlur={() => this.setState({ hoveringKey: null })}
            onKeyPress={async () => { await this.LoadTable(el.key);} }
            // role="button"  - comment out as shows blue border. Should fix for accessibility in future.
            // tabIndex="-4"
          >
            {selectedKey === el.key ? (
              <div style={{ display: 'flex', alignItems: 'center', userSelect: 'none' }}>
                <Icon
                  style={{ marginRight: '10px' }}
                  path={el.gbicon}
                  size="25px"
                  color={selectedKey === el.key ? 'white' : null}
                />
                {isExpanded ? el.text : null}
              </div>
            ) : (
              <>
                <Icon
                  style={{ marginRight: '10px' }}
                  path={el.gbicon}
                  size="25px"
                  color={selectedKey === el.key ? 'white' : null}
                />
                {isExpanded ? el.text : null}
              </>
            )}
            {selectedKey === el.key && el.key !=='home' && role===3 ? (
              <div
                style={{
                  position: 'absolute',
                  left: isExpanded ? '200px' : '30px',
                  zIndex: '600px',
                  marginRight: '5px',
                }}
              >
                <Dropdown
                  direction={isExpanded ? 'left' : 'right'}
                  closeOnChange={false}
                  upward={index > 7}
                  onClick={!isExpanded ? () => this.props.toggleExpand() : null}
                  selectOnBlur={false}
                  value=""
                  onChange={(e, data) => this.TabAction(data.value, el.key, index)}
                  trigger={this.trigger}
                  options={
                    !el.isSystemTable
                      ? this.options
                      : this.options.filter((itm) => itm.key !== 'delete' && itm.key !== 'edit')
                  }
                />
              </div>
            ) : null}
          </div>
        ) : (
          <div
            onMouseOver={() => this.setState({ hoveringKey: el.key })}
            onMouseOut={() => this.setState({ hoveringKey: null })}
            style={{
              display: 'flex',
              color,
              alignItems: 'center',
              padding: '5px',
              margin: '5px',
              cursor: 'pointer',
            }}
            onClick={() => {
              this.LoadTable(el.key);
            }}
            onFocus={() => this.setState({ hoveringKey: el.key })}
            onBlur={() => this.setState({ hoveringKey: null })}
            onKeyPress={() => {
              this.LoadTable(el.key);
            }}
            role="button"
            tabIndex="-1"
          >
            <Icon style={{ marginRight: '10px' }} path={el.gbicon} size="25px" color={color} />
            {isExpanded ? el.text : null}
          </div>
        )}
      </div>
    ));
  };

  

  deleteTable = async (tableid) => {
    const { userInfo} = this.context;
    const {ShowError} = this.props;

    if (this.input.current && this.input.current.value !== 'delete') {
      return false;
    }

    //8-4-2022 if pro/enterpise plan, check if table is referenced as nested table. We don't allow a table to be deleted in this case.
    if(userInfo.plan==='Pro' || userInfo.plan==='Enterprise') {
      const nestedFields= await checkIfNestedTable(tableid);
      if(nestedFields.length >0) {
        let fields=
        nestedFields.map(el=>(
          <ul>{el.name}: {el.header}</ul>
        ))
  
        ShowError(<div style={{margin:'10px'}}>This tab is used in a relational nested fields. It can not be deleted until you remove it from the nested fields in the following relational field(s):<p>{fields}</p></div>)
        // toast.info(<div style={{marginRight:'10px'}}>This tab is used in a relational nested fields. It can not be deleted until you remove it from the nested fields in the following relational field(s):<p>{fields}</p></div>, {
        //   position: toast.POSITION.BOTTOM_CENTER,
        //   autoClose: false,
        // });
        return false;
      }
    }

    const { RELOAD,blockid } = this.props;
    let {settings}=this.state;
    if(settings !==null) {
      settings.defaultTab='home'
    } else {
      settings={defaultTab:'home'}
    }

     // update the default tab each time they select a tab to view.
    //  await updateBlockUserSettings(blockid,settings)
     await updateDefaultTab(blockid,'home')

    this.setState({ modalIsOpen: true, isLoading: true, isDone: false,settings });

    // const result = await removeTableAdmin(schema, zoneid, tableid);
    const payload = { tableid,blockid };
    const result = await cloudrun.post('/removeTable', { payload });

    if (result !== 'success') {
      let tables = '';
      result.forEach((el, i) => {
        tables += `${el.name}, `;
      });
      const message =<div>
        This table is referenced by following tables: {tables} You must remove all references to related tables or within a messaging / email field before you can delete this table.
      </div>

      this.confirmAction('Existing relationships exist', message,'',tableid)
     
    } else if (result === 'success') {
      // eslint-disable-next-line no-console
      const tables = await this.GetTables(blockid);
      await this.LoadTable('home'); // After deleting table, load "home" for block
      this.setState({selectedKey:'home'})
      await RELOAD('home', 'home', '', 'home');
    } 
    // this.setState({ modalIsOpen: false, isLoading: false });
     this.AnimatedLoadingDelay(false);
  };

  closeModal = async () => {
    this.setState({ modalIsOpen: false });
    // this.hotTableComponent.current.hotInstance.loadData(this.state.tabledata);
  };

  NavigateToZones = () => {
    const { history } = this.props;
    history.push('/');
  };

  ToggleShowMore = (toggle) => {
    this.setState({ showMore: toggle });
  };

  render() {
    const {
      modalIsOpen,
      isLoading,
      isDone,
      height,
      tabMode,
      selectedKey,
      currentTabname,
      currentRecordname,
      currentIcon,
      tableinfo,
      tables,
      modalControl,
      isSubTable
    } = this.state;
    const { color, isExpanded, toggleExpand, issystemblock,role,ShowError, blockid } = this.props;
    const { userInfo} = this.context;
    const zoneInfo = userInfo.zones.filter(el=>el.id===parseInt(Global.zoneid))[0];
    const paddingTableCount = tables.length > 6 ? 2 : 1 // added 12-2-2020 used to add appropripate table count (from 'home' and 'less/more' table options in list) to plan defined numbers so they get full use of the plans defined tabs.
    const maxTabs = zoneInfo !==undefined  ? plans.filter(itm=>itm.type===zoneInfo.plan)[0].tabs+paddingTableCount : 0; // used to determine if they can add more tabs based on their plans
    return (
      <div
        style={{
          transition: 'width 250ms linear 0s',
          marginLeft: '10px',
          width: isExpanded ? '250px' : '70px',
          height: 'calc(100vh - 40px)', // 65
          marginBottom: '0px',
          // minHeight: 'calc(100vh - 65px)',
          borderStyle: 'solid',
          borderColor: 'rgba(0, 0, 0, .25)',
          // border:'2px solid black',
          borderRightWidth: '1px',
          borderLeftWidth: '0px',
          borderTopWidth: '0px',
          borderBottomWidth: '0px',
          justifyContent: 'space-between',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <div style={{ height: '100%', overflowY: 'auto' }}>
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                position: 'fixed',
                width: 620,
                height: modalControl==='showTabSetup' ?  'calc(100vh - 100px)' : '350px',
                top: '60px',
                left: '260px',
                // right: '140px',
                // bottom: '140px',
                border: `.3px solid ${color}`,
                background: '#fff',
                overflow: 'auto',
                zIndex: '5010',
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '0px',
                // boxShadow: '0px 3px 6px #707070',
              },
            }}
            contentLabel="Example Modal"
          >
            {isLoading ? (
              <Spinner isLoading={isLoading} isDone={isDone} color={color} />
            ) : modalControl==='showTabSetup' ? (
              <Newtab
              updateTable={this.updateTable}
              updateTemplateTable={this.updateTemplateTable}
              close={this.closeModal}
              blockid={blockid}
              color={color}
              tabMode={tabMode}
              tableid={selectedKey}
              tabname={currentTabname}
              tabrecord={currentRecordname}
              tabicon={currentIcon}
              tableinfo={tableinfo}
              plan={zoneInfo.plan}
              ShowError={ShowError}
              isSubTable={isSubTable}
            />
            ) : 
            (
              <DupliateTabSetup duplicate={this.copyTableInternal}  blockid={blockid} tableid={selectedKey}  currentTabName={currentTabname}  close={this.closeModal} color={color} />
            )
            }
          </Modal>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              // overflowX:'hidden',
              position: 'relative',
              // border: '1px solid blue',
            }}
          >
            {this.displayTables(false)}
          </div>
          {!issystemblock && role===3? (
            <AddTabButton isExpanded={isExpanded} Action={tables.length < maxTabs ? this.addTable : ()=>this.showUpgradeMessage(zoneInfo.role)} color={color} />
          ) : null}
        </div>
        <BottomBar isExpanded={isExpanded} toggleExpand={toggleExpand} color={color} />
      </div>
    );
  }
}

TableControls.propTypes = {
  blockid: PropTypes.number.isRequired,
  RELOAD: PropTypes.func.isRequired,
  NextPage: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
};

export default TableControls;
