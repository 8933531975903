/* eslint-disable */
import React from 'react';

const stringFunctions =[
    {field: 'CALCULATE_AND_REPLACE',tooltip:{fn:'CALCULATE_AND_REPLACE(string)', text:<div>Custom GraceBlocks function which takes a string containing pipe delimited math equation and calculates the number within the pipe delimter. Usefull when combining lookup field values and needing to dynamically calculate math within a string.</div>, example:'CALCULATE_AND_REPLACE("You have |2 * 4| cups of water and |2 * 2| bags of chips.")', result:'=> You have 8 cups of water and 4 bags of chips'}},
    {field: 'CONCAT',tooltip:{fn:'CONCAT(string1, string2, ...stringn)',text:'Concatenate two or more strings into one',example:'CONCAT({First Name},\' \',{Last Name})', result:'=> John Smith' }},
    {field: 'CONCAT_WS',tooltip:{fn:'CONCAT_WS(separator, str_1, str_2, ..., str_n)', text:<div>Concatenates multiple strings with a specified delimiter.<div>separator: The delimiter to use for concatenation. It can be a string or a column name.</div></div>, example:'CONCAT_WS(\' \', \'John\', \'Doe\', \'Smith\')', result:'=> John Doe Smith'}},
    {field: 'REGEX_REPLACE',tooltip:{fn:'REGEXP_REPLACE(source, pattern, replacement_string,[, flags])',text:'Replace substrings that match a POSIX regular expression with a new substring',example:'REGEX_REPLACE(\'(123) 456-7890\', \'[^0-9]+\', \'\', \'g\')',result:'=> 1234567890'}},
    {field: 'COALESCE',tooltip:{fn:'COALESCE(value [, ...])', text:'This function evaluates multiple arguments and will return the first non-null string it finds. If all arguments are null, a null value is returned', example:'COALESCE ({Preferred Name},{First Name})',result:'=> Peggy (In an example where {First Name} = Margaret and the {Preferred Name} is Peggy, Since the preferred name is populated and not null, the value of Peggy is returned.)'}},
    {field: 'LOWER',tooltip:{fn:'LOWER(string)', text:'Convert a string to lowercase', example:'LOWER(‘hI tHERE’)', result:'=> hi there'}},
    {field: 'UPPER',tooltip:{fn:'UPPER(string)', text:'Convert a string to uppercase', example:'UPPER(‘hI tHERE’)', result:'=> HI THERE'}},
    {field: 'OVERLAY',tooltip:{fn:'OVERLAY(string placing replacement from bigint [, for bigint])', text:'Used to replace a portion of a string with another string. It takes three arguments: the source string, the replacement string, and the position at which the replacement should start. The function then returns the modified string with the specified portion replaced', example:'overlay(\'w3333333rce\' placing \'resou\' from 3)', result:'=> w3resou3rce'}},
    {field: 'SUBSTRING',tooltip:{fn:'SUBSTRING(string, from int [, length])',text:'Extract a substring from a string',example:'SUBSTRING(\'ABC\', 1, 1)', result:'=> A'}},
    {field: 'LEFT',tooltip:{fn:'LEFT(string, length)', text: 'Extracts a substring from the beginning (left-hand side) of a given string', example:'LEFT(\'example\', 3)', result:'=> exa'}},
    {field: 'RIGHT',tooltip:{fn:'RIGHT(string, length)', text: 'Extracts a substring from the end (right-hand side) of a given string', example:'RIGHT(\'example\', 3)', result:'=> ple'}},
    {field: 'REPLACE',tooltip:{fn:'REPLACE(string, from_substring, to_substring)',text:'Replace all occurrences of a substring within a given string with a new substring', example:'REPLACE(\'ABC\', \'B\', \'A\')', result:'=> AAC'}},
    {field: 'FIND_STRING',tooltip:{fn:'FIND_STRING(searchString, SourceString,[startposition])',text:'Finds an occurrence of searchString in sourceString starting from an optional startPosition.(startPosition is 0 by default.) If no occurrence of searchString is found, the result will be 0', example:'FIND_STRING("World", "This is The World")', result:'=> 13'}},
    {field: 'TRIM',tooltip:{fn:'TRIM([BOTH | LEADING | TRAILING] [characters FROM] string)', text:'Used to remove the specified characters either from the beginning or end of a string', example:'TRIM(\'   hello   \')', result:'=> hello'}},
    {field: 'ASCII',tooltip:{fn:'ASCII(string)', text:'Returns the ASCII code of the first character of a string', example:'ASCII(\'A\')',result:'=> 65'}},
    {field: 'INITCAP',tooltip:{fn:'INITCAP(string)', text:'Used to capitalize the first letter of each word in a given string', example:'INITCAP(\'hello world\')', result:'=>Hello World'}},
    {field: 'LENGTH',tooltip:{fn:'LENGTH(string)', text:'Return the number of characters in a string', example:'length(\'Hello world\')', result:'=> 11'}},
    {field: 'LPAD',tooltip:{fn:'LPAD(string, length, fill)', text:'Left-pads a string with a specified character or set of characters until the resulting string reaches a specified length', example:'LPAD(\'hello\', 10, \'*\')', result:'=> ****hello'}},
    {field: 'LTRIM',tooltip:{fn:'LTRIM(string[, characters])', text:'Used to remove any characters from the beginning of a string.[Characters] specifies the characters to be removed from the beginning of the string. If not specified, whitespace characters are removed',example:'LTRIM(\'   hello   \')', result:'=> \'hello   \''}},
    {field: "QUOTE_IDENT",tooltip:'QUOTE_IDENT(string)', text:'Wraps the string in double quotes', example:'QUOTE_IDENT(\'hello world\')', result:'=> "hello world"'},
    {field: 'REPEAT',tooltip:{fn:'REPEAT(string, number_of_repeats', text:'Repeat string the specified number of times',example:'REPEAT(\'*\', 5)', result:'=> *****'}},
    {field: 'RPAD',tooltip:{fn:'RPAD(string, length, fill)', text:'Right-pads a string with a specified character or set of characters until the resulting string reaches a specified length', example:'RPAD(\'hello\', 10, \'*\')', result:'=> hello****'}},
    {field: 'RTRIM',tooltip:{fn:'RTRIM(string[, characters])', text:'Used to remove any characters from the end of a string.[Characters] specifies the characters to be removed from the end of the string. If not specified, whitespace characters are removed',example:'RTRIM(\'   hello   \')', result:'=> \'     hello\''}},
    {field: 'SPLIT_PART',tooltip:{fn:'SPLIT_PART(string, delimiter, field)',text:'Used to split a string into an array based on a delimiter and then return a specific element of that array.', example:'SPLIT_PART(\'John Doe,123 Main St,Anytown,USA\', \',\', 3)', result:'=> Anytown'}},
    {field: 'TRANSLATE',tooltip:{fn:'TRANSLATE(string, from, to)', text:'Replaces any occurrence of a set of characters in a string with a corresponding set of characters in another string', example:'TRANSLATE(\'hello world\', \'od\', \'xy\')', result:'=> hellx wxrly'}},
    {field: 'REVERSE',tooltip:{fn:'REVERSE(string)', text:'Returns a string with the order of characters reversed', example:'REVERSE(\'hello world\')', result:'=> dlrow olleh'}},
    {field: 'GENERATE_FORM_ID',tooltip:{fn:'GENERATE_FORM_ID(numeric)', text:'Used to generate an encrypted id value from system id value. Can be used with a GraceBlocks Form URL to pre-fill form values and update existing record. ', example:'GENERATE_FORM_ID(178)', result:'=> MTQzNDY4'}},
    {field: 'CREATE_RECORD_TOKEN',tooltip:{fn:'CREATE_RECORD_TOKEN(numeric zoneid,numeric blockid, numeric tableid, numeric systemid)', text:'Used to create unique record token to be used for deep linking to a GraceBlocks record. A record URL is in the form of https://my.graceblocks.com/record/<token> ', example:'CREATE_RECORD_TOKEN(101,764,453,23)', result:'=> NjYtOTc1LTM=_101'}},
]

const mathFunctions=[
    {field: 'ABS',tooltip:{fn:'ABS(numeric)', text:'Returns the absolute (positive) value of a number. If the number is already positive, it returns the same number. If the number is negative, it returns the positive equivalent',example:'ABS(-10.5)', result:'=> 10.5'}},
    {field: 'CBRT',tooltip:{fn:'CBRT(numeric)', text:'Calculates the cube root of a given numeric value', example:'CRBT(27)', result:'=> 3'}},
    {field: 'SCALE',tooltip:{fn:'SCALE(numeric)', text:'Return the number of decimal digits in the fractional part', example:'SCALE(2.375)', result:'=> 3'}},
    {field: 'CEIL',tooltip:{fn:'CEIL(numeric)', text:'Returns the smallest integer value greater than or equal to the argument', example:'CEIL(4.2)', result:'=> 5'}},
    {field: 'DEGREES',tooltip:{fn:'DEGREES(numeric)', text:'Used to convert a value in radians to its equivalent value in degrees',example:'DEGREES(pi())', result:'=> 180'}},
    {field: 'DIV',tooltip:{fn:'DIV(numeric, numeric)', text:'Return the integer quotient of two numeric values', example:'DIV(8,3)', result:'=> 2'}},
    {field: 'EXP',tooltip:{fn:'EXP(numeric)', text:'Return the exponential value in the scientific notation of a number', example:'EXP(1)', result:'=> 2.7182818'}},
    {field: 'LN',tooltip:{fn:'LN(numeric)', text:'Return the natural logarithm of a numeric value', example:'LN(3)', result:'=> 1.0986123'}},
    {field: 'LOG',tooltip:{fn:'LOG(numeric)', text:'Return the base 10 logarithms of a numeric value', example:'LOG(1000)', result:'=> 3'}},
    {field: 'MOD',tooltip:{fn:'MOD(numeric, numeric)', text:'Divide the first parameter by the second one and return the remainder', example:'MOD(10, 4)', result:'=> 2'}},
    {field: 'PI',tooltip:{fn:'PI()', text:'Return the value of PI', example:'PI()', result:'=> 3.1415927'}},
    {field: 'POWER',tooltip:{fn:'POWER(numeric, numeric)', text:'Raise a numeric value to the power of a second numeric value', example:'POWER(5, 3)', result:'=> 125'}},
    {field: 'ROUND',tooltip:{fn:'ROUND(numeric)', text:'Round a number to the nearest integer or to specified decimal places', example:'ROUND(10.3)', result:'=> 10'}},
    {field: 'SIGN',tooltip:{fn:'SIGN(numeric)', text:'Returns the sign of a number. It returns 1 if the number is positive, -1 if the number is negative, and 0 if the number is zero', example:'SIGN(-10)', result:'=> -1'}},
    {field: 'SQRT',tooltip:{fn:'SSQRT(numeric)', text:'Return the square root of a numeric value', example:'SQRT(3)', result:'=> 1.7320508'}},
    {field: 'RANDOM',tooltip:{fn:'RANDOM()', text:'Generates a random number between 0 and 1.Note: the output result of this function will be randomly different for each row in the tab.', example:'RANDOM()', result:'=> .39'}},
    {field: 'WIDTH_BUCKET',tooltip:{fn:'WIDTH_BUCKET(target, start, end, num_buckets)', text:'Returns the bucket number to which a given value belongs within a range of values with a specified number of buckets', example:'WIDTH_BUCKET(3.5, 1, 10, 5)', result:'=> 2 (indicating that 3.5 belongs to the second bucket (which includes values from 3.0000001 to 4.9999999))'}},
    {field: 'TRUNC',tooltip:{fn:'TRUNC(value, precision)', text:'Truncate a numeric value to a whole number of the specified decimal places', example:'TRUNC(3.14159265, 2)', result:'=> 3.14'}},
    {field: 'FLOOR',tooltip:{fn:'FLOOR(value)', text:'Returns the largest integer that is less than or equal to a specified numeric value', example:'FLOOR(10.6)', result:'=> 10'}}
]

const dateFunctions=[
    {field: 'DATEDIFF',tooltip:{fn:'DATEDIFF(startDate, endDate, interval)', text:'Returns the difference between datetimes in specified units. The difference between datetimes is determined by subtracting [endDate] from [startDate]. This means that if [startDate] is later than [endDate], the resulting value will be negative.', example:'DATEDIFF(\'04/05/2019 11:00\',\'04/06/2019 12:00\', \'hours\')', result:'=> 25'}},
    {field: 'DATEADD',tooltip:{fn:'DATEADD(dateTime, unit, interval)', text:'Adds specified "count" units to a datetime. Valid intervals [seconds, minutes, hours, days, weeks, months, years]', example:'DATEADD(\'04/05/2019 11:00\',2, \'days\'', result:'2019-04-07 11:00:00-04'}},
    {field: 'CURRENT_DATE',tooltip:{fn:'CURRENT_DATE', text:'Returns the current date', example:'CURRENT_DATE', result:'=> 2023-05-02'}},
    {field: 'NOW',tooltip:{fn:'NOW()', text:'Returns the current date and time', example:'NOW()', result:'=> 2023-05-02 09:58:49.879692-04'}},
    {field: 'CURRENT_TIME',tooltip:{fn:'CURRENT_TIME', text:'Returns the current time', example:'CURRENT_TIME', result:'=> 09:59:17.286027-04:00'}},
    {field: 'DATE_PART',tooltip:{fn:'DATE_PART(field,source)', text:'Extracts a specific part of a date/time value such as year, month, day, hour, minute, second, etc', example:'DATE_PART(\'year\', \'2022-06-01\'::date)', result:'=> 2022'}},
    {field: 'DATE_TRUNC',tooltip:{fn:'DATE_TRUNC(precision, timestamp)',text:'Used to truncate a timestamp or interval value to a specified precision.', example:'DATE_TRUNC(\'month\', \'2022-03-15 12:30:00\'::timestamp)', result:'=> 2022-03-01 00:00:00'}},
    {field: 'TO_DATE',tooltip:{fn:'TO_DATE(text, text)', text:'Used to convert a string to a date, based on the specified format.', example:'TO_DATE(\'2022-05-04\', \'YYYY-MM-DD\')', result:'=> 2022-05-04 (date object)'}},
    {field: 'TO_TIMESTAMP',tooltip:{fn:'TO_TIMESTAMP(text, text)', text:'Used to convert a string to a timestamp with time zone value.', example:'TO_TIMESTAMP(\'2022-05-05 15:30:00\', \'YYYY-MM-DD HH24:MI:SS\')', result:'=> 2022-05-05 15:30:00-04 (teimstamp object)'}},
    {field: 'WORKDAY',tooltip:{fn:'WORKDAY(startDate, numdays, list of date holidays)',text:'Returns a date that is numDays working days after startDate. Working days exclude weekends and an optional list of holidays, formatted as a comma-separated string of ISO-formatted date', example:'WORKDAY(\'5/1/23\',10,\'5/5/2023,5/9/23\')', result:'=> 2023-05-17'}},
    {field: 'WORKDAY_DIFF',tooltip:{fn:'WORKDAY_DIFF(starDate, endDate, holidays)', text:'Counts the number of working days between startDate and endDate. Working days exclude weekends and an optional list of holidays, formatted as a comma-separated string of ISO-formatted dates.', example:'WORKDAY_DIFF(\'5/1/23\',\'5/15/23\',\'5/8/2023, 5/9/2023\')', result:'=> 9'}}
]

const otherFunctions = [
    {field: 'CASE',tooltip:{fn:'CASE \r   WHEN condition1 THEN result1 \r   WHEN condition2 THEN result2 \r   ... \r   ELSE default_result \r END \r',text:'Used to perform conditional processing', example:'CASE \r  WHEN {age} >= 18 THEN \'Adult\' \r  ELSE \'Minor\' \r  END ',result:'=> Adult'}},
    {field: 'COUNT',tooltip:{fn:'COUNT(field)', text:'Returns the number of items in a given field array. Only valid on multi-value fields.', example:'COUNT({jobs})',result:'=> 10'}},
    {field: 'TO_CHAR',tooltip:{fn:'TO_CHAR(date, format)',text:'Used to convert a timestamp or date into a formatted string', example:'TO_CHAR(\'5/1/2022\'::date, \'YYYY-MM-DD HH24:MI:SS\')', result:'=> 2022-05-01 00:00:00'}},
    {field: 'GREATEST',tooltip:{fn:'GREATEST(value1, value2, ...)', text:'Returns the greatest value in a list of input values. The function takes two or more arguments, and the data type of each argument must be compatible with each other', example:'GREATEST(5, 10, 15)', result:'=> 15'}},
    {field: 'LEAST',tooltip:{fn:'LEAST(value1, value2, ...)', text:'Returns the lowest value in a list of input values. The function takes two or more arguments, and the data type of each argument must be compatible with each other', example:'LEAST(5, 10, 15)', result:'=> 5'}},
    {field: 'MAX',tooltip:{fn:'MAX(expression)', text:'Returns the maximum value from a multi-value field. Used on multi-value date and number fields', example:'MAX({cost})', result:'=> 29.99'}},
    {field: 'MIN',tooltip:{fn:'MIN(expression)', text:'Returns the minimum value from a multi-value field. Used on multi-value date and number fields', example:'MIN({startDate})', result:'=> 5/1/2023'}},
    {field: 'AVG',tooltip:{fn:'AVG(expression)', text:'Returns the average value from a multi-value field. Used on multi-value number fields', example:'AVG({cost})', result:'=> 55.20'}},
    {field: 'SUM',tooltip:{fn:'SUM(expression)', text:'Returns the sum of all values a multi-value field. Used on multi-value number fields', example:'SUM({cost})', result:'=> 515.20'}},
    {field: 'STRING_AGG',tooltip:{fn:'STRING_AGG(expression, delimiter)', text:'Used to concatenate strings from a multi-value field into a single string with a separator', example:'STRING_AGG({department}, \',\')', result:'=> HR,Sales,IT,HR,IT'}},
    {field: 'ARRAY_AGG',tooltip:{fn:'ARRAY_AGG(expression)', text:'Returns an array of values from a multi-value field. Will appear as a string in grid, but you can reference nodes in the array using the [] syntax', example:'ARRAY_AGG({Departments})[0]',result:'=> IT (first element in array)'}},
    {field: 'CAST',tooltip:{fn:'CAST(expression AS data_type)', text:'Used to convert one data type to another', example:'CAST(\'123\' AS INTEGER)', result:'=> 123'}},
    {field: 'NULLIF',tooltip:{fn:'NULLIF(expression1, expression2)', text:'Takes two arguments and returns NULL if they are equal, otherwise it returns the first argument. Should be used when dividing by a field. This ensures if value of field is 0, you don\'t get an error.', example:'NULLIF({price}, 0)', result:'=> null whenever price field is 0'}}
]

export const functions = stringFunctions.map(el=>el.field).concat(otherFunctions.map(el=>el.field)).concat(mathFunctions.map(el=>el.field)).concat(dateFunctions.map(el=>el.field));
export const fullFunctions = stringFunctions.concat(otherFunctions.concat(mathFunctions).concat(dateFunctions))