/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import { Input, Dropdown, Checkbox, TextArea,Form,Icon as SIcon } from 'semantic-ui-react';
import { mdiClose, mdiEmail, mdiPlayBox, mdiClipboardCheck } from '@mdi/js';
import { AccountContext } from '../../providers/AccountProvider';
import Icon from '@mdi/react';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import { getBlocksAPI } from '../../api/blocks';
import IconButton from '../Block/components/FilterData/IconButton';


const ActionAddorUpdateUser = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [showPassword, setShowPassword] = useState(false);
  const [sendNotification, setSendNotification] =useState(localData.sendNotification ?? false);
  const [emailFields, setEmailFields] = useState([]);
  const [passwordFields, setPasswordFields] =useState([]);
  const [textFields, setTextFields]=useState([]);
  const [collabFields, setCollabFields]=useState([]);
  const [selectedAddedBy, setSelectedAddedBy] = useState(localData.selectedAddedBy ?? 'manual');
  const [addedByValue, setAddedByValue] = useState(localData.selectedAddedBy ?? '');
  const [selectedEmail, setSelectedEmail] = useState(localData.selectedEmail ?? 'manual');
  const [selectedFirstName, setSelectedFirstName] = useState(localData.selectedFirstName ?? 'manual');
  const [selectedLastName, setSelectedLastName] = useState(localData.selectedLastName ?? 'manual');
  const [selectedPassword, setSelectedPassword] = useState(localData.selectedPassword ?? 'manual');
  const [emailValue, setEmailValue] = useState(localData.emailValue ?? '');
  const [firstNameValue, setFirstNameValue] = useState(localData.firstNameValue ?? '');
  const [lastNameValue, setLastNameValue] = useState(localData.lastNameValue ?? '');

  const [newUserSubject,setNewUserSubject] = useState(localData.newUserSubject ?? 'You\'ve been added to {{zone}} on GraceBlocks')
  const [existingUserSubject,setExistingUserSubject] = useState(localData.existingUserSubject ?? 'You\'ve been added to {{zone}} on GraceBlocks')
  
  const [passwordValue, setPasswordValue] = useState(localData.passwordValue ?? '');
  const [newUserInstructions, setNewUserInstructions] = useState(localData.newUserInstructions ?? 'Hi {{firstName}},  Your GraceBlocks account has been created for {{Zone}}.\r\n \r\nTo login go to https://my.graceblocks.com. Please contact {{AddedBy}} for your password instructions.\r\n\r\nThank you. ')
  const [existingUserInstructions, setExistingUserInstructions] = useState(localData.existingUserInstructions ?? 'Hi {{firstName}},  Your GraceBlocks account has been connected to {{Zone}} by {{AddedBy}}.\r\n \r\nTo login go to https://my.graceblocks.com.\r\n\r\nThank you. ')
  const [zoneRole, setZoneRole]=useState(localData.zoneRole ?? 'Zone user');
  const [blocks, setBlocks] =useState([]);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [refreshDate, setRefreshDate] = useState(Date.now());

  const zoneOptions=[{key:'1',value:'Zone owner',text:'Zone owner'}, {key:'2',value:'Zone builder',text:'Zone builder'},{key:'3',value:'Zone user',text:'Zone user'}]


  useEffect(() => {
    getBlocks();
    getEmailandCollaboratorFields();
  }, []);


  const updateSendNotification = (val) =>{
    setSendNotification(val);
    localData.sendNotification = val;
    updateData(localData);
  }

  const updateEmailValue = (val) => {
    setEmailValue(val);
    localData.emailValue = val;
    updateData(localData);
  };

  const updatePasswordValue = (val) => {
    setPasswordValue(val);
    localData.passwordValue = val;
    updateData(localData);
  };

  const updateZoneRole = (val) =>{
    setZoneRole(val);
    localData.zoneRole = val;
    updateData(localData);
  }

  const updateNewUserSubject = (val) =>{
    setNewUserSubject(val);
    localData.newUserSubject = val;
    updateData(localData)
  }

  const updateExistingUserSubject = (val) => {
    setExistingUserSubject(val);
    localData.existingUserSubject =val
    updateData(localData);
  }

  const getBlocks = async () => {
    const myblocks = await getBlocksAPI(0, 'all');
    setBlocks(myblocks);

    if(localData.selectedBlocks !==undefined) {
        setSelectedBlocks(localData.selectedBlocks)
    }
  };



  const getEmailandCollaboratorFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype === 10 ||
            (el.source?.lookupuitype === 10 && el.source.lookup === 'single'),
        )
        .map((col) => {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    const passwordOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    sourceNodes.map((node) => {
        node.data?.tableData?.tableinfo?.columns
          .filter(
            (el) =>
              el.uitype === 14 ||
              (el.source?.lookupuitype === 14 && el.source.lookup === 'single'),
          )
          .map((col) => {
            passwordOptions.push({
              key: `${node.data.tableData.id}|${col.data}`,
              value: `${node.data.tableData.id}|${col.data}`,
              text: `${node.data.tableData.name} (${col.header})`,
            });
          });
      });

      const textOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
      sourceNodes.map((node) => {
          node.data?.tableData?.tableinfo?.columns
            .filter(
              (el) =>
                el.uitype === 2 ||
                (el.source?.lookupuitype === 2 && el.source.lookup === 'single'),
            )
            .map((col) => {
              textOptions.push({
                key: `${node.data.tableData.id}|${col.data}`,
                value: `${node.data.tableData.id}|${col.data}`,
                text: `${node.data.tableData.name} (${col.header})`,
              });
            });
        });

        const collabOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
        sourceNodes.map((node) => {
            node.data?.tableData?.tableinfo?.columns
              .filter(
                (el) =>
                  (el.uitype === 8 && el.lookup==='single') ||
                  (el.source?.lookupuitype === 8 && el.source.lookup === 'single' && el.lookup==='single'),
              )
              .map((col) => {
                collabOptions.push({
                  key: `${node.data.tableData.id}|${col.data}`,
                  value: `${node.data.tableData.id}|${col.data}`,
                  text: `${node.data.tableData.name} (${col.header})`,
                });
              });
          });
  

    setEmailFields(emailOptions);
    setTextFields(textOptions);
    setCollabFields(collabOptions);
    setPasswordFields(passwordOptions);
  };

  const handleEmailSelection = (val) => {
    setSelectedEmail(val);
    localData.selectedEmail = val;
    updateData(localData);
  };

  const handleSelectedFirstName =(val) =>{
    setSelectedFirstName(val)
    localData.selectedFirstName=val
    updateData(localData)
  }

  const handleSelectedLastName =(val) =>{
    setSelectedLastName(val)
    localData.selectedLastName=val
    updateData(localData)
  }

  const handleSelectedAddedBy = (val) =>{
    setSelectedAddedBy(val);
    localData.selectedAddedBy = val;
    updateData(localData)
  }

  const updateAddedByValue =(val) =>{
    setAddedByValue(val);
    localData.addedByValue = val;
    updateData(localData)
  }

  const updateFirstNameValue =(val) =>{
    setFirstNameValue(val);
    localData.firstNameValue = val;
    updateData(localData)
  }

  const updateLastNameValue =(val) =>{
    setLastNameValue = val;
    localData.lastNameValue = val;
    updateData(localData);
  }

  const handlePasswordSelection = (val) => {
    setSelectedPassword(val);
    localData.selectedPassword = val;
    updateData(localData);
  };

  const addremoveblocks = (blockid) => {
    let tempBlocks = [...selectedBlocks];
    const inList = tempBlocks.findIndex((el) => el.blockid === blockid) !== -1;
    if (!inList) {
      // the "items" array of blocks passed in includes user specific block settings (if they exist)
      // and userid is already defined. Here, a user could deselect/then reselect a block. In this
      // case we don't want to lose the settings. so we first look in "items" to see if it's there,
      // otherwise we add blank block and set the userid attribute and set settings = null
     
      const newblock = blocks.filter((itm) => itm.blockid === blockid)[0];
      newblock.role='Block user (General)'
      tempBlocks.push(newblock);
      tempBlocks.sort((a, b) => (a.name > b.name ? 1 : -1));
      setSelectedBlocks(tempBlocks);
    } else {
      tempBlocks = tempBlocks.filter((el) => el.blockid !== blockid);
      tempBlocks.sort((a, b) => (a.name > b.name ? 1 : -1));

      setSelectedBlocks(tempBlocks);
    }
    localData.selectedBlocks = tempBlocks;
    updateData(localData)
  };

  const isCheckedHandler = (blockid) => {
    return selectedBlocks.map((el) => el.blockid).includes(blockid);
  };

  const displayBlocks = () =>
  blocks.map((el) => (
    <div
      key={el.blockid}
      style={{ display: 'flex', margin: '10px', flexDirection: 'row', aligntItems: 'center' }}
    >
      <Checkbox
        onChange={() => addremoveblocks(el.blockid)}
        checked={isCheckedHandler(el.blockid)}
      />
      <div style={{ width: '10px' }} />
      <Icon path={el.icon} size="20px" />
      <div style={{ width: '5px' }} />
      {el.name}
    </div>
  ));

  const updateRole = (blockid,role) => {
    const tempBlocks = [...selectedBlocks]
     const idx = tempBlocks.findIndex(itm=>itm.blockid===blockid)
     if(idx !== -1) {
      tempBlocks[idx].role = role;
      setSelectedBlocks(tempBlocks)
     }
     localData.selectedBlocks = tempBlocks
     updateData(localData);
  }

  const updateNewUserInstructions =(val) =>{
    setNewUserInstructions(val);
    localData.newUserInstructions = val;
    updateData(localData)
  }

  const updateExistingUserInstructions =(val) =>{
    setExistingUserInstructions(val);
    localData.existingUserInstructions = val;
    updateData(localData)
  }

  const displayRoles = () =>
  selectedBlocks.map((el) => (
    <div key={el.blockid} style={{display:'flex', flexDirection:'column',marginBottom:'10px'}}>
      <div style={{ display: 'flex', margin: '5px', flexDirection: 'row', alignItems: 'center',justifyContent:'space-between',marginRight:'0px' }}>
      <div
        style={{ display: 'flex',marginLeft:'10px', margin: '5px', flexDirection: 'row', alignItems: 'center' }}
        key={el.blockid}
      >
        <Icon path={el.icon} size="20px" /> {el.name}
      </div>
      <IconButton
                        iconSize="20px"
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={addremoveblocks}
                        ActionValue={el.blockid}
                      />
        {/* <div
            onClick={() => addremoveblocks(el.blockid)}
            css={{
              svg: { color: 'red' },
              borderRadius: '50px',
              marginRight: '30px',
              '&:hover': {
                transition: 'all .3s ease',
                svg: { color: 'white', borderRadius: '30px', backgroundColor: '#F6685E' },
              },
            }}
          >
            <Icon path={mdiClose} size="15px" />
          </div> */}
      </div>
      <select onChange={(e)=>updateRole(el.blockid,e.target.value)} value={el.role} style={{height:'35px',marginRight:'30px',marginLeft:'10px'}}>
        <option value="Block user (General)">Block user (General)</option>
        <option value="Block user (L1 Security)">Block user (L1 Security)</option>
        <option value="Block user (L2 Security)">Block user (L2 Security)</option>
        <option value='Block builder'>Block builder</option>
      </select>
    </div>
  ));



  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        <div>
          <div style={{ marginTop: '5px', marginBottom: '5px' }}>
           <GBSwitch 
           text="Send invitation notice to new users, and add notice to record email history if possible" 
           isChecked={sendNotification}
           Action={()=>updateSendNotification(!sendNotification)} 
           color="#0D99FF"
           />
          </div>
        </div>
        <div>
          User to add <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          options={emailFields}
          value={selectedEmail}
          selection
          fluid
          onChange={(e, data) => handleEmailSelection(data.value)}
        />
        {selectedEmail === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={emailValue}
              fluid
              label="Email"
              onChange={(e, data) => updateEmailValue(data.value)}
            />
          </div>
        ) : null}

        <div style={{marginTop:'10px'}}>       
          User first name <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          options={textFields}
          value={selectedFirstName}
          selection
          fluid
          onChange={(e, data) => handleSelectedFirstName(data.value)}
        />
        {selectedFirstName === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={firstNameValue}
              fluid
              label="First name"
              onChange={(e, data) => updateFirstNameValue(data.value)}
            />
          </div>
        ) : null}

        <div style={{marginTop:'10px'}}>       
          User last name <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          options={textFields}
          value={selectedLastName}
          selection
          fluid
          onChange={(e, data) => handleSelectedLastName(data.value)}
        />
        {selectedLastName === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={lastNameValue}
              fluid
              label="Last name"
              onChange={(e, data) => updateLastNameValue(data.value)}
            />
          </div>
        ) : null}

        <div style={{marginTop:'10px'}}>       
         Added by <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          options={collabFields}
          value={selectedAddedBy}
          selection
          fluid
          onChange={(e, data) => handleSelectedAddedBy(data.value)}
        />
        {selectedAddedBy === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={addedByValue}
              fluid
              label="Added by"
              onChange={(e, data) => updateAddedByValue(data.value)}
            />
          </div>
        ) : null}

        <div style={{marginTop:'20px',fontWeight:'bold'}}>
         Zone role <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown fluid selection options={zoneOptions} value={zoneRole} onChange={(e,data)=> updateZoneRole(data.value)} />
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
         Select Blocks user can access <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <div
            style={{
              paddingTop: '10px',
              overflow: 'auto',
              paddingLeft: '10px',
            //   width: '500px',
              border: `1px solid #0D99FF`,
              height: '200px',
            }}
          >
            {displayBlocks()}
          </div>
        
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
            Select the role per Block
        </div>
        <div
            style={{
              paddingTop: '10px',
              overflow: 'auto',
              paddingLeft: '10px',
              border: `1px solid #0D99FF`,
              height: '200px',
            }}
          >
            {selectedBlocks.length > 0 ? displayRoles() : 'No Blocks selected'}
          </div>
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
          New user password
        </div>
        <Dropdown
          options={passwordFields}
          value={selectedPassword}
          selection
          fluid
          onChange={(e, data) => handlePasswordSelection(data.value)}
        />
        {selectedPassword === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              type={showPassword ? 'text' :'password'}
              value={passwordValue}
              fluid
              label="Password"
              onChange={(e, data) => updatePasswordValue(data.value)}
              action={{
                // color: 'teal',
                icon: 'eye',
                onClick: ()=>setShowPassword(!showPassword)
              }}
            />
          </div>
        ) : null}
        <div style={{marginTop:'20px',fontWeight:'bold'}}>
            New user created
        </div>
        <div style={{marginTop:'5px'}}>
            Subject
        </div>
        <div>
            <Input fluid value={newUserSubject} onChange={(e,data)=>updateNewUserSubject(data.value)} />
        </div>
        <div style={{marginTop:'5px'}}>Message body</div>
        <div>
        <Form>
                <TextArea
                  style={{
                    marginTop:'5px',
                    fontSize: '12px',
                    border: '1px solid black',
                    borderRadius: '5px',
                  }}
                  onChange={(e, data) => updateNewUserInstructions(data.value)}
                  value={newUserInstructions}
                  placeholder="Enter email New user will receive."
                />
              </Form>
        </div>

        <div style={{marginTop:'20px',fontWeight:'bold'}}>
           Existing user account added
        </div>
        <div>
            Subject
        </div>
        <div>
            <Input fluid value={existingUserSubject} onChange={(e,data)=>updateExistingUserSubject(data.value)} />
        </div>
        <div style={{marginTop:'5px'}}>Message body</div>
        <div>
        <Form>
                <TextArea
                  style={{
                    marginTop:'5px',
                    fontSize: '12px',
                    border: '1px solid black',
                    borderRadius: '5px',
                  }}
                  onChange={(e, data) => updateExistingUserInstructions(data.value)}
                  value={existingUserInstructions}
                  placeholder="Enter email New user will receive."
                />
              </Form>
        </div>

      </div>

    </div>
  );
};

export default ActionAddorUpdateUser;
