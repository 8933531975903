/* eslint-disable */
import React, { useCallback, useEffect, useState,useRef } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import Icon from '@mdi/react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import {
  getTableAPI,
  getTablesAPI,
  getFieldsAPI,
  getTablesWithEmailSendFields,
  getTablesWithMessaging,
} from '../../api/tables';

import { getForms } from '../../api/forms';
import Global from '../../clients/global';
import { mdiGateNor, mdiTrashCan, mdiPencil } from '@mdi/js';
import { getBlockAPI, getBlocksAPI } from '../../api/blocks';
import TextButton from '../../components/TextButton/TextButton';
import FilterData from '../Block/components/FilterData/index';
import FilterTags from '../Block/components/FilterData/FilterTags';
import GBButton from '../../components/GBButton/GBButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import TriggerTest from './TriggerTest';
import { node } from 'prop-types';

const TriggerPanel = ({ close, addNode, nodeData, updateNode, removeNode }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState('');
  const [triggerEvent, setTriggerEvent] = useState('');
  const [blocks, setBlocks] = useState(null);
  const [emailBlocks, setEmailBlocks] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [tableData, setTableData] = useState(null);
  const [testRows, setTestRows]=useState(null);
  const [tables, setTables] = useState([]);
  const [fields, setFields] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const [fieldUpdateFilters, setFieldUpdateFilter] = useState([]);
  const [filterType, setFilterType] = useState('existing'); // type = new or existing.
  const [nodeId, setNodeId] = useState(null);
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [editMode, setEditMode] = useState(true);
  const [entryFilter, setEntryFilter] =useState([]);
  const divRef = useRef();

  const triggerOptions = [
    { key: '1', value: 'newrecord', text: 'New record' },
    { key: '2', value: 'neworupdate', text: 'New or updated record' },
    { key: '3', value: 'fieldupdate', text: 'Updated field' },
    { key: '4', value: 'recorddelete', text: 'Deleted record' },
    { key: '5', value: 'receiveemail', text: 'Receive email' },
    { key: '6', value: 'sendemail', text: 'Send email' },
    { key: '7', value: 'receivetext', text: 'Receive text' },
    { key: '8', value: 'sendtext', text: 'Send text' },
  ];

  const entryOptions = [
    { key: '1', value: 'manual', text: 'Manual' },
    { key: '2', value: 'api', text: 'Api' },
    { key: '3', value: 'webform', text: 'Web form / Add record' },
    { key: '4', value: 'workflow', text: 'Workflow' },
  ]

  const stateMap = { blocks, tables, forms, triggerOptions,entryOptions };

  useEffect(() => {
    fetchBlocks();
  }, []);

  const showFilters = (type) => {
    setFilterType(type);
    setModalIsOpen(true);
  };

  const removeNodeLocal = (id) =>{
    if (divRef.current.value === 'delete') {
      removeNode(id);
    }
  }

  const addTestRow =(rows) =>{
     setTestRows(rows);
  }

  const onEntryChange=(val)=>{
    setEntryFilter(val);
    //if it includes web forms, get webforma and show them.
  }

  //Used for forms and entryOptions which are multi-select
  const DisplayMultipleValue = (source,value) =>{
    const sourceArray = stateMap[source];
    
    if (!sourceArray) {
      console.error(`Source ${source} not found.`);
      return;
    }

    const textValues=[];
    value.map(val=>{
        const idx = sourceArray.findIndex((el) => el.value === val);
        if(idx !==-1) {
            textValues.push(sourceArray[idx].text)
        }
    })

    return textValues.join();

  }

  const DisplayValue = (source, value) => {
    const sourceArray = stateMap[source];
    if (!sourceArray) {
      console.error(`Source ${source} not found.`);
      return;
    }
    const idx = sourceArray.findIndex((el) => el.value === value);
    if (idx !== -1) {
      return sourceArray[idx].text;
    } else {
      return 'Value not found';
    }
  };

  const updateTrigger = async (event) => {
    setTriggerEvent(event);

    if (event === 'receiveemail' || event === 'sendemail' || event === 'sendtext') {
      //load and update blocks/tables that have email send fields.
      const results =
        event === 'sendtext'
          ? await getTablesWithMessaging()
          : await getTablesWithEmailSendFields();
      const tempBlocks = [];
      results.map((itm) => {
        const idx = tempBlocks.findIndex((el) => parseInt(el.value) === parseInt(itm.blockid));
        if (idx === -1) {
          tempBlocks.push({ key: itm.blockid, value: itm.blockid, text: itm.blockname });
        }
      });

      setBlocks(tempBlocks);
      setEmailBlocks(results);
    } else {
      //load all blocks
      const blocks = await getBlocksAPI(parseInt(Global.zoneid));

      blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

      const blockswithIcons = [];
      blocks.forEach((itm) => {
        blockswithIcons.push({
          key: itm.key,
          value: itm.value,
          text: itm.text,
          icon: (
            <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
          ),
        });
      });

      setBlocks(blockswithIcons);
    }
  };

  const deleteTriggerConfirm = () => {
    const message = (
      <div>
        You have selected to delete the trigger: {name}. <br/><br/>Are you sure you want to delete this
        trigger? History of this trigger will be retained in the workflow but it will no longer be a
        part of the workflow moving forward once saved. 
      </div>
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={'Delete trigger forever'}
            action={removeNodeLocal}
            width="350px"
            actionValue={nodeData.id}
            buttonLabel="OK"
            message={message}
            divRef={divRef}
            showInput={true}
            confirmAction="delete"
            confirmWord="delete"
            height="450px"
            onClose={onClose}
          />
        );
      },
    });
  };

  const saveNode = () => {
    if (
      name === '' ||
      triggerEvent === '' ||
      selectedBlock === '' ||
      tableData === null ||
      tableData === '' ||
      (triggerEvent === 'formsubmission' && (selectedForm === null || selectedForm === ''))
    ) {
      toast.info(<div style={{ marginRight: '10px' }}>Missing a required field</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: true,
      });
      return false;
    } else if(testRows===null || testRows.length===0) {
        toast.info(<div style={{ marginRight: '10px' }}>You must have test rows ready.</div>, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: true,
          });
     return false;
    }

    const data = {
      triggerEvent,
      blockid: selectedBlock,
      tableData,
      label: name,
      filters,
      fieldUpdateFilters,
      testRows,
      entryFilter
    };

    if (selectedForm!==null && selectedForm !=='') {
      data.formid = selectedForm;
    }

    if (nodeId !== null) {
      data.id = nodeId;
    }

    if (nodeId === null) {
      addNode(name, data,'trigger');
    } else {
      updateNode(nodeId, data);
    }
  };

  const fetchBlocks = async () => {
    setIsLoading(true);
    const blocks = await getBlocksAPI(parseInt(Global.zoneid));

    blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    const blockswithIcons = [];
    blocks.forEach((itm) => {
      blockswithIcons.push({
        key: itm.key,
        value: itm.value,
        text: itm.text,
        icon: (
          <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
        ),
      });
    });

    setBlocks(blockswithIcons);

    if (nodeData !== null) {
      // setEditMode(false);
      setNodeId(nodeData.id);
      setName(nodeData.label);
      setTriggerEvent(nodeData.triggerEvent);
      setFilters(nodeData.filters ?? []);
      setFieldUpdateFilter(nodeData.fieldUpdateFilters ?? []);
      setTestRows(nodeData.testRows)

      if (!['receiveemail', 'sendemail', 'sendtext'].includes(nodeData.triggerEvent)) {
        onBlockSelection(nodeData.blockid);
      }

      if (nodeData.formid !== undefined) {
        const f = await getForms(nodeData.tableData.id);
        const formOptions = [{key:'0',value:0,text:'Basic form'}];
        f.map((el) => {
          formOptions.push({ key: el.formid.toString(), value: el.formid, text: el.name });
        });
        setForms(formOptions);
        setSelectedForm(nodeData.formid);
      }
      if(nodeData.entryFilter !==undefined) {
        console.log(nodeData.entryFilter)
        setEntryFilter(nodeData.entryFilter)
      }

      if (['receiveemail', 'sendemail', 'sendtext'].includes(nodeData.triggerEvent)) {
        //load and update blocks/tables that have email send fields.
        const results =
          nodeData.triggerEvent === 'sendtext'
            ? await getTablesWithMessaging()
            : await getTablesWithEmailSendFields();
        const tempBlocks = [];
        const tempTables = [];
        results.map((itm) => {
          const idx = tempBlocks.findIndex((el) => parseInt(el.value) === parseInt(itm.blockid));
          if (idx === -1) {
            tempBlocks.push({ key: itm.blockid, value: itm.blockid, text: itm.blockname });
          }
        });

        results
          .filter((el) => el.blockid === nodeData.blockid)
          .map((itm) => {
            tempTables.push({ key: itm.id, value: itm.id, text: itm.name });
          });

        setBlocks(tempBlocks);
        setEmailBlocks(results);
        setTables(tempTables);
        setSelectedBlock(nodeData.blockid);
      }

      onTableSelection(nodeData.tableData.id);
    }

    setIsLoading(false);
  };

  const onBlockSelection = async (blockid) => {
    if (blockid === '') {
      setTables([]);
      return false;
    }

    let tables = [];
    if (['receiveemail', 'sendemail', 'sendtext'].includes(triggerEvent)) {
      emailBlocks
        .filter((el) => el.blockid === blockid)
        .map((itm) => {
          tables.push({ key: itm.id, value: itm.id, text: itm.name });
        });
    } else {
      tables = await getTablesAPI(blockid, 3);
      tables.forEach((itm) => {
        delete itm.isSystemTable;
      });
    }

    tables.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    setSelectedBlock(blockid);
    setTables(tables);
  };

  const onTableSelection = async (tableid) => {
    const { fields } = await getFieldsAPI(tableid);

    const tbl = await getTableAPI(tableid);
    setTableData(tbl);
    setFields(fields);

    //We always load all forms. Only show them if they choose method of "web forms"
    const formOptions = [{key:'0',value:0,text:'Basic form'}];
    const f = await getForms(tableid);
    f.map((el) => {
    formOptions.push({ key: el.formid.toString(), value: el.formid, text: el.name });
    });
    setForms(formOptions);
    
  };

  const updateConditions = (tempFilters) => {
    setFilters(tempFilters);
    setModalIsOpen(false);
  };

  const updateNewConditions = (tempFilters) => {
    setFieldUpdateFilter(tempFilters);
    setModalIsOpen(false);
  };

  return (
    <div style={{ margin: '10px', padding: '20px' }}>
      {isLoading ? (
        <div>loading..</div>
      ) : (
        <>
          <div
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {' '}
              {nodeData !== null && !editMode
                ? 'Trigger details'
                : nodeData !== null && editMode
                ? 'Edit trigger'
                : 'Add trigger'}
            </div>
            <div>
              {nodeData !== null && !editMode ? (
                <GBButton
                  text="Edit"
                  icon={mdiPencil}
                  iconSize="20px"
                  iconColor="black"
                  border="1px solid #0D9953"
                  iconPosition="left"
                  iconHoverColor="white"
                  borderRadius="10px"
                  Action={() => setEditMode(true)}
                  ActionValue={'trigger'}
                  textColor="black"
                  width="75px"
                  height={'30px'}
                  color="white"
                  textHoverColor="white"
                  hoverBackgroundColor={'#0D9953'}
                />
              ) : null}
            </div>
          </div>
          <div style={{ height: '20px' }}></div>
          <div style={{ fontWeight: 'bold' }}>
            Trigger name <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
          </div>
          <div>
            {!editMode ? (
              <div>{name}</div>
            ) : (
              <Input value={name} fluid onChange={(e, data) => setName(data.value)} />
            )}
          </div>
          <div style={{ height: '20px' }}></div>
          <div
            style={{
              backgroundColor: '#fff',
              minHeight: '300px',
              border: '1px solid #0D9953',
              borderRadius: '5px',
              padding: '10px',
            }}
          >
            <div style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold' }}>
              Trigger event <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
            </div>
            <div>
              {!editMode ? (
                <div>{DisplayValue('triggerOptions', triggerEvent)}</div>
              ) : (
                <Dropdown
                  options={triggerOptions}
                  fluid
                  selection
                  value={triggerEvent}
                  onChange={(e, data) => updateTrigger(data.value)}
                />
              )}
            </div>
            <div style={{ marginTop: '20px' }}>
              <div style={{ fontWeight: 'bold' }}>
                Block <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
              </div>

              {!editMode ? (
                DisplayValue('blocks', selectedBlock)
              ) : (
                <Dropdown
                  value={selectedBlock}
                  options={blocks}
                  fluid
                  selection
                  onChange={(e, data) => onBlockSelection(data.value)}
                  clearable
                />
              )}

              <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
                Tab <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
              </div>
              {!editMode ? (
                <div>{DisplayValue('tables', tableData?.id)}</div>
              ) : (
                <Dropdown
                  value={tableData?.id}
                  options={tables}
                  fluid
                  selection
                  onChange={(e, data) => onTableSelection(data.value)}
                  clearable
                />
              )}

              {triggerEvent === 'fieldupdate' ? (
                <>
                  <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
                    Field update conditions{' '}
                    <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
                  </div>
                  <TextButton
                    Action={showFilters}
                    ActionValue={'new'}
                    iconPosition="left"
                    icon={mdiGateNor}
                    size="20px"
                    text="Manage conditions"
                    textColor="black"
                  />
                  {fieldUpdateFilters.length > 0 ? (
                    <FilterTags
                      sortHeader={'id'}
                      sortField={'id'}
                      sortOrder={'asc'}
                      filters={fieldUpdateFilters}
                      removeFilter={null}
                      removeSort={null}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>

          {tableData != null ? (
            <div
              style={{
                position:'relative',
                marginTop: '20px',
                overflow:'visible',
                // overflowY: 'visible',
                // overflowX: 'hidden',
                backgroundColor: '#fff',
                // height: '150px',
                border: '1px solid #0D9953',
                borderRadius: '5px',
                padding: '10px',
              }}
            >
              <div style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold' }}>
                Trigger conditions
              </div>

              {(triggerEvent==='newrecord' || triggerEvent==='neworupdate') ? (
              <div style={{marginBottom:'20px'}}>
                <span style={{fontWeight:'bold'}}>Filter by Method</span>
                {(nodeData !==null && editMode) || nodeData===null ? (
                <Dropdown value={entryFilter} options={entryOptions} fluid selection multiple onChange={(e,data)=>onEntryChange(data.value)} />
                ): <div>
                    {DisplayMultipleValue('entryOptions',entryFilter)}
                </div> }
              </div>):null}

              {entryFilter?.includes('webform') && forms.length > 0 ? (
                <div style={{marginBottom:'20px'}}>
                  <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
                  <span style={{fontWeight:'bold'}}>Limit by form type</span>
                  </div>
                  {!editMode ? (
                    <div>{DisplayMultipleValue('forms', selectedForm)}</div>
                  ) : (
                    <Dropdown
                      multiple
                      value={selectedForm}
                      onChange={(e, data) => setSelectedForm(data.value)}
                      options={forms}
                      fluid
                      selection
                      clearable
                    />
                  )}
                </div>
              ) : null}

              {editMode ? (
                <TextButton
                  Action={showFilters}
                  ActionValue={'existing'}
                  iconPosition="left"
                  icon={mdiGateNor}
                  size="20px"
                  text="Manage conditions"
                  textColor="black"
                />
              ) : null}

              {filters.length > 0 ? (
                <FilterTags
                  sortHeader={'id'}
                  sortField={'id'}
                  sortOrder={'asc'}
                  filters={filters}
                  removeFilter={null}
                  removeSort={null}
                />
              ) : null}
            </div>
          ) : null}

        {tableData !==null ? (
            <TriggerTest tableData={tableData} filters={filters} addTestRow={addTestRow} testRows={testRows} />
        ):null}
          

          <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TextButton textColor="black" text="Cancel" Action={close} />
            <div style={{ width: '20px' }}></div>
            {editMode ? (
              <GBButton
                text="Save"
                borderRadius="10px"
                Action={saveNode}
                ActionValue={'trigger'}
                textColor="white"
                width="75px"
                height={'30px'}
                color="#0D9953"
                textHoverColor="white"
                hoverBackgroundColor={'#0D995380'}
              />
            ) : (
              <GBButton
                icon={mdiTrashCan}
                iconPosition="left"
                iconSize="30px"
                text="Delete forever"
                borderRadius="10px"
                Action={deleteTriggerConfirm}
                // ActionValue={nodeData.id}
                textColor="black"
                width="150px"
                height={'35px'}
                color="#eee"
                textHoverColor="#00000080"
                hoverBackgroundColor={'#eee'}
              />
            )}
          </div>

          <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            // onAfterOpen={this.afterOpenModal}
            // onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                // position: 'fixed',
                width: '450px',
                // height: this.state.modalHeight,
                height: 'calc(100vh -300px)',
                // maxHeight:'500px',
                top: 200,
                left: window.innerWidth / 2,
                border: `.3px solid #0D99FF`,
                background: '#fff',
                overflow: 'auto',
                zIndex: 6010,
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '0px',
              },
            }}
            contentLabel="Example Modal"
          >
            <FilterData
              title={
                filterType === 'existing'
                  ? `Conditions for trigger: ${name}`
                  : 'Field update conditions'
              }
              icon={mdiGateNor}
              saveText="Save"
              tableinfo={tableData?.tableinfo}
              tableid={tableData?.tableid}
              close={() => setModalIsOpen(false)}
              FilterData={filterType === 'existing' ? updateConditions : updateNewConditions}
              filters={filterType === 'existing' ? filters : fieldUpdateFilters}
              color={'#0D99FF'}
              blockid={selectedBlock} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
              callingComponent="form"
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default TriggerPanel;
