/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';

import Spinner from '../../components/Spinner/Spinner';
import { Checkbox, Input } from 'semantic-ui-react';
import {stripeGetPromotionCode} from '../../api/zone'

const AddFunds = ({ color, toggleShowFunds, addMessagingFunds, autoCharge, updateAutoCharge }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [method, setMethod] = useState('payment');
  const [code, setCode] = useState('');
  const [activePromoCode,setActivePromoCode] = useState('');
  const [amount, setAmount] = useState(20);

  const { REACT_APP_STRIPE_ENV} = process.env;
  let priceId=''
  if(REACT_APP_STRIPE_ENV==='TEST') {
    priceId='price_1IC8w8ERqpLfk4rF3LfRRVoR'
  } else {
    priceId='price_1IG8GrERqpLfk4rFqdgHpQCI'
  }

  const getPromoCode = async () => {
   const result = await stripeGetPromotionCode(code)
   setActivePromoCode(result)
  };

  const updateCode = (val) => {
    setCode(val)
    setActivePromoCode('')
  }

  const addFundsLocal = async () => {
    setIsLoading(true)
    if(activePromoCode==='') {
      await addMessagingFunds(priceId,amount);
    } else {
      await addMessagingFunds(priceId,amount,activePromoCode.promotion_code.coupon.id); 
    }
    setIsLoading(false)
  }

  const getStatement = () => {
    if (!autoCharge) {
      return (
        <span>
          This is a one time purchase. {' '} To continue sending messages, you will need to purchase additional funds when these funds are depleted.
        </span>
      );
    } else {
      return (
        <span>
          You have chosen to enable auto-recharge. This will automate the re-purchase of your most recently purchased funds amount when your funds balance reaches $10.00 US Dollars.
        </span>
      );
    }
  };

  return (
    <div>
      {!isLoading ? (
        <div
          style={{
            width: '535px',
            backgroundColor: 'white',
            height: '500px',
            zIndex: 200,
            border: '1px solid #AE007B',
            borderRadius: '10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between'
          }}
        ><div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              borderTopRightRadius: '10px',
              borderTopLeftRadius: '10px',
              height: '30px',
              color: 'white',
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#AE007B',
            }}
          >
            <div style={{ marginLeft: '20px' }}>Add messaging funds</div>
          </div>
          <div
            className="row"
            style={{ margin: '30px', marginLeft: '20px', justifyContent: 'center' }}
          >
            <div
              onClick={() => setMethod('payment')}
              css={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                width: '200px',
                height: '30px',
                borderTopLeftRadius: '5px',
                borderBottomLeftRadius: '5px',
                backgroundColor: method === 'payment' ? color : '#FFFFFF',
                color: method === 'payment' ? '#FFFFFF' : color,
                border: method === 'payment' ? `1px solid ${color}` : null,
                '&:hover': {
                  backgroundColor: `${color}80`,
                  color: '#FFFFFF',
                },
              }}
            >
              USE PAYMENT METHOD
            </div>

            <div style={{ width: '20px' }} />
            <div
              onClick={() => setMethod('promo')}
              css={{
                display: 'flex',
                cursor: 'pointer',
                justifyContent: 'center',
                alignItems: 'center',
                width: '200px',
                height: '30px',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
                backgroundColor: method === 'promo' ? color : '#FFFFFF',
                color: method === 'promo' ? '#FFFFFF' : color,
                border: method === 'payment' ? `1px solid ${color}` : null,
                '&:hover': {
                  backgroundColor: `${color}80`,
                  color: '#FFFFFF',
                },
              }}
            >
              USE PROMO CODE
            </div>
          </div>
          {method === 'payment' ? (
            <>
              <div style={{ marginLeft: '53px', marginBottom: '10px' }}>
                Amount to add to your balance:
              </div>
              <div
                style={{
                  marginLeft: '53px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <div>
                  <Input
                    type="number"
                    min="20"
                    max="2000"
                    value={amount}
                    onChange={(e,data) =>setAmount(data.value)}
                    style={{ width: '200px', border: '1px solid black', borderRadius: '5px' }}
                    placeholder="add fund amount"
                  />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                  <div>Minimum $20</div>
                  <div>Maximum: $2,000 </div>
                </div>
              </div>

              <div style={{ color: color, margin: '10px', marginLeft: '53px', fontWeight: 'bold' }}>
                <Checkbox
                  label="Auto-recharge this amount when funds are low"
                  checked={autoCharge}
                  onChange={(e, data) => updateAutoCharge(data.checked)}
                />
                <div style={{ color: color, marginTop: '10px', fontWeight: 'bold' }}>
                  The default payment method for this Zone will be used to process this transaction.{' '}
                </div>
              </div>
              <div style={{ margin: '20px',marginLeft:'50px' }}>
                You authorize GraceRock, d.b.a. GraceBlocks, to charge you the amount shown above.{' '}
                {getStatement()} By clicking the Add funds button below, you agree to these
                terms and the GraceBlocks{' '}
                <a href=" https://www.graceblocks.com/termsofservice" target="_blank">
                  Terms of Service
                </a>{' '}
                and{' '}
                <a href="https://www.graceblocks.com/privacy" target="_blank">
                  Privacy Policy
                </a>
                .
              </div>
             
            </>
          ) : (
            <div style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
              <div className="row">
                  <div style={{marginRight:'10px'}}>Promo code</div>
                <Input
                  placeholder="enter promo code"
                  action={{
                    content: 'Enter',
                    onClick: () => getPromoCode(),
                  }}
                  onChange={(e,data) =>updateCode(data.value)}
                />
              </div>
              {activePromoCode.promotion_code ===null ? (
                  <div style={{fontWeight:'bold', color:color, marginTop:'50px'}}>Promo code not recognized</div>
              ): null}
              {activePromoCode !=='' && activePromoCode.promotion_code !==null ? (
                  <div style={{fontWeight:'bold', color:color, marginTop:'50px'}}>Amount that will be added to your balance: ${activePromoCode.promotion_code.coupon.amount_off*.01.toFixed(2)}</div>
              ): null}
            </div>
          )}
          </div>
           <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginBottom: '30px',
                  marginTop: '20px',
                }}
              >
                <TextButton Action={toggleShowFunds} hoverColor="#75757580" text="Cancel" textColor="black" />
                <div style={{ width: '20px' }}></div>
                <GBButton text="Add funds" color="#AE007B" Action={activePromoCode === '' || activePromoCode.promotion_code !==null ? addFundsLocal : null} />
              </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default AddFunds;
