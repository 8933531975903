/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useEffect,useRef } from 'react';

import Icon from '@mdi/react';


import {
  mdiDragHorizontal,
  mdiArrowLeft,
  mdiChevronRight,
  mdiShareVariant,
  mdiPrinter,
  mdiDockRight,
  mdiDockBottom,
  mdiChevronLeft,
} from '@mdi/js';
import ActionButton from '../ActionBar/ActionButton';
import MultiActionButton from '../ActionBar/MultiActionButton';

const TopNavbar = ({
  color,
  hide,
  itemArray,
  load,
  NextPrev,
  changeLayout,
  layout,
  updateGrid,
  filterCount,
  SelectedRowIndex,
  canEdit,
  toggleShowShare,
  isShare,
  isMobileShare,
  showActionPanel,
  isEditMode,
  nextPrevBool
}) => {

  useEffect(() => {
    const container  = document.getElementById("container");
    if(container) {
      container.scrollLeft = container?.scrollWidth;
    }

  }, [SelectedRowIndex]);

  const buttons= [{param:'horizontal',icon:mdiDockRight}, {param:'vertical',icon:mdiDockBottom}];

  return (

    <div>
      {isMobileShare ? (null) : (
            <div
            style={{
              display: 'flex',
              backgroundColor: !isMobileShare ? `${color}1A` : `${color}`,
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                // border:'1px solid purple',
                alignItems: 'flex-start',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              {!isMobileShare && hide !==null ? (
              <div
                onMouseDown={() => console.log('down...')}
                css={{
                  // position: 'absolute',
                  // paddingLeft: '3px',
                  top: '0px',
                  left: '0px',
                  cursor: 'pointer',
                  display: 'flex',
                  width: '100%',
                  overflow: 'auto',
                  // border:'1px solid red',
                  color: '#312602',
                  zIndex: '100px',
                  alignItems: 'space-between',
                  '&:hover': {
                    svg: {
                      color: '#31260280',
                    },
                  },
                }}
              >
                <Icon path={mdiDragHorizontal} size="25px" />
              </div>
              ): null}
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {hide !==null ? (
                <div
                 onClick={() => hide()}   //hide(false, updateGrid)} 9-23-22
                  css={{
                    paddingLeft:'10px',
                    color: isMobileShare ? 'white' : itemArray.length === 1 ? color : '#312602',
                    cursor:'pointer',
                    minWidth: '25px',
                    // border:'1px solid red',
                    '&:hover': {
                      color: `${color}80`,
                    },
                  }}
                >
                  <Icon path={mdiArrowLeft} size="25px" />
                </div>
                ):null}
      
                <div
                  id="container"
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '100%',
                    overflow: 'auto',
                    // border:'1px solid blue',
                    // overflowY: 'hidden',
                    // marginLeft: '10px',
                    marginTop: '5px',
                    // justifyContent: 'flex-end',
                    marginBottom: '5px',
                    // direction:'rtl',
                    color: !isMobileShare ? color : 'white',
                  }}
                >
                  {itemArray.map((itm, index) => (
                    <div
                      key={index}
                      onClick={
                        index < itemArray.length - 1 ? () => load(itm.selectedRow.id, itm.tableid) : null
                      }
                      css={{
                        cursor: index < itemArray.length - 1 ? 'pointer' : null,
                        color: isMobileShare ? 'white' : index < itemArray.length - 1 ? '#312602' : color,
                        fontSize: '20px',
                        '&:hover': {
                          color: index < itemArray.length - 1 ? `${color}80` : null,
                        },
                      }}
                    >
                      <Icon
                        style={{ marginLeft: '5px', marginRight: '5px' }}
                        path={itm.tableinfo.icon}
                        size="25px"
                      />{' '}
                      {itm.tableinfo.recordname}: {itm.selectedRow.CustomID}
                      {index < itemArray.length - 1 ? <Icon path={mdiChevronRight} size="20px" /> : null}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={{display:'flex',flexDirection:'column'}}>
            <div style={{ width: !isShare ? '300px':  '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {!isShare ? (<>
              <ActionButton
                label="Share"
                tooltip="Share record with others"
                actionIcon={mdiShareVariant}
                Action={toggleShowShare}
                color={color}
                isSelected={false}
              />
              <ActionButton
                label="Print"
                tooltip="This feature is under development"
                actionIcon={mdiPrinter}
                Action={null}
                color={color}
                isSelected={false}
              />
               </>):null}
               {!isMobileShare && showActionPanel? (
              <MultiActionButton
                size="25px"
                viewMode={layout}
                label="Layout"
                buttons={buttons}
                Action={changeLayout}
                color={color}
              />):null}
              {nextPrevBool.prev  && !isEditMode ? (
              <ActionButton
                label="Prev"
                actionIcon={mdiChevronLeft}
                Action={SelectedRowIndex > 0 ? NextPrev : null}
                ActionValue="prev"
                color={color}
                textHoverColor={!isMobileShare ? `${color}80` : '#FFFFFF80'}
                baseColor={!isMobileShare ? '#312602' : "white"}
                isSelected={false}
                invertColors={!isMobileShare ? true : false}
                isMobile ={isMobileShare}
              />): null}
              {(nextPrevBool.prev  && isEditMode) || !nextPrevBool.prev ? (
              <div style={{color:'#75757580',marginBottom:'9px',fontSize:'12px',marginLeft:'10px',marginRight:'10px'}}>
                Prev
                <div><Icon path={mdiChevronLeft} size="25px" /></div>
              </div>
              ): null}
              {nextPrevBool.next && !isEditMode ? (
              <ActionButton
                label="Next"
                actionIcon={mdiChevronRight}
                Action={NextPrev}
                ActionValue="next"
                marginRight={layout==='vertical' ? '25px' : '10px'}
                marginLeft={layout==='vertical' ? '0px' : '10px'}
                color={color}
                baseColor={!isMobileShare ? '#312602' : "white"}
                textHoverColor={!isMobileShare ? `${color}80` : '#FFFFFF80'}
                isSelected={false}
                invertColors={!isMobileShare ? true : false}
                isMobile ={isMobileShare}
              />): null}
               {(nextPrevBool.next  && isEditMode) || !nextPrevBool.next ? (
              <div style={{color:'#75757580',marginBottom:'9px',fontSize:'12px',marginLeft:'10px',marginRight:'10px'}}>
                Next
                <div><Icon path={mdiChevronRight} size="25px" /></div>
              </div>
              ): null}

            </div>{!canEdit && !isShare ? (
            <div style={{marginRight:'5px',fontSize:'12px'}}>🔒 Security applied. This is a view-only detail view.</div>
            ): null}
            </div>
          </div>
      )}
    </div>

  );
};

export default TopNavbar;
