/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useRef,useContext } from 'react';

import Icon from '@mdi/react';
import { mdiDragVertical, mdiGateNor, mdiClose,mdiContentSave } from '@mdi/js';
import { Input,Radio,Form,Dropdown } from 'semantic-ui-react';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import RenderField from './components/RenderField';
import TextElement from './components/TextElement';
import RTFElement from './components/RTFElement';
import PageBreak from './components/PageBreak';
import Thankyou from './components/Thankyou';
import TextButton from '../../../../components/TextButton/TextButton';
import FilterData from '../FilterData/index';
import FilterTags from '../FilterData/FilterTags';
import { AccountContext } from '../../../../providers/AccountProvider';
import {getForms} from '../../../../api/forms'
import GBButton from 'src/components/GBButton/GBButton';


const FormEditObject = ({
  fieldData,
  tableid,
  blockid,
  color,
  btnTextColor,
  updateFieldValue,
  updateFieldLabel,
  updateFieldAttribute,
  removeItemFromSelectedArray,
  fontFamily,
  fontSize,
  labelsInCaps,
  tableinfo,
  fields,
  textColor
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);
  const [modalIsOpen,setModalIsOpen] = useState(false);
  const [selectTableInfo,setSelectTableInfo]=useState({});
  const [RTFContent, updateRTFContent] =useState(false);
  const [forms, setForms] =useState(null);

  const myRef = useRef();
  const {userInfo} = useContext(AccountContext);

  useEffect(()=>{
    const tempInfo = structuredClone(tableinfo);
    //11-29-23 removing filter on fields, as we now need to support allowing them to set 
    //conditions on any field with the ability to update an exising record..so they now can use
    //read only fields to set conditions on whether form field will appear.

    // tempInfo.columns=tempInfo.columns.filter(el=>fields.map(el=>el.data).includes(el.data) && el.data !==fieldData.data)
    
    setSelectTableInfo(tempInfo)

    if(fieldData.fieldInfo?.uitype===1 && forms ==null) {
      getFormLocal(fieldData.fieldInfo.source.lookuptableid)
    }
   
  },[fields])

  const onKeyPressHandler = (e) => {
    if (e.key === 'Enter') {
      setIsEditMode(false);
    }
  };

  const getFormLocal = async (tableid) => {
    const subForms=[];
    const tempForms = await getForms(tableid);
    tempForms.map(el=>{
      subForms.push({key:el.formid,value:el.formid,text:el.name})
    })
    setForms(subForms); // this subforms is in format used by dropdown
    // setFullForms(tempForms) //this contains full form info, so we can get columnsettings to show fields to pick
  }

  const handleClickInside = () => {
    setClickedOutside(false);
    setIsEditMode(true);
  };

  const handleClickOutside = (e) => {

    if (!myRef.current.contains(e.target) && !modalIsOpen && fieldData.type !=='rtf' ) {
      setClickedOutside(true);
      setIsEditMode(false);
    }
  };


  const saveRTFContent =() => {
    if(RTFContent !==false) {
      updateFieldLabel(fieldData.id,RTFContent)
    }

    setIsEditMode(false);
  }

  const updateRTFValue =(val) =>{
    updateFieldLabel(fieldData.id,val)
    updateRTFContent(val)
    
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  const updateRequired =(isRequired) => {
    updateFieldAttribute(fieldData.id,'required',isRequired)
  }

  const updateConditions=(conditions) =>{
   updateFieldAttribute(fieldData.id,"conditions",conditions);
   toggleConditionModal();
  }

  const toggleConditionModal =(e) =>{
    //  console.log(e);

    if(userInfo.plan==='Pro' || userInfo.plan==='Enterprise' || userInfo.plan==='Public'){
    setModalIsOpen(!modalIsOpen)}
    else {
      toast.info(<div style={{margin:'10px'}}>Upgrade to the Pro plan to use conditions functionality.</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose:7000,
      })
    }
  }

  return (
    <div>
    {fieldData.type==='rtf' && isEditMode ? (
      <div style={{display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'flex-end',marginRight:'10px'}}>
       <GBButton text="Close" width='80px' iconColor='white' iconHoverColor="#FFFFFF80" textHoverColor='#FFFFFF80'  Action={()=>setIsEditMode(false)} iconPosition='left' icon={mdiClose} color={color} />
       <div style={{width:'20px'}} />
       {/* <GBButton text="Save RTF" width='120px' iconColor='white' iconHoverColor="#FFFFFF80" textHoverColor='#FFFFFF80'  Action={saveRTFContent} iconPosition='left' icon={mdiContentSave} color={color} /> */}
      </div>
    ): null}

    <div
      ref={myRef}
      onClick={handleClickInside}
      css={{ marginBottom: '10px', display: 'flex', width: '100%' }}
    >
      {fieldData.type === 'field' ? (
        <div
          css={{
            display: 'flex',
            backgroundColor: isEditMode ? `${color}1A` : null,
            borderRadius:'10px',
            flexDirection: 'column',
            width: '100%',
            '&:hover': {
              backgroundColor: `${color}1A`,
              '& .editMode': {
                display: 'flex',
              },
            },
          }}
        >
          {isEditMode ? (
            <div
              style={{
                display: 'flex',
                padding: '10px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'space-between', marginLeft: '12px', marginBottom: '5px' }}>
                <div>{fieldData.header}</div>
                <div style={{color:'#757575'}}>FIELD ID: {fieldData.data.replace('_','-')}</div>
              </div>
              <div>
                <Input
                  style={{width:'100%'}}
                  onKeyPress={onKeyPressHandler}
                  onChange={(e, data) => updateFieldLabel(fieldData.id, data.value)}
                  value={fieldData.label}
                />
              </div>
            </div>
          ) : null}

          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              // border:'1px solid red',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingLeft: '10px',
              paddingRight:'10px'
            }}
          >
            <RenderField
              value=''
              id={fieldData.id}
              tableid={tableid}
              blockid={blockid}
              isEditMode={isEditMode}
              fieldData={fieldData}
              updateValue={null}
              updateFieldValue={updateFieldValue} 
              color={color}
              textColor={textColor}
              error=''
              fontSize={fontSize}
              fontFamily={fontFamily}
              labelsInCaps={labelsInCaps}
              completedSubForms={[]}
            />
        
            <div
              className="editMode"
              css={{
                display: isEditMode ? 'flex' : 'none',
                marginLeft: '10px',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ width: '10px' }} />
              <Icon path={mdiDragVertical} size="20px" />
              <div
                css={{
                  color: '#E06055',
                  width:'20px',
                  '&:hover': {
                    svg: { color: 'white', },
                    backgroundColor: '#E06055',
                    borderRadius: '100%',
                  },
                }}
              >
                <Icon onClick={() => removeItemFromSelectedArray(fieldData.id)} path={mdiClose} size="20px" />
              </div>
            </div>
          </div>

          {isEditMode ? (<>
              <div style={{padding:'10px'}}>
                  <Input placeholder="Enter any help text that may aid users with entering data into this field." onChange={(e,data)=>updateFieldAttribute(fieldData.id,'tooltip',data.value)} value={fieldData.tooltip} fluid />
             </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent:'space-between' ,
                margin:'10px'
              }}
            >
              <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'flex-start'}}>
              <TextButton Action={toggleConditionModal} iconPosition='left' icon={mdiGateNor} size="20px" text="Manage conditions" textColor='black' />
              </div>
              <GBSwitch isChecked={fieldData.required} Action={updateRequired} ActionValue={!fieldData.required} color={color} text="Mandatory" />
            </div>
            {fieldData.conditions !==undefined && fieldData.conditions.length>0 ? (
            <div style={{marginLeft:'10px'}}>
                 <FilterTags 
                 sortHeader={'id'}
                 sortField={'id'}
                 sortOrder={'asc'}
                 filters={fieldData.conditions}
                 removeFilter={null}
                 removeSort={null} />
              </div>): null}
            {fieldData.fieldInfo.uitype===4 && fieldData.fieldInfo.lookup==='multiple' ? (
                 <div style={{display:'flex',flexDirection:'row',alignItems:'center',margin:'10px'}}> 
                <div>Max # Attachments</div>
                <div><Input style={{width:'50px'}} value={fieldData.maxAttachments} onChange={(e,data)=>updateFieldAttribute(fieldData.id,'maxAttachments',data.value)} /></div>
                </div>
             ): null}

            {[6,7].includes(fieldData.fieldInfo.uitype) || (fieldData.fieldInfo.uitype===1 && fieldData.fieldInfo.source.nestedFields===undefined) ? (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',margin:'10px',}}>
                    <div style={{marginRight:'10px',marginBottom:'7px'}}>If fewer than 31 options</div>
                    {/* <Form> */}
                        <Radio
                          label="Button display"
                          name="Group1"
                          value="button"
                          checked={fieldData.listDisplay === 'button'}
                          onChange={(e, data) => updateFieldAttribute(fieldData.id,'listDisplay',data.value)}
                        />
                        <div style={{width:'5px'}} />
                        <Radio
                          label="List display"
                          name="Group1"
                          value="list"
                          checked={fieldData.listDisplay=== 'list'}
                          onChange={(e, data) => updateFieldAttribute(fieldData.id,'listDisplay',data.value)}
                        />
            
                      {/* </Form> */}
                </div>
               ):null}
               {fieldData.fieldInfo.uitype===1 ? (
                <div style={{margin:'10px'}}>
                  <div>Add records through form:</div>
                  <Dropdown
                   placeholder="Select a web form for adding records"
                    options={forms}
                    fluid
                    value={fieldData.subForm ?? null}
                    selection
                    scrolling
                    clearable
                    search
                    onChange={(e, data) => updateFieldAttribute(fieldData.id,'subForm',data.value)}
                  /> 

                  {fieldData.subForm !=='' && fieldData.subForm !==undefined ? (
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',margin:'10px'}}> 
                    <div>Max # of new records that can be added:</div>
                    <div><Input style={{width:'50px'}} value={fieldData.maxRecords} onChange={(e,data)=>updateFieldAttribute(fieldData.id,'maxRecords',data.value)} /></div>
                  </div>
                  ) : null}
                
                  {fieldData.fieldInfo.isSubTable ? (
                    <div style={{marginTop:'5px'}}>🔔 A form must be selected for this field to appear on the form.</div>
                  ):null}
                </div>
               ): null}
             

          </>) : null}
        </div>
      ) : null}

      {fieldData.type === 'h1' || fieldData.type === 'h2' || fieldData.type === 'h3' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            backgroundColor: isEditMode ? `${color}1A` : null,
            // border:'1px solid red',
            alignItems: 'center',
            padding: isEditMode ? '10px' : null,
          }}
        >
          <TextElement
            id={fieldData.id}
            type={fieldData.type}
            name={fieldData.label}
            color={color}
            fontSize={fontSize}
            fontFamily={fontFamily}
            updateFieldLabel={updateFieldLabel}
            removeItemFromSelectedArray={removeItemFromSelectedArray}
            isEditMode={isEditMode}
            setIsEditMode={setIsEditMode}
            textColor ={textColor}
          />
          {isEditMode ? (
            <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'flex-start',padding:'20px'}}>
            <TextButton Action={toggleConditionModal} iconPosition='left' icon={mdiGateNor} size="20px" text="Add conditions" textColor='black' />
            {fieldData.conditions !==undefined ? (<>
              <p/>
               <FilterTags 
               sortHeader={'id'}
               sortField={'id'}
               sortOrder={'asc'}
               filters={fieldData.conditions}
               removeFilter={null}
               removeSort={null} />
            </>): null}
            </div>
          ):null}
        </div>
      ) : null}

      {fieldData.type === 'rtf' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            // border:'1px solid red',
            // backgroundColor: isEditMode ? `${color}1A` : null,
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <RTFElement  
            fieldData={fieldData}
            color={color}
            fontFamily={fontFamily}
            // updateRTFContent={updateRTFContent}
            updateRTFContent={updateRTFValue}
            removeItemFromSelectedArray={removeItemFromSelectedArray}
            isEditMode={isEditMode}
            textColor={textColor}
            />
          {isEditMode ? (
            <div style={{display:'flex',width:'100%',flexDirection:'column',alignItems:'flex-start',padding:'20px'}}>
            <TextButton Action={toggleConditionModal}  iconPosition='left' icon={mdiGateNor} size="20px" text="Add conditions" textColor='black' />
            {fieldData.conditions !==undefined ? (<>
              <p/>
               <FilterTags 
               sortHeader={'id'}
               sortField={'id'}
               sortOrder={'asc'}
               filters={fieldData.conditions}
               removeFilter={null}
               removeSort={null} />
            </>): null}
            </div>
          ):null}

        </div>
      ) : null}



      {fieldData.type === 'pb' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // border:'1px solid red',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <PageBreak
            fieldData={fieldData}
            color={color}
            btnTextColor={btnTextColor}
            isEditMode={isEditMode}
            updateFieldLabel={updateFieldLabel}
            removeItemFromSelectedArray={removeItemFromSelectedArray}
            textColor={textColor}
          />
        </div>
      ) : null}
       {fieldData.type === 'thankyou' ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            // border:'1px solid red',
            alignItems: 'center',
            padding: '10px',
          }}
        >
          <Thankyou
           isEditMode={isEditMode}
            updateFieldLabel={updateFieldLabel}
            fieldData={fieldData}
            color={color}
            textColor={textColor}
            btnTextColor={btnTextColor}
          />
        </div>
      ) : null}

      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        // onAfterOpen={this.afterOpenModal}
        // onRequestClose={this.closeModal}
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            // position: 'fixed',
            width: '450px',
            // height: this.state.modalHeight,
            height: 'calc(100vh -300px)',
            // maxHeight:'500px',
            top: 200,
            left: window.innerWidth/2,
            border: `.3px solid ${color}`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 5010,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
          },
        }}
        contentLabel="Example Modal"
      >
        <FilterData
            title={`Conditions for field: ${fieldData.header}`}
            icon={mdiGateNor}
            saveText="Save"
            tableinfo={selectTableInfo}
            tableid={tableid}
            close={toggleConditionModal}
            FilterData={updateConditions}
            filters={fieldData.conditions !==undefined ? fieldData.conditions : []}
            color={color}
            blockid={blockid} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
            callingComponent='form'
          />
      </Modal>

    </div>
    </div>
  );
};

export default FormEditObject;
