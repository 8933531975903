/* eslint-disable */
import React, { Component } from 'react';
import { Checkbox } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import AdvancedField from './AdvancedField';

import cloudrun from '../../../../clients/httpcloudrun';

class CounterField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo } = this.props;

    this.state = {
      showAdvanced: false,
      startNumber: columnConfigData.startNumber,
      IDEnding: columnConfigData.IDEnding,
      callout: columnConfigData.callout,
      resetNumber: false,
      tableinfo,
      isPrivate:columnConfigData.isPrivate ?? false
    };
  }

  static contextType = AccountContext;

  changeHandler = (field, value) => {
    this.setState({ [field]: value });
  };

  Save = async () => {
    const {
      callout,
      tableinfo,
      startNumber,
      resetNumber,
      isPrivate
    } = this.state;

    const { field, tableid, header, columnConfigData} = this.props;
    const originalUItype = columnConfigData.uitype;

    try {
      // Update Tableinfo with new settings.
      const rowindex = tableinfo.columns.findIndex((el) => el.data === field);

      let payload={
        tableid,
        field,
        startNumber,
        originalUItype,
        resetNumber,
      };


      if(originalUItype ===22)
      {
        payload.castFunction='cast_date_to_numeric';
        payload.newfieldtype='numeric';
        payload.tableinfo=tableinfo;
        // payload = { tableid, tableinfo, field, newfieldtype: 'numeric',castFunction:'cast_date_to_numeric' };
        await cloudrun.post('/convertField', { payload })
      } 

       // Call GCF to convert field
        await cloudrun.post('/addCounterField', { payload })

      if (rowindex !== -1) {
          const defaultNode = `{
            "data": "${field}",
            "width": ${columnConfigData.width},
            "header": "${header}",
            "uitype": 17,
            "displayfield": "${field}",
            "detailviewsort": ${columnConfigData.detailviewsort}
        }`;
    
        const updatedNode = JSON.parse(defaultNode);

        updatedNode.readOnly=true;
        updatedNode.callout=callout;
        updatedNode.startNumber=startNumber

        if(isPrivate) {
          updatedNode.isPrivate =true;
        } else {
          delete updatedNode.isPrivate;
        }
    
        tableinfo.columns[rowindex] = updatedNode;
        // tableinfo.columns[rowindex].callout = callout;
        // tableinfo.columns[rowindex].uitype = 17;
        // tableinfo.columns[rowindex].header = header;
        // tableinfo.columns[rowindex].readOnly = true;
        // delete tableinfo.columns[rowindex].renderer; //remove textrender for this field.
        // delete tableinfo.columns[rowindex].concat; // 8-14-2020 remove concat as field is changing.
        // tableinfo.columns[rowindex].startNumber = startNumber;

        await updateTableInfoAPI(tableid, tableinfo);
 
        return 'success';
      }
    } catch (error) {
      console.log(error.message);
      return `An error occurred: ${error.message}`;
    }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };


  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }


  render() {
    const {
      showAdvanced,
      callout,
      startNumber,
      resetNumber,
      isPrivate
    } = this.state;

    const { color } = this.props;

    return (
      <div>
        <div style={{marginTop: '5px' }}>
        Users will see a read only number that incrementally counts for each record. You can reset the counter and set the starting number under More field attributes below.
        </div>
        <div style={{height:'15px'}}/>
        <GBSwitch
          color={color}
          text="More field attributes"
          isChecked={showAdvanced}
          Action={this.showAdvancedHandler}
        />

        {showAdvanced ? (
          <div>
              <div style={{marginTop:'10px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start',marginTop:'10px' }}>
              <div style={{ display: 'flex', fontWeight:'bold', width:'200px', flexDirection: 'column', alignItems: 'flex-start',marginRight:'20px' }}>
                <div>Reset numbering</div>
                <Checkbox checked={resetNumber} onChange={()=>this.setState({resetNumber: !resetNumber})} />
              </div>
              {resetNumber ? (
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start',justifyContent:'flex-start' }}>
               <div style={{marginRight:'20px'}}>
                <AdvancedField
                  placeholder="Indicate start number (default value is 1)"
                  field="startNumber"
                  name="Start number"
                  marginTop="0px"
                  width="100px"
                  changeHandler={this.changeHandler}
                  value={startNumber}
                />
                </div>
              <div>
                The counter for all of the records in this tab will be erased and re-calculated upon saving these changes.
              </div>
              </div>
            ): null}
            </div>

            <Callout callout={callout} updateCallout={this.CalloutHandler} />
          </div>
        ) : null}
      </div>
    );
  }
}


export default CounterField;
