/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import { getOptionsAPI } from '../../../../api/options';
import FilterOperators from './FilterOperators';

const AttachmentsFilter = ({
  filter,
  field,
  UpdateFilter,
  displayfield,
  header,
  uitype,
  remove,
  placeholder,
  color,
  tableid,
  lookuptableid,
  lookupfield
}) => {
  const [operator, setOperator] = useState('contains');
  const [fieldvalue, setFieldvalue] = useState('');
  const [optionData, setOptionData] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    let localFilter ={...filter}
    const listdata = [];
    const fetchData = async () => {
      const options = await getOptionsAPI(lookupfield !==null ? lookupfield : field, lookuptableid !==null ? lookuptableid : tableid);
      options.forEach((el) => {
        listdata.push({ key: el.optionid, value: el.optionid, text: el.option });
      });
      setOptionData(listdata);
      if(listdata.length>1) {
          setFilterOptions([
            { key: 'searchvalues', text: 'Category', value: 'searchvalues' },
            { key: 'contains', text: 'Search string', value: 'contains' },
            { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
            { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
          ])
         
      } else {
        setFilterOptions([
            { key: 'contains', text: 'Search string', value: 'contains' },
            { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
            { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
          ])
      }

      if (localFilter.value !== undefined) {
        updatefilter(localFilter.value,localFilter.operator)
        setOperator(localFilter.operator);
      } else {
          updatefilter('')
      }
    };
    fetchData();
  }, []);

  const updatefilter = (value,tmpOperator) => {

      setFieldvalue(value)
      // console.log(value)

      const textvalues = [];
      Array.isArray(value) && value.forEach((itm) => {
        const rowindex = optionData.findIndex((el) => el.value === itm);
        if (rowindex !== -1) {
          textvalues.push(optionData[rowindex].text);
        }
      });

      const filterObject = {
        field,
        header,
        value: value,
        textvalues,
        operator: tmpOperator !== undefined ? tmpOperator : operator,
        displayfield,
        uitype,
      };
      UpdateFilter(filterObject);
  };

  const changeOperator = (newoperator) => {
    setOperator(newoperator);
    updatefilter(fieldvalue,newoperator)
  };

  const changeCategory = (items) => {
    updatefilter(items)
  };

  const removeFilter = (newfield) => {
    remove(newfield);
  };


  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  return (
    <div style={{ marginBottom: '23px', width: '100%' }}>
      <FilterOperators
        header={header}
        operator={operator}
        filterOption={filterOptions}
        RemoveAction={removeFilter}
        RemoveActionValue={field}
        ChangeOperator={changeOperator}
        color={color}
      />

      {operator === 'isnull' || operator === 'isnotnull' ? (
        <Input style={{ width: '100%' }} value={displayvalue} />
      ) : null}
      {operator === 'contains' ? (
        <Input
          value={fieldvalue}
          onChange={(e, data) => updatefilter(data.value)}
          style={{ width: '100%' }}
          placeholder={placeholder}
        />
      ) : null}
      {operator === 'searchvalues' ? (
        <Dropdown
          fluid
          selection
          multiple
          value={fieldvalue}
        //   icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
          options={optionData}
          onChange={(e, data) => changeCategory(data.value)}
        />
      ) : null}
    </div>
  );
};

export default AttachmentsFilter;
