/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import { Input, Dropdown, Form, TextArea } from 'semantic-ui-react';
import { mdiClose, mdiEmail, mdiPlus, mdiChat } from '@mdi/js';
import Modal from 'react-modal';
import { AccountContext } from '../../providers/AccountProvider';
import Global from '../../clients/global';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import IconButton from '../Block/components/FilterData/IconButton';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import EmailControl from '../Block/components/MessageView/EmailControl';

const ActionSendMessage = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showChatBox, setShowChatBox] = useState(localData.textMessage !==undefined && localData.textIsActive ? true : false);
  const [fromAddresses, setFromAddresses] = useState([
    {
      key: '1',
      value: 'clientemaildelivery@graceblocks.com',
      text: 'clientemaildelivery@graceblocks.com',
    },
  ]);
  const [fromAddress, setFromAddress] = useState('clientemaildelivery@graceblocks.com');
  const [emailFields, setEmailFields] = useState([]);
  const [textFields, setTextFields] = useState([]);
  const [emailIsActive, setEmailIsActive] = useState(localData.emailIsActive ?? true);
  const [textIsActive, setTextIsActive] = useState(localData.textIsActive ?? false);
  const [selectedEmail, setSelectedEmail] = useState(
    localData.selectedEmail ?? [{ id: 1, field: 'manual', value: '' }],
  );
  const [selectedText, setSelectedText] = useState(
    localData.selectedText ?? [{ id: 1, field: 'manual', value: '' }],
  );
  const [refreshDate, setRefreshDate] = useState(Date.now());
  const [emailMessage, setEmailMessage] = useState(
    localData.emailMessage ?? 
    {
    subject: '',
    message: ``,
    fromAddress: 'clientemaildelivery@graceblocks.com',
    fromName: 'GraceBlocks Support',
    replyTo: 'support@graceblocks.com',
  });

  const [textMessage, setTextMessage] =useState(localData.textMessage ?? '');

  useEffect(() => {
    loadData();
  }, []);

  const addEmail = () => {
    const copyEmailSelected = structuredClone(selectedEmail);
    const id = Math.floor(Math.random() * 1000);
    copyEmailSelected.push({ id: id.toString(), field: 'manual', value: '' });
    setSelectedEmail(copyEmailSelected);

    localData.selectedEmail = copyEmailSelected;
    updateData(localData);
  };

  const removeEmail = (id) => {
    const copyEmailSelected = structuredClone(selectedEmail).filter((el) => el.id !== id);
    setSelectedEmail(copyEmailSelected);
    localData.selectedEmail = copyEmailSelected;
    updateData(localData);
  };

  const removeText = (id) => {
    const copyTextSelected = structuredClone(selectedText).filter((el) => el.id !== id);
    setSelectedText(copyTextSelected);
    localData.selectedText = copyTextSelected;
    updateData(localData);
  };

  const addText = () => {
    const copyTextSelected = structuredClone(selectedText);
    const id = Math.floor(Math.random() * 1000);
    copyTextSelected.push({ id: id.toString(), field: 'manual', value: '' });
    setSelectedText(copyTextSelected);

    localData.selectedText = copyTextSelected;
    updateData(localData);
  };

  const updateTextMessage =(val) =>{
    setTextMessage(val);

    localData.textMessage= val;
    updateData(localData)
  }

  const updateEmailisActive = () => {
    setEmailIsActive(!emailIsActive);
    localData.emailIsActive = !emailIsActive;
    updateData(localData);
  };

  const updateTextisActive = () => {
    setTextIsActive(!textIsActive);
    localData.textIsActive = !textIsActive;
    updateData(localData);
  };

  const loadData = async () => {
    getEmailandCollaboratorFields();

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);
      setAvailableFields(localData.availableFields);
    }

    const currentZone = userInfo.zones.filter((el) => el.id === parseInt(Global.zoneid))[0];
    let tempAddresses = [
      {
        key: '1',
        value: 'clientemaildelivery@graceblocks.com',
        text: 'clientemaildelivery@graceblocks.com',
      },
    ];
    if (currentZone.attributes.fromAddresses) {
      currentZone.attributes.fromAddresses.map((el) => {
        if (el.isActive) {
          tempAddresses.push({ key: el, value: el.email, text: el.email });
        }
      });
      setFromAddresses(tempAddresses);
    }
  };

  const updateEmailValue = (fld, val) => {
    const copySelectedEmail = structuredClone(selectedEmail);
    const idx = copySelectedEmail.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmail[idx].value = val;
    }

    setSelectedEmail(copySelectedEmail);
    localData.selectedEmail = copySelectedEmail;
    updateData(localData);
  };

  const updateTextValue = (fld, val) => {
    const copySelectedText = structuredClone(selectedText);
    const idx = copySelectedText.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedText[idx].value = val;
    }

    setSelectedText(copySelectedText);
    localData.selectedText = copySelectedText;
    updateData(localData);
  };

  const updateFromAddress = (val) => {
    const copyTask = structuredClone(taskMessage);
    copyTask.fromAddress = val;
    setFromAddress(val);
    setTaskMessage(copyMessage);
    localData.taskMessage = taskMessage;
    updateData(localData);
  };

  const getEmailandCollaboratorFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    const textOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];

    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype == 8 ||
            el.source?.lookupuitype === 8 ||
            el.uitype === 10 ||
            el.source?.lookupuitype === 10,
        )
        .map((col) => {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            el.uitype == 9 ||
            el.source?.lookupuitype === 9 ||
            el.uitype === 9 ||
            el.source?.lookupuitype === 9,
        )
        .map((col) => {
          textOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    setEmailFields(emailOptions);
    setTextFields(textOptions);
  };

  const handleEmailSelection = (fld, value) => {
    const copySelectedEmail = structuredClone(selectedEmail);
    const idx = copySelectedEmail.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedEmail[idx].field = value;
    }
    setSelectedEmail(copySelectedEmail);

    localData.selectedEmail = copySelectedEmail;
    updateData(localData);
  };

  const handleTextSelection = (fld, value) => {
    const copySelectedText = structuredClone(selectedText);
    const idx = copySelectedText.findIndex((el) => el.id === fld.id);
    if (idx !== -1) {
      copySelectedText[idx].field = value;
    }
    setSelectedText(copySelectedText);

    localData.selectedText = copySelectedText;
    updateData(localData);
  };

  const configureEmail = (
    emailObjectArray,
    messageSetting,
    templateFields,
    ids,
    blockRoles,
    role,
  ) => {
    const copyEmail = structuredClone(emailMessage);

    if (templateFields !== null) {
      copyEmail.message = templateFields.filter((el) => el.field === 'message')[0].fieldValue;
      copyEmail.fromName = templateFields.filter((el) => el.field === 'fromName')[0].fieldValue;
      copyEmail.subject = templateFields.filter((el) => el.field === 'subject')[0].fieldValue;
      copyEmail.replyTo = templateFields.filter((el) => el.field === 'replyTo')[0].fieldValue;
      if (templateFields.filter((el) => el.field === 'CC')[0].fieldValue !== '') {
        copyEmail.CC = templateFields.filter((el) => el.field === 'CC')[0].fieldValue;
      }
      if (templateFields.filter((el) => el.field === 'BCC')[0].fieldValue !== '') {
        copyEmail.BCC = templateFields.filter((el) => el.field === 'BCC')[0].fieldValue;
      }
      if (templateFields.filter((el) => el.field === 'files')[0].fieldValue !== '') {
        copyEmail.files = templateFields.filter((el) => el.field === 'files')[0].fieldValue;
      }

      setEmailMessage(copyEmail);
    } else if (emailObjectArray !== null) {
      copyEmail.message = emailObjectArray[0].message;
      copyEmail.fromName = emailObjectArray[0].fromName;
      copyEmail.subject = emailObjectArray[0].subject;
      copyEmail.replyTo = emailObjectArray[0].replyTo;
      if (emailObjectArray[0].CC !== '') {
        copyEmail.CC = emailObjectArray[0].CC;
      }
      if (emailObjectArray[0].BCC !== '') {
        copyEmail.BCC = emailObjectArray[0].BCC;
      }
      if (emailObjectArray[0].files !== null) {
        copyEmail.files = emailObjectArray[0].files;
      }
      setEmailMessage(copyEmail);
    }

    localData.emailMessage = copyEmail;
    updateData(localData)

    setModalIsOpen(false);
  };

  return (
    <div
      style={{
        position:'relative',
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '5px',
              marginBottom: '5px',
            }}
          >
            <div>
              <GBSwitch
                text="Send email message"
                isChecked={emailIsActive}
                Action={updateEmailisActive}
              />
            </div>
            <div style={{ width: '30px' }}></div>
            <div>
              <GBSwitch
                text="Send text message"
                isChecked={textIsActive}
                Action={updateTextisActive}
              />
            </div>
          </div>
        </div>
        {emailIsActive ? (
          <>
            <div style={{ marginTop: '10px', marginBottom: '10px', fontWeight: 'bold' }}>
              From address:{' '}
              <Dropdown
                fluid
                value={fromAddress}
                selection
                options={fromAddresses}
                onChange={(e, data) => updateFromAddress(data.value)}
              />
            </div>

            <div style={{ fontWeight: 'bold' }}>
              Email to <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
            </div>
            {selectedEmail.map((fld) => (
              <div
                style={{
                  width: '100%',
                  marginBottom: '10px',
                  border: '1px solid #0D99FF',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '100%' }}>
                  <Dropdown
                    options={emailFields}
                    value={fld.field}
                    selection
                    fluid
                    onChange={(e, data) => handleEmailSelection(fld, data.value)}
                  />
                  {fld.field === 'manual' ? (
                    <div style={{ marginTop: '10px' }}>
                      <Input
                        value={fld.value}
                        fluid
                        label="Email"
                        onChange={(e, data) => updateEmailValue(fld, data.value)}
                      />
                    </div>
                  ) : null}
                </div>
                {fld.id !== 1 ? (
                  <div style={{ marginLeft: '20px' }}>
                    <IconButton
                      iconSize="20px"
                      color="red"
                      hoverColor="white"
                      hoverBackgroundColor="#F6685E"
                      icon={mdiClose}
                      Action={removeEmail}
                      ActionValue={fld.id}
                    />
                  </div>
                ) : null}
              </div>
            ))}
            <div style={{ marginTop: '10px' }}>
              <TextButton
                hoverColor="#0D99FF80"
                textColor="#0D99FF"
                iconColor="#0D99FF"
                iconSize="20px"
                text="Add another to line"
                Action={addEmail}
                icon={mdiPlus}
                iconPosition="left"
              />
            </div>

            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <GBButton
                    text="Configure email"
                    icon={mdiEmail}
                    iconSize="20px"
                    iconColor="white"
                    iconHoverColor="white"
                    iconPosition="left"
                    borderRadius="10px"
                    Action={() => setModalIsOpen(true)}
                    ActionValue={'trigger'}
                    textColor="white"
                    width="150px"
                    height={'30px'}
                    color="#0D99FF"
                    textHoverColor="white"
                    hoverBackgroundColor={'#0D99FF80'}
                  />
                </div>
              </div>
            </div>
          </>
        ) : null}

        {textIsActive ? (
          <>
            <div style={{ marginTop: '20px' }}>
              <div style={{ fontWeight: 'bold' }}>
                Text to <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
              </div>
              {selectedText.map((fld) => (
                <div
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    border: '1px solid #0D99FF',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <Dropdown
                      options={textFields}
                      value={fld.field}
                      selection
                      fluid
                      onChange={(e, data) => handleTextSelection(fld, data.value)}
                    />
                    {fld.field === 'manual' ? (
                      <div style={{ marginTop: '10px' }}>
                        <Input
                          value={fld.value}
                          fluid
                          label="Phone number"
                          onChange={(e, data) => updateTextValue(fld, data.value)}
                        />
                      </div>
                    ) : null}
                  </div>
                  {fld.id !== 1 ? (
                    <div style={{ marginLeft: '20px' }}>
                      <IconButton
                        iconSize="20px"
                        color="red"
                        hoverColor="white"
                        hoverBackgroundColor="#F6685E"
                        icon={mdiClose}
                        Action={removeText}
                        ActionValue={fld.id}
                      />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
            <div style={{ marginTop: '10px' }}>
              <TextButton
                hoverColor="#0D99FF80"
                textColor="#0D99FF"
                iconColor="#0D99FF"
                iconSize="20px"
                text="Add another phone number"
                Action={addText}
                icon={mdiPlus}
                iconPosition="left"
              />
            </div>

            <div style={{ marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <div>
                  <GBButton
                    text="Configure text message"
                    icon={mdiChat}
                    iconSize="20px"
                    iconColor="white"
                    iconHoverColor="white"
                    iconPosition="left"
                    borderRadius="10px"
                    Action={() => setShowChatBox(!showChatBox)}
                    ActionValue={'trigger'}
                    textColor="white"
                    width="200px"
                    height={'30px'}
                    color="#0D99FF"
                    textHoverColor="white"
                    hoverBackgroundColor={'#0D99FF80'}
                  />
                </div>
              </div>
            </div>
            {showChatBox ? (
              <Form>
                <TextArea
                  style={{
                    marginTop:'20px',
                    fontSize: '12px',
                    border: '1px solid black',
                    borderRadius: '10px',
                    // width: '470px',
                  }}
                  onChange={(e, data) => updateTextMessage(data.value)}
                  value={textMessage}
                  placeholder="Enter text message here"
                />
              </Form>
            ) : null}
          </>
        ) : null}
      </div>

      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        style={{
          overlay: {
            position: 'fixed',
            zIndex:100,
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            border: `.3px solid #0D99FF`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 10,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
          },
        }}
        contentLabel="Example Modal"
      >
        <EmailControl
          toAddress={'{{dynamic}}'}
          tableid={sourceNodes[0]?.data?.tableData?.id}
          emailField={{
            fromAddress: emailMessage.fromAddress,
            replyTo: emailMessage.replyTo,
            fromName: emailMessage.fromName,
          }}
          replyMsg={emailMessage}
          color="#0D99FF"
          sendMessage={configureEmail}
          close={() => setModalIsOpen(false)}
          role={3}
        />
      </Modal>
    </div>
  );
};

export default ActionSendMessage;
