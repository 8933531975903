/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown,Input} from 'semantic-ui-react';
import { GetLookupOptionTableData,GetListData } from '../../../../api/GetData';
import FilterOperators from './FilterOperators';

const LookupFilter = (props) => {

    const [lookupData, setLookupData] = useState([])
    const [operator, setOperator] = useState('searchvalues');
    const [selectedOptions, setSelectedOptions] = useState([]);


    useEffect(() => {
        console.log(props.uitype)
        const fetchData = async () => {
            // const listdata = await GetLookupOptionTableData(props.tableinfo, props.fieldnode,props.tableid);
            let listdata=[];
            let tempData=[];
            if(fieldnode.jointype==='relational-lookup') {
                tempData = await GetListData(props.fieldnode.source.lookuptableid,props.fieldnode.source.lookupfield.replace('_options',''))
            } else if(fieldnode.jointype==='relational-lookup-lookup' || fieldnode.jointype==='relational-lookup-special' || fieldnode.jointype==='relational-special-special') { //relational-lookup-lookup
                const tblid = fieldnode.source.grouptable.split('_')[1];
                tempData = await GetListData(tblid,props.fieldnode.source.finallookupfield.replace('_options',''))
            } else if(fieldnode.jointype==='relational-special') {
                tempData = await GetListData(props.fieldnode.source.lookuptableid,props.fieldnode.source.lookupfield)
            }
            tempData.forEach(el => {
                listdata.push({ key: el.optionid, value: el.optionid, text: el.option })
            })
            setLookupData(listdata)
        }
        fetchData();
        
        if(props.filter.value !== undefined)
        {
         setSelectedOptions(props.filter.value)
        }
        // eslint-disable-next-line
    }, [props.fieldnode.data]);

    // const updateFilter =(items) => {
    //     var filterObject={field:props.fieldnode.data, value: items}
    //     props.UpdateFilter(filterObject);
    // }

    const updateFilter = (items, tmpoperator) => {
        setSelectedOptions(items);
        const textvalues = []
        
        // eslint-disable-next-line no-unused-expressions
        items.length > 0 && items.forEach(itm => {
            const rowindex = lookupData.findIndex(el => el.value === itm)
            if (rowindex !== -1) { textvalues.push(lookupData[rowindex].text) }
        })
        const filterObject = {
            field: props.fieldnode.data,
            header: props.fieldnode.header,
            value: items,
            uitype:props.uitype,
            textvalues,
            displayfield: props.fieldnode.displayfield,
            operator: tmpoperator === undefined ? operator : tmpoperator,
        }
        props.UpdateFilter(filterObject);
    }

    // const filterOption = [
    //     { key: 'searchvalues', text: 'Search values', value: 'searchvalues' },
    //     { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
    //     { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
    // ]
    let filterOption = [
        { key: 'searchvalues', text: 'Includes (or)', value: 'searchvalues' },
        { key: 'doesnotinclude', text: 'Does not include (or)', value: 'doesnotinclude' },
        { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
        { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
      ];

        //8-21-23  I've removed check out uitype.This filter is for lookup of single/multi select, but because it's a lookup
        //even a single select can have multiple values due to a multi-relational field. For now, this filter is separate from
        //options filter, but it may be we can combine this into a single filter. 
    //   if (props.fieldnode.uitype===6) {
        filterOption.splice(1, 0, { key: 'searchvalues_and', text: 'Includes (and)', value: 'searchvalues_and' });
        filterOption.splice(3, 0, { key: 'doesnotinclude_and', text: 'Does not include (and)', value: 'doesnotinclude_and' });
    //   }
    

    const changeOperator = (newoperator) => {
        setOperator(newoperator);
        updateFilter(selectedOptions, newoperator);
    }

    const removeFilter =(field) => {
        // updateFilter([]);
        props.remove(field);
    }

    const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

    const {fieldnode,color} = props;
    return (
        <div style={{ marginBottom: '23px', width: '100%' }}>

    <FilterOperators
        header={fieldnode.header}
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={fieldnode.data}
        ChangeOperator={changeOperator}
        color={color}
      />

        {operator==='isnull' || operator==='isnotnull' ? 
            (
               <Input style={{ width: '330px' }}  value={displayvalue} />
            )
            :
            (
                <Dropdown
                    style={{
                        borderTopLeftRadius:'5px', 
                        borderBottomLeftRadius:'5px', 
                        borderLeftWidth:'7px',
                        borderLeftColor:color,
                    }}
                    value={selectedOptions}
                    multiple search fluid selection
                    options={lookupData}
                    onChange={(e, data) => updateFilter(data.value)}
                />
            )}

        </div>
    )

}

LookupFilter.propTypes = {
    uitype: PropTypes.number.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    filter: PropTypes.object.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    fieldnode: PropTypes.object.isRequired,
    UpdateFilter: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
}

export default LookupFilter;