/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/core'
import NativeListener from 'react-native-listener';
import { BaseEditorComponent } from '@handsontable/react';
import { Dropdown } from 'semantic-ui-react';
import SelectedUserItem from '../../../../components/UserDropdown/SelectedUserItem'
import UserDropdown from '../../../../components/UserDropdown/UserDropdown';
import { getTableUsersAPI,getBlockUsersAPI,getUsersAPI } from '../../../../api/users';


class Collaborator extends BaseEditorComponent {
  constructor(props) {
    super(props);

    this.state = {
      renderResult: null,
      value: null,
      val: null,
      editMode: false,
      selectedItems: [],
      displayfield: '',
      workingItems: [],
      source:'',
      selectmode: '',
      uitype: '',
      left: 20,
      top: 30,
      row: 0,
      width: this.props.cellWidth,
      height: this.props.cellHeight,
      startingWidth:0,
      hasData: false,
      isExpanded: false,
      display: 'none',
      isChanged: false,
      expandDown:true,
      userType: 'zone',
      test:'2',
      definedUsers: []
    };
  }

  fetchTableData = async (field,userType,definedUsers,blockid) => {
       let result=null;
        if(!this.state.hasData){
          if(userType==='zone' || userType==='specific') {
            const filters=[{field:"status",value:['Active']}]
            result = await getUsersAPI(filters,false,"raw",userType==='specific' ? definedUsers : null) 
          } else if(userType==='block') {
            result = await getBlockUsersAPI(blockid,true)
          }
            this.setState({ field: result,hasData:true });
        }
  };

  renderLabel = (label) => {
    const { value, text } = label;

    const customLabel = (
      <div 
      style={{
        // backgroundColor: 'blue',
        // width:'50px',
        // overflow:'auto',
        color: 'white',
        borderRadius: '7px',
        display: 'inline-flex', // Use 'inline-flex' to make sure the label and the remove icon are in the same line
        // alignItems: 'center', // Center items vertically
        marginRight: '3px', // Add some space between labels
      }}>
        {text}
      </div>
    );

    return {
      color: 'white',
      content: customLabel
    };

  }


  displayOptions = () => {
    let userOptions=[];
    this.state.field?.map(itm=>{
      const control =<SelectedUserItem
          key={itm.userid}
          item={itm}
          backgroundColor='white'
          color={itm.attributes ? itm.attributes.text : 'white'}
        />
      userOptions.push({key:itm.userid, value:itm.userid,text:control})
    })

    return userOptions
  }

  searchItems =(options, query) =>{
    let textOptions=[];

    this.state.field.filter(el=>el.firstname.toLowerCase().startsWith(query)).map(itm=>{
      const control=<SelectedUserItem
      key={itm.userid}
      item={itm}
      backgroundColor='white'
      color={itm.attributes ? itm.attributes.text : 'white'}
    />
      textOptions.push({key:itm.userid, value:itm.userid,text:control})
    })
 
    return textOptions
  }


  stopMousedownPropagation(e) {
    e.stopImmediatePropagation();
    e.preventDefault();
  }

  stopKeydownPropagation(e) {
    e.stopImmediatePropagation();
    // e.preventDefault();
  }

  setValue(value, callback) {
    this.setState(() => {
      return { value };
    }, callback);
  }

  getValue() {
    return this.state.value;
  }

  open() {
    this.setState({ display: 'block' });
  }

  expand =(isExpanded,isCancel) => {
   
    this.setState({isExpanded})
    if(!isExpanded){
      if(isCancel){
        // this.props.Cancel()
      } 
    }
  }

  close= ()=> {

    this.setState({
      pickedColor: null,
      dataID: 0,
      display: 'none',
      isExpanded: false,
      isChanged: false,
      selectedItems: null,
      value: null,
      val:null
    });

    // this.finishEditing();

  }

   prepare(row, col, prop, td, originalValue, cellProperties) {
    super.prepare(row, col, prop, td, originalValue, cellProperties);
    // const { displayfield } = this.state;

    
    const item = cellProperties.data;

    if (this.state[item] == null) {
      this.fetchTableData(cellProperties.data,this.props.userType ?? 'zone',this.props.definedUsers ?? [], this.props.blockid);
    } 

    let tempValue =null;
    if(cellProperties.selectmode==='single' && originalValue !==null && originalValue[0] !==undefined)  {
      tempValue= originalValue[0].userid
    } else if(cellProperties.selectmode==='multiple' && originalValue !==null && originalValue !=='') {
      tempValue =  originalValue.map(el=>el.userid);
    }

    const tdPosition = td.getBoundingClientRect();
    const selectItems=originalValue !==null ? originalValue: null
    const halfHeight = window.innerHeight*.5
    const expandDown = tdPosition.y<halfHeight;

    this.setState({
      selectedItems:selectItems,
      blockid: this.props.blockid,
      displayfield: cellProperties.data,
      selectmode: cellProperties.selectmode,
      value: selectItems,
      val: tempValue,
      row: row,
      uitype: cellProperties.uitype,
      width: cellProperties.width+100,
      height: this.props.cellHeight,
      startingWidth: cellProperties.width,
      editMode:true,
      // dataID: this.props.GetRowData(row),
      left: `${tdPosition.left+1}px`,
      top: `${tdPosition.top+2}`,
      expandDown:expandDown,
      userType: this.props.userType ?? 'zone',
      definedUsers: this.props.definedUsers ?? []
    });
    

  }

  updateItems =(items) => {
    const {selectmode} = this.state;

    // 7-24-2020 fixing bug, The User object needs to match identically to the retrieved user object
    // in order to trigger the update on the grid. If the user object changes, we need to make sure it's the same
    // retrieved from userdropdown.
    // Because of the BlockUsers table (users in each block), the email address is retrieved twice (in order to display email field via the tableinfo metadata). As such
    // the email is first attribute, so we need to match this order. To resolve, in the Userdropdown component, when retrieving user data, we manually
    // construct the user object and put email first in attributes. This resolves the issue for when reviewing blockusers table
    // we need to test this also works for all other scenarios related to users.
    // console.log(JSON.stringify(items[0]))

    // 1-21-2021 we've added the avatar URL and avatarFilename to the user profile attributes JSON. As mentioned above, the format
    // needs to match identiacally to what is retrieved from grid search to recognize changes, so here we simply remove these 2
    // attributes so the user changes are recognized. 
    // delete items[0].attributes.avatar;
    

    if(items==='' && selectmode==='single'){
      items=[];
      this.setState({value:[],val:null})
      if(selectmode==="single")
      {
        this.setState(
          () => {
            return this.state.value;
          },
          () => {
            this.finishEditing();
          },
        );
      }
    }


    this.setState({val:items})

    if(this.state.selectmode==='single') {
      const idx = this.state.field.findIndex(el=>el.userid===items);
      if(idx !==-1) {
        const itm =this.state.field[idx];
        this.setState({value:[itm]},()=> this.finishEditing())
      }
    } else {
      const finalValues=[];
      items.map(itm=>{
        const idx = this.state.field.findIndex(el=>el.userid===itm);
        if(idx !==-1) {
          const itm = this.state.field[idx];
          finalValues.push(itm);
        }
      })
      this.setState({value:finalValues});
    }


    if(this.state.selectmode==='single')
    {
      this.setState(
        () => {
          return this.state.value;
        },
        () => {
          this.finishEditing();
        },
      );
    }

  }
 


  render() {
    let renderResult = null;
    const {expandDown,left,width,top,display,selectmode,val,selectedUsers} =this.state;
    const heightCalc =this.state.field?.length <5 ? this.state.field?.length *70 : 400

    if (this.state.editMode) {
      renderResult = (
        <NativeListener 
        onMouseDown={this.stopMousedownPropagation}
        onKeyDown={this.stopKeydownPropagation}
        >
          <div
            css={{
              // '&& .ui.selection.dropdown': {
              //   backgroundColor:`${this.props.color}1A !important`
              //  },
              //  '&& .ui.dropdown .menu': {
              //   maxHeight: '250px !important'
              //  },
              position: 'absolute',
              zIndex: 1001,
              // height: heightCalc,
              // overflow: 'auto',
              width:  this.state.width,
              backgroundColor: 'white',
              marginLeft:'1px',
              marginRight:'1px',
              left: left,
              top: expandDown ? `${top}px` : `${top-heightCalc}px`,
              // top: expandDown ? `${this.state.top}px` :  `${this.state.top-300}px`,
              display: display,
           
            }}
          >

           <Dropdown 
            scrolling={selectmode==='multiple'}
            multiple={selectmode==='multiple'}
            text={val===null ? 'Select an option' : null}
            key={new Date().getTime()} 
            search={this.searchItems} 
            renderLabel={this.renderLabel}
            fluid 
            value={val}
            clearable 
            // {...(callingComponent==='detailview' || callingComponent==='grid' ? { open: true } : {})}
            open={true}
            selection 
            options={this.displayOptions()} 
            onChange={(e,data)=>this.updateItems(data.value)} 
            />


        {/* <UserDropdown
          field={this.state.displayfield}
          uitype={8}
          row={this.state.row}
          blockid={this.props.blockid}
          updateFilter={this.updateItems}
          expand={this.expand}
          selectedUsers={this.state.selectedItems}
          color={this.props.color}
          lookup={this.state.selectmode}
          width={this.state.width}
          type={"restricted"} 
          userType={this.state.userType}
          definedUsers={this.props.definedUsers}
          callingComponent="grid"
        /> */}

          </div>
        </NativeListener>
      );
    } else if (this.props.isRenderer) {
      const colorboxStyle = {
        background: this.props.value,
        display:'flex',
        flexDirection:'row',
        flexWrap:'wrap',
        width: this.state.width,
        height: "30px" ,
        float: 'left',
        marginRight: '5px',
      };

      renderResult = (
        <>
          <div style={colorboxStyle}>
             {/* {this.props.value !=null && this.props.value.toString()} */}
            {this.props.value !==null && this.props.value !==undefined && Array.isArray(this.props.value) && this.props.value.map((usr,i) => (
                <SelectedUserItem uitype={this.props.uitype} key={i} item={usr} backgroundColor='white' color={usr.attributes.text} Action={null} />
            ))}
          </div>
        </>
      );
    }

    return <>{renderResult}</>;
  }
}

export default Collaborator;
