/* eslint-disable */

/* MPH  8-24-22 
  This method is used to determine which fields will appear to user based on defined filters per fields and 
  completed data. Need to construct proper method to determine if conditions are met in order to display the field.
  This is called on a per page basic. So, on a given form, fields can be organized by page. The controls for a given page
  are passed in here and the result will be a filtered result based on existing data.
  a field paramter will have a "conditions" node which is the array of filters to process. 
*/

import moment from 'moment';

const filterFields = (fields,values,nestedFields,rowData) =>{


    // 11-29-23 Added two new methods (convertDurationsInArray and durationTodays)
    //This is to suppoort formula fields that return a duration string. In order to filter
    //on the duration, we need to detect if its a duration string and then convert to a
    //fractional number of days.

    /* 11-29-23 Added logic for use-case where it's an update to the form, and we now
    allow all fields on the record to be used for filter conditions. If it's an update,
    they can reference existing read-only fields in the conditional logic. 

    Note: If they add read-only fields to a fields condition and it's a normal filling out of form
    (not an update), those fields will never appear on the form. So the user must be knowledable
    about what filter conidtionss they are using and how the will be applied. 

    */

    // GPT Generated
    const convertDurationsInArray=(numberValues) =>{
        return numberValues.map(value => {
            if (typeof value === 'string') {
                // Check if the string is a duration
                if (/\d+\s*days?|\d{1,2}:\d{2}:\d{2}/.test(value)) {
                    return durationToDays(value);
                }
            }
            // Return the value as-is if it's not a duration string
            return value;
        });
    }

    // GPT Generated
    const durationToDays = (durationStr) => {
        const daysRegex = /(\d+)\s*days?/;
        const timeRegex = /(\d{1,2}):([0-5][0-9]):([0-5][0-9])/;
    
        let totalDays = 0;
    
        const daysMatch = durationStr.match(daysRegex);
        if (daysMatch) {
            totalDays += parseInt(daysMatch[1], 10);
        }
    
        const timeMatch = durationStr.match(timeRegex);
        if (timeMatch) {
            const hours = parseInt(timeMatch[1], 10);
            const minutes = parseInt(timeMatch[2], 10);
            const seconds = parseInt(timeMatch[3], 10);
    
            const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
            totalDays += totalSeconds / 86400; // Convert seconds to fractional days
        }
    
        return parseFloat(totalDays.toFixed(3)); // Round to three decimal places
    }


    const filterField =(field,values) =>{
      
        let showField=true;
        
        field.conditions.map(filter => {

        
            //check if field is in values array
            const idx = Object.keys(values).findIndex(el=>el==filter.field);
        
            if(idx !==-1 && filter.operator!=='isnull' && filter.operator!=='isnotnull'){
               const currentValue = values[Object.keys(values)[idx]]
               if([1,6,7,8].includes(filter.uitype)){
                let answer=true;
                let nestedAnswer=false;
                
                //create mapping object so we can use this when evaluating condition depending
                //on which filter operator they specified. 
                const methodMapping = {
                    "searchvalues": "some",
                    "searchvalues_and": "every",
                    "doesnotinclude":"some",
                    "doesnotinclude_and": "every"
                };

                if(filter.operator==='searchvalues' || filter.operator==='searchvalues_and') {
                    const arrayMethod = methodMapping[filter.operator];
                    answer =currentValue.id[arrayMethod](item => filter.value.includes(item));
                    if(nestedFields[filter.field] !==undefined && filter.nestedLevels !==undefined) {
                      
                        nestedFields[filter.field].map(el=>{
                            
                            Object.keys(el).map(k =>{
                                if(filter.nestedLevels[k]!==undefined && filter.nestedLevels[k]!==null) {
                                    const testCondition = el[k].some(item=>filter.nestedLevels[k].value.includes(item))
                                    
                                    if(testCondition){
                                        nestedAnswer=true;
                                    }
                                }   
                            })
               
                        })
                    }
                } else if(filter.operator==='doesnotinclude' || filter.operator==='doesnotinclude_and'){
                    console.log('here')
                    const arrayMethod = methodMapping[filter.operator];
                    answer =!currentValue.id[arrayMethod](item => filter.value.includes(item));
                } else if(filter.uitype===1 && filter.operator==='contains') {
                     // 4-15-24 adding logic to allow relational values to incluide contain, notcontains
                     answer=false; 
                    if(currentValue.label !==undefined) {
                      answer = currentValue.label.some(itm=>itm.toLowerCase().includes(filter.value.toLowerCase()))
                    } else {
                      answer= currentValue.toLowerCase().includes(filter.value.toLowerCase());
                    }
                } else if(filter.uitype===1 && filter.operator==='notcontains') {
                     answer=false;
                    if(currentValue.label !==undefined) {
                        answer = currentValue.label.some(itm=>!itm.toLowerCase().includes(filter.value.toLowerCase()))
                    } else {
                        answer= !currentValue.toLowerCase().includes(filter.value.toLowerCase());
                    }
                }


                if(!answer && !nestedAnswer){
                    showField=false;
                }
               } else if(filter.uitype===5) {
                const val = filter.value==='true' ? true :  filter.value
                const answer= currentValue==='true' || currentValue===filter.value;
                if(!answer){
                showField=false;
                }
               } else if(filter.uitype===24 || filter.uitype===25) {
                const answer = filter.value.some(itm=>itm===currentValue.userid);
                if(!answer){
                    showField=false;
                }
               }
               else if([2,3,9,10,11,18].includes(filter.uitype) || (filter.uitype===16 && filter.startDate===undefined && filter.startNumber===undefined) ){
                    if(filter.operator==='contains'){
                       let answer=false;
                       if(currentValue.label !==undefined) {
                         answer = currentValue.label.some(itm=>itm.toLowerCase().includes(filter.value.toLowerCase()))
                       } else {
                         answer= currentValue.toLowerCase().includes(filter.value.toLowerCase());
                       }
                     
                       if(!answer){
                        showField=false;
                       }
                    } 
                    else if(filter.operator==='startswith') {
                        let answer=false;
                        if(currentValue.label !==undefined) {
                           answer = currentValue.label.some(itm=>itm.toLowerCase().startsWith(filter.value.toLowerCase()))
                        } else {
                           answer= currentValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        }
                        // const answer= currentValue.toLowerCase().startsWith(filter.value.toLowerCase());
                        if(!answer){
                         showField=false;
                        }
                    }
                    else if(filter.operator==='equalto') {
                       
                        let answer=false;
                        if(currentValue.label !==undefined) {
                           answer = currentValue.label.some(itm=>itm.toLowerCase() === filter.value.toLowerCase())
                        } else {
                           answer= currentValue.toLowerCase() ===filter.value.toLowerCase();
                        }

                        // const answer= currentValue.toLowerCase() ===filter.value.toLowerCase();
                        if(!answer){
                         showField=false;
                        }
                    }  
                    else if(filter.operator==='notequalto'){
                        let answer=false;
                        if(currentValue.label !==undefined) {
                           answer = currentValue.label.some(itm=>itm.toLowerCase() !== filter.value.toLowerCase())
                        } else {
                           answer= currentValue.toLowerCase() !== filter.value.toLowerCase();
                        }

                        // const answer= currentValue.toLowerCase() !== filter.value.toLowerCase();
                        if(!answer){
                         showField=false;
                        }
                    }
                    else if(filter.operator==='notcontains'){
                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>!itm.toLowerCase().includes(filter.value.toLowerCase()))
                        } else {
                            answer= !currentValue.toLowerCase().includes(filter.value.toLowerCase());
                        }
                        // const answer= !currentValue.toLowerCase().includes(filter.value.toLowerCase());
                        if(!answer){
                         showField=false;
                        }
                    }
               }
               else if(filter.uitype===22 || filter.uitype===19 || filter.uitype===20 ||  (filter.uitype===16 && filter.startDate !==undefined)){
              
                if(filter.operator==='after') {
                    let answer=false;
                    if(currentValue.label !==undefined) {
                        answer = currentValue.label.some(itm=>moment(itm) > moment(filter.startDate))
                    } else {
                        answer =moment(currentValue) > moment(filter.startDate);
                    }
                
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='before') {
                    let answer=false;
                    if(currentValue.label !==undefined) {
                        answer = currentValue.label.some(itm=>moment(itm) < moment(filter.startDate))
                    } else {
                        answer =moment(currentValue) < moment(filter.startDate);
                    }
                    // const answer = activeDate < moment(filter.startDate);
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='fromto') {

                    let answer=false;
                    if(currentValue.label !==undefined) {
                        answer = currentValue.label.some(itm=>moment(itm) < moment(filter.startDate) && moment(itm) < moment(filter.endDate))
                    } else {
                        answer = moment(currentValue) > moment(filter.startDate) && moment(currentValue) < moment(filter.endDate);
                    }

                    // const answer = activeDate > moment(filter.startDate) && activeDate < moment(filter.endDate);
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='specificdate') {
                    let answer=false;
                    if(currentValue.label !==undefined) {
                        answer = currentValue.label.some(itm=>moment(itm).format('YYYY-MM-DD') === moment(filter.startDate).format('YYYY-MM-DD'))
                    } else {
                        answer = moment(currentValue).format('YYYY-MM-DD') === moment(filter.startDate).format('YYYY-MM-DD') ;
                    }

                    // const answer = activeDate.format('YYYY-MM-DD') === moment(filter.startDate).format('YYYY-MM-DD') ;
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='timeframe') {
                    const tf2 = [24, 48, 72,168, 336, 720, 2160, 4320, 8640]
                    if(tf2.includes(filter.timeframe)){
                        const date = moment().subtract(filter.timeframe,'hours');

                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>moment(itm) > date);
                        } else {
                            answer =moment(currentValue) > date;
                        }
                        
                        // const answer = activeDate > date;
                        if(!answer){
                            showField=false;
                        }
                    } else if(filter.timeframe==='thisquarter') {
                        const startdate = moment().startOf('quarter');
                        const enddate =  moment().endOf('quarter');
                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>moment(itm) > startdate && moment(itm) < enddate);
                        } else {
                            answer = moment(currentValue) > startdate && moment(currentValue) < enddate;
                        }

                        // const answer = activeDate > startdate && activeDate < enddate;
                        if(!answer){
                            showField=false;
                        }
                    } else if(filter.timeframe=='lastquarter') {
                        const startdate = moment().subtract(90,"days").startOf('quarter');
                        const enddate =moment().subtract(90,"days").endOf('quarter');
                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>moment(itm) > startdate && moment(itm) < enddate);
                        } else {
                            answer = moment(currentValue) > startdate && moment(currentValue) < enddate;
                        }

                        // const answer = activeDate > startdate && activeDate < enddate;
                        if(!answer){
                            showField=false;
                        }
                    }
                    else if(filter.timeframe==='ytd') {
                        const startdate = moment().startOf('year');
                        const enddate = moment();

                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>moment(itm) > startdate && moment(itm) < enddate);
                        } else {
                            answer = moment(currentValue) > startdate && moment(currentValue) < enddate;
                        }

                        // const answer = activeDate > startdate && activeDate < enddate;
                        if(!answer){
                            showField=false;
                        }
                    }
                    else if(filter.timeframe=='lastyear') {
                        const startdate =moment().subtract(365,"days").startOf('year');
                        const enddate = moment().subtract(365,"days").endOf('year');

                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>moment(itm) > startdate && moment(itm) < enddate);
                        } else {
                            answer = moment(currentValue) > startdate && moment(currentValue) < enddate;
                        }

                        // const answer = activeDate > startdate && activeDate < enddate;
                        if(!answer){
                            showField=false;
                        }
                    } else if(filter.timeframe==='thisandlastquarter') {
                        const startdate = moment().subtract(90,"days").startOf('quarter');
                        const enddate = moment().endOf('quarter');
                        let answer=false;
                        if(currentValue.label !==undefined) {
                            answer = currentValue.label.some(itm=>moment(itm) > startdate && moment(itm) < enddate);
                        } else {
                            answer = moment(currentValue) > startdate && moment(currentValue) < enddate;
                        }

                        // const answer = activeDate > startdate && activeDate < enddate;
                        if(!answer){
                            showField=false;
                        }
                    } 
                }
               }
               else if(filter.uitype===23 || (filter.uitype===16 && filter.startNumber !==undefined)) {
            
                //11-29-23 for formula fields, they could be calculating an interval/duration.
                //that is represented by a string in the formula. So, we need to detect if the 
                //strings are durations and if so, convert to a days float. This method does that,
                //otherwise it returns the original array of values/strings.
                if(currentValue.label !==undefined) {
                    currentValue.label = convertDurationsInArray(currentValue.label)
                }

                if(filter.operator==='minmax') {
                    let answer=false;
                    if(currentValue.label!==undefined) {
                         answer = currentValue.label.some(itm=>parseInt(itm) > parseInt(filter.startNumber) && parseInt(itm) < parseInt(filter.endNumber))
                    } else {
                        answer = currentValue >= parseInt(filter.startNumber) && currentValue <= parseInt(filter.endNumber);
                    }
                    // const answer = currentValue >= parseInt(filter.startNumber) && currentValue <= parseInt(filter.endNumber);
                    
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='gt') {
                    let answer=false;
                    if(currentValue.label!==undefined) {
                         answer = currentValue.label.some(itm=>parseInt(itm) > parseInt(filter.startNumber))
                    } else {
                        answer = currentValue >= parseInt(filter.startNumber);
                    }

                    // const answer = currentValue > parseInt(filter.startNumber);
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='lt') {
                    let answer=false;
                    if(currentValue.label!==undefined) {
                         answer = currentValue.label.some(itm=>parseInt(itm) < parseInt(filter.startNumber))
                    } else {
                        answer = currentValue < parseInt(filter.startNumber);
                    }
                    // const answer = currentValue < parseInt(filter.startNumber);
                    if(!answer){
                        showField=false;
                    }
                } else if(filter.operator==='eq') {
                    let answer=false;
                    if(currentValue.label!==undefined) {
                         answer = currentValue.label.some(itm=>parseInt(itm) === parseInt(filter.startNumber))
                    } else {
                        answer = currentValue === parseInt(filter.startNumber);
                    }
                    // const answer = parseInt(currentValue) ===  parseInt(filter.startNumber);
                    if(!answer){
                        showField=false;
                    }
                }
               }
            } else if(idx!==-1 && filter.operator==='isnull'){
                showField=false;
            } else if(idx===-1 && filter.operator==='isnotnull'){
                showField=false;
            } 
            else if(idx ===-1 && (!['notcontains','notequalto','doesnotinclude','isnull'].includes(filter.operator) && filter.uitype!==5) ){
                showField=false;
            } 
            else if(idx===-1 && filter.uitype===5 && (filter.value===true || filter.value==='true')){
                showField=false;
            }
        })
        return showField;
    }

    const finalFields =[];

    const combinedValues = { ...values, ...rowData };

    fields.map(fld =>{
        if(fld.conditions !==undefined && fld.conditions.length>0) {
            const show = filterField(fld,combinedValues);
            if(show){
                finalFields.push(fld);
            }
        } else {
            finalFields.push(fld);
        }
    })

    return finalFields;

}

export default filterFields;