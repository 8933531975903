/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form, Radio, Dropdown,Input } from 'semantic-ui-react';
import { AccountContext } from '../../../../providers/AccountProvider';
import {updateTableInfoAPI} from '../../../../api/tables';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import {MapOptionValuesToText} from '../../../../api/GetData';

class NumberField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData } = this.props;
    this.state = {
      showAdvanced: false,
      callout: '',
      radiovalue: 'general',
      decimals: 0,
      symbol: 'en-US',
      minValue:'',
      maxValue:'',
      separator:'',
      defaultText: '',
      isPrivate: columnConfigData.isPrivate ?? false
    };
  }

  static contextType = AccountContext;
  
  componentDidMount() {
    const {columnConfigData} = this.props;

    let radiovalue='general';
    let separator ='';
    let decimals =0;
    let symbol ='en-US'

    if(columnConfigData.numericFormat && columnConfigData.numericFormat.pattern.toString().includes('$'))
    {
      radiovalue='currency';
    } else if (columnConfigData.numericFormat && columnConfigData.numericFormat.pattern.toString().includes('%'))
    {
      radiovalue="percent";
    } else{
      radiovalue='general';
    }

    if(columnConfigData.numericFormat && columnConfigData.numberSeparator==='comma')
    {
      separator='comma'
    } else if (columnConfigData.numericFormat && columnConfigData.numberSeparator==='period')
    {
      separator='period';
    } else{
      separator="none";
    }
    if (columnConfigData.numericFormat)
    {
      symbol = columnConfigData.numericFormat.culture;
    }
  
    decimals = columnConfigData.numericFormat ? columnConfigData.numericDecimals : '0';
    this.setState({decimals,radiovalue,separator,defaultText:columnConfigData.defaultText,symbol,maxValue:columnConfigData.maxValue, minValue:columnConfigData.minValue})
  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  Save = async () => {
    const { tableinfo, field, tableid,header,columnConfigData } = this.props;
    const {decimals,callout,separator,defaultText,minValue,maxValue,isPrivate} = this.state;

    const originalUIType = columnConfigData.uitype;

    const defaultNode=`{
      "data": "${field}",
      "width": 125,
      "displayfield": "${field}",
      "detailviewsort": ${columnConfigData.detailviewsort !==undefined ? columnConfigData.detailviewsort : 100}
  }`

  const updatedNode = JSON.parse(defaultNode)

    try {
    const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
    if (rowindex !== -1) {
      updatedNode.header=header;
      updatedNode.uitype=23;
      // updatedNode.type='numeric';
      updatedNode.renderer='TextRender';
      updatedNode.numericFormat=this.numberFormat();
      updatedNode.numericDecimals=decimals;
      updatedNode.numberSeparator = separator;
      updatedNode.callout= callout;
      updatedNode.defaultText=defaultText;
      if(minValue !==''){
        updatedNode.minValue=minValue;
      }
      if(maxValue !==''){
        updatedNode.maxValue=maxValue;
      }

      if(isPrivate) {
        updatedNode.isPrivate =true;
      } else {
        delete updatedNode.isPrivate;
      }

      tableinfo.columns[rowindex] = updatedNode;

      // if the source field is a date field, we need to run the date to numberic converstion, which will
      // result in all data being wiped out to null.
      if(originalUIType ===22)
      {
        const payload = { tableid, tableinfo, field, newfieldtype: 'numeric',castFunction:'cast_date_to_numeric' };
        await cloudrun.post('/convertField', { payload })
      }  else if(originalUIType ===17) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'numeric',castFunction:'cast_integer_to_numeric' };
        await cloudrun.post('/convertField', { payload })
      }
       // coming from single/multi-select. Map value to text first, then delete related select tables. 
      else if (originalUIType===6 || originalUIType===7){
        const table=`tbl_${tableid}`;
        await MapOptionValuesToText(table,columnConfigData.data,255)

        let payload = { tableid, tableinfo, field, newfieldtype: 'numeric',castFunction:'cast_text_to_numeric' };
        await cloudrun.post('/convertField', { payload })

        payload={tableid,fieldname:field,removeRelatedStructureOnly:true}
        await cloudrun.post('/removeField', { payload })
      }
      else if(originalUIType !==23) {
        const payload = { tableid, tableinfo, field, newfieldtype: 'numeric',castFunction:'cast_text_to_numeric' };
        await cloudrun.post('/convertField', { payload })
      } 

     
    
        // Update physical field in DB with default text.
    if(defaultText !== undefined)
    {
        const payload ={field,tableid,defaultText,uitype:23};
        await cloudrun.post('/updateFieldDefaultText', { payload })
    }
     // finally, we update metadata.
    await updateTableInfoAPI(tableid,tableinfo,columnConfigData);
    }
    return "success"
  } catch (error) {
    return `An error occurred: ${error.message}`
  }

  };

  handleChange = (value) => {
    this.setState({ radiovalue: value,decimals: value==='currency' ? '2' :'0' });
  };

  numberFormat = () => {
  const {decimals,symbol,radiovalue,separator} = this.state;
  let zeros='';
    for(let i=0;i<decimals;i+=1)
    {
      zeros+='0'
    }
    const pattern = `${radiovalue==='currency' ? '$': ''}0${separator==='comma' ? ',':''}${separator==='period' ? '.':''}${zeros.length>0 ? `.${zeros}`:''}${radiovalue==='percent' ? '%':''}`;
    const numericFormat = {"pattern": pattern,"culture": symbol};
    return numericFormat;

  }

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  CalloutHandler = (callout) => {
    this.setState({callout})
  }

  render() {
    const { showAdvanced, callout, radiovalue,decimals,symbol,separator,defaultText,minValue,maxValue,isPrivate } = this.state;
    const {color} = this.props;
    return (
      <div>
        <div>Store a numeric value</div>
        <div style={{height:'15px'}}/>
        <GBSwitch color={color} text="More field attributes" isChecked={showAdvanced} Action={this.showAdvancedHandler} /> 
        {showAdvanced ? (
          <Form>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
            <div style={{marginTop:'5px',marginBottom:'10px'}}>
            <GBSwitch
              color={color}
              text="Private field"
              isChecked={isPrivate}
              Action={this.togglePrivate}
            />
            </div>

              <div className="subtext" style={{ marginBottom: '3px' }}>
                <strong>Number type</strong>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '10px',
                  justifyContent: 'flex-start',
                 
                }}
              >
                <Form.Field>
                  <Radio
                    label="General"
                    name="type"
                    value="general"
                    checked={radiovalue === 'general'}
                    onChange={(e, data) => this.handleChange(data.value)}
                    style={{ marginRight: '10px', fontSize:"13px" }}
                  />
                </Form.Field>

                <Form.Field>
                  <Radio
                    label="Currency"
                    name="type"
                    value="currency"
                    checked={radiovalue === 'currency'}
                    onChange={(e, data) => this.handleChange(data.value)}
                    style={{ marginRight: '10px',fontSize:'13px' }}
                  />
                </Form.Field>

                <Form.Field>
                  <Radio
                    label="Percent"
                    name="type"
                    value="percent"
                    checked={radiovalue === 'percent'}
                    onChange={(e, data) => this.handleChange(data.value)}
                    style={{fontSize:'13px'}}
                  />
                </Form.Field>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    // marginTop: '10px',
                    marginRight: '15px',
                  }}
                >
                  <div style={{ marginBottom: '3px' }}>
                    <strong>000's seperator</strong>
                  </div>
                  <Dropdown
                    style={{ width: 'max-content', fontSize:'13px' }}
                    placeholder="None"
                    value={separator}
                    onChange={(e,data) =>this.setState({separator: data.value})}
                    floating
                    options={[
                      { key: '1', value: 'none', text: 'none' },
                      { key: '2', value: 'comma', text: ',' },
                    ]}
                  />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '15px',
                  }}
                >
                  <div style={{ marginBottom: '3px' }}>
                    <strong># of decimals</strong>
                  </div>
                  <Dropdown
                    floating
                    upward
                    value={decimals}
                    style={{fontSize:'13px'}}
                    onChange={(e,data) =>this.setState({decimals: data.value})}
                    scrolling
                    options={[
                      { key: '0.5', value: '0', text: '0' },
                      { key: '1', value: '1', text: '1' },
                      { key: '2', value: '2', text: '2' },
                      { key: '3', value: '3', text: '3' },
                      { key: '4', value: '4', text: '4' },
                      { key: '5', value: '5', text: '5' },
                      { key: '6', value: '6', text: '6' },
                      { key: '7', value: '7', text: '7' },
                      { key: '8', value: '8', text: '8' },
                      { key: '9', value: '9', text: '9' },
                    ]}
                  />
                </div>
                {radiovalue==='currency' ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className="subtext" style={{ marginBottom: '3px' }}>
                    <strong>Symbol</strong>
                  </div>
                  <Dropdown  
                  style={{fontSize:'11px'}}
                  value={symbol}
                  onChange={(e,data) =>this.setState({symbol: data.value})}
                  options={[
                      { key: '1', value: 'en-US', text: '$' },
                      { key: '2', value: 'en-GB', text: '£' },
                      { key: '3', value: 'de-DE', text: '€' },
                    ]} />
                </div>
                ): null}
              </div>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <div style={{ marginBottom: '3px' }}>
                <strong> Default value</strong>
              </div>
                <Input onChange={(e,data) =>this.setState({defaultText:data.value})} value ={defaultText ? defaultText : ''} size="small" placeholder="Default value" />
            </div>
              <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <div style={{ marginBottom: '3px' }}>
                    <strong> Min value</strong>
                  </div>
                    <Input onChange={(e,data) =>this.setState({minValue:data.value})} value ={minValue ? minValue : ''} size="small" placeholder="Min value" />
                </div>
                <div style={{width:'20px'}} />
                <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
                  <div style={{ marginBottom: '3px' }}>
                    <strong> Max value</strong>
                  </div>
                    <Input onChange={(e,data) =>this.setState({maxValue:data.value})} value ={maxValue ? maxValue : ''} size="small" placeholder="Max value" />
                </div>
              </div>

            <Callout callout={callout} updateCallout={this.CalloutHandler} />   
          </Form>
        ) : null}
      </div>
    );
  }
}

NumberField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tableinfo: PropTypes.object.isRequired,
};

export default NumberField;
