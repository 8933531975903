/* eslint-disable */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import DOMPurify from 'dompurify';

import { getTableHistory } from '../../../../api/tables';
import Avatar from '../../../../components/Avatar/Avatar';
import HistoryTag from './HistoryTag';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import SelectedUserItem from '../../../../components/UserDropdown/SelectedUserItem';
import MaskedText from '../../../../components/MaskedText/MaskedText';

const History = ({ color,tableid, id,canUnmask,tableinfo,role }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let d = await getTableHistory(tableid, id);

    //3-22-23 We need to remove any changes to "private" fields if the user does not have access to those fields.
    const privateFields = tableinfo.columns.filter(col=>col.isPrivate !==undefined).map(el=>el.data);
    if(tableinfo.security !==undefined && tableinfo.security.viewPrivate !==undefined && tableinfo.security.viewPrivate > role && privateFields.length>0) {
      
      //Strip out any changes from private fields. Remember a given change can consist of multiple fields.
      d.map((itm,i) =>{
        itm.changes = itm.changes.filter(e=>!privateFields.includes(e.field));
      })
      // remove any change nodes that no longer has any change data.
      d= d.filter(itm=>itm.changes.length>0);
    }
    setData(d);
  };

  const createMarkup = (html) => {
    DOMPurify.setConfig({ ADD_ATTR: ['target'] });
    return {
      __html: DOMPurify.sanitize(html),
    };
  };

  const showHtml =(value) =>{
    if(value !==null) {
    try{
      return <div style={{ height:'100%', width: '100%',  }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value) }} />
    } catch(error) {
      return <div></div>
    }
    } else {
      return <div></div>
    }
  }

  const displayPreviousValue = (oldValue,previousUIType) =>{
    if(![1,4,6,7,8,13].includes(previousUIType)) {
      return <div
            style={{
              marginRight: '10px',
              backgroundColor: '#FFDCE5',
              padding: '3px',
              textDecoration: 'line-through',
            }}
          >
            {previousUIType===22 ? `${moment(oldValue).format('DD-MMM-YYYY hh:mm:ss a zz')} EST`  :  oldValue.toString()}
          </div>
     } else if(previousUIType===13) {
       showHtml(oldValue);
     }
     else if(previousUIType===7 && oldValue.attributes !==undefined) {
       return  <HistoryTag color={oldValue.attributes.color} textColor={oldValue.attributes.text} update='r' text={oldValue.data} />
     } else if( previousUIType===6) {
        return  oldValue.map((itm) => (
          <HistoryTag key={itm.id} color={itm.attributes.color} textColor={itm.attributes.text} update={itm.update} text={itm.data} />
         ))
     }
     else if(previousUIType===4) {
        return  <ImageThumbnails maxWidth="50px" items={oldValue} Action={null} showDeleteIcon={false} showHover={false} />
     } else if(previousUIType===8) {
       return  <SelectedUserItem item={oldValue} backgroundColor={oldValue.backgroundColor} Action={null} />
     } else if(previousUIType===1) {
       return  <HistoryTag color={color} textColor='white' update='r' text={oldValue.data} />
     }
  }

  const displayChange = (itm, x) => {
    const finalChanges = [];
    let previousUIType = 2; 

    try {
    itm.changes.map((change) => {

      //Find the previous Change for this field.
      let previousChange = null;

      //11-1-22 We need to support logic where field types can change, and hence the data structure changes
      //we'll keep track of uitype of previous change and set default to 2 (importing spreadhsheet doesn't assign uitype). This is then used in logic
      //to just show previous type in it's original form.
      
      const index = data.findIndex(
        (el, i) => el.changes.findIndex((c) => c.field === change.field) !== -1 && i > x,
      );

      if (index !== -1) {
        previousChange = data[index].changes.filter((el) => el.field === change.field)[0];
        if(previousChange.uitype !==undefined) {
          previousUIType=previousChange.uitype; //if old value has a type, set it accordingly. 
        }
      }
     
      // process all multi-value types for multi-select, collaborators & attachments.
      // relational values will be processed separately.

      if (change.uitype === 6 || (change.lookup==='multiple' && [4,8].includes(change.uitype))) {
        let finalItems = [];
        let oldValue=null;
        if(previousChange !==null) {
          if(previousChange.uitype===7 || (change.lookup==='single' && [1,4,8].includes(change.uitype)) )
            {
              oldValue = structuredClone(previousChange.value)[0];
            } else{
              oldValue =structuredClone(previousChange.value);
            }
        }

        if (previousChange !== null && change.uitype===previousUIType) {
          //  oldValue =  structuredClone(previousChange.value);
           
          //Check old value versus new value. If it's in newvalue, mark with 'e'
          //for existing else with 'r' to indicate it was removed.
          oldValue.map((itm) => {
            let idx=-1;
            if(change.uitype===6){

                 idx = change.value.findIndex((el) => el.optionid == itm.optionid);
                 itm.update = idx === -1 ? 'r' : 'e';
                 finalItems.push(itm);
            } else if(change.uitype===8){
                idx = change.value.findIndex((el) => el.userid === itm.userid);
                itm.backgroundColor = idx === -1 ? '#F6685E' : null;
                finalItems.push(itm);
            } else if(change.uitype===4) {
                idx = change.value.findIndex((el) => el.url === itm.url);
                itm.guidname=itm.url;
                if(idx===-1){
                  itm.attributes = {color:'#F6685E'}
                } 
                else {
                  delete itm.attributes;
                }
                finalItems.push(itm);
            }
          });
        

          //check new value and mark any items no in old value as update ='n' for new.
          change.value.map((itm) => {
            let idx =-1;
            if(change.uitype===6) {
                idx = oldValue.findIndex((el) => el.optionid == itm.optionid);
                if(idx===-1) {
                  itm.update = 'n';
                  finalItems.push(itm);
                }
                
            } else if (change.uitype===8){
                idx = oldValue.findIndex((el) => el.userid === itm.userid);
                if(idx===-1) {
                  itm.backgroundColor='#93E088'
                  finalItems.push(itm);
                }
               
            } else if (change.uitype===4) {
                idx = oldValue.findIndex(el=>el.url===itm.url);
                if(idx===-1){
                  itm.guidname=itm.url;
                  itm.attributes = {color:'#93E088'}
                  finalItems.push(itm);
                }
            }
          });
        } else {
            change.value.map(itm =>{
              if(change.uitype===6){
                itm.update='n'
                finalItems.push(itm);
              } else if(change.uitype===8) {
                itm.backgroundColor='#93E088'
                finalItems.push(itm);
              } else if(change.uitype===4) {
                itm.guidname=itm.url;
                itm.attributes = {color:'#93E088'}
                finalItems.push(itm);
              }
            })
        } 
        finalChanges.push({ header: change.header, uitype: change.uitype,previousUIType:previousUIType, lookup:change.lookup, newValue: finalItems,oldValue });
      } else if (change.uitype === 7 || (change.lookup==='single' && [1,4,8].includes(change.uitype))) {
        let oldValue=null;
        if(previousChange !==null ) {
          if(previousChange.uitype===7 || (change.lookup==='single' && [1,4,8].includes(change.uitype)) )
            {
              oldValue = previousChange.value !==null ? structuredClone(previousChange.value)[0] : '';
            } else{
              oldValue =structuredClone(previousChange.value);
            }
        }
        let newValue=null;
        
        if (previousChange !== null && change.uitype===previousUIType) {
        
          // set old value of single select field
          if(change.uitype===7){
            oldValue = structuredClone(previousChange.value)[0];
          }  else  if(change.uitype===4 && previousChange.value.length>0) {
            oldValue =  structuredClone(previousChange.value);
            oldValue[0].guidname = oldValue[0].url;
            oldValue[0].attributes = {color:'#F6685E'}
          } else if(change.uitype===8 && previousChange.value.length>0) {
            oldValue =  structuredClone(previousChange.value[0]);
            oldValue.backgroundColor='#F6685E'
          } else if(change.uitype===1 && change.value !==null && change.value.length===1) {
            oldValue =  structuredClone(previousChange.value[0]);
          } else if(change.uitype===1 && change.value !==null && change.value.length===2) {
            //7-13-22. IF you use the grid to update a single relational field, both chanage
            //are recorded in the change. In this case, we use the change from the grid
            //instead of looking up previous change. The check above, where change length=1
            //happens when updating a single select from the detail page. In that case, use prevoius value.
            oldValue =  structuredClone(change.value).filter(rel=>rel.type===-1)[0];
          }
        }

        //set new value of single select field
        if(change.uitype===7) {
          newValue= change.value.length>0 ? change.value[0] : null;
        } else if(change.uitype===4 && change.value.length>0) {
          change.value[0].guidname=change.value[0].url;
          change.value[0].attributes = {color:'#93E088'}
          newValue= change.value;
        } else if(change.uitype===8 && change.value.length>0){
          change.value[0].backgroundColor='#93E088'
          newValue=change.value[0];
        } else if(change.value !==null && change.uitype===1 && change.value.length===1) {
          newValue=change.value[0];
        } else if(change.value !==null && change.uitype===1 && change.value.length===2){
          newValue = change.value.filter(rel=>rel.type===0)[0]
        }
          
          
          finalChanges.push({
            header: change.header,
            uitype: change.uitype,
            lookup:change.lookup,
            newValue: newValue,
            oldValue,
            previousUIType
          });
      }    
      else if (change.uitype===1 && change.lookup==='multiple')  {
        let oldValue=null;
        if(previousChange !==null && (previousChange.uitype===7 || (change.lookup==='single' && [1,4,8].includes(change.uitype))) )
          {
            oldValue = structuredClone(previousChange.oldValue)[0];
          } else if(previousChange !==null){
            oldValue = structuredClone(previousChange.oldValue);
          }
        finalChanges.push({ header: change.header,uitype:change.uitype,lookup:change.lookup, newValue: change.value,oldValue });
      } else {
        let oldValue = null;
        
        const index = data.findIndex(
          (el, i) => el.changes.findIndex((c) => c.field === change.field) !== -1 && i > x,
        );
        if (index !== -1) {
          oldValue = data[index].changes.filter((el) => el.field === change.field)[0].value;
        }
     
        // here, we have a text value. We then need to look a previous change and if it's a single select
        // set the value to [0] 1st index. This is what's expected with the historytag , etc...single select
        // values will not be arrays. 
        if(previousChange !==null && (previousChange.uitype===7 || (change.lookup==='single' && [1,4,8].includes(change.uitype))) )
          {
            oldValue = structuredClone(oldValue)[0];
          } 
        
        finalChanges.push({ header: change.header, oldValue, newValue: change.value !==null ? change.value.toString() : null,uitype:change.uitype,previousUIType });
      }
    }
    );

    //LOOP over finalchanges to render appropriate change UI per field type.l
    return finalChanges.map((el,i) => (
      <div key={i.toString()} style={{ marginTop: '1px',marginBottom:'10px',paddingLeft:'5px' }}>
        <div style={{ color: '#929292' }}>{el.header}</div>
       
        {![1, 4, 6, 7, 8, 14].includes(el.uitype) ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {el.oldValue !== null ? (
              <div
                style={{
                  marginRight: '10px',
                  backgroundColor: '#FFDCE5',
                  padding: '3px',
                  textDecoration: 'line-through',
                }}
              >
                {el.uitype===13 ? showHtml(el.oldValue) : el.oldValue.toString()}
              </div>
            ) : null}
            {el.newValue!==null ? (
            <div style={{ backgroundColor: '#D1F7C4', padding: '3px' }}>
              {el.uitype===22 ? `${moment(el.newValue).format('DD-MMM-YYYY hh:mm:ss a zz')} EST` : el.uitype===13 ? showHtml(el.newValue) : el.newValue}</div>
            ): null}
            {el.newValue===null ? (
             <div style={{ backgroundColor: '#D1F7C4', padding: '3px' }}>Removed all values</div>
            ):null}
          </div>
        ) : null}

        {el.uitype===14 ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {el.oldValue !== null ? (
             
              <div
                style={{
                  marginRight: '10px',
                  backgroundColor: '#FFDCE5',
                  padding: '3px',
                  textDecoration: 'line-through',
                }}
              >
                <MaskedText value={el.oldValue} uniformLength="8" maskType="full" canView={canUnmask} />
              </div>
            ) : null}
            {el.newValue!==null ? (
            <div style={{ backgroundColor: '#D1F7C4', padding: '3px' }}>
            <MaskedText value={el.newValue} uniformLength="8" maskType="full" canView={canUnmask} />
              </div>
            ): null}
            {el.newValue===null ? (
             <div style={{ backgroundColor: '#D1F7C4', padding: '3px' }}>Removed all values</div>
            ):null}
          </div>
        ) : null}

        {el.uitype === 7 ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {el.oldValue !== null && el.oldValue !==undefined ? (
                // <HistoryTag color={el.oldValue.attributes.color} textColor={el.oldValue.attributes.text} update='r' text={el.oldValue.data} />
                displayPreviousValue(el.oldValue,el.previousUIType)
            ) : null}
            {el.newValue !==null && el.newValue.attributes ? (
                <HistoryTag color={el.newValue.attributes.color} textColor={el.newValue.attributes.text} update='n' text={el.newValue.data} />
            ):null}
          </div>
        ) : null}
        {el.uitype === 6 ? (
          <div style={{whiteSpace:'wrap' }}>
             {el.oldValue !== null && el.oldValue !==undefined && el.uitype !==el.previousUIType ? (
                // <HistoryTag color={el.oldValue.attributes.color} textColor={el.oldValue.attributes.text} update='r' text={el.oldValue.data} />
                displayPreviousValue(el.oldValue,el.previousUIType)
            ) : null}
            {el.newValue.map((itm) => (
             <HistoryTag key={itm.id} color={itm.attributes.color} textColor={itm.attributes.text} update={itm.update} text={itm.data} />
            ))}
          </div>
        ) : null}
        {el.uitype===4 && el.lookup==='multiple' ? (
          <ImageThumbnails maxWidth="50px" items={el.newValue} Action={null} showDeleteIcon={false} showHover={false} />
        ): null}

        {el.uitype===4 && el.lookup==='single' ? (
           <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {/* {el.oldValue !==null && el.oldValue !==undefined ? (
                <ImageThumbnails maxWidth="50px" items={el.oldValue} Action={null} showDeleteIcon={false} showHover={false} />
            ):null} */}
            {el.newValue !==null ? (
                <ImageThumbnails maxWidth="50px" items={el.newValue} Action={null} showDeleteIcon={false} showHover={false} />
            ):null}
          
          </div>
        ): null}

        {el.uitype===8 && el.lookup==='multiple' ? (
          <div>
            {el.newValue.map(usr => (
              <SelectedUserItem key={usr.userid} item={usr} backgroundColor={usr.backgroundColor} Action={null} />
            ))}
        </div>): null}

        {el.uitype===8 && el.lookup==='single' ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
             {/* {el.oldValue !==null && el.oldValue !==undefined ? (
                <SelectedUserItem item={el.oldValue} backgroundColor={el.oldValue.backgroundColor} Action={null} />
             ) : null} */}
              {el.newValue !==null ? (
                 <SelectedUserItem item={el.newValue} backgroundColor={el.newValue.backgroundColor} Action={null} />
            ):null}
            </div>
        ): null}

      {el.uitype === 1 && el.lookup==='single' ? (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {el.oldValue !== null && el.oldValue !==undefined ? (
                <HistoryTag color={color} textColor='white' update='r' text={el.oldValue.data} />
                // displayPreviousValue(el.oldValue,el.previousUIType)
            ) : null}
            {el.newValue !==null && el.newValue !==undefined && el.newValue.type!==-1 ? (
                <HistoryTag color={color} textColor='white' update='n' text={el.newValue.data} />
            ):null}
                
          </div>
        ) : null}
        {el.uitype===1 && el.lookup==='multiple' && el.newValue !==null ? (
          <div style={{whiteSpace:'wrap' }}>
            {el.newValue.map(rel=> (
                <HistoryTag key={rel.id} color={color} textColor='white' update={rel.type===-1 ? 'r' : 'n'} text={rel.data} />
            ))}
            </div>
        ): null}
        {el.uitype===1 && (el.newValue===null || el.newValue.length===0) ? (
           <HistoryTag key={itm.id} color={color} textColor='white' update={'r'} text={'All items were removed.'} />
        ): null}

      </div>
    ));
    } catch(error) {
      return <div>An error occurred</div>
    }

  };

  const getDayDisplay = (currentDate) => {
    // const today = moment().startOf('day');
    const displayDate = `${moment(currentDate).format('DD-MMM-YYYY hh:mm:ss a zz')} EST`;
    const today = moment();
    const changeDate = moment(currentDate);
    const days = today.diff(changeDate, 'days');
    const minutes = today.diff(changeDate,'minutes');
    let hours = minutes/60
    const remainderMinutes = parseInt((hours.toFixed(2) + "").split(".")[1]*.60);
    // const finaltime = `${minutes}/60`
    const hourMinuteTime = `${parseInt(hours)} hours ${remainderMinutes} min ago`


    if (minutes <= 60) {
      return `${minutes} min ago`;
    } else if (minutes < 1440) {
      return hourMinuteTime;
    } else {
      return displayDate;
    }

  };

  return (
    <div style={{ margin: '10px',marginBottom:'20px', overflow:'auto',height:'100%' }}>
     
      {data.length > 0 &&
        data.map((itm, i) => (
          <div key={i.toString()} style={{ margin: '10px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <SelectedUserItem fontSize="12px" item={itm.user} Action={null}/>
              </div>
              <div style={{fontSize:'12px'}}>{getDayDisplay(itm.addedon)}</div>
            </div>
            <div style={{ backgroundColor: '#F2F2F2', marginLeft: '25px', paddingBottom: '2px' }}>
              {displayChange(itm, i)}
            </div>
          </div>
        ))}
    </div>
  );
};

export default History;
