/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useRef } from 'react';

import moment from 'moment';
import { mdiCloseCircleOutline, mdiEmailPlus, mdiRefresh,mdiReply } from '@mdi/js';
import { Label, Input } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import { AccountContext } from '../../../../providers/AccountProvider';
import {getEmailMessagesAPI, deleteScheduledEmail,  getAllToAddressPerRow} from '../../../../api/messages';
import Avatar from '../../../../components/Avatar/Avatar';
import cloudrun from '../../../../clients/httpcloudrun';
import Spinner from '../../../../components/Spinner/Spinner';
import ImageThumbnails from '../../../../components/ImageThumbnails/ImageThumbnails';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import LetterColors from '../../../../utils/LetterColors';
import { getUsersAPI } from '../../../../api/users';
import EmailControl from './EmailControl';
import ViewFullEmail from './ViewFullEmail';
import SendButton from './SendButton';
import Global from '../../../../clients/global'



const EmailMessages = ({
  toAddress,
  color,
  tableid,
  dataID,
  tableinfo,
  RID,
  canMessage = true,
  role,
  callingComponent='detailview',
  isShare=false
}) => {
  const { userInfo } = useContext(AccountContext);

  const [messages, SetMessages] = useState([]);
  const [messageUsers, setMessageUsers] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [currentToAddress, setCurrentToAddress] = useState(toAddress);
  const [emailField, setEmailField] = useState(null);
  const [allAddresses, setAllAddresses] = useState([]);
  const [hoverMessageId, setHoverMessageId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [showCompose, setShowCompose] = useState(false);
  const [currentEmail, setCurrentEmail]=useState(null);
  const [showFullEmail, setShowFullEmail] =useState(false);
  const [replyMsg, setReplyMsg] = useState(null);
  const divRef = useRef(null);

  useEffect(() => {
    setCurrentToAddress(toAddress)
    setAllAddresses([]);
    fetchData(true,toAddress);
    
  }, [toAddress]);

  // 6-15-2023 Load any other toAddresses that have been sent email messages from this table/row. This
  // happens when a user changes the recepient email.
  const getAddresses = async () => {
    const uniqueAddresses = await getAllToAddressPerRow(tableid, dataID);
    const addresses = [];
    if (uniqueAddresses.length > 0) {
      uniqueAddresses.map((el) => {
        addresses.push(el.toaddress);
      });
    }

    //Check if existing toAddress is in list of prevoius email addresses used. If not, add it.
    const idx = addresses.findIndex(e=>e===toAddress);
    if(idx===-1){
      addresses.push(toAddress)
    }

    return addresses;
  };

  const Reply =(replyMsg)=>{
    const msg = structuredClone(replyMsg);
    msg.subject='Re: ' +msg.subject

    const replyHeader = "<p/><p style='color: grey;'>----- Original Message -----</p>";
    const indentedOriginalContent = `<blockquote style='border-left: 2px solid grey; padding-left: 10px; margin-left: 10px; color: grey;'>${msg.message}</blockquote>`;
    msg.message=replyHeader + indentedOriginalContent;

    setReplyMsg(msg);
    setShowCompose(true);
  }

  useEffect(() => {
    const emailFieldTemp = tableinfo.columns.filter(
      (itm) => itm.uitype === 10 && itm.enableEmailMessages,
    )[0];
    setEmailField(emailFieldTemp);
  }, [tableid]);

  const scrollHandler = () => {
    if (divRef.current !== null) {
      divRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  };

  const toggleShowCompose = () => {
    setReplyMsg(null);
    setShowCompose(!showCompose);
  };

  const toggleFullEmail =(email) =>{
    setCurrentEmail(email)
    setShowFullEmail(true);
  }


  const changeAddress = async (address) => {
    setCurrentToAddress(address);
    await fetchData(true,address);
  };

  const fetchData = async (showSpinner = true,address) => {
    // 12-4-2020 added showSpinner param so that when we are doing interval checks for new messages
    // it doesn't show spinner, but when a user sends a message from UI, it shows spinner.

    let AllUsers = [];
    if (messageUsers.length === 0) {
      AllUsers = await getUsersAPI([], true);
      setMessageUsers(AllUsers);
    } else {
      AllUsers = messageUsers;
    }

    setIsLoading(showSpinner ? true : false);

    const emailField = tableinfo.columns.filter(
      (itm) => itm.uitype === 10 && itm.enableEmailMessages,
    )[0];

    // let newMessages = await getEmailMessagesAPI(schema, tableid, dataID, phonefield);
    let newMessages = await getEmailMessagesAPI(
      tableid,
      dataID,
      emailField.replyTo,
      address,
      emailField.replyConfiguration
    );

    //7-12-23 moved this outside the if statement below...we shoudld always show previous used emails

    const oldAddresses = await getAddresses();
    setAllAddresses(oldAddresses);

    if (newMessages.length > 0 && AllUsers.length > 0) {
  
      newMessages.sort((a, b) => (a.addedon > b.addedon ? 1 : -1));

      newMessages.forEach((itm) => {
        const today = moment().startOf('day');
        const msgDate = moment(itm.addedon).startOf('day');
        const days = today.diff(msgDate, 'days');
        if (days === 0) {
          itm.day = 'Today';
        } else if (days === 1) {
          itm.day = 'Yesterday';
        } else {
          itm.day = moment(itm.addedon).format('DD-MMM-YYYY');
        }
        const addedbyUser = AllUsers.filter((el) => el.userid === itm.addedby)[0];
        itm.User = addedbyUser;
      });
      SetMessages(newMessages);

      
      scrollHandler();
    } else if (newMessages.length === 0) {
      SetMessages([]);
    }
    setIsLoading(false);
  };


  const ConvertDelayIntoSeconds = (messageSetting) => {
    let seconds = 0;
    if (messageSetting.interval === 'minutes') {
      return messageSetting.number * 60;
    }
    if (messageSetting.interval === 'hours') {
      return messageSetting.number * 3600;
    }
    if (messageSetting.interval === 'days') {
      return messageSetting.number * 86400;
    }
  };

  const DeleteMessage = async (messageid,batch_id) => {
    await deleteScheduledEmail(messageid, batch_id);
    await fetchData();
  };

  const getMessageBorder = (msg) => {

    if (['delivery_unknown', 'failed', 'undelivered'].includes(msg.status)) {
      return '2px solid red';
    } else if (msg.status === 'queued') {
      return `1px solid ${color}1A`;
    } else if (msg.schedule !== null && isStillScheduled(msg.schedule)) {
      return `2px dashed ${color}`;
    } else {
      return `1px solid ${color}`;
    }
  };

  const getHeight = () =>{
    let height='100%';
    if(callingComponent==='chatview') {
      height='calc(100vh - 330px)'
    }
    else if(callingComponent==='detailview' && !isShare) {
      height='90%'
    } 
    return height;
  }

  const sendMessage = async (emailObjectArray,messageSetting,templateFields,ids,blockRoles,role) => {

    // 1-28-2021 adding zoneRole for business logic in send message (if out of funds, different message based on role/owner or user)
    const zoneRole = userInfo.zones.filter((itm) => itm.id === parseInt(Global.zoneid))[0].role;
    let result = '';
    const payload = {
      tableid,
      emailObjectArray,
      templateFields,
      ids,
      blockRoles,
      role,
      zoneRole,
      schedule: messageSetting.interval === 'none' ? null : ConvertDelayIntoSeconds(messageSetting),
      replyConfiguration: emailField.replyConfiguration
    };

    result = await cloudrun.post('/sendEmailApi', { payload });
    // result = await cloudrun.post('/sendEmailTest', { payload });


    // Put error message in Toast.
    if (result !== 'success') {
      const errormessage = (
        <div>
          Oops, something went wrong. Issue(s) identified:
          {result.map((el) => (
            <ul>
              <li>
                {el.error === 'invalidFunds' ? (
                  <div>
                    This Zone has depleted all available messaging funds. To continue sending
                    messages, a Zone owner will need to purchase more funds under Zone settings
                    (gear icon in left panel of the GraceBlocks Homepage).
                  </div>
                ) : null}
                {el.error === 'nofunds' ? <div>{el.message}</div> : null}
              </li>
            </ul>
          ))}
        </div>
      );
      toast.info(errormessage, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    await fetchData(true,currentToAddress);
    return result;
  };

  const DisplayAddresses = () => {
    
    if (allAddresses.length > 1) {
      return (
        <select value={currentToAddress} onChange={(e) => changeAddress(e.target.value)}>
          {allAddresses.map((el) => (
            <option key={el} value={el}>
              {el}
            </option>
          ))}
        </select>
      );
    } else {
      return currentToAddress;
    }
  };

  const ShowImageInWindows = (item) => {
    window.open(item.url);
  };

  const getLetterColor = (letter) => {
    const idx = LetterColors.findIndex((el) => el.letter === letter.toLowerCase());
    if (idx !== -1) {
      return LetterColors[idx].color;
    } else {
      return '#1B8892';
    }
  };

  const isStillScheduled =(schedule) =>{
    const sch = new Date(schedule)
    const now = new Date();
    return sch > now;
  }

  const DisplayMessages = () => {
    // The UI groups messages by day. We create a unique set of days by which
    // we loop over to display messages chronologically and by day.
    const days = messages.map((el) => el.day);
    let uniquedays = [...new Set(days)];

    return uniquedays.map((itm) => (
      <div
        style={{ display: 'flex', width: '100%', flexDirection: 'column' }}
        key={itm}
        // onClick={() => setShowTemplates(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <hr style={{ border: '1px solid #eee', width: '100%' }} />
          <div
            style={{
              width: '100px',
              marginLeft: '10px',
              marginRight: '10px',
              whiteSpace: 'nowrap',
            }}
          >
            {itm}
          </div>
          <hr style={{ border: '1px solid #eee', width: '100%' }} />
        </div>
        {messages
          .filter((el) => el.day === itm)
          .map((msg) => (
            <div key={msg.messageid}>
              <div
                onMouseOver={() => setHoverMessageId(msg.messageid)}
                onMouseOut={() => setHoverMessageId(null)}
                style={{
                  display: 'flex',
                  marginLeft: '10px',
                  marginRight: '10px',
                  flowDirection: 'row',
                  alignItems: 'center',
                  minWidth: '0px',
                  justifyContent: msg.tablefrom !== null ? 'flex-end' : 'flex-start',
                }}
              >
                {msg.tablefrom === null ? (
                  <div
                    style={{
                      backgroundColor: getLetterColor(RID.slice(0, 1)),
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '30px',
                      height: '30px',
                      borderRadius: '30px',
                      color: 'white',
                    }}
                  >
                    {RID.slice(0, 1)}
                  </div>
                ) : null}
                {msg.schedule !== null && isStillScheduled(msg.schedule) ? (
                  <div
                    onClick={() => DeleteMessage(msg.messageid,msg.batch_id)}
                    css={{
                      color: '#E89089',
                      cursor: 'pointer',
                      '&:hover': {
                        svg: {
                          color: '#E8908980',
                        },
                      },
                    }}
                  >
                    <Icon path={mdiCloseCircleOutline} size="25px"></Icon>
                  </div>
                ) : null}
                <Label
                  onClick={()=>toggleFullEmail(msg)}
                  pointing={msg.tablefrom === null ? 'left' : 'right'}
                  style={{
                    zIndex: '0',
                    maxWidth: '50%',
                    backgroundColor: msg.tablefrom === null ? `white` : '#F0F0F0',
                    border: msg.messageid === hoverMessageId ? `1px solid ${color}`: getMessageBorder(msg),
                    boxShadow:  msg.messageid === hoverMessageId ? '0px 3px 6px #707070'  : null,
                    cursor:'pointer'
                  }}
                >
                  {msg.tablefrom === null ? (
                      <div style={{maxHeight:'50px',overflow:'hidden'}} dangerouslySetInnerHTML={{ __html: msg.message }}></div>
                    ) : null}

                  {msg.files !== null ? (
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                      <ImageThumbnails
                        items={msg.files}
                        maxWidth="50px"
                        Action={ShowImageInWindows}
                        showDeleteIcon={false}
                      />
                    </div>
                  ) : null}

                  {msg.tablefrom !== null ? <div onClick={()=>toggleFullEmail(msg)} style={{maxHeight:'50px',overflow:'hidden'}} dangerouslySetInnerHTML={{ __html: msg.message }}></div> : null}
                </Label>
                {msg.tablefrom==null ? (
                  <div style={{marginLeft:'10px'}}>
                  <GBButton text='Reply' color={color} textColor='white' iconHoverColor='white'  width='75px' iconSize='20px' iconColor='white' icon={mdiReply} iconPosition='left' Action={Reply} ActionValue={msg} />
                  </div>

                ):null}
                {msg.tablefrom !== null ? (
                  <div>
                    <Avatar item={msg.User} width="35px" height="35px" fontSize="13px" />
                  </div>
                ) : null}
              </div>
              <div
                style={{
                  visibility: msg.messageid === hoverMessageId ? 'visible' : 'hidden',
                  display: 'flex',
                  marginLeft: '50px',
                  marginRight: '60px',
                  fontSize: '10px',
                  justifyContent: msg.tablefrom !== null ? 'flex-end' : 'flex-start',
                }}
              >
                {msg.tablefrom !== null ? (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <span>
                        {msg.schedule !== null ? 'Scheduled by' : 'Sent by'} {msg.User.firstname}{' '}
                        {msg.User.lastname} from {msg.fromaddress} {' at '}
                        {moment(msg.addedon).format('hh:mm:ss a ')} EST{' '}
                      </span>
                    </div>
                  </div>
                ) : (
                  <span>Received @ {moment(msg.addedon).format('hh:mm:ss a ')} EST </span>
                )}
              </div>
            </div>
          ))}
      </div>
    ));
  };

  return (
    <div
      tabIndex={0}
      style={{
        display: 'flex',
        // height: callingComponent==='detailview' ? 'calc(100vh - 200px)' : 'calc(100vh - 300px)',
        height: getHeight(),
        width: '100%',
        outline: 'none',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        // overflow:'auto',
        // position:'relative'
      }}
    > 
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          // maxHeight:'600px',
          width: '100%',
          // border:'1px solid green',
          overflow: 'auto',
          // position:'relative',
          zIndex: 2,
        }}
      >
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', margin: '10px', width: '100%',flexDirection:'row',alignItems:'center',justifyContent:'space-between' }}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            Send to {RID}
            {toAddress !== null ? (
              <div style={{marginLeft:'5px'}}>({DisplayAddresses()})</div>
            ) : (
              <span style={{ color: 'red' }}>'NO VALID EMAIL ADDRESS'</span>
            )}
            </div>
            <div>
              <TextButton text="Refresh" icon={mdiRefresh} fontSize='17px' iconSize='17px' textColor='black' hoverColor="#00000080" Action={()=>fetchData(true,currentToAddress)} />
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflow: 'auto' }}>
          {messages.length > 0 ? DisplayMessages() : null}
          <div style={{ width: '1px', height: '0px' }} ref={divRef} />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: '10px',
          position: 'relative',
          zIndex: 10,
        }}
      >
        {showCompose ? (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 100,
              backgroundColor: 'white',
              width: '80%',
              height: 'calc(100vh - 100px)',
              // height: '100%',
              border: '1px solid #aaa',
              boxShadow: '0px 3px 6px #707070',
              borderRadius: '10px',
            }}
          >
            <EmailControl
              tableid={tableid}
              dataID={dataID}
              toAddress={currentToAddress}
              emailField={emailField}
              color={color}
              sendMessage={sendMessage}
              close={() => setShowCompose(false)}
              role={role}
              replyMsg={replyMsg}
            />
          </div>
        ) : null}

        {showFullEmail ? (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 100,
              backgroundColor: 'white',
              width: '80%',
              height: 'calc(100vh - 100px)',
              border: '1px solid #aaa',
              boxShadow: '0px 3px 6px #707070',
              borderRadius: '10px',
            }}
          >
            <ViewFullEmail
              color={color}
              message={currentEmail}
              close={() => setShowFullEmail(false)}
            />
          </div>
        ) : null}

        {isLoading ? (
          <div style={{ position: 'absolute', bottom: '250px', left: '50%', zIndex: 1000 }}>
            <Spinner color={color} showMessages={false} />
          </div>
        ) : null}

        {toAddress !== null && canMessage ? (
           <div style={{display:'flex',flexDirection:'row', justifyContent:'flex-end',marginRight:'20px'}}>
           <SendButton
           iconPosition="right"
           color={color}
           icon={mdiEmailPlus}
           Action={() => toggleShowCompose()}
           text="Compose new email"
           width={'170px'}
         /></div>
          // <button onClick={() => toggleShowCompose()}>Compose message</button>
        ) : null}
      </div>

    </div>
  );
};

export default EmailMessages;
