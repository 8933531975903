/* eslint-disable */
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Dropdown, Popup } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiInformationOutline, mdiAlert,mdiAccountCircle } from '@mdi/js';
import {
  updateTableInfoAPI,
  getTablesAPI,
  getFieldsAPI,
} from '../../../../api/tables';
import { getZoneAPI,provisionPhoneNumber } from '../../../../api/zone';
import { AccountContext } from '../../../../providers/AccountProvider';
import { getBlocksAPI } from '../../../../api/blocks';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import GBButton from '../../../../components/GBButton/GBButton';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import Callout from './Callout';
import cloudrun from '../../../../clients/httpcloudrun';
import invalidUSRegions from '../../../../utils/invalidUSRegions';
import Global from '../../../../clients/global'

class MessageField extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo } = this.props;
    this.input = React.createRef();

    this.state = {
      showAdvanced: false,
      maxlength: columnConfigData.maxlength,
      callout: columnConfigData.callout,
      tableinfo,
      defaultPhoneNumber:
        columnConfigData.phonenumber !== undefined ? columnConfigData.phonenumber : null,
      phoneNumbers: [],
      phoneFields:[],
      phoneField: columnConfigData.phonefield !== undefined ? columnConfigData.phonefield : null,
      blocks: [],
      selectedBlock: 0,
      tables: [],
      selectedTable: 0,
      fields: [],
      confirmText: '',
      selectedField: '',
      isPrivate: columnConfigData.isPrivate ?? false,
      altPhoneField: columnConfigData.altPhoneField ?? null

    };
  }

  static contextType = AccountContext;

  async componentDidMount() {
    const { userInfo } = this.context;
    const { columnConfigData,tableinfo, color,uitype } = this.props;
    const pfields= tableinfo.columns.filter(itm=>(itm.uitype===9 || (itm.source?.lookupuitype===9 && itm?.source.lookup==='single')) && itm.isMobile===true);
    
    // if there are no configure phone fields, show general message to add phone field
    // and skip any addition logic to check if Twilio number is configured.

    if(pfields.length > 0)
    {
      //Check if user has validated Phone number, if Not, check this first.
        let title=''
        let message=''
        const configTest = await this.configureTwilioNumber();
         if(configTest ==="ValidatePhone") {
              title="Phone validation warning"
              message=<div>
                In order to add text messaging, we need a validated phone number on file associated
                  with your record. Please go to your Profile page
                  <Icon path={mdiAccountCircle} size="25px" />
                  in the top right area of this page, add a mobile phone number, and verify it. Then you
                  will be able to proceed with this action.
              </div>
              this.confirmAction(title,message,false);
         } else if(configTest==='ActivateMessaging')
         {
            message= <div>Messaging has not yet been activated in this Zone. By using the text messaging template, you will activate the free 30-day messaging trial which allows send/receive your first few messages using some initial funds we have provided. To continue using messages beyond this time-period or amount, a Zone Owner will need to upgrade this Zone to a paid plan and also purchase message funds.</div>
            title="Text messaging activation warning"
            this.confirmAction(title,message,true);
         } else if(configTest==='NonUSZoneOwner') {
          title="Upgrade to activate text messaging"
          message=<div>
            This Zone is currently on the free plan. Text messaging can not be activated until you upgrade to a paid plan. Click Upgrade Now in the top left corner of from the View all my GraceBlocks Homepage to get started. You will also need to purchase a phone number in your desired country and message funds once you are on a paid plan.<p/><p/> These steps can be completed from the page you will land on once you complete the upgrade process. (Zone settings page - also accessed via the gear icon next to the Zone name in the left side navigation panel of the View all my GraceBlocks Homepage.)
          </div>
          this.confirmAction(title,message,false);
         } else if(configTest==='NONUSUser'){
          title="Upgrade to activate text messaging"
          message=<div>
           This Zone is currently on the free plan and text messaging can not be activated until you upgrade to a paid plan. Contact your Zone owner for more details.
          </div>
          this.confirmAction(title,message,false);
         }
         else if(configTest==='NoActivePhones'){
          title="Add Phone number"
          message=<div>
           This Zone currently has no active phone numbers. In order to use Messaging, please purchase a phone number from the Zone settings page.
          </div>
          this.confirmAction(title,message,false);
         }
         await this.FetchBlocks();
       } 

  }

  configureTwilioNumber = async () => {

    // Check if Twilio number is ocnfigured, if Not, show the "activation" warning message
    // that they need to confirm provisioning new number. 
    const { userInfo } = this.context;
    const { columnConfigData,tableinfo, color } = this.props;
    const zoneid = parseInt(Global.zoneid);
    const zoneInfo = await getZoneAPI(zoneid);
   
    const tempphonefields=[];
    const pfields= tableinfo.columns.filter(itm=>(itm.uitype===9 || (itm.source?.lookupuitype===9 && itm?.source.lookup==='single')) && itm.isMobile===true);
    if(pfields.length>0){
      pfields.forEach((itm,index) => {
        tempphonefields.push({key:index,value:itm.data,text:itm.header})
      })
    }
  
    if (zoneInfo.attributes && zoneInfo.attributes.PhoneNumbers !== undefined) {
      const numbers = [];
      zoneInfo.attributes.PhoneNumbers.forEach((itm, index) => {
        if(itm.isActive) {
          numbers.push({ key: index, value: itm.number, text: itm.number });
        }
      });

     
      
      //if there are ctive phonenumbers, return success.
     if(numbers.length>0){
        this.setState({
          phoneNumbers: numbers,
          defaultPhoneNumber:
            columnConfigData.phonenumber !== undefined
              ? columnConfigData.phonenumber
              : numbers[0].value,
          phoneFields: tempphonefields,
          phoneField: columnConfigData.phonefield !== undefined ? columnConfigData.phonefield : tempphonefields[0].value
        });

        return "success"
    } else {
      return "NoActivePhones"
    }

    } else { //no Twilio number. Now check if the user has validated phone, otherwise 
      // Check if User mobile is valid US/CAN and if user is zone owner.
      
      const currentRole=userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0].role;
      const areaCode = parseInt(userInfo.phoneNumber.slice(2,5))

      if(userInfo.mobileverifydate ===null) {
        return "ValidatePhone"
      } else if(userInfo.phoneNumber.slice(0,2)==='+1' && !invalidUSRegions.includes(areaCode)) {
        return "ActivateMessaging"
      } else if ((userInfo.phoneNumber.slice(0,2) !=='+1' || invalidUSRegions.includes(areaCode)) && currentRole==='Zone owner'){
        return "NonUSZoneOwner"
      } else if  ((userInfo.phoneNumber.slice(0,2)!=='+1' || invalidUSRegions.includes(areaCode)) && currentRole!=='Zone owner') {
        return "NONUSUser"
      }
    }
 
  }

  togglePrivate = () =>{
    const {isPrivate} = this.state;
    const {userInfo} = this.context;

    if(userInfo.plan==='Free' || userInfo.plan==='Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
         To make a field private, you must upgrade to the pro plan. 
         <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({isPrivate: !isPrivate});
    }
  }

  FetchBlocks = async () => {
    const { columnConfigData,tableid,tableinfo } = this.props;
    const blocks = await getBlocksAPI(parseInt(Global.zoneid));
    
    let tables = [];
    let fields =[];

    if (columnConfigData.selectedBlock !== undefined && columnConfigData.selectedTable !==undefined) {
      tables = await getTablesAPI(columnConfigData.selectedBlock,3);
      const fieldsandTable = await getFieldsAPI(columnConfigData.selectedTable,3);
      fields = fieldsandTable.fields;
    }

    this.setState({
      blocks,
      tables,
      fields,
      selectedBlock: columnConfigData.selectedBlock !==undefined ? columnConfigData.selectedBlock : '',
      selectedTable: columnConfigData.selectedTable !==undefined ? columnConfigData.selectedTable : '',
      selectedField: columnConfigData.selectedField !==undefined ? columnConfigData.selectedField : ''
    });
  };

  Save = async () => {
    const {
      maxlength,
      callout,
      defaultText,
      tableinfo,
      defaultPhoneNumber,
      selectedBlock,
      selectedTable,
      selectedField,
      phoneField,
      isPrivate,
      altPhoneField
    } = this.state;
    const { field, tableid, header, selectedUIType, columnConfigData, uitype,color } = this.props;

    // if no phone field is desiganted, just return
    if(phoneField===null)
    {
      return
    }

    const originalUIType = columnConfigData.uitype;

    try {
      const defaultNode = `{
      "data": "${field}",
      "width": ${columnConfigData.width},
      "header": "${header}",
      "uitype": ${uitype},
      "displayfield": "messages"
  }`;

      // 7-14-2020 if this is first time configuring field, create new array relationship,
      // otherwise just update metadata settings.
      let result="success";
      if(originalUIType !==12)
      {
       // 7-15-2020, we also will update default value of smsnumber in this table to selected number.
       let payload = { field:'smsnumber', tableid, defaultText:defaultPhoneNumber, uitype: 12 };
       await cloudrun.post('/updateFieldDefaultText', { payload });
      }

      const updatedNode = JSON.parse(defaultNode);

      //   Update Tableinfo with new settings.
      const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
      if (rowindex !== -1) {
        updatedNode.callout = callout;
        updatedNode.renderer = 'MessageRender';
        updatedNode.editor ="MessageEditor";
        updatedNode.phonenumber = defaultPhoneNumber;
        updatedNode.phonefield = phoneField;

        if (selectedField !== '') {
          updatedNode.selectedTable = selectedTable;
          updatedNode.selectedBlock = selectedBlock;
          updatedNode.selectedField = selectedField;
        } else {
          delete updatedNode.selectedTable;
          delete updatedNode.selectedField;
          delete updatedNode.selectedBlock;
        }

        if(isPrivate) {
          updatedNode.isPrivate =true;
        } else {
          delete updatedNode.isPrivate;
        }
        if(altPhoneField !==null) {
          updatedNode.altPhoneField=altPhoneField
        } else {
          delete updatedNode.altPhoneField
        }

        tableinfo.columns[rowindex] = updatedNode;

        // we update the meta data last, otherwise functions above would be retreiving wrong starting metadata.
        await updateTableInfoAPI(tableid, tableinfo,columnConfigData);
        return 'success';
      } else {
        return `An error occurred: ${result}`
      }

      
    } catch (error) {
      console.log(error);
      return `An Error Occurred: ${error.message}`;
    }
    return 'Error, no matching column foiund';
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  SelectedBlockHandler = async (blockid) => {
    const {role} = this.props;
    const tables = await getTablesAPI(blockid,role);
    // 7-2-2020 react complains about including the "IsSystemtalb" in dropdown element...so I just remove it here.
    tables.forEach((itm) => {
      delete itm.isSystemTable;
    });

    this.setState({ tables, selectedBlock: blockid });
  };

  SelectedTableHandler = async (tableid) => {
    const fields = await getFieldsAPI(tableid);
    const filteredFields = fields.fields.filter((itm) => itm.uitype === 2 || itm.uitype === 3);
    this.setState({ selectedTable: tableid, fields: filteredFields });
  };

  SelectedFieldHandler = async (fieldname) => {
   
    if(fieldname==='') {
      this.setState({selectedField:'', selectedBlock:'',selectedTable:''})
    } else {
      this.setState({ selectedField: fieldname });
    }
  };

  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  activateMessaging = async () => {
    const {confirmText} = this.state;
    const { userInfo } = this.context;

    if(this.input.current.value==='activate') {
      // note that the provision method takes care of updating the zone attributes with new phone number.
      // as such when calling configureTwilio, it will now find the number in the PhoneNumbers part of attribute.
      const twilNumber = await provisionPhoneNumber(userInfo.phoneNumber)
      await this.configureTwilioNumber()
    } 
    
  }


 confirmAction = (title,message,showInput) => {
  const {color} = this.props;

  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <GBConfirm
          title={title}
          action={showInput ? this.activateMessaging : onClose}
          buttonLabel="OK"
          message={message}
          width="500px"
          divRef={this.input}
          showInput={showInput}
          showCancelButton={showInput}
          confirmAction=""
          confirmWord="activate"
          height="430px"
          onClose={onClose}
        />
      );
    },
  });
  };


  render() {
    const {
      showAdvanced,
      callout,
      phoneNumbers,
      defaultPhoneNumber,
      blocks,
      selectedBlock,
      tables,
      selectedTable,
      fields,
      selectedField,
      phoneFields,
      phoneField,
      isPrivate,
      altPhoneField
    } = this.state;
    const { color,columnConfigData } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{marginTop: '5px' }}>
          Exchange messages with known contacts. Choose a number below for sending messages.
        </div>
        {phoneFields.length >0 ? (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginTop: '5px' }}>
        <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                fontSize: '12px',
                marginBottom: '3px',
                marginTop: '5px',
                fontWeight: 'bold',
              }}
            >
              PHONE FIELD FOR MESSAGE DELIVERY
            </div>
            <div style={{ marginRight: '10px' }}>
              <Popup
                hoverable
                // on="hover"
                wide="very"
                mouseEnterDelay={1000}
                content={<div>Select from the available mobile phone number fields in the tab.<p/> GraceBlocks will send messages to records via the mobile phone number field you specify here. This field must reside on this tab and must have the "is mobile number" attribute toggled on under more field attributes. Learn more: <a href="https://www.graceblocks.com/support-article/field-type-phone-number" target="_newindow">Field type: phone</a></div>}
                position="top center"
                trigger={<Icon path={mdiInformationOutline} size="20px" />}
              />
            </div>
          </div>
          {phoneFields.length > 0 ? (<>
            <Dropdown
              fluid
              selection
              // disabled={columnConfigData.uitype===12}
              options={phoneFields}
              value={phoneField !== null ? phoneField : phoneFields[0].value}
              onChange={(e, data) => {
                this.setState({phoneField:data.value});
              }}
            />
            <div style={{marginTop:'10px',marginBottom:'5px',fontWeight:'bold'}}> ALT PHONE FIELD FOR MESSAGE DELIVERY</div>
             <Dropdown
              fluid
              selection
              clearable
              // disabled={columnConfigData.uitype===12}
              options={phoneFields}
              value={altPhoneField !== null ? altPhoneField : null}
              onChange={(e, data) => {
                this.setState({altPhoneField:data.value});
              }}
            />
            
          </>) : null}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div
              style={{
                fontSize: '12px',
                marginTop:'10px',
                marginBottom: '3px',
                fontWeight: 'bold',
              }}
            >
              GRACEBLOCKS PHONE # FOR SENDING MESSAGES
            </div>
            <div style={{ marginRight: '10px' }}>
              <Popup
                hoverable
                // on="hover"
                wide="very"
                mouseEnterDelay={1000}
                content={<div>Select the number you will use for sending messages.  If you have just activated Messaging, only one number will display here. 
                  However, if a <a href="https://www.graceblocks.com/support-article/zone-owner-responsibilities-and-capabilities" target="_newwindow">Zone owner</a> has added more phone numbers, all phone number options will display in this list. The same number can be used throughout the Zone, or individual numbers can be created for different Messaging Thread fields throughout the Zone. All numbers sent from and to the same number are collected into a single comprehensive message thread visible across the Zone. (Sometimes sharing message threads is good and keeps everyone on the same page; sometimes it's not. If messages are sensitive in a particular Block or Tab, consider procuring a dedicated number for that thread.) 
                  Learn More: <a href="https://www.graceblocks.com/support-article/messaging-management-and-pricing" target="_newwindow">Message management and pricing</a>.
                </div>}
                position="top center"
                trigger={<Icon path={mdiInformationOutline} size="20px" />}
              />
            </div>
          </div>
          {phoneNumbers.length > 0 ? (
            <Dropdown
              fluid
              selection
              disabled={columnConfigData.uitype===12}
              options={phoneNumbers}
              value={defaultPhoneNumber !== null ? defaultPhoneNumber : phoneNumbers[0].value}
              onChange={(e, data) => {
                this.setState({defaultPhoneNumber:data.value});
              }}
            />
          ) : null}
        </div>
       ) : null}
       {phoneFields.length> 0 ? (<div style={{marginTop:'10px',marginBottom:'10px'}}>
        <GBSwitch
          color={color}
          text="More field attributes"
          isChecked={showAdvanced}
          Action={this.showAdvancedHandler}
        /></div>)
        : <div style={{color:'#E06055',marginTop:'20px'}}>Before you can add the <u>Message thread field type</u>, this tab must already have a <u>phone number field type</u> added with the <b>is mobile number</b> advanced setting option <b>enabled</b>.</div>}

        {showAdvanced ? (
          <div style={{ display: 'flex', width: '100%',flexDirection:'column' }}>
             <div style={{marginTop:'5px',marginBottom:'10px'}}>
              <GBSwitch
                color={color}
                text="Private field"
                isChecked={isPrivate}
                Action={this.togglePrivate}
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div>Specify message template field</div>
                <div style={{ marginRight: '10px' }}>
                  <Popup
                    hoverable
                    // on="hover"
                    wide="very"
                    mouseEnterDelay={1000}
                    content="Sometimes it's handy to give people starter text to help them quickly send the same message over and over.  You can specify any text field across the zone as the holder of your template text.  Specify it here, and users will be able to search for the template using the # sign while typing messages"
                    position="top center"
                    trigger={<Icon path={mdiInformationOutline} size="20px" />}
                  />
                </div>
              </div>
              <div style={{marginTop:'10px'}}>Block</div>
              <Dropdown
                fluid
                upward
                style={{ zIndex: '4', position: 'relative', marginTop: '5px' }}
                placeholder="Blocks"
                value={selectedBlock}
                selection
                options={blocks}
                onChange={(e, data) => {
                  this.SelectedBlockHandler(data.value);
                }}
              />
              <div style={{marginTop:'10px'}}>Tab</div>
              <Dropdown
                fluid
                placeholder="Tables"
                selection
                upward
                value={selectedTable}
                options={tables}
                onChange={(e, data) => {
                  this.SelectedTableHandler(data.value);
                }}
              />
              <div style={{marginTop:'10px'}}>Field</div>
              <Dropdown
                fluid
                // onFocus={()=>this.setState({zindex:10})}
                // onBlur={()=>this.setState({zindex:3})}
                // style={{ zIndex: zindex, position: 'relative', marginTop: '5px' }}
                placeholder="Field"
                selection
                clearable
                upward
                value={selectedField}
                options={fields}
                onChange={(e, data) => {
                  this.SelectedFieldHandler(data.value);
                }}
              />
              <Callout callout={callout} updateCallout={this.CalloutHandler} />
            </div>
          </div>
        ) : null}
       
      </div>
    );
  }
}

MessageField.propTypes = {
  field: PropTypes.string.isRequired,
  tableid: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  columnConfigData: PropTypes.object.isRequired,
};

export default MessageField;
