/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { mdiClose,mdiChevronDown,mdiChevronUp } from '@mdi/js';
import IconButton from '../FilterData/IconButton';
import TextButton from '../../../../components/TextButton/TextButton';


const NestedFieldsDisplay = ({data,nestedFields,nestedSearchData,searchValue}) =>{

    const [currentLevel,setCurrentLevel] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [currentData, setCurrentData] = useState(data);
    const [showCurrentLevel, setShowCurrentLevel] =useState(true);
    const [count,setCount] = useState(0);


    useEffect(()=>{
        nestedFields.map(col=>{
            if(col.uitype===8) {
                data.map(row=>{
                    if(row[col.field] !==null && row[col.field][0] !==null) {
                        row[col.field][0].data=`${row[col.field][0].firstname} ${row[col.field][0].lastname}`
                    }
                })
            }
        })
       
        setCurrentData(data)
        firstLevel(data);
        setCount(data.length);
    },[])

    useEffect(()=>{
       setShowCurrentLevel(searchValue==='')
    },[searchValue])


    const firstLevel = (tempData) => {

        let firstList=[];   
        if([1,7,8,16,18].includes(nestedFields[0].uitype) ){
             firstList =  [...new Set(tempData.filter(el=>el[nestedFields[0].field]!==null).map(el=> el[nestedFields[0].field].map(c=>c.data)[0]))]
        }  else {
            firstList = [
              ...new Set(
                tempData
                  .filter((el) => el[nestedFields[0].field] !== null)
                  .map((el) => el[nestedFields[0].field]),
              ),
            ];
          }

        setCurrentLevel({
            field:nestedFields[0].field, 
            label: nestedFields[0].label, 
            data:firstList,
            isExpanded:true,
            uitype:nestedFields[0].uitype,
            lookupuitype: nestedFields[0].lookupuitype
        });
    }

    const addLevel = async (level,value,items,runQuery=true) =>{
        
        // when they click to remove a selected option, we pass in the remainting items
        // so logic can properly display list of options
        let tempItems=[];
        if(items ===undefined) {
            tempItems =structuredClone(selectedItems);
        } else {
            tempItems=structuredClone(items);
        }

    
        // we add the defined value to the existing object. This is always the text version which
        //is used to display on page. For single select option & user fields, we also store the id value
        //which is used in the actual filter used to filter the results. This is defined below.
        level.value= value;
        
        let levelData=[];
        let filteredData=[];
        const currentIndex = nestedFields.findIndex(el=>el.field===level.field)

        const nextlevelIndex =currentIndex + 1;
        const dataCopy = currentData

        if([1,7,8,16,18].includes(nestedFields[currentIndex].uitype)){
            
            filteredData = dataCopy.filter(itm=>itm[level.field]!==null && itm[level.field][0].data===value);
            if(tempItems.length>0 ){
                tempItems.map(s=>{
                    if([1,7,8,16,18].includes(s.uitype)){
                    filteredData = filteredData.filter(itm=>itm[s.field]!==null && itm[s.field][0].data===s.value);
                    } else {
                    filteredData = filteredData.filter(itm=>itm[s.field]!==null && itm[s.field]===s.value);
                    }
                })
            }

            if(nestedFields[currentIndex].uitype===7){
                const id =filteredData[0][level.field][0].optionid
                level.idValue=id
            } else if(nestedFields[currentIndex].uitype===8){
                const userid =filteredData[0][level.field][0].userid;
                level.idValue=userid;
            } else if(nestedFields[currentIndex].uitype===1){
                const id =filteredData[0][level.field][0].id
                level.idValue=id
            } else if(nestedFields[currentIndex].uitype===18){
                if(nestedFields[currentIndex].lookupuitype===1) {
                    const id =filteredData[0][level.field][0].id 
                    level.idValue=[id]
                } else if([6,7].includes(nestedFields[currentIndex].lookupuitype)){
                    const id =filteredData[0][level.field][0].optionid 
                    level.idValue=[id]
                }  else if(nestedFields[currentIndex].lookupuitype===8) {
                    const id =filteredData[0][level.field][0].userid;
                    level.idValue=[id]
                }
            } 
        } 
        else {
            filteredData = dataCopy.filter(itm=>itm[level.field]!==null && itm[level.field]===value);
           
            if(tempItems.length>0 ){
                tempItems.map(s=>{
                    if([1,7,8,16,18].includes(s.uitype)){
                    filteredData = filteredData.filter(itm=>itm[s.field]!==null && itm[s.field][0].data===s.value);
                    } else {
                    filteredData = filteredData.filter(itm=>itm[s.field]!==null && itm[s.field]===s.value);
                    }
                })
            }
        }

        if(nextlevelIndex < nestedFields.length) {
            if([1,7,8,16,18].includes(nestedFields[nextlevelIndex].uitype)){
                levelData =  [...new Set(filteredData.filter(el=>el[nestedFields[nextlevelIndex].field]!==null).map(el=> el[nestedFields[nextlevelIndex].field].map(c=>c.data)[0]))]
            } else {
                levelData =  [...new Set(filteredData.filter(el=>el[nestedFields[nextlevelIndex].field]!==null).map(el=>el[nestedFields[nextlevelIndex].field]))]
            }
            setCurrentLevel({
                field:nestedFields[nextlevelIndex].field, 
                label: nestedFields[nextlevelIndex].label, 
                data:levelData,isExpanded:true,
                uitype:nestedFields[nextlevelIndex].uitype,
                lookupuitype:nestedFields[nextlevelIndex].lookupuitype
            });        
        }

        let x= 0;
        if(items===undefined) {
            tempItems.push(level)
            setSelectedItems(tempItems);
            if(runQuery){
                console.log(tempItems)
               x = await nestedSearchData(tempItems);
            }
        } else {
            setSelectedItems(tempItems);
            if(runQuery){
              x=  await nestedSearchData(tempItems);
            }
        }
       setCount(x);
    }

    const RemoveLevel = async (level) =>{
        const idx = nestedFields.findIndex(el=>el.field===level.field);
        if(idx===0) {
            setSelectedItems([]);
            await nestedSearchData([]);
            firstLevel(currentData);
        } else {
            const idx2 =  structuredClone(selectedItems).findIndex(el=>el.field===level.field);
            let tempSelections=[];
            if(idx2 !==-1) {
                tempSelections=[...selectedItems].filter((el,i) => i <idx2);
                setSelectedItems(tempSelections)
                addLevel(nestedFields[idx-1],tempSelections[tempSelections.length-1].value,tempSelections)
            }
        }
     
    }

    const toggleExpand =(level) =>{
        const tempLevel = structuredClone(currentLevel);
        tempLevel.isExpanded=!tempLevel.isExpanded;
        setCurrentLevel(tempLevel)
    }

    const toggleSelected =(level) =>{
        let tempLevel=structuredClone(selectedItems);
        const idx = selectedItems.findIndex(el=>el.field===level.field);
        if(idx !==-1) {
           tempLevel[idx].isExpanded= !tempLevel[idx].isExpanded;
           setSelectedItems(tempLevel)
        }
    }

    return (
    <div style={{marginLeft:'20px'}}>

        {selectedItems.length> 0 ? selectedItems.map(itm=>(
            <div key={itm.field} style={{marginBottom:'20px'}}>
                <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}> 
                 <TextButton iconPosition="left" Action={toggleSelected}  ActionValue={itm} textColor="black" hoverColor="#00000080" text={itm.label} icon={itm.isExpanded ? mdiChevronDown : mdiChevronUp} size="20px" />
                </div>
            {itm.isExpanded ? (
            <div style={{display:'flex', flexDirection:'row',alignItems:'center',marginLeft:'20px'}}>
              <span style={{marginRight:'5px'}}>{itm.value}</span>
              <IconButton color="red" hoverColor="white" hoverBackgroundColor="#F6685E" icon={mdiClose} Action={RemoveLevel} ActionValue={itm}  />
            </div>
            ): null}

            </div>
        )) : null}
        
      
         {currentLevel && showCurrentLevel && currentLevel.data.length>0 && selectedItems.length < nestedFields.length ? (
             <div style={{marginTop: selectedItems.length>0 ?  '20px' : null}}>
            <div style={{display:'flex', flexDirection:'row',alignItems:'center'}}>
                  <TextButton iconPosition="left" Action={toggleExpand}  ActionValue={currentLevel} textColor="black" hoverColor="#00000080" text={currentLevel.label} icon={currentLevel.isExpanded ? mdiChevronDown : mdiChevronUp} size="20px" />
            </div>
             </div>
         ) :null}
     
       {currentLevel && showCurrentLevel && currentLevel.data.length>0 && currentLevel.isExpanded && selectedItems.length < nestedFields.length ? (
              currentLevel.data.sort((a, b) => (a > b ? 1 : -1)).map(el=>(
                <div key={el} onClick={()=>addLevel(currentLevel,el)} style={{color:'#669CF6',cursor:'pointer',marginLeft:'20px',marginTop:'5px',marginBottom:'5px'}}>{el}</div>
               ))
          
       ): null}
       <div style={{marginLeft:'20px',marginTop:'15px'}}>Matching values ({count}) <span style={{color:'#B3AEAE'}}>Select a value to proceed.</span></div>
     
    </div>
    )
}

export default NestedFieldsDisplay