/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect, useContext, useRef } from 'react';
import Icon from '@mdi/react';

import { mdiAlert,mdiCheck } from '@mdi/js';
import GBButton from '../GBButton/GBButton';


const GBConfirm = ({
  title,
  action,
  actionValue,
  buttonLabel,
  buttonWidth="75px",
  message,
  showInput,
  showCancelButton=true,
  cancelButtonWidth="75px",
  icon='alert',
  width="300px",
  color="#E06055",
  confirmAction,
  confirmWord,
  height="300px",
  divRef,
  onClose
}) => {
  return (
        <div
          style={{
            width: width,
            height: height,
            display:'block',
            // overflow:'hidden',
            backgroundColor: `white`,
            borderRadius: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderTopRightRadius:'20px',
              borderTopLeftRadius:'20px',
              // border:'50px solid E06055',
              backgroundColor: color,
              textAlign: 'center',
              color: 'white',
              // outline:'none',
              verticalAlign: 'center',
              height: '40px',
              fontSize: '20px',
              fontWeight: 'bold',
            }}
          >
            <Icon path={icon==='alert' ? mdiAlert : mdiCheck} size="20px" color="white" style={{ marginRight: '5px' }} />
            {title}
          </div>
          <div
            style={{
              border: `1px solid ${color}`,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              backgroundColor:'white',
              borderBottomLeftRadius:'20px',
              borderBottomRightRadius:'20px',
              overflow:'auto',
              height:height,
              paddingRight: '20px',
              paddingLeft: '20px',
              fontSize: '14px',
              fontWeight: 'bold',
              paddingTop: '10px',
            }}
          >
            {message}
            {showInput ? (
              <>
                <p></p>
                <p>
                  To continue with this {confirmAction} action, enter the word(s){' '}
                  <strong>
                    <u>{confirmWord}</u>
                  </strong>{' '}
                  into the text box blow. Then click OK.
                </p>
                <div style={{ marginTop: '10px', marginBottom: '20px', width: '100%' }}>
                  <input style={{ width: '100%' }} ref={divRef} />
                </div>
              </>
            ) : null}
           
            <div style={{ marginRight: '10px', marginBottom: '10px',marginTop:'10px' }}>
              <GBButton
                text={buttonLabel}
                color={color}
                Action={action}
                ActionValue={actionValue}
                ActionTwo={showCancelButton ? onClose : null}
                width={buttonWidth}
              />
            </div>

            {showCancelButton ? (
            <div style={{ marginBottom: '20px', marginRight: '10px' }}>
              <GBButton
                text="Cancel"
                textColor="black"
                color="#D5D1D1"
                width={cancelButtonWidth}
                Action={onClose}
              />
            </div>
            ): null}
          </div>
        </div>
      );
};

export default GBConfirm;
