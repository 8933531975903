/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { mdiPlus, mdiCloseCircle, mdiClose, mdiFilter, mdiInformationOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Popup, Dropdown, Icon as SIcon } from 'semantic-ui-react';
import TextFilter from './TextFilter';
import OptionsFilter from './OptionsFilter';
import LookupFilter from './LookupFilter';
import DateFilter from './DateFilter';
import NumberFilter from './NumberFilter';
import MessageFilter from './MessageFilter';
import EmailMessagesFilter from './EmailMessagesFilter';
import SystemListFilter from './SystemListFilter';
import BlockFilter from './BlockFilter';
import AttachmentsFilter from './AttachmentsFilter';
import CheckboxFilter from './CheckboxFilter';
import StatusRoleFilter from './StatusRoleFilter';
import UserFilter from './UserFilter';
import IconButton from './IconButton';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';

class FilterData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fields: [],
      filters: [],
    };
  }

  componentDidMount() {
    this.parseString();
    const { filters } = this.props;
    let tmpFilters = [];
    if (filters === '' || filters === undefined) {
      tmpFilters = [];
    } else {
      tmpFilters = structuredClone(filters);
    }

    if (filters !== undefined && filters.length>0 ) {
      this.setState({ filters: tmpFilters }, () => this.buildFieldList());
    } else {
      this.buildFieldList();
    }
  }

  // Started to add functionality to allow for using AND and Or in text query string
  // but have stopped for now, as it gets quite complicated. Instead, may implement UI
  // to make this easier
  parseString = (field, str) => {
    //example of str: (bob and rob) or john
    //example john or bob or tim and steve
    let andValues = [];
    const test = ' bob and henry or john hink or kim penn and steve and ez or stacy';
    const test2 = '(bob or henry) and steve';
    const values = test2.trim().split(' or ');
    let orValues = values.filter((el) => !el.includes(' and '));

    values
      .filter((el) => el.includes(' and '))
      .forEach((el) => {
        andValues = andValues.concat(el.split(' and ').slice(1));
        orValues.push(el.split(' and ')[0]);
      });
  };

  updateFilters = (filter) => {
    const { filters } = this.state;
    const tmpfilters = filters;
    const rowIndex = tmpfilters.findIndex((el) => el.field === filter.field);
    if (rowIndex !== -1) {
      tmpfilters[rowIndex] = filter;
    }
    this.setState({ filters: tmpfilters });
  };

  removeFilter = (field) => {
    const { filters } = this.state;
    const tmpfilters = filters.filter((itm) => itm.field !== field);
    this.setState({ filters: tmpfilters }, () => this.buildFieldList());
  };

  // pass field column info which contains all necessary info to populate filter control.
  buildFilters = () => {
    const { tableinfo } = this.props;
    const { filters } = this.state;

    // const localTableInfo=JSON.parse(JSON.stringify(tableinfo));

    const localTableInfo = structuredClone(tableinfo);
    if(filters.findIndex(fld=>fld.field=='messages') !==-1) {
      const tempCol = {data:'messages',header:'Collaboration notes',uitype:31,displayfield:'messages'}
      localTableInfo.columns.push(tempCol)
    } 
    if(filters.findIndex(fld=>fld.field==='CustomID') !==-1 ) {
      const tempCol2 = {data:'CustomID',header:'Record identifier',uitype:32,displayfield:'CustomID'}
      localTableInfo.columns.push(tempCol2)
    } 
    if(filters.findIndex(fld=>fld.field==='emailmessages') !==-1) {
      const tempCol3 = {data:'emailmessages',header:'Email messages',uitype:33,displayfield:'emailmessages'}
      localTableInfo.columns.push(tempCol3)
    }

    return localTableInfo.columns.map((el) =>
        filters &&
        filters.map((filter) =>
          filter.field === el.data ? this.returnFilterType(el, filter) : null,
        ),
    );
  };

  returnFilterType = (el, filter) => {
    const { tableinfo, color, blockid, tableid, callingComponent, role } = this.props;
    if (el.source && el.source.finallookupfield && el.source.finallookupfield.includes('join')) {
      //    var isjoinfield = true
    }

    //  if the field doens't have a source, I"m simply setting the lookupuitype=false so that
    //  I can use conditions to determine whether to use the source.lookupuitype or it's a non-lookup field and use base uitype.
    if (!el.source) {
      // eslint-disable-next-line no-param-reassign
      el.source = { lookupuitype: false };
    }
    // if ((el.uitype == 2) || (el.source.lookupuitype==2) || (el.uitype==18 && el.lookup=='text') || el.uitype==1 || (el.uitype==18 && (el.source.lookupfield.includes('join') || isjoinfield)))

    if (
      el.data !== 'status' &&
      ([2, 3, 9, 10, 11, 13, 31, 32].includes(el.uitype) ||
        ([2, 3, 9, 10, 11, 13].includes(el.source.lookupuitype) &&
          el.specialfunction !== 'count' && el.specialfunction !== 'countdistinct') ||
          (el.fieldType==='text')
          
          )
    ) {
      return (
        <TextFilter
          key={el.data}
          color={color}
          field={el.data}
          filterData={this.filterData}
          displayfield={el.displayfield}
          header={el.header}
          placeholder={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype}
        />
      );
    }
    if (
      (el.uitype === 14 || el.source.lookupuitype == 14) &&
      (tableinfo.security.unmask == undefined || tableinfo.security.unmask < role)
    ) {
      return (
        <TextFilter
          key={el.data}
          color={color}
          field={el.data}
          filterData={this.filterData}
          displayfield={el.displayfield}
          header={el.header}
          placeholder={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype}
        />
      );
    }
    if (el.data === 'status') {
      return (
        <StatusRoleFilter
          key={el.data}
          tableinfo={tableinfo}
          tableid={tableid}
          color={color}
          fieldnode={el}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.source.lookupuitype}
        />
      );
    }
    if ([30].includes(el.uitype)) {
      return (
        <>
          <BlockFilter
            key={el.data}
            color={color}
            displayfield={el.displayfield}
            field={el.data}
            header={el.header}
            UpdateFilter={this.updateFilters}
            remove={this.removeFilter}
            filter={filter}
            uitype={el.uitype}
          />
        </>
      );
    }
    if (
      el.uitype === 6 ||
      el.uitype === 7 ||
      el.uitype === 1 ||
      (el.source && el.source.lookupuitype === 1)
    ) {
      return (
        <OptionsFilter
          key={el.data}
          color={color}
          colInfo={el}
          role={this.props.role}
          listsource={el.source}
          displayfield={el.displayfield}
          field={el.data}
          tableid={tableid}
          header={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype === 18 ? el.source.lookupuitype : el.uitype}
          baseUIType={el.uitype}
          callingComponent={callingComponent}
        />
      );
    }
    // else if (el.uitype == 18 && (el.lookup == 'single' || el.lookup == 'multiple') && el.jointype=='relational-lookup')

    if (el.source.lookupuitype === 6 || el.source.lookupuitype === 7) {
      return (
        <LookupFilter
          key={el.data}
          tableinfo={tableinfo}
          tableid={tableid}
          color={color}
          fieldnode={el}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.source.lookupuitype}
        />
      );
    }

    if ([19, 20, 22].includes(el.uitype) || [19, 20, 22].includes(el.source.lookupuitype) || el.fieldType==='date' ) {
      return (
        <DateFilter
          key={el.data}
          roottablename={tableinfo.dbname}
          color={color}
          fieldnode={el}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.source.lookupuitype === false ? el.uitype : el.source.lookupuitype}
          isLookup={el.source.lookupuitype !== false}
        />
      );
    }
    if (
      el.uitype === 23 ||
      el.source.lookupuitype === 23 ||
      el.uitype === 17 ||
      el.source.lookupuitype === 17 ||
      (el.uitype === 18 &&
        el.specialfunction !== 'all' &&
          el.specialfunction !== 'unique' &&
          el.source.lookupuitype !== 4 &&
          el.source.lookupuitype !== 14)
          || el.fieldType==='numeric'
          || el.fieldType==='interval'
    ) {
      return (
        <NumberFilter
          key={el.data}
          roottablename={tableinfo.dbname}
          color={color}
          fieldnode={el}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.source.lookupuitype === false ? el.uitype : el.source.lookupuitype}
          isLookup={el.source.lookupuitype !== false}
        />
      );
    }

    if (
      el.uitype === 24 ||
      el.uitype === 25 ||
      el.uitype === 26 ||
      el.uitype === 8 ||
      (el.source !== undefined &&
        (el.source.lookupuitype === 8 ||
          el.source.lookupuitype === 24 ||
          el.source.lookupuitype === 25))
    ) {
      return (
        <UserFilter
          key={el.data}
          tablename={tableinfo.dbname}
          color={color}
          fieldnode={el}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          blockid={blockid}
        />
      );
    }
    if (el.uitype === 27) {
      return (
        <SystemListFilter
          id="blockid"
          type="blocks"
          key={el.data}
          color={color}
          field="blockname"
          displayfield="blockname"
          header="Blocks"
          uitype={el.uitype}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          blockid={blockid}
        />
      );
    }
    if (el.uitype === 12) {
      return (
        <MessageFilter
          key={el.data}
          color={color}
          field={el.data}
          displayfield={el.displayfield}
          header={el.header}
          placeholder={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype}
        />
      );
    }
    if (el.uitype === 33) {
      return (
        <EmailMessagesFilter
          key={el.data}
          color={color}
          field={el.data}
          displayfield={el.displayfield}
          filterData={this.filterData}
          header={el.header}
          placeholder={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype}
        />
      );
    }
    if (el.uitype === 4 || el.source.lookupuitype === 4) {
      return (
        <AttachmentsFilter
          key={el.data}
          color={color}
          lookuptableid={
            el.jointype === 'relational-lookup'
              ? el.source.lookuptableid
              : el.jointype === 'relational-lookup-lookup'
              ? el.source.grouptable.replace('tbl_', '')
              : null
          }
          lookupfield={
            el.jointype === 'relational-lookup'
              ? el.source.lookupfield.replace('_options', '')
              : el.jointype === 'relational-lookup-lookup'
              ? el.source.finallookupfield.replace('_options', '')
              : null
          }
          field={el.data}
          tableid={tableid}
          displayfield={el.displayfield}
          header={el.header}
          placeholder={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype}
        />
      );
    }
    if (el.uitype === 5 || el.source.lookupuitype === 5 || el.fieldType==='boolean') {
      return (
        <CheckboxFilter
          key={el.data}
          color={color}
          field={el.data}
          filterData={this.filterData}
          displayfield={el.displayfield}
          header={el.header}
          UpdateFilter={this.updateFilters}
          remove={this.removeFilter}
          filter={filter}
          uitype={el.uitype}
        />
      );
    }

    return null;
  };

  addremovefilters = (filter) => {
   
    const { filters } = this.state;

    const filterIndex = filters.findIndex((el) => el.field === filter);
    if (filterIndex !== -1) {
      this.removeFilter(filter);
    } else {
      const skeletonFilter = { field: filter };
     
      this.setState({
        filters: filters.concat(skeletonFilter),
      });
    }
  };

  // This is list for drop-down list of fields
  buildFieldList = () => {
    const options = [];
    const { tableinfo, color, role,callingComponent } = this.props;
    const { filters } = this.state;
    const localTableInfo = structuredClone(tableinfo);

     // 8-11-23 Add a collaboration notes filter . This allows users to search the "messages" field in the table
    //which stores all collaboratio notes in jsonb. 
    let sortedFields = localTableInfo.columns;

    if(callingComponent !=='lookupConfig') {
       const collabNotes = {data:'messages',header:'Collaboration notes',uitype:2,displayfield:'messages'};
       sortedFields.push(collabNotes);
    }

    //8-28-23 Adding Record Identifier filter. This will work like collab filter where it will always be a filter that can be used
    //but not a defined column in grid. It will use the same System id field of "id", but here, we will specify uitype of 2, instead of 23
    //the buildSqlQueryFilter supports searching by CustomID, but it is looking for field of "id" and has operator that is a text based operator.
    //the normal "System ID" filter is doing a numeric query.
    if(callingComponent !=='lookupConfig') {
      const RID = {data:'CustomID',header:'Record identifier',uitype:32,displayfield:'CustomID'};
      sortedFields.push(RID);
    }

    //9-12-23 adding support for Email messaging filtering. If table has defined email field with 
    const addEmailFilter = localTableInfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages===true) !== -1;
    
    if(addEmailFilter && callingComponent !=='lookupConfig') {
      const EmailField = {data:'emailmessages',header:'Email messages',uitype:33,displayfield:'emailmessages'};
      sortedFields.push(EmailField);
    }

    //Sort field alphabetically by header
     sortedFields = sortedFields.sort((a, b) =>
      a.header.toLowerCase() > b.header.toLowerCase() ? 1 : -1,
    );

    //3-21-23 remove mask field if current user does not have unmask capability.
    if (
      tableinfo.security !== undefined &&
      tableinfo.security.unmask !== undefined &&
      tableinfo.security.unmask > role
    ) {
      sortedFields = sortedFields.filter(
        (el) => el.uitype !== 14 && el.source?.lookupuitype !== 14,
      );
    }

    sortedFields
      .filter((col) => col.removeColumn === undefined)
      .forEach((el, i) => {
        const rowIndex = filters && filters.findIndex((filter) => filter.field === el.data);
        if (rowIndex !== -1 && rowIndex !== '') {
          options.push({
            key: i,
            text: el.header,
            value: el.data,
            icon: (
              <IconButton
                color="red"
                hoverColor="white"
                hoverBackgroundColor="#F6685E"
                icon={mdiClose}
              />
            ),
          });
        } else {
          options.push({
            key: i,
            text: el.header,
            value: el.data,
            icon: (
              <IconButton
                color="green"
                hoverColor="white"
                hoverBackgroundColor="#9CCC65"
                icon={mdiPlus}
              />
            ),
          });
        }
      });

    return options;
  };

  returnfalse = () => {
    return false;
  };

  filterData = async () => {
    // this.props.FilterData(75, 0, this.state.filters)
    await this.props.FilterData(this.state.filters);
  };

  clearFilters = () => {
    this.setState({ filters: [] });
  };

  render() {
    const { close, color, callingComponent = 'grid', title, icon, saveText } = this.props;
    const { fields, filters } = this.state;
    return (
      <div style={{ marginBottom: '20px' }}>
        {callingComponent !== 'lookupConfig' ? (
          <div
            style={{
              display: 'flex',
              width: '100%',
              backgroundColor: color,
              height: '40px',
              marginBottom: '10px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ marginLeft: '10px', color: 'white', fontSize: '15px' }}>
              <Icon path={icon} size="20px" /> {` `}
              {title}
            </div>
            <div style={{ marginRight: '10px', color: 'white' }}>
              <TextButton
                Action={close}
                textColor="white"
                hoverColor="#FFFFFF80"
                text={saveText === 'Save' ? 'Close' : 'Hide search filter panel'}
                icon={mdiClose}
              />
            </div>
          </div>
        ) : null}
        <div style={{ marginLeft: '10px' }}>
          <div
            css={{
              width: 'max-content',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '&:hover': {
                '& .icon': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '& .plusicon': {
                  transition: 'all .3s ease',
                  borderRadius: '20px',
                  color,
                  opacity: 0.5,
                  backgroundColor: 'white',
                },
              },
            }}
          >
            <Dropdown
              text={saveText === 'Save' ? 'Add field display condition' : 'Add criteria'}
              closeOnChange={false}
              scrolling
              search
              button
              selectOnBlur={false}
              icon={
                <Icon
                  className="plusicon"
                  style={{ marginLeft: '5px' }}
                  path={mdiPlus}
                  size="15px"
                />
              }
              // icon="plus"
              className="gb-filter-dropdown"
              style={{
                borderRadius: '5px',
                fontSize: '12px',
                fontFamily: 'Nunito Sans',
                height: '24px',
                width: saveText === 'Save' ? '250px' : '161px',
                backgroundColor: color,
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              options={this.buildFieldList()}
              value=""
              onChange={(e, data) => this.addremovefilters(data.value)}
            />
            {callingComponent === 'form' ? (
              <Popup
                content={
                  <div>
                    To learn more about this capability,{' '}
                    <a
                      href="https://graceblocks.zendesk.com/knowledge/articles/new/en-us?brand_id=360003603191"
                      target="_newwindow"
                    >
                      click here
                    </a>
                    .
                  </div>
                }
                hoverable
                mouseEnterDelay={200}
                trigger={<Icon path={mdiInformationOutline} size="20px" />}
              />
            ) : null}
          </div>
          <div style={{ height: '20px' }} />
          <div style={{ marginLeft: '20px', marginRight: '30px' }}>{this.buildFilters()}</div>
          <div
            style={{
              marginRight: '30px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TextButton
              textColor="black"
              text="Clear all filters"
              hoverColor="#75757580"
              fontSize="13px"
              Action={this.clearFilters}
            />
            <div style={{ width: '10px' }}></div>
            <GBButton text={saveText} Action={this.filterData} color={color} />
          </div>
        </div>
      </div>
    );
  }
}

FilterData.propTypes = {
  close: PropTypes.func.isRequired,
  FilterData: PropTypes.func.isRequired,
  tableinfo: PropTypes.object.isRequired,
};

export default FilterData;
