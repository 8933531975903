/* eslint-disable */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Input, Dropdown } from 'semantic-ui-react';
import Modal from 'react-modal';
import { mdiGateNor, mdiTrashCan, mdiPencil } from '@mdi/js';

import TextButton from '../../components/TextButton/TextButton';
import FilterData from '../Block/components/FilterData/index';
import FilterTags from '../Block/components/FilterData/FilterTags';

const FilterConditions = ({localData, updateData, editMode,sourceNodes}) => {

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [filters, setFilters] = useState({});
    const [currentSource,setCurrentSource]=useState(null);
    const [sourceOptions,setSourceOptions]=useState([]);
    const [sourceSelection, setSourceSelection]=useState('');
    

    useEffect(()=>{
        //Set initial current node to first source. Could be multiple sources.
        setCurrentSource(sourceNodes[0].data);
        let tempOptions=[];
        if(sourceNodes.length>1) {
            sourceNodes.map(itm=>{
                tempOptions.push({key:itm.id,value:itm.id,text:itm.data.label})
            })
         setSourceOptions(tempOptions);
         setSourceSelection(sourceNodes[0].id);
        }

        if(localData?.filterConditions !==undefined) {
            setFilters(localData.filterConditions)
        }
    },[])


      const updateConditions = (tempFilters) => {
        filters[currentSource.tableData.id] = tempFilters;
        setFilters(filters);
        setModalIsOpen(false);

        localData.filterConditions=filters;
        updateData(localData);

      };

      const handleSourceChange=(val) =>{
        const idx = sourceNodes.findIndex(itm=>itm.id==val);
        if(idx !==-1) {
            setCurrentSource(sourceNodes[idx].data)
            setSourceSelection(sourceNodes[idx].id);
        }
      }

    return (

        <div style={{border: '1px solid #0D99FF',padding:'20px',backgroundColor:'white'}}>
                {currentSource !==null ? (<>
                  <div style={{ fontWeight: 'bold',fontSize: '20px', fontWeight: 'bold',marginBottom:'10px'}}>
                   Action conditions{' '}
                  </div>
                  {sourceOptions.length> 0 ? (
                  <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%',marginTop:'5px',marginBottom:'10px'}}>
                    <div style={{marginRight:'5px'}}>Source</div>
                    <div style={{width:'100%'}}><Dropdown label="Source" fluid selection options={sourceOptions} value={sourceSelection} onChange={(e,data)=>handleSourceChange(data.value)}  /></div>
                  </div>

                  ):null}
                  <TextButton
                    Action={setModalIsOpen}
                    ActionValue={true}
                    iconPosition="left"
                    icon={mdiGateNor}
                    size="20px"
                    text="Manage conditions"
                    textColor="black"
                  />
                  {Object.keys(filters).length> 0 && filters[currentSource?.tableData.id]?.length > 0 ? (
                    <FilterTags
                      sortHeader={'id'}
                      sortField={'id'}
                      sortOrder={'asc'}
                      filters={filters[currentSource?.tableData.id]}
                      removeFilter={null}
                      removeSort={null}
                    />
                  ) : null}
                </>): null }
          
          <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            // onAfterOpen={this.afterOpenModal}
            // onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                // position: 'fixed',
                width: '450px',
                // height: this.state.modalHeight,
                height: 'calc(100vh -300px)',
                // maxHeight:'500px',
                top: 200,
                left: window.innerWidth / 2,
                border: `.3px solid #0D99FF`,
                background: '#fff',
                overflow: 'auto',
                zIndex: 6010,
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '0px',
              },
            }}
            contentLabel="Example Modal"
          >
            <FilterData
              title={ 'Action update conditions' }
              icon={mdiGateNor}
              saveText="Save"
              tableinfo={currentSource?.tableData?.tableinfo}
              tableid={currentSource?.tableData?.tableid}
              close={() => setModalIsOpen(false)}
              FilterData={updateConditions}
              filters={Object.keys(filters).length> 0 ? filters[currentSource?.tableData.id] : []}
              color={'#0D99FF'}
              blockid={currentSource?.blockid} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
              callingComponent="form"
            />
          </Modal>

        </div>
    )
}

export default FilterConditions;