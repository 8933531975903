/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';
import { mdiClose, mdiPlus } from '@mdi/js';
import GBButton from '../../../../components/GBButton/GBButton';
import IconButton from '../FilterData/IconButton';
import TextButton from '../../../../components/TextButton/TextButton';

const NestedDropdowns = ({
  data,
  value,
  colInfo,
  nestedFields,
  role,
  updateValue,
  color,
  callingComponent,
  fontSize = '14px',
  fontFamily,
  labelsInCaps = false,
  preSelected,
  updateSelectedLevel,
  conditions,
  htmlMode=false
}) => {
  const [currentLevel, setCurrentLevel] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [showCurrentLevel, setShowCurrentLevel] = useState(true);
  const [showSelectButton, setShowSelectButton] = useState(false);
  const [currentSelectedItems, setCurrentSelectedItems] = useState([]);
  const [totalSelections, setTotalSelections] = useState([]);
  const [viewSelected, setViewSelected] = useState(false);
  const [finalListData, setFinalListData] = useState([]);
  const [lastSelected, setLastSelected] = useState(null);
  const [lastCompletedLevel, setLastCompletedLevel] = useState(null); //used with web-form filtering
  const [nestedFilterLevels, setNestedFilterLevels] = useState({}); //used with web form filtering.
  const [filterNestCount, setFilterNestCount] = useState(0); //used with web form filtering.

  useEffect(() => {
    nestedFields.map((col) => {
      if (col.uitype === 8) {
        data.map((row) => {
          row[col.field][0].data = `${row[col.field][0].firstname} ${row[col.field][0].lastname}`;
        });
      }
    });

    setCurrentData(data);
    firstLevel(data);
    if (preSelected !== undefined) {
      setTotalSelections(preSelected);
    }

    if (conditions !== undefined) {
      if (conditions.nestedLevels !== undefined) {
        setNestedFilterLevels(conditions.nestedLevels);
        var count = 0;
        Object.keys(conditions.nestedLevels).map((el) => {
          count += conditions.nestedLevels[el].value.length;
        });
        setFilterNestCount(count);
      }
    }
  }, []);

  useEffect(()=>{
    if(value !==undefined && value.id !==undefined && value.id.length>0) {   
    setTotalSelections(value.id);
    setViewSelected(true)
    }
  },[])

  const firstLevel = (tempData) => {
    let firstList = [];
    if ([1, 7, 8,16,18].includes(nestedFields[0].uitype)) {
      firstList = [
        ...new Set(
          tempData
            .filter((el) => el[nestedFields[0].field] !== null)
            .map((el) => el[nestedFields[0].field].map((c) => c.data)[0]),
        ),
      ];
    } else {
      firstList = [
        ...new Set(
          tempData
            .filter((el) => el[nestedFields[0].field] !== null)
            .map((el) => el[nestedFields[0].field]),
        ),
      ];
    }

    setCurrentLevel({
      field: nestedFields[0].field,
      label: nestedFields[0].label,
      data: firstList,
      isExpanded: true,
      uitype: nestedFields[0].uitype,
      lookupuitype:nestedFields[0].lookupuitype
    });
  };

  const updateSelectedValues = (value) => {
    setCurrentSelectedItems(value);

    if (colInfo.lookup === 'multiple') {
      setShowSelectButton(true);
    } else {
      //it's a single select, so we automatically update value on form. Different format for webform versus addnewrecord.
      let data = null;
      if (callingComponent === 'webform') {
        data = { value: value };
        saveSelectionProgress([value]); //added 9-28-22 to update webform with full path of selection to be used to hide/show other fields.
        updateValue(colInfo.data, data);
      } else {
        const record = currentData.filter((el) => el.id === parseInt(value))[0];
        const data = [{ data: record.CustomID, optionid: record.id }];
        updateValue(colInfo.data, data);
      }
    }
  };

  const toggleViewSelection = () => {
    setViewSelected(!viewSelected);
  };

  const addSelections = () => {
    const temp = [...totalSelections];
    currentSelectedItems.map((itm) => {
      if (!temp.includes(itm)) {
        temp.push(parseInt(itm));
      }
    });

    setTotalSelections(temp);

    // Call method to update Web Form with values to be used in filter/hiding of fields.
    if(callingComponent==='webform') {
      saveSelectionProgress(temp);
    }

    //update the form values. Format is differnt between webform and newRecord.
    // Exampples: webform is {value:[1,2,3]} .  newreocrd is [{data:'my first item',optionid:22},{data:'my second item',optionid:54}]

    let data = [];
    if (callingComponent === 'webform') {
      data = { value: temp };
    } else {
      currentData.map((itm) => {
        if (temp.includes(itm.id)) {
          data.push({ data: itm.CustomID, optionid: itm.id });
        }
      });
    }

    updateValue(colInfo.data, data);
  };

  const saveSelectionProgress = (sel) =>{

       /*  CODE THAT SAVES ARRAY OF ALL NESTED VALUES TO BE USED IN WEB FORM FIELD FILTER LOGIC */
       
       const items = currentData.filter((el) => sel.includes(el.id));
  
       const nestedValues = {};
       items.map((itm) => {
         nestedFields.map((fld) => {
           const idParam = fld.uitype === 1 ? 'id' : 'optionid';
           if (nestedValues[fld.field] === undefined) {
            console.log(itm[fld.field])
             nestedValues[fld.field] = [1, 6, 7, 8, 16, 18].includes(fld.uitype) && itm[fld.field] !==null
               ? [itm[fld.field][0][idParam]]
               : [itm[fld.field]];
           } else {
             nestedValues[fld.field].push(
               [1, 6, 7, 8, 16,18 ].includes(fld.uitype) ? itm[fld.field][0][idParam] : itm[fld.field],
             );
           }
         });
       });
       

      
       updateSelectedLevel(colInfo.data, nestedValues)
   
       /*  END OF FIELD FILTER  LOGIC*/
  }

  const removeSelection = (id) => {
    let temp = [...totalSelections];
    temp = temp.filter((itm) => itm !== id);
    setTotalSelections(temp);

    //update the form values. Format is differnt between webform and newRecord.
    // Exampples: webform is {value:[1,2,3]} .  newreocrd is [{data:'my first item',optionid:22},{data:'my second item',optionid:54}]

    let data = [];
    if (callingComponent === 'webform') {
      data = { value: temp };
    } else {
      currentData.map((itm) => {
        if (temp.includes(itm.id)) {
          data.push({ data: itm.CustomID, optionid: itm.id });
        }
      });
    }

    updateValue(colInfo.data, data);
    if (callingComponent === 'webform'){
      saveSelectionProgress(temp);
    }
  };

  const addLevel = (level, value, items) => {
    // when they click to remove a selected option, we pass in the remainting items
    // so logic can properly display list of options
    console.log(level)
    console.log(value)
    console.log(items)
   
    let tempItems = [];
    if (items === undefined) {
      tempItems = structuredClone(selectedItems);
    } else {
      tempItems = structuredClone(items);
    }

    // we add the defined value to the existing object. This is always the text version which
    //is used to display on page. For single select option & user fields, we also store the id value
    //which is used in the actual filter used to filter the results. This is defined below.
    level.value = value;
    setLastSelected(value);

    let levelData = [];
    let filteredData = [];
    const currentIndex = nestedFields.findIndex((el) => el.field === level.field);

    const nextlevelIndex = currentIndex + 1;
    const dataCopy = currentData;

    if ([1, 7, 8, 16, 18].includes(nestedFields[currentIndex].uitype)) {
      filteredData = dataCopy.filter(
        (itm) => itm[level.field] !== null &&  itm[level.field]!==undefined && itm[level.field][0].data === value,
      );

      if (tempItems.length > 0) {
        tempItems.map((s) => {
          if ([1, 7, 8,16,18].includes(s.uitype)) {
            filteredData = filteredData.filter(
              (itm) => itm[s.field] !== null && itm[s.field][0].data === s.value,
            );
          } else {
            filteredData = filteredData.filter(
              (itm) => itm[s.field] !== null && itm[s.field] === s.value,
            );
          }
        });
      }

      if (nestedFields[currentIndex].uitype === 7) {
        const id = filteredData[0][level.field][0].optionid;
        level.idValue = id;
      } else if (nestedFields[currentIndex].uitype === 8) {
        const userid = filteredData[0][level.field][0].userid;
        level.idValue = userid;
      } else if (nestedFields[currentIndex].uitype === 1) {
        const id = filteredData[0][level.field][0].id;
        level.idValue = id;
      } else if(nestedFields[currentIndex].uitype===18){
        if(nestedFields[currentIndex].lookupuitype===1) {
            const id =filteredData[0][level.field][0].id 
            level.idValue=[id]
        } else if([6,7].includes(nestedFields[currentIndex].lookupuitype)){
            const id =filteredData[0][level.field][0].optionid 
            level.idValue=[id]
        }  else if(nestedFields[currentIndex].lookupuitype===8) {
            const id =filteredData[0][level.field][0].userid;
            level.idValue=[id]
        }
      } 

    } else {
      filteredData = dataCopy.filter(
        (itm) => itm[level.field] !== null && itm[level.field] === value,
      );

      if (tempItems.length > 0) {
        tempItems.map((s) => {
          if ([1, 7, 8, 16, 18].includes(s.uitype)) {
            filteredData = filteredData.filter(
              (itm) => itm[s.field] !== null && itm[s.field][0].data === s.value,
            );
          } else {
            filteredData = filteredData.filter(
              (itm) => itm[s.field] !== null && itm[s.field] === s.value,
            );
          }
        });
      }
    }

    // const showNextLevel = ((nextlevelIndex < nestedFields.length) && colInfo.lookup==='multiple')
    // || ((nextlevelIndex < nestedFields.length) && (colInfo.lookup==='single' && filteredData.length>1))

    const showNextLevel = nextlevelIndex < nestedFields.length && filteredData.length > 1;

    if (showNextLevel) {
      if (nextlevelIndex === nestedFields.length && colInfo.lookup === 'multiple') {
        levelData = filteredData;
      } else if ([1, 7, 8, 16, 18].includes(nestedFields[nextlevelIndex].uitype)) {
        levelData = [
          ...new Set(
            filteredData
              .filter((el) => el[nestedFields[nextlevelIndex].field] !== null)
              .map((el) => el[nestedFields[nextlevelIndex].field].map((c) => c.data)[0]),
          ),
        ];
      } else {
        levelData = [
          ...new Set(
            filteredData
              .filter((el) => el[nestedFields[nextlevelIndex].field] !== null)
              .map((el) => el[nestedFields[nextlevelIndex].field]),
          ),
        ];
      }


      const cLevel = {
        field: nestedFields[nextlevelIndex].field,
        label: nestedFields[nextlevelIndex].label,
        data: levelData,
        isExpanded: true,
        uitype: nestedFields[nextlevelIndex].uitype,
        lookupuitype: nestedFields[nextlevelIndex].lookupuitype,
      }

      setCurrentLevel(cLevel);
   
      if (items === undefined) {
        tempItems.push(level);
        setSelectedItems(tempItems);
      } else {
        setSelectedItems(tempItems);
      }
      //if single select, when changing a current value, we need to reset selected values to null
      if (colInfo.lookup === 'single' && callingComponent === 'webform') {
        updateValue(colInfo.data, { value: '' });
      } else if (colInfo.lookup === 'single' && callingComponent === 'newRecord') {
        updateValue(colInfo.data, []);
      }

      setShowSelectButton(false);
      setCurrentIndex(nextlevelIndex);
      setFinalListData([]); //Reset available options when they are reselecting a level.
      if(levelData.length===1) {
        addLevel(cLevel,levelData[0],tempItems)
      }


    } else if (colInfo.lookup === 'single' && filteredData.length === 1) {
      let data = [];
        if (callingComponent === 'webform') {
          data = { value: filteredData.map((el) => el.id)[0] };
        } else {
          data.push({ data: filteredData[0].CustomID, optionid: filteredData[0].id });
        }
        //remove any extra selections
        // setCurrentLevel(level)
        const idx = selectedItems.findIndex((el) => el.field === level.field);
        if (idx !== -1) {
          setSelectedItems(selectedItems.slice(0, idx));
          setCurrentLevel(level);
        }
        setFinalListData([]);
       if(callingComponent==='webform'){
        saveSelectionProgress(filteredData.map((el) => el.id)) //Added 9-28-22...track full nested field values for hide/show on webforms
       }
       
        updateValue(colInfo.data, data);
      
    } else if (colInfo.lookup === 'multiple' && filteredData.length === 1) {
    
      setCurrentSelectedItems([filteredData[0].id]);
      setShowSelectButton(true);

      const idx = selectedItems.findIndex((el) => el.field === level.field);
      if (idx !== -1) {
        setSelectedItems(selectedItems.slice(0, idx));
        setCurrentLevel(level);
      }
      setFinalListData([]);
    } else {
      //in this case, we have used up all levels and need to display final list of matching records.
      //if single select, we want to add empty value to available dropdown so they are forced
      //to choose item which triggers action. If multiple, don't add empty item.
      
      let listData = [];
      if (colInfo.lookup === 'single') {
        listData = [{ key: '0', value: '', text: '' }];
      }

      filteredData.map((el) => {
        listData.push({ key: el.id, value: el.id, text: el.CustomID });
      });
      setFinalListData(listData);
    }
    setLastCompletedLevel(level);
  };


  // Method used when configuring webform filter. Allows user to select whatever last level they completed 
  // to add as criteria to be used in determining if web form field hide / show.
  const addFilterLevel = () => {
    const val = [1, 6, 7, 8,16,18].includes(lastCompletedLevel.uitype)
      ? lastCompletedLevel.idValue
      : lastCompletedLevel.value;
    const textvalue = `${lastCompletedLevel.label}: ${lastCompletedLevel.value}`;
    if (nestedFilterLevels[lastCompletedLevel.field] === undefined) {
      nestedFilterLevels[lastCompletedLevel.field] = { value: [val], text: [textvalue] };
    } else {
      nestedFilterLevels[lastCompletedLevel.field].value.push(val);
      nestedFilterLevels[lastCompletedLevel.field].text.push(textvalue);
    }

    setNestedFilterLevels(nestedFilterLevels);

    var count = 0;
    let textValues=[];
    Object.keys(nestedFilterLevels).map((el) => {
      count += nestedFilterLevels[el].value.length;
      nestedFilterLevels[el].text.map((t) => textValues.push(t))
    });
    setFilterNestCount(count);
   
    //We need to create the textvalue attribute here combining both the level selections & any existing final selections.
    const items = data.filter((itm) => totalSelections.includes(itm.id));
    items.map(itm=>{
      textValues.push(itm.CustomID)
    })
   
    if (updateSelectedLevel !== undefined) {
      updateSelectedLevel(colInfo.data, nestedFilterLevels,textValues);
    }
  };

  const removeNestedLevels = () => {
    setNestedFilterLevels({});
    setFilterNestCount(0);

    if (updateSelectedLevel !== undefined) {
      let textValues=[];
      const items = data.filter((itm) => totalSelections.includes(itm.id));
      items.map(itm=>{
        textValues.push(itm.CustomID)
      })

      updateSelectedLevel(colInfo.data, null,textValues);
    }
  };

  const changeLevelValue = (level, value) => {
    const idx = structuredClone(selectedItems).findIndex((el) => el.field === level.field);

    if (idx !== -1) {
      const tempItems = structuredClone(selectedItems);
      tempItems[idx].value = value;
      // setSelectedItems(tempItems.slice(0,idx+1));
      addLevel(level, value, tempItems.slice(0, idx + 1));
    }
  };

  const options = () => {
    let opts = [{ key: '0', value: '', text: 'Choose value' }];

    currentLevel.data.map((el) => {
      opts.push({ key: el, value:el, text: el });
    });

    return opts;
  };

  const selectedOptions = (data) => {
    let opts = [];
    data.map((el) => {
      opts.push({ key: el, value: el, text: el });
    });
    return opts;
  };

  const getLabelFormat = (label) => {
    if (labelsInCaps) return label.toUpperCase();
    return label;
  };

  const displyFilterLevels = () => {
    return Object.keys(nestedFilterLevels).map((itm) =>
      nestedFilterLevels[itm].text.map((t) => <div>{t}</div>),
    );
  };

  const showCounts = () => (
    <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
      <span style={{ marginLeft: '10px', marginBottom: '5px' }}>
        <Popup
          trigger={
            <div style={{ marginTop: '5px', cursor: 'pointer', marginRight: '5px',color:color }}>
              Selected levels ({filterNestCount})
            </div>
          }
          hoverable
        >
          {displyFilterLevels()}
        </Popup>
      </span>
      <span>
        <IconButton
          color="red"
          hoverColor="white"
          hoverBackgroundColor="#F6685E"
          icon={mdiClose}
          Action={removeNestedLevels}
        />
      </span>
    </div>
  );

  const DisplayDropdowns = () => {
    return (
      <div
        style={{
          fontFamily: fontFamily !== undefined ? fontFamily : null,
          fontWeight: fontFamily !== undefined ? 'bold' : null,
          fontSize: fontSize,
        }}
      >
        {/* {filterNestCount > 0 && selectedItems.length ===0 ? showCounts() : null} */}

        {selectedItems.length > 0
          ? selectedItems.map((itm, i) => (
              <div key={itm.field} style={{ marginBottom: '20px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {getLabelFormat(itm.label)}
                  {/* {callingComponent === 'form' && i === 0 ? (
                    <span style={{ marginLeft: '10px' }}>
                      <TextButton
                        Action={addFilterLevel}
                        text="Add level"
                        icon={mdiPlus}
                        iconPosition="left"
                        textColor="black"
                        hoverColor="#00000080"
                      />
                    </span>
                  ) : null} */}
                  {/* {filterNestCount > 0 && i === 0 ? showCounts() : null} */}
                </div>
                {itm.isExpanded ? (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {!htmlMode ? (
                    <Dropdown
                      fluid
                      search
                      options={selectedOptions(itm.data)}
                      value={itm.value}
                      selection
                      onChange={(e, data) =>
                        data.value !== '' ? changeLevelValue(itm, data.value) : null
                      }
                      style={{
                        borderLeft: callingComponent === 'webform' ? `7px solid ${color}` : null,
                        borderTopLeftRadius: '3px',
                        borderBottomLeftRadius: '3px',
                        outline: 'none',
                      }}
                    />
                    ) : (
                      <select
                       onChange={(e) => e.target.value !=='' ? changeLevelValue(itm, e.target.value) :null}>
                        <option key="0" value="" disabled selected>Select your option</option>
                      {selectedOptions(itm.data).map((opt) => (
                        <option key={opt.key} selected={itm.value===opt.value} value={opt.value}>{opt.text}</option>
                      ))}
                    </select>
                    )} 
                  </div>
                ) : null}
              </div>
            ))
          : null}

        <div style={{ marginTop: selectedItems.length > 0 ? '20px' : null }}>
          {currentLevel && showCurrentLevel && currentLevel.data.length > 0 ? (
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              {getLabelFormat(currentLevel.label)}
            </div>
          ) : null}
        </div>
        {currentLevel &&
        showCurrentLevel &&
        currentLevel.data.length > 0 &&
        currentLevel.isExpanded &&
        selectedItems.length < nestedFields.length ? (
          <>
            {!htmlMode ? (

            <Dropdown
              fluid
              search
              options={options()}
              value={lastSelected !== null ? lastSelected : ''}
              selection
              onChange={(e, data) =>
                data.value !== '' ? addLevel(currentLevel, data.value) : null
              }
              style={{
                borderLeft: callingComponent === 'webform' ? `7px solid ${color}` : null,
                borderTopLeftRadius: '3px',
                borderBottomLeftRadius: '3px',
                outline: 'none',
              }}
            />
            ) :
          (
            <select
               onChange={(e) => addLevel(currentLevel, e.target.value)}>
                {options().map((el) => (
                  <option selected={lastSelected !==null? el.value===lastSelected : null} key={el.key} value={el.value}>{el.text}</option>
                ))}
              </select>
          )} 
          </>
        ) : null}

        {finalListData.length > 0 ? (
          <div style={{ display: 'flex', marginTop: '20px', flexDirection: 'column' }}>
            <div>Available values</div>
            {!htmlMode ? (
            <Dropdown
              fluid
              search
              options={finalListData}
              selection
              multiple={colInfo.lookup === 'multiple'}
              onChange={(e, data) => (data.value !== '' ? updateSelectedValues(data.value) : null)}
              style={{
                borderLeft: callingComponent === 'webform' ? `7px solid ${color}` : null,
                borderTopLeftRadius: '3px',
                borderBottomLeftRadius: '3px',
                outline: 'none',
              }}
            />
             ): (
            <select multiple={colInfo.lookup === 'multiple'}
            value={currentSelectedItems}
            onChange={(e) =>{
              let values=null;
              if(colInfo.lookup === 'multiple') {
                  values = [...e.target.selectedOptions].map(opt => opt.value);
              } else {
                values =e.target.value
              }
              if(values !=='') {updateSelectedValues(values) };
            }}>
                {finalListData.map((itm) => (
                  <option value={itm.value}>{itm.text}</option>
                ))}
              </select>
          )} 
          </div>
        ) : null}
        {showSelectButton ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              margin: '10px',
            }}
          >
            <GBButton text="Select value(s)" color={color} Action={addSelections} />
          </div>
        ) : null}
        {totalSelections.length > 0 ? (
          <div
            onClick={toggleViewSelection}
            style={{
              color: color,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              margin: '10px',
              cursor: 'pointer',
            }}
          >
            View selected values ({totalSelections.length})
          </div>
        ) : null}
        {/* This callingComponent='form' comes from FormEditObject when defining fitlers for when/how a field will appear. */}
        {callingComponent === 'form' ? (<>
                    <div style={{ marginLeft: '10px', display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'flex-end' }}>
                      <TextButton
                        // Action={selectedItems.length>0 ? addFilterLevel : ()=>{return false}}
                        Action={lastSelected !==null ? addFilterLevel : ()=>{return false}}
                        text="Add level"
                        icon={mdiPlus}
                        iconPosition="left"
                        textColor={color}
                        hoverColor={`${color}80`}
                      />
                    {showCounts()} 
                    </div>
                  </>) : null}
           {/* {filterNestCount > 0 && selectedItems.length ===0 ? (
            <div style={{ display:'flex',flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
            {showCounts()}
            </div>
            ) : null} */}

      </div>
    );
  };

  const DisplaySelected = () => {
    const items = data.filter((itm) => totalSelections.includes(itm.id));

    return (
      <div>
        {items.map((itm) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'cente',
              justifyContent: 'space-between',
              margin: '10px',
            }}
          >
            <div>{itm.CustomID}</div>
            <div>
              <IconButton
                color="red"
                hoverColor="white"
                hoverBackgroundColor="#F6685E"
                icon={mdiClose}
                Action={removeSelection}
                ActionValue={itm.id}
              />
            </div>
          </div>
        ))}
        <GBButton text="Back" Action={toggleViewSelection} color={color} />
      </div>
    );
  };

  return <div>{!viewSelected ? <>{DisplayDropdowns()}</> : DisplaySelected()}</div>;
};

export default NestedDropdowns;
