/* eslint-disable */
import React,{ useEffect,useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { generateText } from 'src/api/ai';

//2-5-24 added showMenu=true to support not showing menu's in situations when 
//using control in form from datalist to create new record. In those use-cases, the toolbar menus don't work.

const CustomEditor = ({field, initValue, updateContent,uitype,height,callingComponent,showMenu=true}) => {

  let editor;

  const handleEditorInit = (evt, editorInstance) => {
    if(callingComponent==='EmailControl') {
      editor = editorInstance;
      focusEditor();
    }
  };

  const focusEditor = () => {
    if (editor) {
      editor.focus();
    }
  };


	let plugins ='advlist anchor autolink autoresize code charmap  directionality  emoticons fullscreen help image importcss insertdatetime link  lists media  nonbreaking pagebreak preview quickbars save searchreplace table visualblocks visualchars wordcount'
	let toolbars ='undo redo | formatselect | bold italic backcolor | \
	alignleft aligncenter alignright alignjustify | \
	link | bullist numlist outdent indent | removeformat | customAIButton'
	if(callingComponent==='form') {
		plugins ='advlist anchor autolink autoresize code charmap  directionality  emoticons fullscreen help image importcss insertdatetime link  lists media  nonbreaking pagebreak preview  save searchreplace table visualblocks visualchars wordcount'
		toolbars = showMenu ? 'undo redo | formatselect | bold italic backcolor | \
			alignleft aligncenter alignright alignjustify | \
			link | bullist numlist outdent indent | removeformat' :  'bold italic'
	} 


	return (
		        <Editor
                  onInit={handleEditorInit}
                  initialValue={initValue ?? '<div></div>'}
                  init={{
                    removed_menuitems: "newdocument",
                    convert_urls:false,
                    min_height: height !==undefined ? height : null,
                    max_height: height !==undefined ? height : null,
                    menubar: showMenu,
                    statusbar: false,
                    promotion: false,
                    branding: false,
                    plugins: plugins,
                    toolbar: toolbars,
                    quickbars_selection_toolbar: 'bold italic | h1 h2 h3 h4 formatselect | quicklink',
                  setup: function (editor) {
                    // Add a custom button to the toolbar
                    editor.ui.registry.addButton('customAIButton', {
                      text: 'AI', // Button label
                      onAction: async function () {
                        // Custom action when the button is clicked
                        let content = editor.getContent();
                        const lastIdx = content.lastIndexOf('</p>');
                        const finalString = content.slice(0, lastIdx) + '. return in html' + content.slice(lastIdx);
                     
                        // Do your processing on the content here
                        // For example, let's add "Processed:" before the content
                        const processedContent = await generateText(finalString,false)
                        editor.setContent(processedContent);
                      },
                    });
                  },
                  }}
                  onEditorChange={(val)=> ['RTFElement','EmailControl','Block'].includes(callingComponent) ? updateContent(val) : updateContent(field,val,uitype)}
                />

	);
}


export default CustomEditor