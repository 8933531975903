/* eslint-disable */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Label,Input,Dropdown,Checkbox } from 'semantic-ui-react';
import { mdiPaperclip,mdiClose, } from '@mdi/js';
import Icon from '@mdi/react';
import { AccountContext } from '../../../../providers/AccountProvider';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import {getTablesWithAttachments,getFieldAttachments} from '../../../../api/tables'

const FindAttachments = ({color,close, handleInsertLinks}) =>{

    const { userInfo } = useContext(AccountContext);
    const [tableData,setTableData] = useState([]);
    const [blockid, setBlockid] =useState();
    const [tableid, setTableid] = useState();
    const [tableOptions ,setTableOptions] =useState([]);
    const [fieldOptions ,setFieldOptions] =useState([]);
    const [field, setField] = useState();
    const [attachments, setAttachments] = useState([]);
    const [selectedAttachments, setSelectedAttachments] = useState([]);
    const [filter, setFilter] =useState('')
   

    useEffect(() => {
        loadTableData();
    },[])

    const loadTableData = async () =>{
        const data = await getTablesWithAttachments();
        setTableData(data);

        if(userInfo.fileAttachmentBlockid !==undefined) {
            setBlockid(userInfo.fileAttachmentBlockid);
            const tableOptionsTemp=[];
            data.filter(itm=>itm.blockid===userInfo.fileAttachmentBlockid).map(el =>{
                const idx = tableOptionsTemp.findIndex(itm=>itm.value===el.id)
                if(idx===-1) {
                    tableOptionsTemp.push({key:el.id,value:el.id,text:el.name})
                }
            })
           
             tableOptionsTemp.sort((a, b) => (a.text > b.text ? 1 : -1));
            //Update list of Blocks
            setTableOptions(tableOptionsTemp);
            if(userInfo.fileAttachmentTableid !==undefined) {
                setTableid(userInfo.fileAttachmentTableid)
                
                const fieldOptionsTemp=[];
                data.filter(itm=>itm.id===userInfo.fileAttachmentTableid).map(el =>{
                    const idx = fieldOptionsTemp.findIndex(itm=>itm.value===el.id)
                    if(idx===-1) {
                       fieldOptionsTemp.push({key:el.field,value:el.field,text:el.header})
                    }
                })
        
                fieldOptionsTemp.sort((a, b) => (a.text > b.text ? 1 : -1));
        
                setFieldOptions(fieldOptionsTemp);
                setAttachments([])

                
                //Check if there is only one field, and if yet, auto-select attachments
                if(fieldOptionsTemp.length===1) {
                    const attachs = await getFieldAttachments(userInfo.fileAttachmentTableid,fieldOptionsTemp[0].value);
                    setField(fieldOptionsTemp[0].value);
                     setAttachments(attachs);
                } else {
                    setAttachments([])
                }

                if(userInfo.fileAttachmentField !==undefined && fieldOptionsTemp.length>1) {
                    setField(userInfo.fileAttachmentField);
                    const attachs = await getFieldAttachments(userInfo.fileAttachmentTableid,userInfo.fileAttachmentField);
                    setAttachments(attachs);
                }
            }
           

        }
    }



    const isChecked = (url) =>{
        return selectedAttachments.findIndex(el=>el===url)==-1;
    }

    const handleCheck =(url,filename,value) =>{
        if(!value) {
          
            const idx =selectedAttachments.findIndex(el=>el.url===url)
            if(idx !==-1) {
                selectedAttachments.splice(idx,1)
            }
        } else {
            selectedAttachments.push({url,filename,guidname:filename});
        }

        setSelectedAttachments(selectedAttachments);
    }

    const blockOptions = () =>{
        const options=[];
        tableData.map(el =>{
            const idx = options.findIndex(itm=>itm.value===el.blockid)
            if(idx===-1) {
                options.push({key:el.blockid,value:el.blockid,text:el.blockname})
            }
        })

        options.sort((a, b) => (a.text > b.text ? 1 : -1));

        return  options;
    }

    const handleBlockSelection =async (blockid) =>{
        setBlockid(blockid);
        userInfo.fileAttachmentBlockid=blockid;

        const tableOptionsTemp=[];
        tableData.filter(itm=>itm.blockid===blockid).map(el =>{
            const idx = tableOptionsTemp.findIndex(itm=>itm.value===el.id)
            if(idx===-1) {
                tableOptionsTemp.push({key:el.id,value:el.id,text:el.name})
            }
        })
       
         tableOptionsTemp.sort((a, b) => (a.text > b.text ? 1 : -1));
        //Update list of Blocks
        setTableOptions(tableOptionsTemp);

        //If there is only one matching table, automatically select the table
        //determine if there is only one field and if yes, auto-select field and attachments. 
        if(tableOptionsTemp.length===1) {
            setTableid(tableOptionsTemp[0].value);
            const tempTables= tableData.filter(itm=>itm.id===tableOptionsTemp[0].value);
            if(tempTables.length===1) {
                setFieldOptions([{key:tempTables[0].field,value:tempTables[0].field,text:tempTables[0].header}])
                setField(tempTables[0].field);
                const attachs = await getFieldAttachments(tempTables[0].id,tempTables[0].field);
                setAttachments(attachs);
            }
        } else {
            setAttachments([])
            setFieldOptions([])
        }
    }

    const handleTableSelection = async (id) =>{
        setTableid(id);
        userInfo.fileAttachmentTableid= id;

        const fieldOptionsTemp=[];
        tableData.filter(itm=>itm.id===id).map(el =>{
            const idx = fieldOptionsTemp.findIndex(itm=>itm.value===el.id)
            if(idx===-1) {
               fieldOptionsTemp.push({key:el.field,value:el.field,text:el.header})
            }
        })

        fieldOptionsTemp.sort((a, b) => (a.text > b.text ? 1 : -1));

        setFieldOptions(fieldOptionsTemp);
        setAttachments([])
        
        //Check if there is only one field, and if yet, auto-select attachments
        if(fieldOptionsTemp.length===1) {
            const attachs = await getFieldAttachments(id,fieldOptionsTemp[0].value);
            setField(fieldOptionsTemp[0].value);
             setAttachments(attachs);
        } else {
            setAttachments([])
        }
    }

    const handleFieldSelection = async (field) => {
        setField(field);
        userInfo.fileAttachmentField=field;
        const attachs = await getFieldAttachments(tableid,field);
        setAttachments(attachs);
    }


    return (
        <div style={{height:'600px'}}>
           <div
          style={{
            display: 'flex',
            padding: '10px',
            flexDirection: 'row',
            height: '40px',
            backgroundColor: color,
            color: 'white',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderTopLeftRadius:'10px',
            borderTopRightRadius:'10px'
          }}
        >
          <div
            style={{
              marginLeft: '10px',
              color: 'white',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Icon path={mdiPaperclip} size="25px" />
            <div style={{ marginLeft: '5px',fontSize:'15px' }}>Select attachment </div>
          </div>
          <TextButton
            text="Hide panel when done"
            Action={close}
            textColor="white"
            icon={mdiClose}
            hoverColor="#FFFFFF80"
          />
        </div>
        <div style={{padding:'10px',width:'100%'}}>
            {attachments.length>0 ? (
                <div>
                <Input style={{width:'100%'}} icon="search" iconPosition="left" fluid placeholder="Find existing Zone attachment" onChange={(e,data)=>setFilter(data.value)} />
                </div>
            ): null}
            <div style={{height:'40px'}}></div>
            <div style={{marginTop:'5px',marginBottom:'5px'}}>
                Block: <Dropdown search placeholder="Select a block" value={blockid} selection options={blockOptions()} fluid onChange={(e,data)=>handleBlockSelection(data.value)}  />
            </div>
            <div  style={{marginBottom:'5px'}}>
                Tab: <Dropdown search placeholder="Select a Tab" value={tableid} selection options={tableOptions} fluid onChange={(e,data)=>handleTableSelection(data.value)}  />
            </div>
            <div  style={{marginBottom:'5px'}}>
                Field: <Dropdown search placeholder="Select a Field" value={field} selection options={fieldOptions} fluid onChange={(e,data)=>handleFieldSelection(data.value)}  />
            </div>
            {}
           
            {attachments.length> 0 ? (
            <div style={{margin:'5px',height:'250px',overflow:'auto'}}>
                 <div style={{backgroundColor:"#F2F1F1"}}>Files</div>
                {attachments.filter(a=>a.filename.toLowerCase().startsWith(filter)).map(itm=>(
                    <div style={{margin:'5px'}}>
                    <Checkbox isChecked={isChecked(itm.gcsurl)} onChange={(e,data)=>handleCheck(itm.gcsurl,itm.filename,data.checked)}   />  <a href={itm.gcsurl} target="_newwindow">{itm.filename}</a>
                    </div>
                ))}
            </div>):null}
            <div style={{display:'flex',flexDirection:'row', alignItems:'center',justifyContent:'center'}}>
             <TextButton text="Cancel" textColor='black' hoverColor={"#00000080"} Action={close} />
             <div style={{width:'15px'}} />
             <GBButton color={color} text="Save" width='75px' Action={handleInsertLinks} ActionValue={selectedAttachments} />
            </div>

        </div>

        </div>
    )
}

export default FindAttachments;