/* eslint-disable */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input } from 'semantic-ui-react';
import { AccountContext } from '../../../../providers/AccountProvider';
import { getBlocksAPI } from '../../../../api/blocks';
import FilterOperators from './FilterOperators';
import Global from '../../../../clients/global'

const BlockFilter = (props) => {
  const [optionData, setOptionData] = useState([]);
  const [operator, setOperator] = useState('searchvalues');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [roleValue, setRoleValue] = useState();
  const [roleOperator,setRoleOperator] = useState('searchvalues');
  const [textValues,setTextValues] = useState('');

  const { displayfield, filter, header, field, uitype, UpdateFilter, color } = props;

  const options = [
    { key: 1, value: 'Block user (General)', text: 'Block user (General)' },
    { key: 2, value: 'Block builder', text: 'Block builder' },
  ];

  useEffect(() => {
    const listdata = [];
    const fetchData = async () => {
      let tempData = '';
      tempData = await getBlocksAPI(parseInt(Global.zoneid), 'all');
      tempData.forEach((el) => {
        listdata.push({ key: el.blockid, value: el.blockid, text: el.name });
      });

      setOptionData(listdata);
      if (filter.value !== undefined) {
        setSelectedOptions(filter.value);
        setOperator(filter.operator);
      }
      if(filter.roleValue !== undefined){
          setRoleOperator(filter.roleOperator)
          setRoleValue(filter.roleValue)
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);



  const updateFilter = (items, tmpoperator, rolefield) => {
    if(rolefield===undefined){
        setSelectedOptions(items);
    } else {
        setRoleValue(items)
    }

    let textvalues=[];
    let roleTextValues=[];
    if(rolefield === undefined) {
        items.forEach((itm) => {
        const rowindex = optionData.findIndex((el) => el.value === itm);
        if (rowindex !== -1) {
            textvalues.push(optionData[rowindex].text);
        }
        });
        setTextValues(textvalues)
    } else {
        roleTextValues=items
    }

    const filterObject = {
      field,
      header: 'Block',
      value: rolefield===undefined ? items : selectedOptions,
      roleValue: rolefield !==undefined ? items : roleValue,
      roleOperator: rolefield === undefined && tmpoperator === undefined ? roleOperator : tmpoperator,
      roleTextValues: roleTextValues,
      uitype,
      textvalues: rolefield === undefined ? textvalues : textValues,
      displayfield,
      operator: rolefield===undefined && tmpoperator === undefined ? operator : tmpoperator
    };
    UpdateFilter(filterObject);
  };

  const filterOption = [
    { key: 'searchvalues', text: 'Search values', value: 'searchvalues' },
    { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
    { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
  ];


  const changeOperator = (newoperator) => {
    setOperator(newoperator);
    updateFilter(selectedOptions, operator);
  };

  const changeRoleOperator=(newoperator) => {
    setRoleOperator(newoperator)
    updateFilter(roleValue,newoperator,'role')
  }

  const removeFilter = (newfield) => {
    // updateFilter([]);
    props.remove(newfield);
  };
  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  // Just setting this to default grey color, otherwise it will use block color.
  // keeping logic just in case we want to swap back to using colors.

  return (
    <div style={{ marginBottom: '23px', width: '100%' }}>
      <FilterOperators
        header="Block"
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={field}
        ChangeOperator={changeOperator}
        color={color}
      />

      {operator === 'isnull' || operator === 'isnotnull' ? (
        <Input style={{ width: '100%' }} value={displayvalue} />
      ) : (
          <Dropdown
            multiple
            search
            fluid
            selection
            value={selectedOptions}
            // renderLabel={({text}) => <Label>{text}</Label>}
            options={optionData}
            onChange={(e, data) => updateFilter(data.value)}
          /> )}
          <div style={{height:'20px'}}></div>
         <FilterOperators
            header="Block role"
            operator={roleOperator}
            filterOption={filterOption}
            RemoveAction={removeFilter}
            RemoveActionValue={field}
            ChangeOperator={changeRoleOperator}
            color={color}
        />
         {roleOperator === 'isnull' || roleOperator === 'isnotnull' ? (
              <Input style={{ width: '100%' }} value={displayvalue} />
          ) : (
            <Dropdown
            selection
            multiple
            value={roleValue}
            onChange={(e, data) => updateFilter(data.value,operator,'role')}
            style={{ width: '100%' }}
            options={options}
          />
          )}
    </div>
  );
};

export default BlockFilter;
