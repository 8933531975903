/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { Buffer } from 'buffer';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';

import { Dropdown } from 'semantic-ui-react';
import {
  mdiStar,
  mdiEmailFastOutline,
  mdiLockOutline,
  mdiDragVertical,
  mdiChevronDown,
  mdiEarth,
  mdiPencilRuler,
  mdiContentDuplicate,
  mdiLaunch,
  mdiCog,
  mdiLinkVariantPlus,
  mdiCodeTags,
  mdiDeleteForever,
  mdiImage,
} from '@mdi/js';
import Avatar from '../../../../components/Avatar/Avatar';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Global from '../../../../clients/global';

const FormItem = ({
  formData,
  color,
  userId,
  updateFormEmails,
  editForm,
  editSettings,
  deleteForm,
  duplicateForm,
  updateFormStatus,
  generateSocialShare,
  togglePreview,
  dbname,
}) => {
  const IsUserEmailEnabled = () => {
    if (formData.emailusers === null) {
      return false;
    } else {
      return formData.emailusers.findIndex((el) => el.user === userId) !== -1;
    }
  };

  const copyLink = () => {
    const { REACT_APP_SITE_URL } = process.env;

    const c = Buffer.from(`${Global.zoneid}|${dbname}|${formData.formid}`);
    let base64data = c.toString('base64');
    const link = `${REACT_APP_SITE_URL}/form?x=${base64data}`;

    var input = document.createElement('input');
    input.setAttribute('value', link);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);

    toast.info(<div style={{ margin: '5px' }}>Link was successfully copied!</div>, {
      position: toast.POSITION.BOTTOM_CENTER,
      autoClose: 5000,
      closeButton: false,
    });
  };

  const embedForm = () => {
    const { REACT_APP_SITE_URL } = process.env;
    const c = Buffer.from(`${Global.zoneid}|${dbname}|${formData.formid}`);
    let base64data = c.toString('base64');
    const link = `${REACT_APP_SITE_URL}/form?x=${base64data}`;

    const code = `<iframe src="${link}" frameborder="0" onmousewheel="" width="100%" height="100%" style="background: transparent; border: 1px solid ${color}"></iframe>`;

    var input = document.createElement('input');
    input.setAttribute('value', code);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand('copy');
    document.body.removeChild(input);

    toast.info(
      <div style={{ margin: '5px' }}>
        Embed code successfully copied! Learn more:{' '}
        <a
          style={{ color: 'white', textDecoration: 'underline' }}
          href="https://www.graceblocks.com/support-article/web-form-embed-code"
          target="_newwindow"
        >
          Web forms embed code.
        </a>
      </div>,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const options = [
    {
      key: 'user',
      text: (
        <div>
          <strong>Actions</strong>
        </div>
      ),
      disabled: true,
    },
    {
      key: '1',
      value: 'edit',
      text: 'Edit form design',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiPencilRuler}
          size="20px"
        />
      ),
    },
    {
      key: '2',
      value: 'editsettings',
      text: 'Edit form settings',
      icon: <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiCog} size="20px" />,
    },
    {
      key: '3',
      value: 'duplicate',
      text: 'Duplicate this form',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiContentDuplicate}
          size="20px"
        />
      ),
    },
    {
      key: '12',
      value: 'generate',
      text: 'Generate social sharing image',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiImage} size="20px" />
      ),
    },
    {
      key: '4',
      value: 'launch',
      text: 'Launch form in new tab',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiLaunch} size="20px" />
      ),
    },
    {
      key: '5',
      value: 'copylink',
      text: 'Copy link to form',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiLinkVariantPlus}
          size="20px"
        />
      ),
    },
    {
      key: '6',
      value: 'copyembed',
      text: 'Copy form embed code',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiCodeTags} size="20px" />
      ),
    },
    {
      key: '7',
      value: 'makedefault',
      text: 'Make public default',
      icon: <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiStar} size="20px" />,
    },
    {
      key: '9',
      value: 'makepublic',
      text: 'Make public',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiEarth} size="20px" />
      ),
    },
    {
      key: '10',
      value: 'makeprivate',
      text: 'Make private',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiLockOutline}
          size="20px"
        />
      ),
    },
    {
      key: '11',
      value: 'delete',
      text: 'Delete',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiDeleteForever}
          size="20px"
        />
      ),
    },
  ];

  const formAction = (action) => {
    if (action === 'edit') {
      editForm(formData);
    } else if (action == 'delete') {
      deleteForm(formData.formid, formData.name);
    } else if (action === 'makepublic') {
      updateFormStatus(formData.formid, false, true);
    } else if (action === 'makeprivate') {
      updateFormStatus(formData.formid, false, false);
    } else if (action === 'makedefault') {
      updateFormStatus(formData.formid, true, true);
    } else if (action === 'editsettings') {
      editSettings(formData);
    } else if (action === 'generate') {
      generateSocialShare(formData);
    } else if (action === 'launch') {
      togglePreview(formData, 'live');
    } else if (action === 'copylink') {
      copyLink();
    } else if (action === 'duplicate') {
      duplicateForm(formData.formid, formData.name);
    } else if (action === 'copyembed') {
      embedForm();
    }
  };

  const updateFormEmailsLocal = async (isEnabled) => {
    await updateFormEmails(formData.formid, userId, isEnabled);
  };

  return (
    <div style={{ marginBottom: '15px' }}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div
          onClick={() => togglePreview(formData, 'live')}
          css={{
            width: '250px',
            fontWeight: 'bold',
            color: color,
            '&:hover': { color: `${color}80` },
          }}
        >
          {formData.name}
        </div>
        <div style={{ width: '125px' }}>
          {formData.isdefault ? (
            <div>
              <Icon path={mdiStar} size="20px" /> Public default
            </div>
          ) : formData.ispublic ? (
            <div>
              <Icon path={mdiEarth} size="20px" /> Public
            </div>
          ) : (
            <div>
              <Icon path={mdiLockOutline} size="20px" /> Private
            </div>
          )}
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '200px' }}
        >
          <Avatar item={formData.User} width="30px" height="30px" fontSize="13px" />
          <div style={{ marginRight: '5px' }}>{formData.User.firstname}</div>

          <div>{formData.User.lastname}</div>
        </div>
        <div style={{ width: '125px' }}>{new Date(formData.addedon).toLocaleDateString('en')}</div>
        <div style={{ width: '225px' }}>
          <GBSwitch
            isChecked={IsUserEmailEnabled()}
            text={
              IsUserEmailEnabled() ? (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',fontSize:'18px',marginBottom:'3px'}}>
                  <Icon
                    style={{ marginLeft: '5px',marginRight:'5px'}}
                    path={mdiEmailFastOutline}
                    size="20px"
                  />
                  On
                </div>
              ) : (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',fontSize:'18px',marginBottom:'3px'}}>
                  <Icon
                    style={{ marginLeft: '5px',marginRight:'5px'}}
                    path={mdiEmailFastOutline}
                    size="20px"
                  />
                  Off
                </div>
              )
            }
            Action={updateFormEmailsLocal}
            ActionValue={!IsUserEmailEnabled()}
          />
        </div>
        <div
          css={{
            marginBottom: '15px',
            width: '50px',
            '&:hover': {
              header: {
                svg: {
                  color: 'white',
                  backgroundColor: color,
                  borderRadius: '100%',
                },
              },
            },
          }}
        >
          {/* <div className="ddchevron"> */}
          <Dropdown
            upward={false}
            text=""
            icon={
              <header>
                <Icon className="ddchevron" path={mdiChevronDown} size="20px" />
              </header>
            }
            direction="left"
            selectOnBlur={false}
          >
            <Dropdown.Menu>
              {options.map((el) => (
                <Dropdown.Item
                  key={el.key}
                  text={el.text}
                  icon={el.icon}
                  onClick={() => formAction(el.value)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown>
          {/* </div> */}
        </div>
        <div>
          <Icon path={mdiDragVertical} size="20px" />
        </div>
      </div>
    </div>
  );
};

export default FormItem;
