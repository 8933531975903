/* eslint-disable  */
import { getFormLookupValue,getDataRow } from '../../../../../api/forms';
import preFillForm from './prefillForm';

// Need to consolidate processing of all urlparams so we set set/update fieldValues with single call.
// Trying to do it control by control was resulting in updating just the last url param processed.
const urlParamProcessor= async (formInfo,searchParams,form,updateValues) => {

      const {REACT_APP_API_CLOUDRUN_URL} = process.env;
       const directInsertControls = [2, 3, 5, 22, 23, 8, 9, 10, 11, 14]; // stored directly in table.
       const fieldValues={};
       let hiddenFieldValues = {}; //url params of fields not on form, but in tab.

       /* 8-2-23
        If the searhParams contain both the "id" param AND the "prefill" param, we call
        the prefillForm method to fetch/insert fieldValues and return. 
       */

        if(Object.keys(searchParams).includes('id') && Object.keys(searchParams).includes('prefill')) {
            await preFillForm(formInfo,searchParams,form,updateValues,REACT_APP_API_CLOUDRUN_URL);
           return false;
        }


       await Promise.all(

        //11-5-2021 Changing logic so that url params will use a dash (-) instead of an underscore (_)
        // so field_1=mike , will be passed in like field-1=Mike. This is because we've run into a scenario
        // where a users browser stripped out the underscore from the param name. We believe the - is fully supported.

        //11-19-2021 turns out that - was removed as well. So updating the logic to also match against
        //the field with the "-" stripped. So Field-1, might be passed in as Field1, or field12, etc

        Object.keys(searchParams).map(async (key) => {
           let underscoreKey = '';
           if(key.includes('-')){
               underscoreKey=key.replace('-','_');
           } else{
               underscoreKey=key.replace('field','field_')
           }

          if (key.startsWith('field')) {
            const idx = form.columnsettings.findIndex((el) => el.data === underscoreKey);
            if (idx !==-1){
                    const fieldInfo = form.columnsettings[idx].fieldInfo;
                    if (directInsertControls.includes(fieldInfo.uitype)) {
                    fieldValues[underscoreKey] = searchParams[key];
                    } else if([1,6,7,8].includes(fieldInfo.uitype)){
                        let optionValue = await getFormLookupValue(form.tableid,formInfo,fieldInfo,searchParams[key])
                        if(optionValue.length>0){
                            fieldValues[underscoreKey]= {id:[parseInt(optionValue[0].optionid)],label:[optionValue[0].option]}
                        }
                    }
                } else {
                    hiddenFieldValues[underscoreKey] = searchParams[key];
                }
            } else if(key==='id') {
                fieldValues[underscoreKey] = searchParams[key];
                //Check if prefill key exists, if yes, we fetch data row and populate fieldvalues.
                await getDataRow(searchParams[key],formInfo)
            }
        })
    )    

    updateValues(fieldValues,hiddenFieldValues)
    
}

export default urlParamProcessor