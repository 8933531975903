/* eslint-disable */
import React, { useCallback, useEffect, useState,useRef } from 'react';
import cloudrun from '../../clients/httpcloudrun';
import Global from '../../clients/global';
import Icon from '@mdi/react';
import { mdiCheckCircleOutline } from '@mdi/js';
import GBButton from '../../components/GBButton/GBButton';
import CreateRecordUrl from '../../utils/CreateRecordUrl';
import TextButton from '../../components/TextButton/TextButton';

const TriggerTest =({tableData,filters,addTestRow,testRows}) =>{


    useEffect(()=>{
        //if tetRow passed in, add to Data so it displays.
        if(testRows !==null) {
            setData(testRows)
        }
        console.log(tableData)
    },[])

    const [data, setData] =useState(null);

    const getData = async () =>{

        let payload = {
            tableinfo: tableData.tableinfo,
            tableid: tableData.tableid,
            blockid: tableData.blockid,
            limit: 3,
            offset: 0,
            filters: filters,
            sortfield: 'id',
            sortorder: 'desc',
            role: 3,
          };

        const rowData = await cloudrun.post('/ExecuteTableQuery', { payload });

        const DeepLinks =[];
        rowData.rows.map(itm=>{
            const url =  CreateRecordUrl(Global.zoneid,tableData.blockid,tableData.id,itm.id,null);
            DeepLinks.push({rowid: itm.id, name: itm.CustomID, url: url})
        })

        setData(DeepLinks);
        addTestRow(DeepLinks)
    }

    const onRowSelect =(testRow) =>{
        window.open(testRow.url);
    }

    return (
        <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div>
            <GBButton
                text="Test trigger"
                borderRadius="10px"
                Action={getData}
                ActionValue={'trigger'}
                textColor="white"
                width="100px"
                height={'30px'}
                color="#0D9953"
                textHoverColor="white"
                hoverBackgroundColor={'#0D995380'}
              />
              </div>
              <div style={{width:'30px'}}></div>
              <div>
               {data && data.map(itm=>(
               <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>       
               <TextButton text={itm.name} textColor='green' Action={onRowSelect} ActionValue={itm} />
               </div>))}
              </div>
        </div>
    )
}

export default TriggerTest