/*
SUMMARY: mph 4-11-20
This method is called when a user is bringing up the popup list of relational records and clicks "Create new Entry"
This displays the form of fields for this table.. When user completes form and clicks to insert record, it calls this method.

The goal of this method is to create a new entry into the relational table.
When we eventually add external form for adding new records to a table, this will be method to use to save/update the data.

Params
[datarecord: array of field objects]: This is the array of field/value objects that was created in the form.
[tableinfo: json]: The table json meta data from the CustomTables table.
*/

/* eslint-disable */

import cloudrun from '../clients/httpcloudrun';

const AddRecord = async (datarecord, tableinfo,userid,tableid) => {
  
  console.log(datarecord)
  
  const payload={datarecord,tableinfo,userid,tableid,dbmethod:'addNewRecord'}
  const dataresponse = await cloudrun.post('/executeMethod', {payload})
  return dataresponse
};

export default AddRecord;
