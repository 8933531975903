/* eslint-disable */
import React, { useState, useRef, useCallback, useEffect } from 'react';
import Icon from '@mdi/react';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
const moment = require('moment');
import { Input, Dropdown, Checkbox, Button } from 'semantic-ui-react';
import {
  mdiSitemap,
  mdiArrowDecisionOutline,
  mdiPencil,
  mdiContentDuplicate,
  mdiDeleteForever,
  mdiChevronDown,
  mdiHistory,
  mdiPencilRuler,
} from '@mdi/js';
import Global from '../../clients/global';
import { getBlocksAPI } from '../../api/blocks';
import GBButton from '../../components/GBButton/GBButton';
import TextButton from '../../components/TextButton/TextButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import GBInput from '../../components/GBInput/GBInput';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import {
  getWorkFlows,
  addWorkFlow,
  updateWorkFlowName,
  updateWorkFlowStatus,
  deleteWorkFlow,
  duplicateWorkFlow,
} from '../../api/workflows';
import Workflow from './Workflow';

const WorkFlowManager = ({ blockID }) => {
  const [blocks, setBlocks] = useState([]);
  const [selectedBlock, setSelectedBlock] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showWorkFlow, setShowWorkFlow] = useState(false);
  const [selectedWorkFlow, setSelectedWorkFlow] = useState(null);
  const [workflows, setWorkflows] = useState([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState([]);
  const [showActive, setShowActive] = useState(false);
  const [name, setName] = useState('');
  const [workFlowEditId, setWorkFlowEditId] = useState(null);
  const [tempName, setTempName] = useState('');
  const [actions, setActions] = useState([
    {
      key: 'filter',
      text: 'Find workflow',
      value: 'filter',
      icon: 'search',
      placeholder: 'Find workflows',
      clear: false,
      toolTip: 'Filter workflows by words found in the workflow name. (case sensitive)',
    },
  ]);
  const [searchTerms, setSearchTerms] = useState('');

  const divRef = useRef();

  useEffect(() => {
    loadData();
  }, []);

  const options = [
    {
      key: 'user',
      text: (
        <div>
          <strong>Actions</strong>
        </div>
      ),
      disabled: true,
    },
    {
      key: 'designer',
      value: 'designer',
      text: 'Edit',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiPencilRuler}
          size="20px"
        />
      ),
    },
    {
      key: 'rename',
      value: 'rename',
      text: 'Rename',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiPencil} size="20px" />
      ),
    },
    {
      key: 'duplicate',
      value: 'duplicate',
      text: 'Duplicate',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiContentDuplicate}
          size="20px"
        />
      ),
    },
    {
      key: 'delete',
      value: 'delete',
      text: 'Delete',
      icon: (
        <Icon
          style={{ marginRight: '5px', marginBottom: '3px' }}
          path={mdiDeleteForever}
          size="20px"
        />
      ),
    },
    {
      key: 'history',
      value: 'history',
      text: 'View history',
      icon: (
        <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={mdiHistory} size="20px" />
      ),
    },
  ];

  const closeWorkFlow = (wf) => {
    const idx = workflows.findIndex((w) => w.workflowid === wf.workflowid);

    //if closing window and not in current list, should remove skeletong wf from db.
    if (idx === -1) {
      deleteWorkFlow(wf.workflowid);
    }
    setShowWorkFlow(false);
    setSelectedWorkFlow(null);
  };

  const workFlowAction = async (workflowid, action) => {
    if (action === 'rename') {
      setWorkFlowEditId(workflowid);
      const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
      setTempName(workflows[idx].workflowname);
    } else if (action === 'delete') {
      deleteWorkflowConfirm(workflowid);
    } else if (action === 'designer') {
      const wf = workflows.filter((fl) => fl.workflowid === workflowid)[0];
      setSelectedWorkFlow(wf);
      setShowWorkFlow(true);
    } else if (action==='duplicate') {
      await duplicateWorkflowLocal(workflowid)
    }
  };

  const duplicateWorkflowLocal = async (workflowid) =>{
    await duplicateWorkFlow(workflowid);
    await loadData();
  }

  const renameWorkFlow = async (workflowid) => {
    const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
    if (idx !== -1) {
      const copyWorkflows = structuredClone(workflows);
      copyWorkflows[idx].workflowname = tempName;
      setWorkflows(copyWorkflows);

      await updateWorkFlowName(tempName, workflowid);
    }
    setTempName('');
    setWorkFlowEditId(null);
  };

  const addWorkFlowLocal = async () => {
    const wid = await addWorkFlow(name);
    const tempWorkFlow = { workflowid: wid, workflowname: name };
    setSelectedWorkFlow(tempWorkFlow);
    setName('');
    setShowWorkFlow(true);
  };

  const deleteWorkflowConfirm = (workflowid) => {
    const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
    let wfname = 'workflow';
    if (idx !== -1) {
      wfname = workflows[idx].workflowname;
    }

    const message = (
      <div>
        You have selected to delete the workflow: {wfname}. <br />
        <br />
        Are you sure you want to delete this workflow?
      </div>
    );

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title={'Delete workflow forever'}
            action={deleteWorkflowLocal}
            width="350px"
            actionValue={workflowid}
            buttonLabel="OK"
            message={message}
            divRef={divRef}
            showInput={true}
            confirmAction="delete"
            confirmWord="delete"
            height="450px"
            onClose={onClose}
          />
        );
      },
    });
  };

  const deleteWorkflowLocal = async (workflowid) => {
    if (divRef.current.value === 'delete') {
      await deleteWorkFlow(workflowid);
      await loadData();
    }
  };

  const refresh = (wf) => {
    const copyWorkFlows = structuredClone(workflows);
    const idx = workflows.findIndex((el) => el.workflowid === wf.workflowid);
    if (idx !== -1) {
      copyWorkFlows[idx] = wf;
      setWorkflows(copyWorkFlows);
    } else {
      // new workflow, to refresh list.
      loadData();
    }
  };

  const updateWorkFlowStatusLocal = async (workflowid, isactive) => {
    const idx = workflows.findIndex((fl) => fl.workflowid === workflowid);
    if (idx !== -1) {
      const copyWorkflows = structuredClone(workflows);
      copyWorkflows[idx].isactive = isactive;
      setWorkflows(copyWorkflows);

      await updateWorkFlowStatus(workflowid, isactive);
    }
  };

  const loadData = async () => {
    const blocks = await getBlocksAPI(parseInt(Global.zoneid));
    blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    const blockswithIcons = [];
    blocks.forEach((itm) => {
      blockswithIcons.push({
        key: itm.key,
        value: itm.value,
        text: itm.text,
        icon: (
          <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
        ),
      });
    });

    setBlocks(blockswithIcons);

    const wflows = await getWorkFlows();
    setWorkflows(wflows);
  };

  const handleBlockSelection = (val) => {
    setSelectedBlock(val);
  };

  const updateSearchTerm = (val) => {
    setSearchTerm(val);
  };

  const filterWorkFlows = (action, value) => {
    console.log(value);
    setSearchTerm(value);
  };

  const clearInput = () => {
    setSearchTerm('');
  };

  const selectWorkFlow = (val) => {
    setSelectedWorkFlow(val);
    setShowWorkFlow(true);
  };

  return (
    <div style={{ display: 'relative' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60px',
          width: '100%',
          backgroundColor: '#0D99FF',
        }}
      >
        <div
          style={{
            marginLeft: '20px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiSitemap} size="35px" color="white" />
          <div style={{ width: '10x', marginLeft: '10px' }} />
          <div style={{ fontSize: '20px' }}>WorkFlow Manager</div>
        </div>
      </div>
      <div>
        <div
          style={{
            marginLeft: '40px',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiArrowDecisionOutline} size="30px" color="black" />
          <div style={{ fontSize: '20px', fontWeight: 'bold', marginLeft: '10px' }}>Workflows</div>
        </div>

        <div
          style={{
            margin: '10px',
            marginLeft: '40px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div style={{ width: '300px', marginRight: '30px' }}>
            <Dropdown
              clearable
              options={blocks}
              value={selectedBlock}
              onChange={(e, data) => handleBlockSelection(data.value)}
              fluid
              selection
              placeholder="Filter by blocks"
            />
          </div>
          <div style={{ width: '300px' }}>
            <GBInput
              actions={actions}
              currentValue={searchTerm}
              executeAction={filterWorkFlows}
              clearInput={clearInput}
              recordSet="blockList" //here set to constant value so it doesn't reset currentvalue. used in blocks to reset term when table and/or filter is changed
            />
          </div>
          <div style={{ marginLeft: '200px' }}>
            <Input
              onChange={(e, data) => setName(data.value)}
              value={name}
              labelPosition="right"
              action={{
                color: 'teal',
                className: 'custom-workflow-label',
                labelPosition: 'right',
                icon: 'plus',
                content: 'Create new workflow',
                onClick: addWorkFlowLocal,
              }}
            />
          </div>
        </div>

        <div
          style={{
            marginLeft: '50px',
            marginRight: '50px',
            fontSize: '20px',
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            borderBottom: '1px solid #E5E5E5',
          }}
        >
          <div style={{ width: '150px' }}></div>
          <div style={{ width: '30px' }}>ID</div>
          <div style={{ width: '400px' }}>Name</div>
          <div style={{ width: '200px' }}>Last edit</div>
          <div style={{ width: '200px' }}>Actions</div>
          <div>
            <Checkbox
              style={{ fontSize: '20px' }}
              checked={showActive}
              label="Active"
              onChange={(e, data) => setShowActive(data.checked)}
            />
          </div>
        </div>

        {workflows
          .filter(
            (wf) =>
              wf.workflowname.includes(searchTerm) &&
              (selectedBlock === '' || wf.blockid === selectedBlock),
          )
          .map((w) => (
            <div
              style={{
                // border:'1px solid red',
                marginLeft: '50px',
                marginRight: '50px',
                fontSize: '20px',
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  fontSize: '20px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                {/* <div style={{ width: '150px' }}>Show icons</div> */}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: '150px',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: w.color,
                      height: '45px',
                      width: '45px',
                      borderRadius: '5px',
                      fontSize: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon path={w.icon} size="20px" color="white" />
                  </div>
                  <div style={{ width: '10px' }}></div>
                  <div
                    style={{
                      backgroundColor: '#D9D9D9',
                      height: '45px',
                      width: '45px',
                      borderRadius: '5px',
                      fontSize: '20px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    +1
                  </div>
                </div>

                <div style={{ width: '30px' }}>{w.workflowid}</div>
                <div style={{ width: '400px' }}>
                  {w.workflowid === workFlowEditId ? (
                    <Input
                      fluid
                      onChange={(e, data) => setTempName(data.value)}
                      defaultValue={w.workflowname}
                      // labelPosition="right"
                      action={{
                        color: 'teal',
                        className: 'custom-workflow-label',
                        // labelPosition: 'right',
                        content: 'Rename',
                        onClick: () => renameWorkFlow(w.workflowid),
                      }}
                    />
                  ) : (
                    <TextButton
                      fontSize="20px"
                      textColor="#2BA6FF"
                      hoverColor="#2BA6FF80"
                      text={w.workflowname}
                      Action={selectWorkFlow}
                      ActionValue={w}
                    />
                  )}
                </div>
                <div style={{ width: '200px' }}>
                  {moment(w.updatedon)
                    .format('DD-MMM-YYYY')
                    .toUpperCase()}
                </div>
                <div style={{ width: '200px' }}>
                  <Dropdown
                    upward={false}
                    text=""
                    icon="chevron down"
                    // icon={
                    //   <header>
                    //     <Icon className="ddchevron" path={mdiChevronDown} size="20px" />
                    //   </header>
                    // }
                    direction="left"
                    selectOnBlur={false}
                  >
                    <Dropdown.Menu>
                      {options.map((el) => (
                        <Dropdown.Item
                          key={el.key}
                          text={el.text}
                          icon={el.icon}
                          onClick={() => workFlowAction(w.workflowid, el.value)}
                        />
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div>
                  <GBSwitch
                    color={w.color}
                    isChecked={w.isactive}
                    Action={() => updateWorkFlowStatusLocal(w.workflowid, !w.isactive)}
                  />
                </div>
              </div>
            </div>
          ))}
      </div>
      {showWorkFlow ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            zIndex: 1,
            left: 0,
            backgroundColor: 'white',
            width: '100vw',
            height: '100vh',
          }}
        >
          <Workflow workFlowObj={selectedWorkFlow} close={closeWorkFlow} refresh={refresh} />
        </div>
      ) : null}
    </div>
  );
};

export default WorkFlowManager;
