/* eslint-disable no-unused-vars */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, jsx } from '@emotion/core'
import { mdiClose,mdiChevronDown } from '@mdi/js';
import { Dropdown } from 'semantic-ui-react';
import Icon from '@mdi/react';
import IconButton from './IconButton';

const FilterOperators = ({header,operator,filterOption,RemoveAction,RemoveActionValue,ChangeOperator,color}) => {

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ color: `#757575` }}>{header}</div>
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
        <div css={{
          '& .chevrondown':{
            marginLeft:'2px',
          },
        '&:hover': {
            '& .chevrondown': {
                transition: 'all .3s ease',
                backgroundColor:`${color}80`,
                
                borderRadius:'50px',
                color:'white',
            },
        } }}>
          <Dropdown
            value={operator}
            direction="left"
            icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown}  />}
            style={{ fontSize: '12px', color: '#757575',marginRight:'5px' }}
            options={filterOption}
            onChange={(e, data) => ChangeOperator(data.value)}
          />
        </div>
        <IconButton color="red" hoverColor="white" hoverBackgroundColor="#F6685E" icon={mdiClose} Action={RemoveAction} ActionValue={RemoveActionValue}  />
        </div>
      </div>
    )
}

export default FilterOperators