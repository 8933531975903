/* eslint-disable */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Input, Dropdown, Form, Radio } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiClose,mdiFunctionVariant,mdiPlayBox,mdiClipboardCheck} from '@mdi/js';
import Global from '../../clients/global';
import { getBlocksAPI } from '../../api/blocks';
import {
  getTableAPI,
  getTablesAPI,
} from '../../api/tables';
import TextButton from '../../components/TextButton/TextButton';
import GBSwitch from '../../components/GBSwitch/GBSwitch';
import DuplicateControl from '../Block/components/Forms/components/DuplicateControl';
import FieldButton from './FieldButton';
import IconButton from '../Block/components/FilterData/IconButton';
import CodeEditor from 'src/components/CodeEditor/CodeEditor';
import EvaluateFunction from './EvaluateFunction';

const ActionNewRecord = ({ localData, updateData, editMode,sourceNodes }) => {
  const [blocks, setBlocks] = useState(null);
  const [tables, setTables] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(localData.blockid ?? '');
  const [tableData, setTableData] = useState(null);
  const [enableDupLogic, setEnableDupLogic] = useState(false);
  const [insertFields,setInsertFields] =useState([]);
  const [availableFields, setAvailableFields] =useState([]);
  const [refreshDate,setRefreshDate] =useState(Date.now())


  //Used to render DuplicateControls. We are creating a dummy form that has defined fields used
  //in duplicate control to properly render them.
  const [form, setForm] = useState({ settings: {} });

  const updateFormAttribute = (attr, value) => {
    const tempForm = { ...form };

    tempForm.settings[attr] = value;
    setForm(tempForm);
  };

  const getOptions = () =>{

    const options=[{key:'0',value:"manual",text:'Manual entry'},{key:'function',value:'function',text:'Javascript function'}];
    sourceNodes.map(node=>{
      node.data.tableData.tableinfo.columns.map(col=>{
        options.push({key:`${node.data.tableData.id}|${col.data}`,value:`${node.data.tableData.id}|${col.data}`,text:`${node.data.tableData.name} (${col.header})`})
      })
    })

    return options;
  }

  const getMappedValue = (field,attribute) =>{
    const idx=insertFields.findIndex(col=>col.key===field);
    if(idx !==-1) {
      return insertFields[idx][attribute] ?? false
    }
  }

  const handleMappedValueChange =(field, val,attribute)=>{
    const idx=insertFields.findIndex(col=>col.value===field);
    const temp = structuredClone(insertFields);
    temp[idx][attribute] = val;
    setInsertFields(temp);

    localData.insertFields = temp;
    updateData(localData)
  }

  const updateFieldValue=(field,value) =>{
    const idx =insertFields.findIndex(el=>el.key===field);
    if(idx !==-1) {
   
      insertFields[idx].value=value;
      setInsertFields(insertFields);

      localData.insertFields = insertFields;
      updateData(localData)
      setRefreshDate(Date.now())
    }
  }

  const stateMap = { blocks, tables };

  const DisplayValue = (source, value) => {
    const sourceArray = stateMap[source];
    if (!sourceArray) {
      console.error(`Source ${source} not found.`);
      return;
    }
    const idx = sourceArray.findIndex((el) => el.value === value);
    if (idx !== -1) {
      return sourceArray[idx].text;
    } else {
      return 'Value not found';
    }
  };

  useEffect(() => {
    if(localData.actionEvent==='newrecord') {
      fetchBlocks();
    } else {
      loadUpdateRecordInfo();
    }
  }, [localData.actionEvent]);


  const loadUpdateRecordInfo = () =>{
    //this is run when action is update Triggered record, so we need to get
    //trigger record (which is id='1')

    const triggerNode = sourceNodes.filter(el=>el.id==='1')[0];
    console.log(triggerNode)
    localData.tableData = triggerNode.data.tableData;


    const editablefields = [1,2,3,4,5,6,7,8,9,10,11,13,14,17,22,23]
    let afields=[];
    localData.tableData.tableinfo.columns.filter(el=>editablefields.includes(el.uitype) && el.data !=='id').map(itm=>{
        afields.push({key:itm.data,value:itm.data,text:itm.header});
    })

    setAvailableFields(afields);

    if(localData.insertFields !==undefined) {
      setInsertFields(localData.insertFields)
    }
    if(localData.availableFields !==undefined) {
      setAvailableFields(localData.availableFields);
    }

  }

  const onBlockSelection = async (blockid) => {
    const tables = await getTablesAPI(blockid, 3);
    tables.forEach((itm) => {
      delete itm.isSystemTable;
    });

    tables.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));
    setSelectedBlock(blockid);
    setTables(tables);
    localData.blockid=blockid;
    // localData.tableData=null;
    updateData(localData);

  };

  const onTableSelection = async (tableid) => {
    // const { fields } = await getFieldsAPI(tableid);

    const tbl = await getTableAPI(tableid);
    setTableData(tbl);
    const editablefields = [1,2,3,4,5,6,7,8,9,10,11,13,14,17,22,23]
    let afields=[];
     tbl.tableinfo.columns.filter(el=>editablefields.includes(el.uitype) && el.data !=='id').map(itm=>{
        afields.push({key:itm.data,value:itm.data,text:itm.header});
    })

    afields.sort((a, b) => (a.text > b.text ? 1 : -1));
    setAvailableFields(afields);

    localData.tableData = tbl;
    updateData(localData);
  };

  const fetchBlocks = async () => {
    const blocks = await getBlocksAPI(parseInt(Global.zoneid));

    blocks.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1));

    const blockswithIcons = [];
    blocks.forEach((itm) => {
      blockswithIcons.push({
        key: itm.key,
        value: itm.value,
        text: itm.text,
        icon: (
          <Icon style={{ marginRight: '5px', marginBottom: '3px' }} path={itm.icon} size="20px" />
        ),
      });
    });

    setBlocks(blockswithIcons);
    

    if(localData.blockid !==undefined) {
      await onBlockSelection(localData.blockid);

      if(localData.tableData !==undefined && localData.tableData !==null) {
        console.log('here')
      await onTableSelection(localData.tableData.id);

      if(localData.insertFields !==undefined) {
        setInsertFields(localData.insertFields)
      }
      if(localData.availableFields !==undefined) {
        setAvailableFields(localData.availableFields);
      }
    }

     }

  };

  const addRemoveField = (value) => {
    const idx = insertFields.findIndex(el=>el.value===value);
    const idx2 = availableFields.findIndex(el=>el.value===value);

    let tempInsert =structuredClone(insertFields)
    let tempAvailable = structuredClone(availableFields);


    if(idx === -1) {
        const fld= availableFields[idx2];
        fld.mappedValue = 'manual'
        tempInsert.push(fld)
        tempAvailable =tempAvailable.filter(itm=>itm.value !==value)
    } else {
        const fld = insertFields[idx];
        fld.mappedValue='manual';
        tempAvailable.push(fld);
        tempInsert =tempInsert.filter(itm=>itm.value !==value);
    }

    setInsertFields(tempInsert);
    setAvailableFields(tempAvailable);

    localData.insertFields=tempInsert;
    localData.availableFields = tempAvailable;

    updateData(localData);
  }

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div style={{ marginTop: '20px' }}>
        {localData.actionEvent==='newrecord' ? (<>
        <div style={{ fontWeight: 'bold' }}>
          Block <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        {!editMode ? (
          DisplayValue('blocks', selectedBlock)
        ) : (
          <Dropdown
            value={selectedBlock}
            options={blocks}
            fluid
            selection
            onChange={(e, data) => onBlockSelection(data.value)}
            clearable
          />
        )}

        <div style={{ fontWeight: 'bold', marginTop: '20px' }}>
          Tab <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        {!editMode ? (
          <div>{DisplayValue('tables', tableData?.id)}</div>
        ) : (
          <Dropdown
            value={tableData?.id}
            options={tables}
            fluid
            selection
            onChange={(e, data) => onTableSelection(data.value)}
            clearable
          />
        )}
        <div style={{ marginTop: '20px' }} />

        <GBSwitch
          color={'#0D99FF'}
          isChecked={enableDupLogic}
          Action={() => setEnableDupLogic(!enableDupLogic)}
          text="Update vs create new record when duplicates are identified"
        />

        {enableDupLogic && tableData != null ? (
          <>
            <div style={{ marginTop: '20px' }}>
              <DuplicateControl
                type="duplicateFields"
                text="DUPLICATE CRITERIA"
                tooltip={
                  <div>
                    You can specify one or many fields, or combination of fields that when matching
                    an existing record in the tab will cause a duplicate to be identified. The
                    record will then stack onto the existing record using the logic configured on
                    the subsequent two fields.
                    <p /> To learn more, see{' '}
                    <a
                      href="https://www.graceblocks.com/support-article/web-form-duplicate-logic"
                      target="_newwindow"
                    >
                      web form duplicate logic.
                    </a>{' '}
                  </div>
                }
                selectedFields={form.settings?.duplicateFields}
                tableinfo={tableData.tableinfo}
                placeholder="Click + to add criterial fields set"
                color={'#0D99FF'}
                form={form}
                updateFormAttribute={updateFormAttribute}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>&nbsp;</div>
            <div>
              <DuplicateControl
                type="blockedFields"
                text="BLOCK UPDATES OF THESE FIELDS WHEN DUPLICATE"
                tooltip={
                  <div>
                    Specify any fields you do not want overwritten when a duplicate is identified.
                    The data entered on the form matching the fields specified here will be ignored
                    when the update occurs.
                    <p /> To learn more, see{' '}
                    <a
                      href="https://www.graceblocks.com/support-article/web-form-duplicate-logic"
                      target="_newwindow"
                    >
                      web form duplicate logic.
                    </a>{' '}
                  </div>
                }
                selectedFields={form.settings.blockedFields}
                tableinfo={tableData.tableinfo}
                placeholder="Click to select fields"
                color={'#0D99FF'}
                form={form}
                updateFormAttribute={updateFormAttribute}
              />
            </div>
            <div style={{ marginBottom: '20px' }}>&nbsp;</div>
            <div>
              <DuplicateControl
                type="replaceMultipleFields"
                text="REPLACE VS. APPEND VALUES FOR MULTI-SELECT FIELDS"
                tooltip={
                  <div>
                    When duplicates are found, data from the incoming form appends to the existing
                    record by default. If you wish to override this default behavior and instead
                    have data replaced with what is provided on the form, use this setting. Specify
                    the fields here where you want to override the default behavior.
                    <p /> To learn more, see{' '}
                    <a
                      href="https://www.graceblocks.com/support-article/web-form-duplicate-logic"
                      target="_newwindow"
                    >
                      web form duplicate logic.
                    </a>{' '}
                  </div>
                }
                selectedFields={form.settings.replaceMultipleFields}
                tableinfo={tableData.tableinfo}
                placeholder="Click to select fields"
                color={'#0D99FF'}
                form={form}
                updateFormAttribute={updateFormAttribute}
              />
            </div>
          </>
        ) : null}
        </>) : null}

        <div style={{marginTop:'10px'}}>
            <FieldButton options={availableFields} addRemoveField={addRemoveField} />
            <div style={{display:'flex',flexDirection:'column',alignItems:'flex-start',}}>
            {insertFields.length > 0 ? (
                insertFields.map(fld => (
                <div style={{margin:'10px',padding:'10px', border:'1px solid black',width:'100%'}}>
                    <div style={{marginTop:'10px',width:'100%',display:'flex',flexDirection:'row',alignItems:'center'}}>
                     <div style={{marginRight:'10px'}}>{fld.text}</div>
                     <Dropdown fluid search value={getMappedValue(fld.key,'mappedValue')}  selection options={getOptions()} onChange={(e,data)=>handleMappedValueChange(fld.value,data.value,'mappedValue')} />
                    <div style={{marginLeft:'10px',marginRight:'20px'}}>
                    <GBSwitch
                        color={'#0D99FF'}
                        isChecked={getMappedValue(fld.key,'isRequired')}
                        Action={() => {handleMappedValueChange(fld.Key,!getMappedValue(fld.key,'isRequired'),'isRequired')}}
                        text="Required"
                      />
                      </div>
                    <IconButton  color="red" hoverColor="white" hoverBackgroundColor="#F6685E" icon={mdiClose} Action={addRemoveField} ActionValue={fld.value}  />
                  </div>
                  {getMappedValue(fld.key,'mappedValue')==='manual' ? (
                        <Input fluid onChange={(e,data)=>updateFieldValue(fld.key,data.value)}   style={{ width:'100%', marginTop:'10px'}} defaultValue={fld.value} />
                     ): null}
                  {getMappedValue(fld.key,'mappedValue')==='function' ? (<>
                      <div style={{marginTop:'10px'}}><CodeEditor fields={getOptions().map(el=>el.text)} updateFormula={updateFieldValue} formula={fld.value} isReadOnly={false} mode="javascript" field={fld.key}  /></div>
                      <div>
                      <TextButton text='Evaluate' textColor='#0D99FF'  color="#0D99FF" hoverColor="#0D99FF80"  icon={mdiClipboardCheck} Action={() => {handleMappedValueChange(fld.value,!getMappedValue(fld.key,'evaluateFunction'),'evaluateFunction')}} iconSize="30px" iconPosition='left'  />
                      </div>
                      {getMappedValue(fld.key,'evaluateFunction')==true ? (
                        <div>
                         <EvaluateFunction functionString={fld.value}  />
                        </div>
                      ):null}
                    </>):null}
                </div>))
            ): null}
            </div>
        </div>


      </div>
    </div>
  );
};

export default ActionNewRecord;
