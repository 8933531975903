/* eslint-disable  */
import React, { useState, useEffect } from 'react';
import { Dropdown,Input} from 'semantic-ui-react';
import FilterOperators from './FilterOperators';

const StatusRoleFilter = (props) => {

    const [operator, setOperator] = useState('searchvalues');
    const [selectedOptions, setSelectedOptions] = useState([]);


    useEffect(() => {
        if(props.filter.value !== undefined)
        {
         setSelectedOptions(props.filter.value)
        }
    }, [props.fieldnode.data]);

    const updateFilter = (items, tmpoperator) => {
        setSelectedOptions(items);
        const filterObject = {
            field: props.fieldnode.data,
            header: props.fieldnode.header,
            value: items,
            uitype:props.uitype,
            textvalues: items,
            displayfield: props.fieldnode.displayfield,
            operator: tmpoperator === undefined ? operator : tmpoperator,
        }
        props.UpdateFilter(filterObject);
    }

    const StatusOptions = [
        { key: 'Active', text: 'Active', value: 'Active' },
        { key: 'Inactive', text: 'Inactive', value: 'Inactive' },
        { key: 'Invited', text: 'Invited', value: 'Invited' },
        { key: 'Not yet invited', text: 'Not yet invited', value: 'Not yet invited' },
    ]

    const filterOption = [
        { key: 'searchvalues', text: 'Search values', value: 'searchvalues' },
    ]

    const changeOperator = (newoperator) => {
        setOperator(newoperator);
        updateFilter(selectedOptions, newoperator);
    }

    const removeFilter =(field) => {
        // updateFilter([]);
        props.remove(field);
    }

    const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

    const {fieldnode,color} = props;
    return (
        <div style={{ marginBottom: '23px', width: '100%' }}>

    <FilterOperators
        header={fieldnode.header}
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={fieldnode.data}
        ChangeOperator={changeOperator}
        color={color}
      />

        {operator==='isnull' || operator==='isnotnull' ? 
            (
               <Input style={{ width: '330px' }}  value={displayvalue} />
            )
            :
            (
                <Dropdown
                    style={{
                        borderTopLeftRadius:'5px', 
                        borderBottomLeftRadius:'5px', 
                        borderLeftWidth:'7px',
                        borderLeftColor:color,
                    }}
                    value={selectedOptions}
                    multiple search fluid selection
                    options={StatusOptions}
                    onChange={(e, data) => updateFilter(data.value)}
                />
            )}

        </div>
    )

}


export default StatusRoleFilter;