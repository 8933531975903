/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react';
import { mdiClose, mdiBell, mdiCog } from '@mdi/js';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import {
  getNotifications,
  deleteNotification,
} from '../../../../api/notifications';
import Notification from './notification';

const Notifications =({ color, blockid, setScreen,showNotificationDetails }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        getNotificationsLocal();
      }, []);
    
      const getNotificationsLocal = async () => {
        const noticeArray = await getNotifications(blockid);
        setNotifications(noticeArray);
      };
    
      const deleteSingleNotification = async (notificationid) => {
      
        await deleteNotification(notificationid, null);
        await getNotificationsLocal()
      };

      const clearAllBlockNotifications = async () => {
        await deleteNotification(null, blockid,true);
        await getNotificationsLocal()
      }

      const showSettings = () => {};

    return (
        <div>
        <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          height: 'calc(100vh - 270px)',
        }}
      >
        {notifications !==null && notifications.map((e) => (
          <div>
            <Notification notificationData={e} deleteNotification={deleteSingleNotification} color={color} showNotificationDetails={showNotificationDetails} />
            <hr />
          </div>
        ))}
        {notifications === null || notifications.length===0 ? (
            <div style={{margin:'20px'}}>There are no new notfications.</div>
        ): null}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '20px',
          marginLeft: '10px',
          marginRight: '10px',
        }}
      > {blockid ===undefined ? (
        <div style={{width:'300px', color:color}}>Manage your notification preferences from the bell icon inside each Block.</div>
      ): (
        <GBButton
        width="200px"
        iconPosition="left"
        iconColor="white"
        iconHoverColor="white"
        iconSize="20px"
        height="35px"
        text="Notification settings"
        Action={setScreen}
        ActionValue="settings"
        color={color}
        icon={mdiCog}
      />
      )}
      
        <TextButton
          text="Clear all"
          Action={clearAllBlockNotifications}
          textColor="#F6685E"
          icon={mdiClose}
          hoverColor="#F6685E80"
        />
      </div>
      </div>
    )
}

export default Notifications