/* eslint-disable */
import React, { useCallback, useState,useRef,useEffect } from 'react';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  useNodesState,
  useEdgesState,
  applyEdgeChanges,
  ReactFlowProvider,
  useReactFlow,
} from 'reactflow';
import 'reactflow/dist/style.css';
import Icon from '@mdi/react';
import {
  mdiArrowLeft,
  mdiPlus,
  mdiContentSave,
  mdiArrowDecisionOutline,
  mdiRss,
  mdiTrashCan,
  mdiContentCopy,
  mdiHistory
} from '@mdi/js';
import _ from 'lodash';
import { toast } from 'react-toastify';
import CustomNode from './CustomNode';
import TriggerNode from './TriggerNode';
import ActionNode from './ActionNode';
import 'reactflow/dist/style.css';
import './overview.css';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import GBLogo from '../Block/components/TabLists/GBViewAll.svg';
import TriggerPanel from './TriggerPanel';
import ActionPanel from './ActionPanel';
import {updateWorkFlow} from '../../api/workflows'

const nodeTypes = {
  custom: CustomNode,
  trigger: TriggerNode,
  action: ActionNode
};

const minimapStyle = {
  height: 120,
};

// const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

const WorkFlow = ({ workFlowObj, refresh, close }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const [rfInstance, setRfInstance] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentNode, setCurrentNode] = useState(null);
  const [nodeType, setNodeType] = useState('trigger');
  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef(null);
  const connectingNodeId = useRef(null);
  const [counter, setCounter] = useState(1);
  const [sourceNodes, setSourceNodes] =useState(null);
  const [isChanged, setIsChanged] =useState(false);
  

  useEffect(()=>{
    if(workFlowObj.workflow !==null) {
      onRestore()
    }
  },[])

//   const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

const onRestore = useCallback(() => {
  const restoreFlow = async () => {
    const flow = workFlowObj.workflow

    if (flow) {
      const { x = 0, y = 0, zoom = 1 } = flow.viewport;
      setNodes(flow.nodes || []);
      setEdges(flow.edges || []);
      setCounter(flow.nodes.length)
      // setViewport({ x, y, zoom });
    }
  };

  restoreFlow();
}, [setNodes]);

const getId = () => {
   const newCounter=counter+1;
    setCounter(newCounter)
    return newCounter.toString()
}

const onConnect = useCallback(
    (params) => {
      // reset the start node on connections
      connectingNodeId.current = null;
      setEdges((eds) => addEdge(params, eds))
    },
    [],
  );

  const editNode = (node) => {
    const flow = rfInstance?.toObject();
    //Get all source nodes to this node. This will be passed to Panel for use
    //in configuring node further.
    const tempSourceNodes = flow?.nodes.filter(n=>flow.edges.filter(el=>el.target===node.id).map(e=>e.source).includes(n.id));

    node.data.sourceNodes=tempSourceNodes;
    setCurrentNode(node.data);
    setNodeType(node.type)
    setSourceNodes(tempSourceNodes);
    setModalIsOpen(true);
    
  };

  const closeNode = () => {
    setCurrentNode(null);
    setModalIsOpen(false);
  };

  const removeNodeAndEdges = (nodeId) => {
    // Remove the node by filtering out the node with the given nodeId
    const newNodes = nodes.filter(node => node.id !== nodeId);
    
    // Remove edges connected to the node
    const newEdges = edges.filter(edge => edge.source !== nodeId && edge.target !== nodeId);
    
    // Update the nodes and edges state
    setNodes(newNodes);
    setEdges(newEdges);

    setCurrentNode(null);
    setModalIsOpen(false);
  };

  const onEdgeDoubleClick = useCallback((event, edge) => {
    // Remove the edge by applying an edge change with the 'remove' action
    const changes = [{ id: edge.id, type: 'remove' }];
    setEdges((eds) => applyEdgeChanges(changes, eds));
  }, []);

  const closeLocal = async() =>{
    
    if(isChanged) {
      await onSave();
    }
    close(workFlowObj);
  }
  

  const onSave = useCallback(async () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();     
      const tempWorkFlow = structuredClone(workFlowObj);
      tempWorkFlow.workflow = flow;

      await updateWorkFlow(tempWorkFlow);
      refresh(tempWorkFlow)
      setIsChanged(false);

      toast.info(<div style={{margin:'10px'}}>Your workflow has been saved!</div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      })
    }
  }, [rfInstance]);


  const addNode = (label, nodeData,type) => {

    nodeData.label = label;
    const nodeId = (nodes.length + 1).toString();
    nodeData.id = nodeId;

    const newNode = {
      id: nodeId,
      type: type,
      data: nodeData,
      position: { x: 10, y: 10 },
    };

    // Add the new node to the existing nodes array
    setNodes((nds) => [...nds, newNode]);
    setCurrentNode(null);
    setModalIsOpen(false);
    setIsChanged(true);
  };


  const updateNode = (nodeId, newData) => {
    setNodes((prevNodes) =>
      prevNodes.map((node) =>
        node.id === nodeId
          ? {
              ...node,
              data: {
                ...node.data,
                ...newData,
              },
            }
          : node,
      ),
    );

    setModalIsOpen(false);
    setCurrentNode(null);
    setIsChanged(true);
  };


  const onConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId;
  }, []);


  const onConnectEnd = _.debounce((event) =>  {
      if (!connectingNodeId.current) return;

      const targetIsPane = event.target.classList.contains('react-flow__pane');

      if (targetIsPane) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
       
        const newNode = {
          id,
          type:'action',
          position: screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
          }),
          data: { label: `Node ${id}`,id },
          origin: [0.5, 0.0],
        };

        setNodes((nds) => nds.concat(newNode));
        setEdges((eds) =>
          eds.concat({ id, source: connectingNodeId.current, target: id }),
        );
      }
    },300)


  // we are using a bit of a shortcut here to adjust the edge type
  // this could also be done with a custom edge for example
  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === 'custom').data.selects[edge.sourceHandle];
      edge.type = edgeType;
    } else {
      edge.type = 'smoothstep';
    }

    return edge;
  });

  const addItem = (type) => {
    setNodeType(type);
    setModalIsOpen(true);
  };

  return (
    <div style={{position:'relative'}}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          height: '60px',
          width: '100%',
          backgroundColor: '#0D99FF',
        }}
      >
        <div
          style={{
            marginLeft: '20px',
            color: 'white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Icon path={mdiArrowLeft} size="35px" color="white" />
          <div style={{ width: '10x' }} />
          <TextButton
            text={workFlowObj?.workflowname ?? 'test'}
            fontSize="25px"
            Action={closeLocal}
            textColor="white"
            hoverColor="#FFFFFF80"
            icon={mdiArrowDecisionOutline}
            iconSize="35px"
            iconPosition="left"
          />
        </div>
        {/* <div
          style={{
            marginRight: '20px',
            border: '2px solid white',
            borderRadius: '7px',
            padding: '5px',
          }}
        >
          <img src={GBLogo} height="30px" />
        </div> */}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginRight: '20px',
          marginTop: '10px',
          height: '40px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <GBButton
            text="Save"
            Action={onSave}
            // Action={handleClick}
            textColor="black"
            color="#eeeeee"
            textHoverColor="white"
            iconHoverColor="white"
            hoverBackgroundColor={'#0D9953'}
            icon={mdiContentSave}
            iconSize="30px"
          />
          <div style={{ width: '20px' }} />
          {nodes.length===0 ? (
          <GBButton
            text="Add Trigger"
            Action={addItem}
            ActionValue={'trigger'}
            textColor="black"
            color="#eeeeee"
            textHoverColor="white"
            iconHoverColor="white"
            hoverBackgroundColor={'#0D9953'}
            icon={mdiPlus}
            iconPosition="left"
            iconSize="30px"
          />) :  null}
        </div>
      </div>

      <div style={{ width: '100vw', height: 'calc(100vh - 110px)' }}  className="wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edgesWithUpdatedTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeDoubleClick={onEdgeDoubleClick}
          onNodeDoubleClick={(event, node) => {
            editNode(node);
        }}
          onConnect={onConnect}
          // onInit={onInit}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          onInit={setRfInstance}
          fitView
          nodeTypes={nodeTypes}
        >
          <MiniMap style={minimapStyle} zoomable pannable />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>
      </div>

      {modalIsOpen ? (
        <div
          style={{
            position: 'absolute',
            overflow: 'auto',
            zIndex: 0,
            top: 60,
            right: 0,
            backgroundColor: nodeType === 'action' ? '#ECF7FF' : '#F3FCF7',
            height: 'calc(100vh - 60px)',
            width: '550px',
          }}
        >
          {nodeType === 'trigger' ? (
            <TriggerPanel
              close={closeNode}
              addNode={addNode}
              nodeData={currentNode}
              updateNode={updateNode}
              removeNode={removeNodeAndEdges}
            />
          ) : null}

        {nodeType === 'action' ? (
            <ActionPanel
              close={closeNode}
              addNode={addNode}
              sourceNodes={sourceNodes}
              nodeData={currentNode}
              updateNode={updateNode}
              removeNode={removeNodeAndEdges}
            />
          ) : null}


        </div>
      ) : null}
    </div>
  );
};



export default ({workFlowObj, refresh, close}) => (
    <ReactFlowProvider>
      <WorkFlow workFlowObj={workFlowObj} refresh={refresh} close={close} />
    </ReactFlowProvider>
  );
