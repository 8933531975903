/* eslint-disable */
import React, { useEffect, useState, } from 'react';
import { Input} from 'semantic-ui-react';
import {mdiPlayBox} from '@mdi/js';
import IconButton from '../Block/components/FilterData/IconButton';

const EvaluateFunction = ({functionString}) =>{

    const [terms,setTerms] =useState([]);
    const [results, setResults] =useState('');
    const [localFunction, setLocalFunction] =useState(functionString)

    useEffect(()=>{
        console.log('updated')
        extractTerms(functionString);
    },[])

    useEffect(()=>{
        setLocalFunction(functionString)
    },[functionString])

    const updateValue = (term,value) =>{
        const tempTerms = structuredClone(terms)
        const idx = tempTerms.findIndex(el=>el.term===term);
        if(idx !==-1) {
            tempTerms[idx].value=value;
        }
        setTerms(tempTerms);
    }

    const testFunction = () => {

        let testString = localFunction;
        terms.map(term=>{
            testString = testString.replace(term.term, `'${term.value}'`)
        })

       const returnString= `${testString}`;
       console.log(returnString);
        // const myString=`
        // const currentDate = new Date(); // Get the current date and time
        // const tomorrow = new Date(currentDate.getTime() + 86400000);  
        // const formattedTomorrow = tomorrow.toLocaleDateString('en-US', {
        //     year: 'numeric', 
        //     month: 'long', 
        //     day: 'numeric' 
        // });

        // return formattedTomorrow`

        // const string=`const age=3;
        // if(age >2) {
        //   return true;
        // } else {
        //   return false;
        // } `

        try {
          const func = new Function(returnString);  
         const data= func();
         console.log(data);
         setResults(data);
        } catch (error) {
         setResults("Error: Invalid code");
        }
      }

    // const extractTerms = (str) => {
    //     const regex = /{({)?([^}]+)}?/g; // Updated regular expression
    //     const matches = [];
    //     let match;
    //     while ((match = regex.exec(str)) !== null) {
    //       const term = match[0];
    //       matches.push({term:term,value:''});
    //     }
    //     setTerms(matches);
    //     console.log(matches);
    //   };

    const extractTerms = (str) => {
        const regex = /{{([^}]+)}}/g; // Removed optional braces
        const matches = [];
        let match;
        while ((match = regex.exec(str)) !== null) {
          const term = match[0];
          matches.push({term:term,value:''});
        }
        console.log(matches)
        setTerms(matches);
      };
    
      return (
        <div>
           {terms.length> 0 ? (
            <div>
                {terms.map(t=> (<div style={{display:'flex', marginBottom:'5px', width:'100%', flexDirection:'row',alignItems:'center'}}>
                    <div style={{marginRight:'5px',whiteSpace:'nowrap',width:'maxContent'}}>{t.term}</div>
                    <div style={{width:'100%',marginRight:'20px'}}><Input fluid value={t.value} onChange={(e,data)=>updateValue(t.term,data.value)} /></div>
                </div>))}
            <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>
            <IconButton  color="#0D99FF" hoverColor="white" hoverBackgroundColor="#0D99FF" icon={mdiPlayBox} Action={testFunction} size="30px"  />
            <div>{results}</div>
            </div>
            </div>
           ):null}
        </div>
      )

}

export default EvaluateFunction