/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState,useRef } from 'react';

import { Dropdown } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiPlus} from '@mdi/js';

const FieldButton =({options,addRemoveField,label="Add field"}) =>{

    useEffect(()=>{
        console.log(options)
    },[])

    return (
        <div
            css={{
              width: 'max-content',
              marginLeft: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              '&:hover': {
                '& .icon': {
                  boxShadow: '0px 3px 6px #757575',
                },
                '& .plusicon': {
                  transition: 'all .3s ease',
                  borderRadius: '20px',
                  color:'#0D99FF',
                  opacity: 0.5,
                  backgroundColor: 'white',
                },
              },
            }}
          >
            <Dropdown
              text={label}
              closeOnChange={false}
              scrolling
              button
              selectOnBlur={false}
              iconPosition="left"
              icon={
                <Icon
                  className="plusicon"
                  style={{ marginLeft: '5px' }}
                  path={mdiPlus}
                  size="15px"
                />
              }
              // icon="plus"
              className="icon"
              style={{
                borderRadius: '7px',
                fontSize: '12px',
                fontFamily: 'Nunito Sans',
                height: '35px',
                width:'200px',
                backgroundColor: '#0D99FF',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              options={options}
              value=""
              onChange={(e, data) => addRemoveField(data.value)}
            />
            </div>
    )
}

export default FieldButton