/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Dropdown } from 'semantic-ui-react';
import Icon from '@mdi/react';
import { mdiClose, mdiChevronDown } from '@mdi/js';
import FilterOperators from './FilterOperators';

const EmailMessagesFilter = ({
  filter,
  filterData,
  field,
  UpdateFilter,
  displayfield,
  header,
  uitype,
  remove,
  placeholder,
  color,
}) => {
  const [operator, setOperator] = useState('incoming-outgoing');
  const [fieldvalue, setFieldvalue] = useState('');
  const [timeframe, setTimeFrame] = useState(24);
  const [direction, setDirection] = useState('incoming');
  const [status, setStatus] =useState(['delivered']);
  const [interaction, setInteraction]=useState(['opened']);
  const [timeFrameType, setTimeFrameType] = useState('addedon');

  useEffect(() => {
    if (filter.value != null) {
      if(filter.operator ==='status') {
        setFieldvalue([filter.value])
        setStatus(filter.value)
      } else if(filter.operator==='interaction') {
        setFieldvalue([filter.value])
        setInteraction(filter.value)
      } else if(filter.operator==='timeframe') {
        setFieldvalue(filter.timeFrameType)
        setTimeFrameType(filter.timeFrameType);
      }
      else {
        setFieldvalue(filter.value);
      }
      
      setOperator(filter.operator);
      setTimeFrame(filter.timeframe);
    } else {
      updatefilter('incoming','incoming-outgoing')
    }

  }, []);

  const statusOptions =[
    {key:'processed', value:'processed',  text:'processed'},
    {key:'deferred', value:'deferred',  text:'deferred'},
    {key:'delivered', value:'delivered',  text:'delivered'},
    {key:'bounce', value:'bounce',  text:'bounced'},
]

const interactionCodes = [
  {key:'opened', value:'opened',  text:'opened'},
  {key:'clicked', value:'clicked',  text:'clicked'},
]

const timeFrameTypes = [
    {key:'addedon', value:'addedon',  text:'Delivery'},
    {key:'opened', value:'opened',  text:'Opened'},
    {key:'clicked', value:'clicked',  text:'Clicked'},
  ]

  const updatefilter = (data, tmpoperator,newtimeframe=timeframe) => {
    setFieldvalue(data);

    if (tmpoperator !== undefined) {
      const filterObject = {
        field,
        header,
        value: data,
        timeframe: newtimeframe ,
        timeFrameType: timeFrameType,
        operator: tmpoperator,
        displayfield,
        uitype,
      };
      UpdateFilter(filterObject);
    } else {
      const filterObject = { field, header, value: data, timeframe, operator, displayfield, uitype,timeFrameType };
      UpdateFilter(filterObject);
    }
  };

  const changeDirection =(dir) => {
    setDirection(dir);
    updatefilter(dir,'incoming-outgoing')
  }

  const changeOperator = (newoperator) => {
    setOperator(newoperator);
    if(newoperator==='status') {
      updatefilter(['delivered'],newoperator)
    } else if(newoperator==='interaction') {
      updatefilter(['opened'],newoperator)
    }
    else {
      updatefilter(fieldvalue, newoperator);
    }
  };

  const changeTimeframe = (tf) => {
      setTimeFrame(tf);
      updatefilter(fieldvalue,'timeframe',tf)
  }

  const handleEnter = e => {
    if (e.keyCode === 13) {
      filterData()
    }
  };

  const changeTimeFrameType  =(tt) =>{
    setTimeFrameType(tt);
    const filterObject = {
        field,
        header,
        value: tt,
        timeframe: timeframe ,
        timeFrameType: tt,
        operator: operator,
        displayfield,
        uitype,
      };

      UpdateFilter(filterObject);
  }

  const changeStatus =(tmpstatus) =>{
    setStatus(tmpstatus)
    updatefilter(tmpstatus,'status')
  }

  const changeInteraction =(val) =>{
    setInteraction(val)
    updatefilter(val,'interaction')
  }

  const removeFilter = (newfield) => {
    remove(newfield);
  };

  const filterOption = [
    { key: 'incoming-outgoing', text:'Incoming/Outgoing',value:'incoming-outgoing'},
    { key: 'contains', text: 'Search string', value: 'contains' },
    { key: 'status', text: 'Last message delivery status', value: 'status' },
    { key: 'interaction', text: 'Last interaction', value: 'interaction' },
    { key: 'timeframe', text: 'Time frame', value: 'timeframe' },
    { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
    { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
  ];
  const timeframes = [
    { key: '24', text: 'Last 24 hours', value: 24 },
    { key: '48', text: 'Last 48 hours', value: 48 },
    { key: '72', text: 'Last 72 hours', value: 72 },
    { key: '168', text: 'Last 7 days', value: 168 },
    { key: '336', text: 'Last 14 days', value: 336 },
    { key: '720', text: 'Last 30 days', value: 720 },
    { key: '2160', text: 'Last 90 days', value: 2160 },
    { key: '4320', text: 'Last 180 days', value: 4320 },
    { key: '8640', text: 'Last 360 days', value: 8640 },
    { key: 'thisquarter', text: 'This quarter', value: 'thisquarter' },
    { key: 'lastquarter', text: 'Last quarter', value: 'lastquarter' },
    { key: 'thisandlast', text: 'This and Last quarter', value: 'thisandlastquarter' },
    { key: 'ytd', text: 'Year to date', value: 'ytd' },
    { key: 'lastyear', text: 'Last Year', value: 'lastyear' },
  ];

  const flowdirections = [
    { key: 'incoming', text: 'Last message was incoming', value: 'incoming' },
    { key: 'outgoing', text: 'Last message was outgoing', value: 'outgoing' },
  ]

  const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';

  return (
    <div style={{ marginBottom: '23px', width: '100%' }}>
      <FilterOperators
        header={header}
        operator={operator}
        filterOption={filterOption}
        RemoveAction={removeFilter}
        RemoveActionValue={field}
        ChangeOperator={changeOperator}
        color={color}
      />

      {operator === 'isnull' || operator === 'isnotnull' ? (
        <Input style={{ width: '100%' }} value={displayvalue} />
      ) : null}
      {operator === 'contains' ? (
        <Input
          value={fieldvalue}
          onChange={(e, data) => updatefilter(data.value)}
          onKeyDown={handleEnter}
          style={{ width: '100%' }}
          placeholder={placeholder}
        />
      ) : null}
      {operator === 'timeframe' ? (<>
        <Dropdown
          fluid
          selection
          value={timeframe}
          icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
          // style={{ fontSize: '14px', color: '#486FB3' }}
          options={timeframes}
          onChange={(e, data) => changeTimeframe(data.value)}
        />
        <div style={{height:'20px'}} />
        Select time frame type
        <Dropdown
        fluid
        selection
        value={timeFrameType}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={timeFrameTypes}
        onChange={(e, data) => changeTimeFrameType(data.value)}
      />

      </>) : null}
      {operator==='incoming-outgoing' ? (
        <Dropdown
        placeholder='Select a message direction'
        fluid
        selection
        value={direction}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={flowdirections}
        onChange={(e, data) => changeDirection(data.value)}
        />
      ): null}
      {operator==='status' ? (
        <Dropdown
        placeholder='Select a status'
        fluid
        multiple
        selection
        value={status}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={statusOptions}
        onChange={(e, data) => changeStatus(data.value)}
        />
      ): null}
       {operator==='interaction' ? (
        <Dropdown
        multiple
        placeholder='Select an interaction'
        fluid
        selection
        value={interaction}
        icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
        // style={{ fontSize: '14px', color: '#486FB3' }}
        options={interactionCodes}
        onChange={(e, data) => changeInteraction(data.value)}
        />
      ): null}
    </div>
  );
};


export default EmailMessagesFilter;
