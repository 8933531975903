/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import { Input, Dropdown, Form, Radio } from 'semantic-ui-react';
import { mdiClose, mdiEmail, mdiPlayBox, mdiClipboardCheck } from '@mdi/js';
import Modal from 'react-modal';
import { AccountContext } from '../../providers/AccountProvider';
import Global from '../../clients/global';
import FieldButton from './FieldButton';
import IconButton from '../Block/components/FilterData/IconButton';
import TextButton from '../../components/TextButton/TextButton';
import GBButton from '../../components/GBButton/GBButton';
import CodeEditor from 'src/components/CodeEditor/CodeEditor';
import EmailControl from '../Block/components/MessageView/EmailControl';
import EvaluateFunction from './EvaluateFunction';
import { getAllForms, getForm } from '../../api/forms';

const ActionFormTask = ({ localData, updateData, editMode, sourceNodes }) => {
  const { userInfo } = useContext(AccountContext);
  const [insertFields, setInsertFields] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [availableFields, setAvailableFields] = useState([]);
  const [forms, setForms] = useState([]);
  const [fromAddresses, setFromAddresses] = useState([
    {
      key: '1',
      value: 'clientemaildelivery@graceblocks.com',
      text: 'clientemaildelivery@graceblocks.com',
    },
  ]);
  const [fromAddress,setFromAddress] =useState('clientemaildelivery@graceblocks.com')
  const [formData, setFormData] = useState(null);
  const [selectedForm, setSelectedForm] = useState('');
  const [overdue, setOverDue]=useState(localData.overdue ?? 3);
  const [reminder, setReminder]=useState(localData.reminder ?? 1)
  const [unFinished, setUnFinished]=useState(localData.unfinished ?? 6);

  const [emailFields, setEmailFields] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(localData.selectedEmail ?? 'manual');
  const [emailValue, setEmailValue] = useState(localData.emailValue ?? '');
  const [refreshDate, setRefreshDate] = useState(Date.now());
  const [taskMessage, setTaskMessage] = useState({
    subject: 'Form required',
    message: `
      <div>
        Greetings,<br/><br/>
        You have an {{taskStatus}} task. Click the link below to complete this task.<br/><br /> 
        {{taskLink}} <br/><br/>
        Thank you.
      </div>
    `,
    fromAddress:'clientemaildelivery@graceblocks.com',
    fromName:'GraceBlocks Support',
    replyTo:'support@graceblocks.com'
  });

  useEffect(() => {
    loadForms();
  }, []);

  const loadForms = async () => {
    const formResult = await getAllForms();
    const options = [];
    formResult.map((el) => {
      options.push({
        key: el.formid.toString(),
        value: el.formid,
        text: `${el.blockname} - ${el.tablename}: (${el.formname})`,
      });
    });

    if (localData.form !== undefined) {
      setSelectedForm(localData.form.formid);
      setFormData(localData.form);

      if (localData.availableFields === undefined) {
        const aFields = [];
        localData.form.columnsettings
          .filter((fld) => fld.type === 'field')
          .map((col) => {
            aFields.push({ key: col.data, value: col.data, text: col.label });
          });
        setAvailableFields(aFields);
      }
    }

    setForms(options);
    getEmailandCollaboratorFields();

    if (localData.insertFields !== undefined) {
      setInsertFields(localData.insertFields);
      setAvailableFields(localData.availableFields);
    }

    const currentZone = userInfo.zones.filter((el) => el.id === parseInt(Global.zoneid))[0];
    let tempAddresses = [
      {
        key: '1',
        value: 'clientemaildelivery@graceblocks.com',
        text: 'clientemaildelivery@graceblocks.com',
      },
    ];
    if (currentZone.attributes.fromAddresses) {
      currentZone.attributes.fromAddresses.map((el) => {
        if (el.isActive) {
          tempAddresses.push({ key: el, value: el.email, text: el.email });
        }
      });
      setFromAddresses(tempAddresses);
    }
  };

  const updateEmailValue = (val) => {
    setEmailValue(val);
    localData.emailValue = val;
    updateData(localData);
  };

  const updateOverDue = (val) =>{
    setOverDue(val);
    localData.overdue = val;
    updateData(localData)
  }

  const updateReminder = (val) =>{
    setReminder(val);
    localData.reminder = val;
    updateData(localData)
  }

  const updateUnfinished = (val) =>{
    setUnFinished(val);
    localData.unfinished = val;
    updateData(localData)
  }

  const updateFromAddress = (val) =>{
    const copyTask = structuredClone(taskMessage);
    copyTask.fromAddress =val;
    setFromAddress(val)
    setTaskMessage(copyMessage)
    localData.taskMessage =taskMessage
    updateData(localData)
  }

  const getEmailandCollaboratorFields = () => {
    const emailOptions = [{ key: '0', value: 'manual', text: 'Manual entry' }];
    sourceNodes.map((node) => {
      node.data?.tableData?.tableinfo?.columns
        .filter(
          (el) =>
            (el.uitype == 8 && el.lookup === 'single') ||
            (el.source?.lookupuitype === 8 &&
              el.lookup === 'single' &&
              el.source.lookup === 'single') ||
            el.uitype === 10 ||
            (el.source?.lookupuitype === 10 && el.source.lookup === 'single'),
        )
        .map((col) => {
          emailOptions.push({
            key: `${node.data.tableData.id}|${col.data}`,
            value: `${node.data.tableData.id}|${col.data}`,
            text: `${node.data.tableData.name} (${col.header})`,
          });
        });
    });

    setEmailFields(emailOptions);
  };

  const handleEmailSelection = (val) => {
    setSelectedEmail(val);
    localData.selectedEmail = val;
    updateData(localData);
  };

  const configureEmail = (
    emailObjectArray,
    messageSetting,
    templateFields,
    ids,
    blockRoles,
    role,
  ) => {
    console.log(templateFields);
    const copyTask = structuredClone(taskMessage);
    copyTask.message = templateFields.filter(el=>el.field==='message')[0].fieldValue;
    copyTask.fromName = templateFields.filter(el=>el.field==='fromName')[0].fieldValue;
    copyTask.subject = templateFields.filter(el=>el.field==='subject')[0].fieldValue;
    copyTask.replyTo = templateFields.filter(el=>el.field==='replyTo')[0].fieldValue;
    if(templateFields.filter(el=>el.field==='CC')[0].fieldValue !=='') {
        copyTask.CC = templateFields.filter(el=>el.field==='CC')[0].fieldValue;
    }
    if(templateFields.filter(el=>el.field==='BCC')[0].fieldValue !=='') {
        copyTask.BCC = templateFields.filter(el=>el.field==='BCC')[0].fieldValue;
    }
    setTaskMessage(copyTask)
    setModalIsOpen(false);
  };

  const handleFormSelection = async (formid) => {
    const form = await getForm(formid);
    setSelectedForm(formid);
    setFormData(form[0]);
    localData.form = form[0];
    updateData(localData);

    const aFields = [];
    form[0].columnsettings
      .filter((fld) => fld.type === 'field')
      .map((col) => {
        aFields.push({ key: col.data, value: col.data, text: col.label });
      });
    setAvailableFields(aFields);
    delete localData.availableFields;
    delete localData.insertFields;
    updateData(localData);
  };

  const addRemoveField = (value) => {
    const idx = insertFields.findIndex((el) => el.value === value);
    const idx2 = availableFields.findIndex((el) => el.value === value);

    let tempInsert = structuredClone(insertFields);
    let tempAvailable = structuredClone(availableFields);

    if (idx === -1) {
      const fld = availableFields[idx2];
      fld.mappedValue = 'manual';
      tempInsert.push(fld);
      tempAvailable = tempAvailable.filter((itm) => itm.value !== value);
    } else {
      const fld = insertFields[idx];
      fld.mappedValue = 'manual';
      tempAvailable.push(fld);
      tempInsert = tempInsert.filter((itm) => itm.value !== value);
    }

    setInsertFields(tempInsert);
    setAvailableFields(tempAvailable);

    localData.insertFields = tempInsert;
    localData.availableFields = tempAvailable;

    updateData(localData);
  };

  const getOptions = () => {
    const options = [
      { key: '0', value: 'manual', text: 'Manual entry' },
      { key: 'function', value: 'function', text: 'Javascript function' },
    ];
    sourceNodes.map((node) => {
      node.data.tableData.tableinfo.columns.map((col) => {
        options.push({
          key: `${node.data.tableData.id}|${col.data}`,
          value: `${node.data.tableData.id}|${col.data}`,
          text: `${node.data.tableData.name} (${col.header})`,
        });
      });
    });

    return options;
  };

  const getMappedValue = (field, attribute) => {
    const idx = insertFields.findIndex((col) => col.key === field);
    if (idx !== -1) {
      return insertFields[idx][attribute] ?? false;
    }
  };

  const handleMappedValueChange = (field, val, attribute) => {
    const idx = insertFields.findIndex((col) => col.value === field);
    const temp = structuredClone(insertFields);
    temp[idx][attribute] = val;
    setInsertFields(temp);

    localData.insertFields = temp;
    updateData(localData);
  };

  const updateFieldValue = (field, value) => {
    const idx = insertFields.findIndex((el) => el.key === field);
    if (idx !== -1) {
      insertFields[idx].value = value;
      setInsertFields(insertFields);

      localData.insertFields = insertFields;
      updateData(localData);
      setRefreshDate(Date.now());
    }
  };

  return (
    <div
      style={{
        backgroundColor: '#fff',
        minHeight: '300px',
        border: '1px solid #0D99FF',
        borderRadius: '5px',
        padding: '20px',
        paddingBottom: '20px',
      }}
    >
      <div style={{ marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
        Action details
      </div>
      <div>
        <div>
          <div style={{ marginTop: '5px', marginBottom: '5px' }}>
            From address:{' '}
            <Dropdown
              fluid
              value={fromAddress}
              selection
              options={fromAddresses}
              onChange={(e, data) => updateFromAddress(data.value)}
            />
          </div>
        </div>
        <div>
          Assigned to <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
        </div>
        <Dropdown
          options={emailFields}
          value={selectedEmail}
          selection
          fluid
          onChange={(e, data) => handleEmailSelection(data.value)}
        />
        {selectedEmail === 'manual' ? (
          <div style={{ marginTop: '10px' }}>
            <Input
              value={emailValue}
              fluid
              label="Email"
              onChange={(e, data) => updateEmailValue(data.value)}
            />
          </div>
        ) : null}
      </div>

      <div style={{ marginTop: '10px' }}>
        <div>Form</div>
        <Dropdown
          value={selectedForm}
          selection
          options={forms}
          fluid
          onChange={(e, data) => handleFormSelection(data.value)}
        />
      </div>

      <div style={{ marginTop: '10px' }}>
        <FieldButton
          options={availableFields}
          addRemoveField={addRemoveField}
          label="Map form field"
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {insertFields.length > 0
            ? insertFields.map((fld) => (
                <div
                  style={{
                    margin: '10px',
                    padding: '10px',
                    border: '1px solid black',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      marginTop: '10px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: '10px' }}>{fld.text}</div>
                    <Dropdown
                      fluid
                      search
                      value={getMappedValue(fld.key, 'mappedValue')}
                      selection
                      options={getOptions()}
                      onChange={(e, data) =>
                        handleMappedValueChange(fld.key, data.value, 'mappedValue')
                      }
                    />
                    <IconButton
                      color="red"
                      hoverColor="white"
                      hoverBackgroundColor="#F6685E"
                      icon={mdiClose}
                      Action={addRemoveField}
                      ActionValue={fld.value}
                    />
                  </div>
                  {getMappedValue(fld.key, 'mappedValue') === 'manual' ? (
                    <Input
                      fluid
                      onChange={(e, data) => updateFieldValue(fld.key, data.value)}
                      style={{ width: '100%', marginTop: '10px' }}
                      defaultValue={fld.value}
                    />
                  ) : null}
                  {getMappedValue(fld.key, 'mappedValue') === 'function' ? (
                    <>
                      <div style={{ marginTop: '10px' }}>
                        <CodeEditor
                          fields={getOptions().map((el) => el.text)}
                          updateFormula={updateFieldValue}
                          formula={fld.value}
                          isReadOnly={false}
                          mode="javascript"
                          field={fld.key}
                        />
                      </div>
                      <div>
                        <TextButton
                          text="Evaluate"
                          textColor="#0D99FF"
                          color="#0D99FF"
                          hoverColor="#0D99FF80"
                          icon={mdiClipboardCheck}
                          Action={() => {
                            handleMappedValueChange(
                              fld.value,
                              !getMappedValue(fld.key, 'evaluateFunction'),
                              'evaluateFunction',
                            );
                          }}
                          iconSize="30px"
                          iconPosition="left"
                        />
                      </div>
                      {getMappedValue(fld.key, 'evaluateFunction') == true ? (
                        <div>
                          <EvaluateFunction functionString={fld.value} />
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ))
            : null}
        </div>

        <div style={{ marginTop: '20px' }}>
          Notifications
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginRight: '10px' }}>Initial email</div>
            <div>
              <GBButton
                text="Configure email"
                icon={mdiEmail}
                iconSize="20px"
                iconColor="white"
                iconHoverColor="white"
                iconPosition="left"
                borderRadius="10px"
                Action={() => setModalIsOpen(true)}
                ActionValue={'trigger'}
                textColor="white"
                width="150px"
                height={'30px'}
                color="#0D99FF"
                textHoverColor="white"
                hoverBackgroundColor={'#0D99FF80'}
              />
            </div>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
            This task is overdue after <Input type="number" style={{width:'70px'}} value={overdue} onChange={(e,data)=>updateOverDue(data.value)} /> days
            <br/><br/>
            If not completed, set reminders every <Input type="number" style={{width:'70px'}} value={reminder} onChange={(e,data)=>updateReminder(data.value)}  /> days
            <br/><br/>
            Mark task as Unfinished if not submitted <Input  type="number" style={{width:'70px'}} value={unFinished} onChange={(e,data)=>updateUnfinished(data.value)}  /> days
        </div>

      </div>
      <Modal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        style={{
          overlay: {
            // position: 'fixed',
            top: 50,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0)',
          },
          content: {
            // position: 'fixed',
            // width: '700px',
            // height: 'calc(100vh -300px)',
            // top: 200,
            // left: window.innerWidth / 2,
            border: `.3px solid #0D99FF`,
            background: '#fff',
            overflow: 'auto',
            zIndex: 6010,
            WebkitOverflowScrolling: 'touch',
            borderRadius: '10px',
            outline: 'none',
            padding: '0px',
          },
        }}
        contentLabel="Example Modal"
      >
        <EmailControl
          toAddress={'{{dynamic}}'}
          tableid={sourceNodes[0].data.tableData.id}
          emailField={{fromAddress:taskMessage.fromAddress, replyTo: taskMessage.replyTo, fromName:taskMessage.fromName }}
          replyMsg={taskMessage}
          color="#0D99FF"
          sendMessage={configureEmail}
          close={() => setModalIsOpen(false)}
          role={3}
        />
      </Modal>
    </div>
  );
};

export default ActionFormTask;
