/* eslint-disable */
import cloudrun from '../clients/httpcloudrun';
import axios from 'axios';


export const getForms= async (tableid,role=3) => {
    const payload={tableid,role}
    const dataresponse = await cloudrun.post('/getForms', {payload});
    return dataresponse
}

export const getUserForms= async (tableid) => {
    const payload={tableid,dbfunction:'getUserForms'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    const ListItems =[]
    // console.log(dataresponse)

    if(dataresponse.length>0){
        dataresponse.forEach(el=>{
            ListItems.push({key:el.formid,value:el.formid,text:el.name,isdefault:el.isdefault})
        })
    }

    return ListItems
}

export const getForm= async (formid) => {
    const payload={formid,dbfunction:'getForm'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

export const getAllForms= async () => {
    const payload={dbfunction:'getAllForms'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return dataresponse
}

export const getFormByToken = async (formInfo,addedby,userZoneId,id)=>{
    
    /* 10-3-23 added id param to be passed to form. This will often be undefined
    it is used specifially when the id param is passed in the url, indicating an update to an existing form.
    We are now implementing token logic in form labels, which requires the id in order to get token fieldss.

    */

    const {REACT_APP_API_KEY,REACT_APP_API_CLOUDRUN_URL} = process.env;

    const formElements=formInfo.split('|')
    const zoneid=formElements[0]
    const dbname=formElements[1]
    const formid=formElements[2]

    // const servermap = JSON.parse(REACT_APP_SERVER_MAP)
    // const serverItem = servermap.filter(el=>el.dbname==dbname)[0]
    const payload={ReactAPIKey: REACT_APP_API_KEY,formid,dbname,zoneid,userZoneId,addedby,id}
  
    const result = await axios({
      url: `${REACT_APP_API_CLOUDRUN_URL}/getForm`,
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      data: {
        payload,
      },
  });
                 
    return result.data;
}

export const getZoneServer = async (zoneid) =>{
    const {REACT_APP_API_CLOUDRUN_URL,REACT_APP_API_KEY} = process.env;
    const payload={zoneid, ReactAPIKey:REACT_APP_API_KEY };
    
    const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/getZoneServer`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
    });
    return result.data;
}

export const saveFormSubmission = async (form,fieldValues,hiddenValues,formInfo,url,addedby,isRelationalRecord,completedSubForms) => {
    
    const {REACT_APP_API_KEY,REACT_APP_API_CLOUDRUN_URL} = process.env;
    const formElements=formInfo.split('|')
    const zoneid=formElements[0]
    const dbname=formElements[1]

    // const servermap = JSON.parse(REACT_APP_SERVER_MAP)
    // const serverItem = servermap.filter(el=>el.dbname==dbname)[0]

    const payload={ReactAPIKey: REACT_APP_API_KEY,form,fieldValues,hiddenValues,zoneid,dbname,url,addedby,isRelationalRecord,completedSubForms}
  
    const result = await axios({
      url: `${REACT_APP_API_CLOUDRUN_URL}/saveFormSubmission`,
      headers: { 'Content-Type': 'application/json' },
      method: 'post',
      data: {
        payload,
      },
  });
                 
    return result.data;

}

export const getFormFieldOptions = async (field,source,formInfo,filterTerm='',dbfunction,categoryid=null,tableid,colInfo,format="list",limit=100,role,addedby,blockid) => {

    //1-27-23 added blockid in order to support getting only block Users for collab field.
    const {REACT_APP_API_KEY, REACT_APP_API_CLOUDRUN_URL} = process.env;
   
    const formElements=formInfo.split('|')
    const zoneid=formElements[0]
    const dbname=formElements[1]
    

    const payload={ReactAPIKey: REACT_APP_API_KEY,filterTerm,source,field,tableid,dbname,zoneid,categoryid,dbfunction,colInfo,limit,role,addedby,blockid}
   
    const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/getFormFieldOptions`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
    });
    let res='';
    let Listitems = [];


    //8-18-22 added "format" attribute in attempt to provide way of returning data in
    //standard formats used in app for either "raw" or "dropdown". 

    if(dbfunction==='getOptionsApi'){
        res =result.data[0].root
        if(format==="list") {
        res.forEach(itm => {
        Listitems.push({ "optionid": itm.optionid.toString(), "option": itm.option, "rank": itm.rank, "attributes": itm.attributes })
        })
       } else if(format==='dropdown')  {
        res.forEach(itm => {
        Listitems.push({ "key": itm.optionid, "value": itm.optionid,"text": itm.option });
        })
       }

    } 

    else if(dbfunction==='getZoneUsers') //adding zonuser so external forms can have Collaborator fields.
    {
        //11-29-22 remove the anonymous user so it doesn't appear in users list on forms.
        res = result.data[0].array.filter(usr=>!usr.email.includes('anonymous_'));

        if(res !==null){
            if(format==='list'){
            res.forEach(itm => {
                Listitems.push({ "optionid": itm.userid.toString(), "option":  `${itm.firstname} ${itm.lastname}` })
            })
        } else if(format==='dropdown') {
            res.forEach(itm => {
                Listitems.push({ "key": itm.userid, value:itm.userid,"text": `${itm.firstname} ${itm.lastname}` })
            })
        }
        }
        return Listitems;
    } else if(dbfunction==='getBlockUsers') { //1-27-23 adding support for new ability for collab field to define users from block (and zone, defined users)
        Listitems=result.data
    }
    
    else if(dbfunction==='getRelationalTableData') { //8-5-22 adding to retrieve the full table needed to drive nested relational dropdown control
        
        if(format==='list') {
            Listitems= result.data
        } else if(format==='dropdown') {
            result.data.forEach(itm => {
                Listitems.push({ "key": itm.id, value:itm.id,"text": itm.CustomID})
            })
        }
    }
    
    return Listitems;
  }

  // 4-19-2021 added to support passsing in relation values as url param. Since it can be a very long
  // list, it might not be inthe default 50 items loaded by default. this method will retrieve the specifi
  // match on any size list.

  export const getFormLookupValue = async (tableid,formInfo,fieldInfo,value) => {

    const {REACT_APP_API_KEY, REACT_APP_API_CLOUDRUN_URL } = process.env;
   
    const formElements=formInfo.split('|')
    const zoneid=formElements[0]
    const dbname=formElements[1]

    // const servermap = JSON.parse(REACT_APP_SERVER_MAP)
    // const serverItem = servermap.filter(el=>el.dbname==dbname)[0]

    const payload={ReactAPIKey: REACT_APP_API_KEY,value,dbname,zoneid,fieldInfo,tableid}
    const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/getFormLookupValue`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
    });
    let res='';
    const Listitems = [];
    res =result.data;

    res.forEach(itm => {
        Listitems.push({ "optionid": itm.id.toString(), "option":  itm.option  })
    })
    
    return Listitems;
  }

export const getDataRow =async (id, tableid,formInfo) => {
    const {REACT_APP_API_KEY,REACT_APP_API_CLOUDRUN_URL } = process.env;
    const payload={ReactAPIKey: REACT_APP_API_KEY,id,formInfo,tableid}
    
    const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/getDataRow`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
    });

    return result.data;
}

// 11-30-23 method added to dynamically replace tokens in form labels/rtf content
//based upon a selection of s single relational value. 
export const getDynamicLabels = async (id,formInfo,templateFields,tableid,userid) => {
    const {REACT_APP_API_KEY,REACT_APP_API_CLOUDRUN_URL } = process.env;
    const payload={ReactAPIKey: REACT_APP_API_KEY,id,tableid,formInfo,templateFields,userid}
    
    const result = await axios({
        url: `${REACT_APP_API_CLOUDRUN_URL}/getDynamicLabels`,
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        data: {
          payload,
        },
    });

    return result.data;
}

export const addForm = async (name,tableid,blockid) => {
    const payload={name,tableid,blockid,dbfunction:'addForm'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
}

export const createSocialShareImage = async (form) => {
    const payload={form}
    const dataresponse = await cloudrun.post('/createSocialShareImage', {payload})
    // console.log(dataresponse)
    return dataresponse
}

/* added 9-2-22 This is for use-case for Form Login. If user successfully is logged into system
we need to retrieve thier role for the associated block of this form as well as the addRecord priv.
If 
*/
export const getFormRole =async (formid) =>{
    const payload={formid,dbfunction:'getFormRole'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    console.log(dataresponse)
    return dataresponse
}

export const updateFormEmailUsers = async (formid, emailusers) =>{
    const payload={formid,emailusers,dbfunction:'updateFormEmailUsers'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}

export const deleteForm = async (formid,tableid) =>{
    const payload={formid,tableid,dbfunction:'deleteForm'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}

export const duplicateForm = async (formid,name) => {
    const newName='Copy of '+ name;
    const payload={formid,name: newName,dbfunction:'duplicateForm'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}

export const updateFormStatus = async (formid,tableid,isdefault,ispublic) =>{
    const payload={formid,tableid,ispublic,isdefault, dbfunction:'updateFormStatus'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"

}

export const reOrderForms = async (formids) =>{
    const payload={formids, dbfunction:'reOrderForms'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}

export const saveForm = async (form) => {
    const payload={form, dbfunction:'updateForm'}
    const dataresponse = await cloudrun.post('/executeQueryAPI', {payload})
    return "success"
}