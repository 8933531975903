/* eslint-disable  */
/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import { mdiClose, mdiChevronDown } from '@mdi/js';
import { Dropdown, Input } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FilterOperators from './FilterOperators';

const DateFilter = (props) => {
  const [operator, setOperator] = useState('fromto');
  const [timeframe, setTimeFrame] = useState(24);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
 
    const filterObject = {
      field: props.fieldnode.data,
      header: props.fieldnode.header,
      startDate,
      uitype: props.uitype,
      timeframe,
      endDate,
      displayfield: props.fieldnode.displayfield,
      operator,
      isLookup: props.isLookup
    };

    if(operator !=='fromto' || (operator==='fromto' && startDate !==null & endDate !==null)){
      props.UpdateFilter(filterObject);
    }
    

    // eslint-disable-next-line
  }, [operator, timeframe, startDate, endDate]);

  useEffect(() => {
    if (props.filter.operator !== undefined) {
      
      setOperator(props.filter.operator);
      const sd = new Date(props.filter.startDate)
      setStartDate(sd);

      if (props.filter.endDate !== undefined) {
        const ed = new Date(props.filter.endDate)
        setEndDate(ed);
      }
      if (props.filter.timeframe !== undefined) {
        setTimeFrame(props.filter.timeframe);
      }
    }
    // eslint-disable-next-line
  }, []);

  const filterOptions = [
    { key: 'fromto', text: 'From - To', value: 'fromto' },
    { key: 'specificdate', text: 'Specific date', value: 'specificdate' },
    { key: 'timeframe', text: 'Time frame', value: 'timeframe' },
    { key: 'before', text: 'Before', value: 'before' },
    { key: 'after', text: 'After', value: 'after' },
    { key: 'isnull', text: 'Empty (null)', value: 'isnull' },
    { key: 'isnotnull', text: 'Not empty (not null)', value: 'isnotnull' },
  ];

  const timeframes = [
    { key: '24', text: 'Last 24 hours', value: 24 },
    { key: '48', text: 'Last 48 hours', value: 48 },
    { key: '72', text: 'Last 72 hours', value: 72 },
    { key: '168', text: 'Last 7 days', value: 168 },
    { key: '336', text: 'Last 14 days', value: 336 },
    { key: '720', text: 'Last 30 days', value: 720 },
    { key: '2160', text: 'Last 90 days', value: 2160 },
    { key: '4320', text: 'Last 180 days', value: 4320 },
    { key: '8640', text: 'Last 360 days', value: 8640 },
    { key: 'thisquarter', text: 'This quarter', value: 'thisquarter' },
    { key: 'lastquarter', text: 'Last quarter', value: 'lastquarter' },
    { key: 'thisandlast', text: 'This and Last quarter', value: 'thisandlastquarter' },
    { key: 'ytd', text: 'Year to date', value: 'ytd' },
    { key: 'lastyear', text: 'Last Year', value: 'lastyear' },
    { key: 'past', text: 'In the past', value: 'past' },
    { key: 'future', text: 'In the future', value: 'future' },
    { key: '15min', text: 'Next 15 minutes', value: '15min' },
    { key: '1hour', text: 'Next hour', value: 'nexthour' },
    { key: '24hour', text: 'Next 24 hours', value: 'next24hours' },
    { key: '48hour', text: 'Next 48 hours', value: 'next48hours' },
    { key: '72hour', text: 'Next 72 hours', value: 'next72hours' },
    { key: '7days', text: 'Next 7 days', value: 'next7days' },
    { key: '14days', text: 'Next 14 days', value: 'next14days' },
    { key: '30days', text: 'Next 30 days', value: 'next30days' },
    { key: '90days', text: 'Next 90 days', value: 'next90days' },
    { key: '365days', text: 'Next 365 days', value: 'next365days' },
    { key: 'nextquarter', text: 'Next quarter', value: 'nextquarter' },
    { key: 'thisandnext', text: 'This and next quarter', value: 'thisandnext' },
    { key: 'untilyearend', text: 'Until end of year', value: 'untilyearend' },
    { key: 'nexyear', text: 'Next year', value: 'nextyear' },
  ];

  const DisplayDateControl = () => {
    if (operator === 'fromto') {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showMonthDropdown
              showYearDropdown
              show
              customInput={<Input style={{ width: '175px' }} />}
            />
          </div>
          -
          <div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              showMonthDropdown
              showYearDropdown
              show
              customInput={<Input style={{ width: '175px' }} />}
            />
          </div>
        </div>
      );
    }
    if (operator === 'specificdate' || operator === 'before' || operator === 'after') {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              showMonthDropdown
              showYearDropdown
              show
              customInput={<Input style={{ width: '400px' }} />}
            />
          </div>
        </div>
      );
    }
    if (operator === 'timeframe') {
      return (
        <div
          css={{
            '& .chevrondown': {
              marginLeft: '2px',
            },
            '&:hover': {
              '& .chevrondown': {
                transition: 'all .3s ease',
                backgroundColor: `${color}80`,

                borderRadius: '50px',
                color: 'white',
              },
            },
          }}
        >
          <Dropdown
            fluid
            selection
            value={timeframe}
            icon={<Icon size="15px" className="chevrondown" path={mdiChevronDown} />}
            // style={{ fontSize: '14px', color: '#486FB3' }}
            options={timeframes}
            onChange={(e, data) => setTimeFrame(data.value)}
          />
        </div>
      );
    }
    if (operator === 'isnull' || operator === 'isnotnull') {
      const displayvalue = operator === 'isnull' ? 'Empty (is null)' : 'Not empty (is not null)';
      return <Input style={{ width: '380px' }} value={displayvalue} />;
    }

    return null;
  };

  const { fieldnode, remove, color } = props;

  return (
    <div style={{ marginBottom: '23px', width: '100%' }}>
      <FilterOperators
        header={fieldnode.header}
        operator={operator}
        filterOption={filterOptions}
        RemoveAction={remove}
        RemoveActionValue={fieldnode.data}
        ChangeOperator={setOperator}
        color={color}
      />

      {DisplayDateControl()}
    </div>
  );
};

DateFilter.propTypes = {
  uitype: PropTypes.number.isRequired,
  filter: PropTypes.object.isRequired,
  UpdateFilter: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  fieldnode: PropTypes.object.isRequired,
};

export default DateFilter;
