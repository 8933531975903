/* eslint-disable */
/** @jsxImportSource @emotion/react */
import React, { Component } from 'react';

import PropTypes from 'prop-types';
import {
  Input,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Loader,
  Label,
  Button,
  Dimmer,
} from 'semantic-ui-react';
import Icon from '@mdi/react';
import {
  mdiRectangle,
  mdiCardTextOutline,
  mdiArrowDownDropCircle,
  mdiFormatListBulletedSquare,
  mdiTransitConnectionVariant,
mdiFunctionVariant,
mdiCheckboxOutline,
  mdiNumeric,
  mdiCalendar,
  mdiEmail,
  mdiPhone,
  mdiChat,
  mdiLink,
  mdiBinoculars,
  mdiAccount,
  mdiFormatTextbox,
  mdiCounter,
  mdiAttachment,
  mdiIdentifier,
  mdiAlert,
  mdiAccountOutline,
  mdiPencilBox,
  mdiEyeOff
} from '@mdi/js';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { toast } from 'react-toastify';
import OptionSelect from './OptionSelect';
import RelationFieldSelector from './RelationFieldSelector';
import LookupField from './LookupField';
import { getTableAPI,  checkFieldIsReferenced, checkIfNestedField } from '../../../../api/tables';
import { FieldHasData } from '../../../../api/GetData';
import DateField from './DateField';
import CheckBoxField from './CheckBoxField';
import NumberField from './NumberField';
import ShortText from './ShortText';
import EmailField from './EmailField';
import PhoneField from './PhoneField';
import MessageField from './MessageField';
import CounterField from './CounterField';
import AttachmentField from './AttachmentField';
import CollaboratorField from './CollaborationField';
import { AccountContext } from '../../../../providers/AccountProvider';
import Spinner from '../../../../components/Spinner/Spinner';
import TextButton from '../../../../components/TextButton/TextButton';
import GBButton from '../../../../components/GBButton/GBButton';
import GBConfirm from '../../../../components/GBConfirm/GBConfirm';
import UserFields from './UserFields';
import cloudrun from '../../../../clients/httpcloudrun';
import GetConversionFieldWarning from './ConversionFieldWarning';
import {removeFieldByFieldChange} from '../../../../api/tables';
import MaskedField from './MaskedField';
import Formula from './Formula';
import Global from '../../../../clients/global'

/*
{ key: '1', value: '1', icon: 'linkify', text: 'Link to another record' },
{ key: '2', value: '2', icon: 'font', text: 'Single line text' },
{ key: '3', value: '3', icon: 'content', text: 'Long text' },
{ key: '4', value: '4', icon: 'attach', text: 'Attachment' },
{ key: '5', value: '5', icon: 'check square', text: 'Check Box' },
{ key: '6', value: '6', icon: 'options', text: 'Multiple Select' },
{ key: '7', value: '7', icon: 'chevron circle down', text: 'Single Select' },
{ key: '8', value: '8', icon: 'user', text: 'Collaborator' },
{ key: '9', value: '9', icon: 'phone', text: 'Phone number' },
{ key: '10', value: '10', icon: 'mail', text: 'Email' },
{ key: '11', value: '11', icon: 'world', text: 'URL' },
{ key: '12', value: '12', icon: 'message', text: 'Message Thread' }, MEESAGE THREAD
{ key: '13', value: '13', icon: 'rtf', text: 'RTF' },  
{ key: '14', value: '14', icon: 'time', text: 'Masked' },
{ key: '15', value: '15', icon: 'star', text: 'Rating' },
{ key: '16', value: '16', icon: 'code', text: 'Function' },
{ key: '17', value: '17', icon: 'plus circle', text: 'Counter' },
{ key: '18', value: '18', icon: 'staylinked', text: 'Lookup' }
{ key: '19', value: '19', icon: 'clock outline', text: 'Create time' },
{ key: '20', value: '20', icon: 'clock outline', text: 'Last modified time' },
{ key: '21', value: '21', icon: 'sort numeric down', text: 'ID' }, // NOT USED ANYMORE
{ key: '22', value: '22', icon: 'Calendar', text: 'Date' },
{ key: '23', value: '23', icon: 'Calendar', text: 'Number' },
{ key: '24', value: '24', icon: 'Calendar', text: 'Added By' },
{ key: '25', value: '25', icon: 'Calendar', text: 'Updated By' },
{ key: '26', value: '26', icon: 'Calendar', text: 'BlockUser' },
{ key: '27', value: '27', icon: 'Calendar', text: 'Block' },
{ key: '30', value: '30', icon: 'Calendar', text: 'BlockUser' }, 10-7-2020 adding a control for "users" table that allows block/user configuration
{ key: '31', value: '31', icon: 'content', text: 'Collaboration Notes' }, 8-11-23 Added to support the filtering of collaboration nodes. This is not a column that can be displayed (at least not yet)
{ key: '32', value: '32', icon: 'content', text: 'Record identifer' }, 8-28-23 Added to support the filtering of Record Identifier (CUSTOMID). I've specified a different UItype to keep logic separate from real fields in building query.
{ key: '33', value: '33', icon: 'content', text: 'Email messages' }, 9-11-23 Adding support for filtering by email messages. This will be similiar to text messaging, but doesn't have column control. A separate uicontrol will make it cleaner to filter.
*/

class ColumnConfig extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.input = React.createRef();

    this.state = {
      selectvalue: 0,
      header: '',
      columnConfigData: {},
      showUnique: false,
      makeUnique: false,
      isValid: true,
      tablename: '',
      initialMakeUnique: false,
      tableinfo: {},
      fieldtype: 0,
      isLoading: false,
      isDone: false,
      isSourceCustomID: false,
      warningMessageID: 0,
      hasData: true,
      FieldOptions: [
        {
          key: '2',
          value: 2,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiFormatTextbox}
              size="20px"
            />
          ),
          text: 'Short text',
        },
        {
          key: '3',
          value: 3,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiCardTextOutline}
              size="20px"
            />
          ),
          text: 'Long text',
        },
        {
          key: '14',
          value: 14,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiEyeOff}
              size="20px"
            />
          ),
          text: 'Masked text',
        },
        {
          key: '13',
          value: 13,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiPencilBox}
              size="20px"
            />
          ),
          text: 'RTF Editor',
        },
        {
          key: '7',
          value: 7,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiArrowDownDropCircle}
              size="20px"
            />
          ),
          text: 'Single select',
        },
        {
          key: '6',
          value: 6,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiFormatListBulletedSquare}
              size="20px"
            />
          ),
          text: 'Multiple select',
        },
        { key: '4', value: 4, icon: 'attach', icon: (
          <Icon
            style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
            path={mdiAttachment}
            size="20px"
          />
        ), text: 'Attachments' },
        
        // { key: '7', value: 7, icon: 'chevron circle down', text: 'Single Select' },
        {
          key: '1',
          value: 1,
          icon: (
            <Icon
              style={{
                padding: '0px',
                marginRight: '5px',
                marginBottom: '3px',
                color: this.props.color,
              }}
              path={mdiTransitConnectionVariant}
              size="20px"
            />
          ),
          text: 'Relational',
        },
        {
          key: '18',
          value: 18,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiBinoculars}
              size="20px"
            />
          ),
          text: 'Lookup',
        },
        {
          key: '23',
          value: 23,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiNumeric}
              size="20px"
            />
          ),
          text: 'Number',
        }, // decimal
        {
          key: '22',
          value: 22,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiCalendar}
              size="20px"
            />
          ),
          text: 'Date',
        }, // timestamp
        {
          key: '17',
          value: 17,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiCounter}
              size="20px"
            />
          ),
          text: 'Counter',
        },
        {
          key: '9',
          value: 9,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiPhone}
              size="20px"
            />
          ),
          text: 'Phone number',
        },
        {
          key: '12',
          value: 12,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiChat}
              size="20px"
            />
          ),
          text: 'Messaging thread',
        },
        {
          key: '10',
          value: 10,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiEmail}
              size="20px"
            />
          ),
          text: 'Email',
        },
        {
          key: '11',
          value: 11,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiLink}
              size="20px"
            />
          ),
          text: 'URL',
        },
        {
          key: '5',
          value: 5,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiCheckboxOutline}
              size="20px"
            />
          ),
          text: 'Checkbox',
        },
        { key: '8', value: 8, icon: 'attach', icon: (
          <Icon
            style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
            path={mdiAccountOutline}
            size="20px"
          />
        ), text: 'Collaborator' },
        { key: '16', value: 16, icon: 'attach', icon: (
          <Icon
            style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
            path={mdiFunctionVariant}
            size="20px"
          />
        ), text: 'Formula' },

        // bool
        // { key: '15', value: 15, icon: 'star', text: 'Rating' }, // phase 2
        // { key: '16', value: 16, icon: 'code', text: 'Function' }, // hase 2
        // { key: '17', value: 17, icon: 'plus circle', text: 'Count' }, // phase 2?
        // { key: '21', value: 21, icon: 'sort numeric down', text: 'ID' },

        // 5-19-2020 I am defining some new "user type" fields which will not be
        // directly accessible via UI, but will be used in system defined tables user as BlockUsers, Zoneusers, etc
        // The reason for defining custom types is to provide a way to process these special types of filters, etc.

        // key:26 type =BlockUser. //This is used to show users within a block, allow users to filter by block-users
        // and restrict list of users for a block to those who have not been assigned.

        {
          key: '19',
          value: 19,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiCalendar}
              size="20px"
            />
          ),
          text: 'Added on',
        },
        {
          key: '20',
          value: 20,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiCalendar}
              size="20px"
            />
          ),
          text: 'Updated on',
        },
        {
          key: '24',
          value: 24,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiAccount}
              size="20px"
            />
          ),
          text: 'Added by',
        },
        {
          key: '25',
          value: 25,
          icon: (
            <Icon
              style={{ marginRight: '5px', marginBottom: '3px', color: this.props.color }}
              path={mdiAccount}
              size="20px"
            />
          ),
          text: 'Updated by',
        },
      ],
    };
  }

  // We need following parameters
  // 1. tableid
  // 2. fieldname (data node value)

  static contextType = AccountContext;

  async componentDidMount() {
    const { colIndex,duplicateField } = this.props;
    const { FieldOptions } = this.state;

    await this.FetchFieldInfo();
  }

  handleToggle = () => {
    const { makeUnique } = this.state;
    this.setState({ makeUnique: !makeUnique });
  };

  // 7-3-2020 Adding this method so that specific controls can bubble up a warning message
  // based on attributes set within the field. First use-case is for the RelationalSelector.
  // If they toggle the multiple select to single for either source/destination, we need to show
  // warning message that all data weill be deleted. This would be only from going from multiple to single.
  updateWarningMessage = (messageID) => {
    this.setState({ warningMessageID: messageID });
  };

  confirmAction = () => {
    const { color } = this.props;
    const { warningMessageID,selectvalue,columnConfigData,FieldOptions } = this.state;
    const fromField = FieldOptions.filter(itm=>itm.value===columnConfigData.uitype)[0].text
    const toField = FieldOptions.filter(itm=>itm.value===selectvalue)[0].text
    
    let message='';
    if(warningMessageID===1) message = 'If you continue, all data currently in the field that does not match the converted to field type will be abolished.';
    if(warningMessageID===2) message = 'If you continue, all data currently in this field will be abolished.';
    if(warningMessageID===3) message = 'If you continue, data may be truncated, and all data currently in the field that does not match the converted to field type will be abolished.';
    if(warningMessageID===4) message = 'If you continue, the data that is invalid for the converted to field type will appear in red and should be corrected.';


    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <GBConfirm
            title="Edit field warning"
            action={this.SaveUpdates}
            buttonLabel="OK"
            message={message}
            // width="500px"
            divRef={this.input}
            showInput={true}
            confirmAction=""
            confirmWord="continue"
            height="430px"
            onClose={onClose}
          />
        );
      },
    });
  };

  AnimatedLoadingDelay = async (modalIsOpen) => {
    this.setState({ isDone: true });
    setTimeout(() => {
      this.setState({ isLoading: false, modalIsOpen });
    }, 1000);
  };

  FetchFieldInfo = async () => {
    const { tableid, field, header, colIndex,duplicateField } = this.props;
    const { FieldOptions } = this.state;
    const { tableinfo, name } = await getTableAPI(tableid);
    let newlist = FieldOptions;
    let dupField=null;

    if (tableinfo !== null) {
      const rowIndex =  tableinfo && tableinfo.columns && tableinfo.columns.findIndex((el) => el.data === field);
      if(duplicateField !=null) {
        const dupIndex =  tableinfo && tableinfo.columns && tableinfo.columns.findIndex((el) => el.data === duplicateField);
        if(dupIndex !==-1) {
          dupField = tableinfo.columns[dupIndex];
        }
      }

      const hasRelationalFields = tableinfo.columns.findIndex((el) => el.uitype === 1);

      if (rowIndex !== -1) {
        const columnData = tableinfo.columns[rowIndex];
        const fieldtype = columnData.uitype;
        const firstColumnFieldstoExclude = [8, 12, 18, 4, 5, 22, 19, 20, 24, 25];

        // 7-2-2020 we are not supporting the "ID" field type anymore, however
        // the first column must still be one of the allowed types.

        // if (colIndex === 1) {
        //   newlist = FieldOptions.filter((el) => !firstColumnFieldstoExclude.includes(el.value));
        //   console.log(newlist)
        // }

        // 7-5--2020 if table has no relational fields, don't show lookup field option.
        if (hasRelationalFields === -1) {
          newlist = newlist.filter((el) => el.value !== 18);
        }

        // Remove standard columns from list if they are already configured for table.
        // We only do this if uitype=2,3, which for now are the only "starter" fields
        // in which we let you change the field type. If uitype = 2,3 , then field
        // is already set and we need full ist so it appears and will be in disabled mode.
        // 7-14-2020 added message field type to list (12)

        if (columnData.uitype === 2 || columnData.uitype === 3) {
          const stdfields = [19, 20, 24, 25, 12];
          const existingStandardFields = tableinfo.columns.filter((el) =>
            stdfields.includes(el.uitype),
          );
          existingStandardFields.forEach((itm) => {
            newlist = newlist.filter((el) => el.value !== itm.uitype);
          });
        }

        // 5-8-2020. For a given table, we need to know if the first column (field_1) is using
        // a Custom ID column or regular column . This is used when converting to a Relation type field
        // and this param will add a "IsCustom" attribute to source node of relationship table which
        // ultimate is used to create correct graph query to retrieve the CustomID{fullname} value from view.

        const isSourceCustomID = tableinfo.columns[1].displayfield === 'CustomID';

        // if they choose first column, we remove field types that aren't valid to use
        this.setState({
          showUnique: colIndex === 1,
          FieldOptions: newlist,
          tablename: name,
          selectvalue: fieldtype,
          columnConfigData: columnData,
          header,
          tableinfo,
          makeUnique: columnData.isUnique,
          initialMakeUnique: columnData.isUnique,
          fieldtype,
          isSourceCustomID,
        }, async () => {
            if(dupField !==null) {
              console.log('change field type')
              await this.showResults(dupField.uitype);
            }
        });
      }
    }
  };

  showResults = async (newitem) => {

    const { columnConfigData } = this.state;
    const { userInfo} = this.context;
    const { tableid, field,ShowError } = this.props;
   

   // 2-8-2021 Check if field is referenced / is lookup in any other tables. If yes, we don't
    // allow them to change type
    if(newitem !== columnConfigData.uitype) {
      const lookupfields= await checkFieldIsReferenced(field,tableid)
      if(lookupfields.length >0) {
        let fields=
        lookupfields.map(el=>(
         <ul>{el.name}: {el.header}</ul>
        ))

        toast.info(<div style={{marginRight:'10px'}}>This field is used in a Lookup field elsewhere. It can not be edited, but it can be deleted. If editing is necessary, you must first delete the following lookup field(s):<p>{fields}</p></div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: false,
        });

        // ShowError(<div>This field is used in a Lookup field elsewhere. It can not be edited, but it can be deleted. If editing is necessary, you must first delete the following lookup field(s):<p>{fields}</p></div>)
        return false
      }

      if(userInfo.plan==='Free' && newitem===13) {
        toast.info(
          <div style={{ margin: '5px' }}>
           To use the RTF field type, you must upgrade to a paid plan. 
           <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
          },
        );
        return false;
      }

      if((userInfo.plan==='Free' || userInfo.plan==='Starter') && newitem===14) {
        toast.info(
          <div style={{ margin: '5px' }}>
           To use the masked field type, you must upgrade to the pro plan. 
           <a style={{color:'white', textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/how-to-upgrade" target="_newwindow"> Learn more.</a>
          </div>,
          {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: false,
            // closeButton: false,
          },
        );
        return false;
      }

   /* 8-3-22 If user is changing field type to a field type that is not supported for a nested
    field type in a relational definition, we need to see if the field is used in any
    nestedfield relational fields. IF yes, they cannot change the field type until they 
    remove the field from the nested field definition.  We run this check here before we 
    invoke the child component .save() method. 

    Note for relational fields and collaboration fields, we need to check within the field logic
    if they are trying to move from a single to multi-select...we cannot let that happen either.
    */

    const nestedFields= await checkIfNestedField(field,tableid);
    if(nestedFields.length >0) {
      let fields=
      nestedFields.map(el=>(
        <ul>{el.name}: {el.header}</ul>
      ))

      toast.info(<div style={{marginRight:'10px'}}>This field is used in a relational nested fields. It can not be changed/deleted until you remove it from the nested fields in the following relational field(s):<p>{fields}</p></div>, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: false,
      });

      // ShowError(<div>This field is used in a Lookup field elsewhere. It can not be edited, but it can be deleted. If editing is necessary, you must first delete the following lookup field(s):<p>{fields}</p></div>)
      return false
    }


    }

    // 6-24-2020 Implementing showing specific warning message based on converting from one field type to another.
    
    const messageID = GetConversionFieldWarning(columnConfigData.uitype, newitem);

    // 7-2-2020
    // When user changes field, we want to check if there is any data in the column.
    // if there isn't any data, we won't show any warning message about losing data.
    // if there is data, we use normal warning message.
    
    const schema = `schema_${Global.zoneid}`;
    const hasData = await FieldHasData(schema, tableid, field,columnConfigData.uitype);
    this.setState({ selectvalue: newitem, warningMessageID: messageID, hasData });
  };

  SaveUpdates = async () => {
    const { warningMessageID, tableinfo, header, selectvalue,columnConfigData} = this.state;

    const currentField= {...columnConfigData};

    if (this.input.current && this.input.current.value !== 'continue' && warningMessageID !== 0) {
      return false;
    }


    if(header !==columnConfigData.header) {
      const idx = tableinfo.columns.findIndex(el=>el.header===header);
      if(idx !==-1) {
        toast.info(<div style={{margin:'10px'}}>
       The field name must be unique in the tab.
      </div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 30000,
        });
        return false;
      }
    }


    let isValid = true;

    /*4-24-23 Because the formula field is an input field and if there is an error,
    the UI would refresh and lose the formula. So, here we don't use the loading page
    so that if an error occurs, it sticks on the page and shows the toast message. 
    We will need to see if performance to create the field is too long and will need to 
    figure out how to show loading icon and still maintain state on the component. 
    */
    if(selectvalue !==16) {
      this.setState({ isLoading: true, isDone: false });
    }

    const { field, tableid, Rename, ShowError, Refresh } = this.props;
    const mychild = this.child.current;

    // 8-14-2020 if the field being updated was part of the record identifier (concat attribute exists) (therefore used in View construction)
    // we need to update the CustomID view to remove this field...so that it can be altered/updated.
    // 7-19-222 changed to use "currentfield"
    if (currentField.concat !== undefined && selectvalue !== currentField.uitype) {
      const payload = { tableid, tableinfo, field };
      await cloudrun.post('/updateCustomIdView', { payload });
      
      // 12-1-2021 Logic to notify user that relational identifer is changed and to review
      // if they want to update to new settings.
      // Logic goes here. 

        toast.info(<div style={{margin:'10px'}}>
        🔔Good job building! IMPORTANT NOTE: With this more recent change, you have altered the "record identifier" for this tab. As a result, you will want to update how you will refer to records in this tab. <a style={{color:'white',textDecoration:'underline'}} href="https://www.graceblocks.com/support-article/managing-tabs" target="_newWindow">Please see this help article </a>(in particular, review Modifying tabs, step 2B) to learn more.
      </div>, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 30000,
        });
    }

 
    // Each field component implements their own "Save" method. We call it from this parent component.
    // Each field component is responsible for saving itself, updating field types,etc

    if (isValid) {
     
      if (mychild) {
        const result = await mychild.Save();
        if (result !== 'success') {
          await this.AnimatedLoadingDelay(true);
          ShowError(result);
          return "error";
        }
      }


     

      // 5-12-2020
      // to be safe, if you change the field type, we will do a full refresh of the grid.
      // 6-21-22 changed to copy of columnConfigData, as it was changing after saving child object.
      // this now correctly keeps difference between field start/end type.
      if (currentField.uitype !== selectvalue) {
        
        // 5-8-201 New method which will remove this field from 
        // any user's custom filters/bookmark filters and any forms associated with this table.
        // The field will remain in their tableusers fields and bookmark fields.

        // 6-21-2022 we also deactivate any shares that use a bookmark that uses this field
        // we delete all special fields that would be referenced by this field.
        await removeFieldByFieldChange(tableid,field);


        await Refresh();
      } else {
        // this chnages column header on grid, without needing to refresh all metadata.
        await Rename(field, header, selectvalue);
      }

      await this.AnimatedLoadingDelay(false);
    }
    return 'error';
  };



  RenderFieldConfig = () => {
    const {
      selectvalue,
      columnConfigData,
      tableinfo,
      header,
      tablename,
      isSourceCustomID,
      hasData,
    } = this.state;
    const { tableid, field, color, updateHeight,ShowError,blockid,role } = this.props;
    const { userInfo} = this.context;
    const currentZone=userInfo.zones.filter(itm=>itm.id===parseInt(Global.zoneid))[0];
    // console.log(currentZone)

    if (selectvalue === 7 || selectvalue === 6) {
      return (
        <OptionSelect
          ref={this.child}
          hasData={hasData}
          color={color}
          updateHeight={updateHeight}
          tableid={tableid}
          ShowError={ShowError}
          uitype={selectvalue}
          columnConfigData={columnConfigData}
          header={header}
        />
      );
    }
    if (selectvalue === 1) {
      return (
        <RelationFieldSelector
          ref={this.child}
          tableid={tableid}
          tableinfo={tableinfo}
          color={color}
          isSourceCustomID={isSourceCustomID} // We need to know both source/relationship table if they have customID or just use field_1
          columnConfigData={columnConfigData}
          tablename={tablename}
          header={header}
          updateWarningMessage={this.updateWarningMessage}
          plan={currentZone.plan}
          blockid={blockid}
        />
      );
    }
    if (selectvalue === 18) {
      return (
        <LookupField
          ref={this.child}
          color={color}
          tableinfo={tableinfo}
          updateHeight={updateHeight}
          tableid={tableid}
          columnconfigdata={columnConfigData}
          header={header}
          blockid={blockid}
        />
      );
    }
    if (selectvalue === 22 || selectvalue === 19 || selectvalue === 20) {
      return (
        <DateField
          ref={this.child}
          color={color}
          updateHeight={updateHeight}
          tableid={tableid}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
          uitype={selectvalue}
        />
      );
    }
    if (selectvalue === 5) {
      return (
        <CheckBoxField
          ref={this.child}
          color={color}
          tableid={tableid}
          columnConfigData={columnConfigData}
          field={field}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 23) {
      return (
        <NumberField
          ref={this.child}
          color={color}
          updateHeight={updateHeight}
          tableid={tableid}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 17) {
      return (
        <CounterField
          ref={this.child}
          color={color}
          updateHeight={updateHeight}
          tableid={tableid}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 2 || selectvalue === 3 || selectvalue===13) {
      return (
        <ShortText
          ref={this.child}
          color={color}
          tableid={tableid}
          updateHeight={updateHeight}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 14) {
      return (
        <MaskedField
          ref={this.child}
          color={color}
          tableid={tableid}
          updateHeight={updateHeight}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }

    if (selectvalue === 16) {
      return (
        <Formula
          ref={this.child}
          color={color}
          tableid={tableid}
          updateHeight={updateHeight}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }

    if (selectvalue === 24 || selectvalue === 25) {
      return (
        <UserFields
          ref={this.child}
          color={color}
          tableid={tableid}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 10 || selectvalue === 11) {
      return (
        <EmailField
          ref={this.child}
          color={color}
          tableid={tableid}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 12) {
      return (
        <MessageField
          ref={this.child}
          color={color}
          uitype={selectvalue}
          tableid={tableid}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          updateHeight={updateHeight}
          header={header}
          role={role}
        />
      );
    }
    if (selectvalue === 9) {
      // eslint-disable-next-line react/destructuring-assignment
      return (
        <PhoneField
          ref={this.child}
          color={color}
          tableid={tableid}
          updateHeight={updateHeight}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
        />
      );
    }
    if (selectvalue === 4) {
      return (
        <AttachmentField
          ref={this.child}
          hasData={hasData}
          color={color}
          updateHeight={updateHeight}
          tableid={tableid}
          ShowError={ShowError}
          uitype={selectvalue}
          columnConfigData={columnConfigData}
          header={header}
        />
      );
    }
    if (selectvalue === 8) {
      return (
        <CollaboratorField
          ref={this.child}
          color={color}
          tableid={tableid}
          blockid={blockid}
          selectedUIType={selectvalue}
          field={field}
          columnConfigData={columnConfigData}
          tableinfo={tableinfo}
          header={header}
          plan={currentZone.plan}
          />
      );
    }
    return null;
  };

  handleFocus = (event) => event.target.select();  

  render() {
    const {
      isLoading,
      isDone,
      header,
      selectvalue,
      FieldOptions,
      showUnique,
      makeUnique,
      isValid,
      fieldtype,
      isSourceCustomID,
      warningMessageID,
      hasData,
    } = this.state;
    const { close, color, colIndex } = this.props;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            backgroundColor: color,
            height: '40px',
            marginBottom: '10px',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginLeft: '20px', color: 'white',fontSize:'15px' }}>Edit field properties</div>
        </div>
        <div>
          <Grid style={{ margin: '10px', marginLeft: '20px', marginRight: '20px' }}>
            <GridColumn>
              {isLoading ? (
                <div>
                  <Spinner isLoading={isLoading} isDone={isDone} color={color} />
                </div>
              ) : (
                <>
                  <GridRow>
                    <div style={{ width: '100%' }}>
                      <div style={{ fontSize: '13px', marginBottom: '3px' }}>
                        <strong>Field name</strong>
                      </div>
                      <Input
                        value={header}
                        fluid
                      
                        onFocus={this.handleFocus}
                        onChange={(e, data) => this.setState({ header: data.value })}
                      />
                    </div>
                  </GridRow>

                  <GridRow style={{ marginTop: '10px' }}>
                    <div style={{ fontSize: '13px', marginBottom: '3px' }}>
                      <strong>Field type</strong>
                    </div>
                    <Dropdown
                      placeholder="Choose Field Type"
                      value={selectvalue}
                      fluid
                      search
                      disabled={
                        fieldtype === 25 ||
                        fieldtype === 24 ||
                        fieldtype === 1 ||
                        fieldtype === 18 ||
                        fieldtype === 19 ||
                        fieldtype === 20 ||
                        fieldtype === 12 ||
                        fieldtype===4 ||
                        fieldtype===8 || 
                        fieldtype===13 || 
                        fieldtype===16
                      }
                      selection
                      options={FieldOptions}
                      onChange={(e, data) => this.showResults(data.value)}
                    />
                  </GridRow>
                  {/* <GridRow>
                    {showUnique && (!isSourceCustomID || selectvalue !== 21) ? (
                      <GBSwitch
                        color={color}
                        text="Make Unique"
                        isChecked={makeUnique !== undefined ? makeUnique : false}
                        Action={this.handleToggle}
                      />
                    ) : null}
                  </GridRow> */}
                  <GridRow>
                    {!isValid ? (
                      <Label>
                        You must first remove any duplicate entries before you can make unique
                      </Label>
                    ) : null}
                  </GridRow>
                  <GridRow>{this.RenderFieldConfig()}</GridRow>
                  <GridColumn width={16} textAlign="right">
                    <GridRow
                      style={{
                        marginTop: '10px',
                        marginRight: '20px',
                        justifyContent: 'flex-end',
                        display: 'flex',
                      }}
                    >
                      <TextButton text="Cancel" textColor="black" Action={close} hoverColor="#75757580" fontSize="13px" />
                      <div style={{width:'10px'}}></div>
                      <GBButton
                        text="Save"
                        fontSize="13px"
                        Action={
                          warningMessageID === 0 || !hasData
                            ? () => this.SaveUpdates()
                            : () => this.confirmAction()
                        }
                        color={color}
                        width="75px"
                      />
                    </GridRow>
                  </GridColumn>
                </>
              )}
            </GridColumn>
          </Grid>
        </div>
      </div>
    );
  }
}

ColumnConfig.propTypes = {
  colIndex: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  Rename: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
};
export default ColumnConfig;
