/* eslint-disable */
import "ace-builds/src-noconflict/mode-java"; // Existing import
import "ace-builds/src-noconflict/mode-javascript"; // Import JavaScript mode

import {functions} from './functions'

function createCustomHighlightRules(fields) {

  class CustomHighlightRules extends window.ace.acequire(
    "ace/mode/javascript_highlight_rules"
  ).JavaScriptHighlightRules {
    constructor() {
      super();
      const keywords =['DISTINCT','WHEN','THEN','END','ELSE','NULL'];
      this.$rules = {
        start: [
          {
            token: "variable",
            regex: /{{[^}]+}}/ 
            // regex: /{[^{}]*}/
            },
          {
            token: "keyword",
            regex: '\\b(if|else|for|while|function|return|var|let|const|this|new|try|catch|switch|case|break|continue|default|throw|void|class|extends|super|import|export|from|as|yield|await|null|true|false|undefined|NaN|Infinity)\\b' 
          },
          {
            token: "support.function",
            regex: '\\b(toLowerCase()|toUpperCase())\\b' 
          },
          {
            token: "string",
            regex: "'[^']*'"
          },
          {
            token:"constant.numeric",
            regex: /\b(?:\d+(?:\.\d+)?|0x[\da-fA-F]+)\b/ 
          },
          {
            token: 'constant.language.boolean',
            regex: /\b(true|false)\b/
          }
        ]
      };
    }
  }
  return CustomHighlightRules;
}

export default class JavaScriptMode extends window.ace.acequire("ace/mode/java").Mode {
  constructor(fields) {
    super();
    const CustomHighlightRules = createCustomHighlightRules(fields);
    this.HighlightRules = CustomHighlightRules;
  }
}