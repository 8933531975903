/* eslint-disable */
import React, { useEffect, useState,useContext } from 'react';
import cloudrun from '../../../../../clients/httpcloudrun';
import { AccountContext } from '../../../../../providers/AccountProvider';
import { toast } from 'react-toastify';
import LeftNav from './LeftNav';
import RightNav from './RightNav';
import NewMessage from './NewMessage';
import EmailControl from '../../MessageView/EmailControl';
import Global from '../../../../../clients/global'


const MessagesView = ({ data, tableinfo, color, tableid, role,useSecurity,blockid,showBookmarkBar,loadData,recordCount,isExpanded }) => {
  const { userInfo } = useContext(AccountContext);
  const [isLoading, setIsLoading] =useState(false);
  const [messageField, setMessageField] = useState(null);
  const [messageNode, setMessageNode] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(data[0]);
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [showCompose,setShowCompose] =useState(false);
  const [showText, setShowText] =useState(false);
  const [emailNode,setEmaiNode] =useState(null);
  const [selectedRecords, setSelectedRecords] = useState(null);

  const dataItem = {
    tableid: tableid,
    id: data[0],
    selectedRow: currentRecord,
    tableinfo: tableinfo,
    hasMessages: true,
  };

  useEffect(() => {
    setIsLoading(true);
    const textIdx = tableinfo.columns.findIndex(col=>col.uitype===12);
    const emailIdx = tableinfo.columns.findIndex(col=>col.uitype===10 && col.enableEmailMessages);
    if(textIdx !==-1) {
       setMessageField(tableinfo.columns[textIdx].data);
       setMessageNode(tableinfo.columns[textIdx]);
    }

    if(emailIdx !==-1) {
      setEmaiNode(tableinfo.columns[emailIdx]);
    }

    updateSelectedRecord(data[0])
    setIsLoading(false);

  }, [data,tableid]);

  const composeEmail = (records) =>{
    setSelectedRecords(records)
    setShowCompose(true);
  }

  const updateSelectedRecord =(record) =>{
    let canEdit = true;
    if (useSecurity) {
      canEdit = checkRowSecurity(record);
    }
    record.canEdit = canEdit;
    setCurrentRecord(record);
  }

  const checkRowSecurity = (record) => {
    let canEdit = false;

    if (tableinfo.security.editOthersRecord <= role) {
      const collabFields = tableinfo.columns.filter(
        (el) => el.uitype === 8 || (el.source && el.source.lookupuitype === 8),
      );
      collabFields.forEach((itm) => {
        if (record[itm.data] !== null) {
          const idx = record[itm.data].findIndex((el) => el.userid === userInfo.id);
          if (idx !== -1) canEdit = true;
        }
      });
      if (record.addedby.userid === userInfo.id) canEdit = true;
    } else {
      if (record.addedby.userid === userInfo.id) canEdit = true;
    }
    return canEdit;
  };

  const composeText = (records) =>{
    setSelectedRecords(records);
    setShowText(true);
  }

  const ConvertDelayIntoSeconds = (messageSetting) => {
    let seconds = 0;
    if (messageSetting.interval === 'minutes') {
      return messageSetting.number * 60;
    }
    if (messageSetting.interval === 'hours') {
      return messageSetting.number * 3600;
    }
    if (messageSetting.interval === 'days') {
      return messageSetting.number * 86400;
    }
  };

   //7-13-23 
   //I added the additional 4 params of templateFields,ids,blockRoles, and role for the use-case
   //where we are in multi-email composer and they selecte a template. In this mode, when sending
   //email, it needs these additional params in order to construct the merged content prior to sending.
   //with single email, these fields will not be passed in.
  const sendMessage = async (emailObjectArray,messageSetting,templateFields,ids,blockRoles,role) =>{

    // 1-28-2021 adding zoneRole for business logic in send message (if out of funds, different message based on role/owner or user)
    const zoneRole = userInfo.zones.filter((itm) => itm.id === parseInt(Global.zoneid))[0].role;
    let result = '';

    const payload = {
      tableid,
      emailObjectArray,
      zoneRole,
      schedule: messageSetting.interval === 'none' ? null : ConvertDelayIntoSeconds(messageSetting),
      templateFields,
      ids,
      blockRoles,
      role,
      replyConfiguration: emailNode.replyConfiguration
    };

    result = await cloudrun.post('/sendEmailApi', { payload });
    // result = await cloudrun.post('/sendEmailTest', { payload });

    // Put error message in Toast.
    if (result !== 'success') {
      const errormessage = (
        <div>
          Oops, something went wrong. Issue(s) identified:
          {result.map((el) => (
            <ul>
              <li>
                {el.error === 'invalidFunds' ? (
                  <div>
                    This Zone has depleted all available messaging funds. To continue sending
                    messages, a Zone owner will need to purchase more funds under Zone settings
                    (gear icon in left panel of the GraceBlocks Homepage).
                  </div>
                ) : null}
                {el.error === 'nofunds' ? <div>{el.message}</div> : null}
              </li>
            </ul>
          ))}
        </div>
      );
      toast.info(errormessage, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 10000,
      });
    }
    return result;
  }

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        height: !showBookmarkBar ? 'calc(100vh - 160px)' :  'calc(100vh - 185px)',
        width: '100%',
        border: '1px solid #eee',
        flexDirection: 'row',
      }}
    >
    

      <LeftNav
        data={data}
        messageField={messageField}
        messageNode={messageNode}
        emailNode={emailNode}
        color={color}
        setCurrentRecord={updateSelectedRecord}
        tableinfo={tableinfo}
        useSecurity={useSecurity}
        role={role}
        loadData={loadData}
        recordCount={recordCount}
        composeEmail={composeEmail}
        composeText={composeText}
        tableid={tableid}
      />

  
      <RightNav
            color={color}
            id={currentRecord.id}
            currentRecord={currentRecord}
            tableid={tableid}
            tableinfo={tableinfo}
            role={role}
            blockid={blockid}
            isExpanded={isExpanded}
          />

      {showText ? (
        <div
          style={{
            display: 'flex',
            backgroundColor: 'white',
            position: 'absolute',
            top: '0px',
            left: '10px',
            right: '50px',
            zIndex: 100,
          }}
        >
          <NewMessage 
             tableid={tableid}
             close={() => setShowText(false)}
             selectedRecords={selectedRecords}
             color={color}
             messageNode={messageNode}
             role={role}
          />
          {/* <SendTextMessages
           selectedRecords={selectedRecords}
           tableid={tableid}
           color={color}
           close={() => setShowText(false)}
           messageNode={messageNode}
          /> */}
          {/* <NewMessage
            tableid={tableid}
            data={data.filter((itm) => itm[messageNode.phonefield] !== null)}
            close={setIsNewMessage}
            color={color}
            messageNode={messageNode}
          /> */}
        </div>
      ) : null}

      {showCompose ? (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 100,
              backgroundColor: 'white',
              width: '80%',
              height: 'calc(100vh - 100px)',
              border: '1px solid #aaa',
              boxShadow: '0px 3px 6px #707070',
              borderRadius: '10px',
            }}
          >
            <EmailControl
              tableid={tableid}
              emailRecords={selectedRecords}
              emailField={emailNode}
              color={color}
              sendMessage={sendMessage}
              close={() => setShowCompose(false)}
              role={role}
            />
          </div>
        ) : null}

    </div>
  );
};

export default MessagesView;
