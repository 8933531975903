/* eslint-disable */
import React, { useCallback, useEffect, useState,useRef } from 'react';
import Global from '../../clients/global';
import { confirmAlert } from 'react-confirm-alert';
import Icon from '@mdi/react';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import { Input, Dropdown } from 'semantic-ui-react';
import { mdiGateNor, mdiTrashCan, mdiPencil,mdiCheckCircleOutline } from '@mdi/js';
import GBButton from '../../components/GBButton/GBButton';
import TextButton from '../../components/TextButton/TextButton';
import GBConfirm from '../../components/GBConfirm/GBConfirm';
import EventSettings from './EventSettings';
import ActionNewRecord from './Action_NewRecord';
import FilterConditions from './FilterConditions';
import ActionSchedule from './ActionSchedule';
import ActionDeleteRecord from './Action_DeleteRecord';
import ActionFormTask from './Action_FormTask';
import ActionSendMessage from './Action_SendMessage';
import ActionAddorUpdateUser from './Action_AddorUpdateUser';
import ActionDeactivateUser from './Action_DeactivateUser';


const ActionPanel = ({close, addNode, nodeData, updateNode, removeNode,sourceNodes}) =>{

    const [editMode, setEditMode] =useState(true);
    const [name, setName] = useState('');
    const [actionEvent, setActionEvent] =useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [nodeId, setNodeId] = useState(null);
    const [formula, setFormula]=useState(null);
    const [localData,setLocalData]=useState(null);
    const [testRows, setTestRows]=useState(null);
    const [testRow, setTestRow]=useState('');
    const [testResult,setTestResult]=useState(null);
    const [allNodes, setAllNodes]=useState([]);

    const divRef = useRef(); 

    useEffect(()=>{
      setName(nodeData.label);
      setNodeId(nodeData.id);
      
      if(nodeData.actionEvent !==undefined) {
        setActionEvent(nodeData.actionEvent);
      }
      setLocalData(nodeData);

      //If we have testRows, create options to populate dropdown.
      const allSourceNodes = get_all_source_nodes(nodeData);
      const triggerNode = allSourceNodes.filter(itm=>itm.id==='1')[0];

      const testOptions=[];

       triggerNode.data.testRows.map(row=>{
        testOptions.push({key:row.rowid,value:row.rowid,text:row.name})
        })
        setTestRows(testOptions);
        setAllNodes(allSourceNodes);
  
      const date = new Date();
    
    },[])

    const updateTestRow =(id) =>{
      setTestRow(id);
      setTestResult(null);
    }

    const executeTest =() =>{
      //use testRow to pass rowId to code to run test.
      console.log('implement test')
      setTestResult(true)
    }

    const get_all_source_nodes = (nodeData) => {
      const allNodeData = [];
      const seenIds = new Set(); // Use a Set to track unique IDs
    
      const extractSourceNodes = (data) => {
        if (data.sourceNodes) {
          data.sourceNodes.forEach((sourceNode) => {
            if (sourceNode.id && !seenIds.has(sourceNode.id)) { // Check for 'id' and uniqueness
              allNodeData.push(sourceNode);
              seenIds.add(sourceNode.id); 
            }
    
            if (sourceNode.data) {
              extractSourceNodes(sourceNode.data);
            }
          });
        }
      };
    
      extractSourceNodes(nodeData);
      return allNodeData;
    };
    

    const removeNodeLocal = (id) =>{
      if (divRef.current.value === 'delete') {
        removeNode(id);
      }
    }
    
   
    const saveNode = () => {
        if (
          name === '' ||
          actionEvent === '' ||
          (actionEvent==='newrecord' && localData.blockid===undefined) ||
          (actionEvent==='newrecord' && (localData.tableData===undefined || localData.tableData ===null)) ||
          (actionEvent==='deleterecord' && localData.deletionLevel==='soft' &&  (localData.insertFields===undefined || localData.insertFields.length===0))
        ) {
          toast.info(<div style={{ marginRight: '10px' }}>Missing a required field</div>, {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: true,
          });
          return false;
        } 

        localData.label=name;
        updateNode(localData.id, localData)
    
      };

    const deleteTriggerConfirm = () => {
        const message = (
          <div>
            You have selected to delete the action: {name}. <br/><br/>Are you sure you want to delete this
            action? History of this action will be retained in the workflow but it will no longer be a
            part of the workflow moving forward once saved. 
          </div>
        );
    
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <GBConfirm
                title={'Delete action forever'}
                action={removeNodeLocal}
                width="350px"
                actionValue={nodeData.id}
                buttonLabel="OK"
                message={message}
                divRef={divRef}
                showInput={true}
                confirmAction="delete"
                confirmWord="delete"
                height="450px"
                onClose={onClose}
              />
            );
          },
        });
      };

    return <div style={{margin:'10px',padding:'20px'}}>
            <div
            style={{
              fontSize: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{fontSize:'30px',fontWeight:'bold'}}>
              {' '}
              {nodeData !== null && !editMode
                ? 'Action details'
                : nodeData !== null && editMode
                ? 'Edit action'
                : 'Add new action'}
            </div>
            <div>
              {nodeData !== null && !editMode ? (
                <GBButton
                  text="Edit"
                  icon={mdiPencil}
                  iconSize="20px"
                  iconColor="black"
                  border="1px solid #0D9953"
                  iconPosition="left"
                  iconHoverColor="white"
                  borderRadius="10px"
                  Action={() => setEditMode(true)}
                  ActionValue={'trigger'}
                  textColor="black"
                  width="75px"
                  height={'30px'}
                  color="white"
                  textHoverColor="white"
                  hoverBackgroundColor={'#0D9953'}
                />
              ) : null}
            </div>
          </div>
          <div style={{ height: '20px' }}></div>
          <div style={{ fontWeight: 'bold',fontSize:'20px' }}>
            Action name <span style={{ color: 'red', fontSize: '15px' }}>*</span>{' '}
          </div>
          <div>
            {!editMode ? (
              <div>{name}</div>
            ) : (
              <Input value={name} fluid onChange={(e, data) => setName(data.value)} />
            )}
          </div>
       
          <div style={{ height: '20px' }}></div>
          {allNodes.length>0 ? (<>
          <EventSettings localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} updateEvent={setActionEvent} actionEvent={nodeData.actionEvent} sourceNodes={sourceNodes}  />
          <div style={{ height: '20px' }}></div>
          {actionEvent==='newrecord' || actionEvent==='updaterecord' ? (
            <div>
            <ActionNewRecord localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
            </div>
          ):null}
          
          {actionEvent==='deleterecord' ? (
            <div>
            <ActionDeleteRecord localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
            </div>
          ):null}

          {actionEvent==='formtask' ? (
            <div>
            <ActionFormTask localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
            </div>
          ):null} 

          {actionEvent==='sendmessage' ? (
           <div>
           <ActionSendMessage localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

          {actionEvent==='adduser' ? (
           <div>
           <ActionAddorUpdateUser localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

        {actionEvent==='deactivateuser' ? (
           <div>
           <ActionDeactivateUser localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
           </div>
          ):null}

          <div style={{marginTop:'10px'}}>
            <FilterConditions localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
          </div>

          <div style={{marginTop:'10px'}}>
            <ActionSchedule localData={localData ?? nodeData} updateData={setLocalData}  editMode={editMode} sourceNodes={allNodes} />
          </div>

          </>):null}

          {testRows?.length > 0 ? (
          <div style={{marginTop:'20px'}}>
            <div style={{fontSize:'20px', fontWeight:'bold',marginBottom:'5px'}}>Test Action</div>
            <div>Test action using the following Trigger test record:</div>
            <Dropdown selection fluid value={testRow} options={testRows} onChange={(e,data)=>updateTestRow(data.value)} />
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginTop:'10px'}}>
            <GBButton
                text="Test Action"
                borderRadius="10px"
                Action={executeTest}
                ActionValue={'trigger'}
                textColor="white"
                width="100px"
                height={'30px'}
                color="#0D99FF"
                textHoverColor="white"
                hoverBackgroundColor={'#0D99FF80'}
              />
              {testResult !=null ? (
                <div style={{display:'flex',flexDirection:'row',alignItems:'center',marginLeft:'10px'}}>
                  <div style={{marginRight:'5px'}}><Icon path={mdiCheckCircleOutline} size="25px" /></div>
                  <div>Success! If you are ready<br/>to proceed click save.</div>
                </div>
              ):null}
              </div>
          </div>
          ):null}
        
        <div
            style={{
              marginTop: '20px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <TextButton textColor="black" text="Cancel" Action={close} />
            <div style={{ width: '20px' }}></div>
            {editMode ? (
              <GBButton
                text="Save"
                borderRadius="10px"
                Action={saveNode}
                ActionValue={'trigger'}
                textColor="white"
                width="75px"
                height={'30px'}
                color="#0D99FF"
                textHoverColor="white"
                hoverBackgroundColor={'#0D99FF80'}
              />
            ) : (
              <GBButton
                icon={mdiTrashCan}
                iconPosition="left"
                iconSize="30px"
                text="Delete forever"
                borderRadius="10px"
                Action={deleteTriggerConfirm}
                // ActionValue={nodeData.id}
                textColor="black"
                width="150px"
                height={'35px'}
                color="#eee"
                textHoverColor="#00000080"
                hoverBackgroundColor={'#eee'}
              />
            )}
          </div>

          <GBButton
                icon={mdiTrashCan}
                iconPosition="left"
                iconSize="30px"
                text="Delete forever"
                borderRadius="10px"
                Action={deleteTriggerConfirm}
                // ActionValue={nodeData.id}
                textColor="black"
                width="150px"
                height={'35px'}
                color="#eee"
                textHoverColor="#00000080"
                hoverBackgroundColor={'#eee'}
              />

          <Modal
            shouldCloseOnOverlayClick={false}
            isOpen={modalIsOpen}
            // onAfterOpen={this.afterOpenModal}
            // onRequestClose={this.closeModal}
            style={{
              overlay: {
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: 'rgba(0, 0, 0, 0)',
              },
              content: {
                // position: 'fixed',
                width: '450px',
                // height: this.state.modalHeight,
                height: 'calc(100vh -300px)',
                // maxHeight:'500px',
                top: 200,
                left: window.innerWidth / 2,
                border: `.3px solid #0D99FF`,
                background: '#fff',
                overflow: 'auto',
                zIndex: 6010,
                WebkitOverflowScrolling: 'touch',
                borderRadius: '10px',
                outline: 'none',
                padding: '0px',
              },
            }}
            contentLabel="Example Modal"
          >
            {/* <FilterData
              title={`Conditions for action: ${name}`}
              icon={mdiGateNor}
              saveText="Save"
              tableinfo={tableData?.tableinfo}
              tableid={tableData?.tableid}
              close={() => setModalIsOpen(false)}
              FilterData={filterType === 'existing' ? updateConditions : updateNewConditions}
              filters={filterType === 'existing' ? filters : fieldUpdateFilters}
              color={'#0D99FF'}
              blockid={selectedBlock} // added 5-20-2020 as UserFilter for BlockUser(uitype==26) requires blockid
              callingComponent="form"
            /> */}
          </Modal>

    </div>
}

export default ActionPanel