/* eslint-disable */
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { Input, TextArea, Form, Radio, Dropdown } from 'semantic-ui-react';
import Spinner from 'src/components/Spinner/Spinner';
import { updateTableInfoAPI } from '../../../../api/tables';
import { AccountContext } from '../../../../providers/AccountProvider';
import GBSwitch from '../../../../components/GBSwitch/GBSwitch';
import Callout from './Callout';
import CodeEditor from 'src/components/CodeEditor/CodeEditor';
import cloudrun from '../../../../clients/httpcloudrun';

class Formula extends Component {
  constructor(props) {
    super(props);
    const { columnConfigData, tableinfo, selectedUIType } = this.props;

    this.state = {
      isLoading: false,
      showAdvanced: false,
      callout: columnConfigData.callout,
      formula: columnConfigData.formula ?? '',
      uitype: selectedUIType,
      tableinfo,
      isPrivate: columnConfigData.isPrivate ?? false,
      isError: false,
      formatting: columnConfigData.formatting ?? '0' , //0 =none 1 =date, 2 = numeric
      clockformat: '12',
      dateFormat: 'DD-MMM-YYYY',
      timeFormat: 'minutes',
      includeTime: false,
      UTC: false,
      numberFormat: 'general',
      decimals: 0,
      symbol: 'en-US',
      separator: '',
      useButtonDisplay: columnConfigData.useButtonDisplay ?? false,
      buttonLabel: columnConfigData.buttonLabel ?? '',
      cacheResults: columnConfigData.cacheResults ?? false
    };
  }

  static contextType = AccountContext;

  componentDidMount() {
    const { columnConfigData, uitype } = this.props;
    let clockFormat = '12';
    let timeFormat = 'minutes';
    clockFormat =
      columnConfigData.dateFormat && columnConfigData.dateFormat.includes('A') ? '12' : '24';
    timeFormat =
      columnConfigData.dateFormat && columnConfigData.dateFormat.includes('ss')
        ? 'seconds'
        : 'minutes';

    let numberFormat = 'general';
    let separator = '';
    let decimals = 0;
    let symbol = 'en-US';

    if (
      columnConfigData.numericFormat &&
      columnConfigData.numericFormat.pattern.toString().includes('$')
    ) {
      numberFormat = 'currency';
    } else if (
      columnConfigData.numericFormat &&
      columnConfigData.numericFormat.pattern.toString().includes('%')
    ) {
      numberFormat = 'percent';
    } else {
      numberFormat = 'general';
    }

    if (columnConfigData.numericFormat && columnConfigData.numberSeparator === 'comma') {
      separator = 'comma';
    } else if (columnConfigData.numericFormat && columnConfigData.numberSeparator === 'period') {
      separator = 'period';
    } else {
      separator = 'none';
    }
    if (columnConfigData.numericFormat) {
      symbol = columnConfigData.numericFormat.culture;
    }

    decimals = columnConfigData.numericFormat ? columnConfigData.numericDecimals : '0';

    this.setState({
      UTC: columnConfigData.UTC ?? true,
      clockFormat,
      timeFormat,
      decimals,
      numberFormat,
      separator,
      symbol,
      callout: columnConfigData.callout ? columnConfigData.callout : '',
      dateFormat: columnConfigData.dateFormatOnly ? columnConfigData.dateFormatOnly : 'DD-MMM-YYYY',
      defaultValue: columnConfigData.defaultValue ? columnConfigData.defaultValue : '',
      isFutureDays: columnConfigData.isFutureDays ? columnConfigData.isFutureDays : true,
      includeTime: columnConfigData.includeTime ? columnConfigData.includeTime : false,
    });
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.selectedUIType !== prevProps.selectedUIType) {
      this.setState({ uitype: this.props.selectedUIType });
    }
  }

  updateFormula = (val) => {
    this.setState({
      formula: val,
    });
  };

  DateStringFormat = () => {
    const { clockFormat, timeFormat, dateFormat, includeTime } = this.state;

    const hourformat = clockFormat === '24' ? 'HH' : 'hh';
    const minuteformat =
      timeFormat === 'minutes'
        ? `${hourformat}:mm ${clockFormat === '12' ? 'A' : ''}`
        : `${hourformat}:mm:ss ${clockFormat === '12' ? 'A' : ''}`;
    let finaldateFormat = dateFormat;
    if (includeTime) {
      finaldateFormat = `${dateFormat} ${minuteformat.trim()}`;
    }

    return finaldateFormat;
  };

  toggleCacheResults =() =>{
    const {cacheResults} = this.state;
    this.setState({cacheResults: !cacheResults})
  }

  getNumberFormat = () => {
    const {decimals,symbol,numberFormat,separator} = this.state;
    let zeros='';
      for(let i=0;i<decimals;i+=1)
      {
        zeros+='0'
      }
      const pattern = `${numberFormat==='currency' ? '$': ''}0${separator==='comma' ? ',':''}${separator==='period' ? '.':''}${zeros.length>0 ? `.${zeros}`:''}${numberFormat==='percent' ? '%':''}`;
      const numericFormat = {"pattern": pattern,"culture": symbol};
      return numericFormat;
  
    }

  Save = async () => {
    const {
      callout,
      tableinfo,
      uitype,
      isPrivate,
      formula,
      formatting,
      includeTime,
      clockFormat,
      timeFormat,
      dateFormat,
      UTC,
      numberFormat,
      symbol,
      decimals,
      separator,
      useButtonDisplay,
      buttonLabel,
      cacheResults
    } = this.state;
    const { field, tableid, header, selectedUIType, columnConfigData } = this.props;

    this.setState({ isLoading: true });

    try {
      // Add the function field as this is first time it's being configured.
      if (columnConfigData.uitype != selectedUIType || columnConfigData.formula !==formula) {
        const updatedNode = structuredClone(columnConfigData);
        updatedNode.header = header;
        updatedNode.uitype = uitype;
        updatedNode.displayfield = `tbl_${tableid}_${field}_formula`;
        delete updatedNode.renderer;
        updatedNode.renderandedit = 'LookupValues';
        updatedNode.callout = callout;
        updatedNode.formula = formula;
        updatedNode.formatting= formatting;
        updatedNode.includeTime = includeTime;
        updatedNode.dateFormat = this.DateStringFormat();
        updatedNode.dateFormatOnly = dateFormat;
        updatedNode.UTC = UTC;
        updatedNode.numericFormat=this.getNumberFormat();
        updatedNode.numericDecimals=decimals;
        updatedNode.numberSeparator = separator;
        updatedNode.buttonLabel=buttonLabel;
        updatedNode.cacheResults = cacheResults;


        if (isPrivate) {
          updatedNode.isPrivate = true;
        } else {
          delete updatedNode.isPrivate;
        }

        if(useButtonDisplay) {
          updatedNode.useButtonDisplay=true;
        } else {
          delete updatedNode.useButtonDisplay;
          delete updatedNode.buttonLabel;
        }

        if (columnConfigData.uitype !== uitype) {
          delete updatedNode.concat;
        }

        const payload = { field, tableid, tableinfo, formula, updatedNode };

        const result = await cloudrun.post('/addFormulaField', { payload });

        this.setState({ isLoading: false });
        return result;
      } else {
        // Update Tableinfo with new settings.
        const rowindex = tableinfo.columns.findIndex((el) => el.data === field);
        

        if (rowindex !== -1) {
          const newNode = structuredClone(tableinfo).columns[rowindex];
          newNode.header = header;
          if (isPrivate) {
            newNode.isPrivate = true;
          } else {
            delete newNode.isPrivate;
          }
          newNode.formatting = formatting;
          newNode.includeTime = includeTime;
          newNode.dateFormat =  this.DateStringFormat();
          newNode.dateFormatOnly = dateFormat;
          newNode.UTC = UTC;
          newNode.numericFormat = this.getNumberFormat();
          newNode.numericDecimals = decimals;
          newNode.numberSeparator = separator;
          newNode.callout = callout;
          newNode.buttonLabel=buttonLabel;
          newNode.cacheResults = cacheResults;
          tableinfo.columns[rowindex] = newNode;

          if(useButtonDisplay) {
           newNode.useButtonDisplay=true;
          } else {
            delete newNode.useButtonDisplay;
            delete newNode.buttonLabel;
          }

          await updateTableInfoAPI(tableid, tableinfo,columnConfigData);
          this.setState({ isLoading: false });
          return 'success';
        }
      }
    } catch (error) {
      console.log(error);
      return `An Error Occurred: ${error.message}`;
    }
  };

  showAdvancedHandler = () => {
    const { showAdvanced } = this.state;
    const { updateHeight } = this.props;
    this.setState({ showAdvanced: !showAdvanced });
    !showAdvanced ? updateHeight(600) : updateHeight(500);
  };

  CalloutHandler = (callout) => {
    this.setState({ callout });
  };

  keyPressHandler = (e) => {
    if (e.key === '#') {
      console.log('show functions');
    } else if (e.key === '{') {
      console.log('show fields');
    }
  };

  togglePrivate = () => {
    const { isPrivate } = this.state;
    const { userInfo } = this.context;

    if (userInfo.plan === 'Free' || userInfo.plan === 'Starter') {
      toast.info(
        <div style={{ margin: '5px' }}>
          To make a field private, you must upgrade to the pro plan.
          <a
            style={{ color: 'white', textDecoration: 'underline' }}
            href="https://www.graceblocks.com/support-article/how-to-upgrade"
            target="_newwindow"
          >
            {' '}
            Learn more.
          </a>
        </div>,
        {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 7000,
          closeButton: false,
        },
      );
    } else {
      this.setState({ isPrivate: !isPrivate });
    }
  };

  IncludeTimeHandler = () => {
    const { includeTime } = this.state;
    this.setState({ includeTime: !includeTime });
  };

  handleChange = (value) => {
    this.setState({ numberFormat: value, decimals: value === 'currency' ? '2' : '0' });
  };

  render() {
    const {
      showAdvanced,
      callout,
      defaultText,
      uitype,
      isPrivate,
      isLoading,
      formula,
      formatting,
      includeTime,
      clockFormat,
      timeFormat,
      dateFormat,
      UTC,
      numberFormat,
      symbol,
      decimals,
      separator,
      useButtonDisplay,
      buttonLabel,
      cacheResults
    } = this.state;
    const { color, tableinfo, columnConfigData } = this.props;
    return (
      <div style={{width:'100%'}}>
        <div style={{ marginTop: '5px' }}>
          <div>
          Write a formula using fields to determine a new value. <a href="https://www.graceblocks.com/support-article/building-field-type-formula" target="_newWindow">Learn more here.</a>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px',width:'100%' }}>
          {/* <div style={{ marginBottom: '3px' }}>
            <strong>Formula text (Type # to select a formula and {'{'} to select a field)</strong>
          </div> */}
          {isLoading ? <Spinner /> : null}
          <CodeEditor
            fields={tableinfo.columns.filter(c=>![4,12,14].includes(c.uitype) && (![4,12,14].includes(c.source?.lookupuitype)) ).map((col) => col.header)}
            updateFormula={this.updateFormula}
            formula={formula}
            isReadOnly={false}
            // isReadOnly={columnConfigData.uitype === 16}
          />
        </div>

        <div style={{ height: '15px' }}></div>
        <GBSwitch
          color={color}
          text="More field attributes"
          isChecked={showAdvanced}
          Action={this.showAdvancedHandler}
        />

        {showAdvanced ? (
          <div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <GBSwitch
                color={color}
                text="Private field"
                isChecked={isPrivate}
                Action={this.togglePrivate}
              />
            </div>

          {/* removed setting, we now do this at the tab level */}
            {/* <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <GBSwitch
                color={color}
                text="Cache results for 24 hours"
                isChecked={cacheResults}
                Action={this.toggleCacheResults}
              />
            </div> */}

            {/* Formatting options */}
            <div style={{ marginTop: '10px' }}>Formatting options</div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                width: '270px',
                justifyContent: 'space-between',
              }}
            >
              {/* using extra div to align radio buttions */}
              <div>
                <Radio
                  checked={formatting === '0'}
                  name="formatting"
                  value="0"
                  onChange={(e, data) => this.setState({ formatting: data.value })}
                  label="None"
                  style={{
                    zIndex: '1',
                    position: 'relative',
                    marginTop: '10px',
                    fontSize: '13px',
                  }}
                />
              </div>
              <Radio
                checked={formatting === '1'}
                name="formatting"
                value="1"
                onChange={(e, data) => this.setState({ formatting: data.value })}
                label="Date"
                style={{
                  zIndex: '1',
                  position: 'relative',
                  marginTop: '10px',
                  fontSize: '13px',
                }}
              />
              <Radio
                checked={formatting === '2'}
                name="formatting"
                value="2"
                onChange={(e, data) => this.setState({ formatting: data.value })}
                label="Numeric"
                style={{
                  zIndex: '1',
                  position: 'relative',
                  marginTop: '10px',
                  fontSize: '13px',
                }}
              />
               <Radio
                checked={formatting === '3'}
                name="formatting"
                value="3"
                onChange={(e, data) => this.setState({ formatting: data.value })}
                label="URL"
                style={{
                  zIndex: '1',
                  position: 'relative',
                  marginTop: '10px',
                  fontSize: '13px',
                }}
              />
            </div>

            {/* Date Formatting options */}
            {formatting === '1' ? (
              <>
                <div
                  className="subtext"
                  style={{ marginBottom: '3px', marginTop: '10px', fontSize: '13px' }}
                >
                  <strong>Date format</strong>
                </div>
                <Dropdown
                  style={{ width: 'max-content', fontSize: '13px' }}
                  placeholder="None"
                  value={dateFormat}
                  fluid
                  onChange={(e, data) => this.setState({ dateFormat: data.value })}
                  floating
                  options={[
                    { key: '1', value: 'DD-MMM-YYYY', text: 'DD-MMM-YYYY: 8-MAR-2020' },
                    { key: '2', value: 'DD/MM/YYYY', text: 'DD/MM/YYYY: 8/3/2020' },
                    { key: '3', value: 'MM/DD/YYYY', text: 'MM/DD/YYYY: 3/8/2020' },
                    { key: '4', value: 'YYYY-MM-DD', text: 'YYYY-MM-DD: 2020-03-08' },
                    { key: '5', value: 'dddd, MMMM D, YYYY', text: 'Sunday, March 8, 2020' },
                  ]}
                />
                <GBSwitch
                  color={color}
                  text="Include time"
                  isChecked={includeTime}
                  Action={this.IncludeTimeHandler}
                />
                {includeTime ? (
                  <>
                    <div
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
                    >
                      {/* using extra div to align radio buttions */}
                      <div style={{ width: '170px' }}>
                        <Radio
                          checked={clockFormat === '12'}
                          name="clock"
                          value="12"
                          onChange={(e, data) => this.setState({ clockFormat: data.value })}
                          label="12 hour clock (am/pm)"
                          style={{
                            zIndex: '1',
                            position: 'relative',
                            marginTop: '10px',
                            fontSize: '13px',
                          }}
                        />
                      </div>
                      <Radio
                        checked={clockFormat === '24'}
                        name="clock"
                        value="24"
                        onChange={(e, data) => this.setState({ clockFormat: data.value })}
                        label="24 hour clock"
                        style={{
                          zIndex: '1',
                          position: 'relative',
                          marginTop: '10px',
                          fontSize: '13px',
                        }}
                      />
                    </div>
                    <div
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}
                    >
                      <div style={{ width: '170px' }}>
                        <Radio
                          checked={timeFormat === 'minutes'}
                          name="time"
                          value="minutes"
                          onChange={(e, data) => this.setState({ timeFormat: data.value })}
                          label="hh:mm"
                          style={{
                            zIndex: '1',
                            position: 'relative',
                            marginTop: '10px',
                            fontSize: '13px',
                          }}
                        />
                      </div>
                      <Radio
                        checked={timeFormat === 'seconds'}
                        name="time"
                        value="seconds"
                        onChange={(e, data) => this.setState({ timeFormat: data.value })}
                        label="hh:mm:ss"
                        style={{
                          zIndex: '1',
                          position: 'relative',
                          marginTop: '10px',
                          fontSize: '13px',
                        }}
                      />
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
            {formatting === '2' ? (
              <div>
                <div className="subtext" style={{ marginBottom: '3px' }}>
                  <strong>Number type</strong>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Form.Field>
                    <Radio
                      label="General"
                      name="type"
                      value="general"
                      checked={numberFormat === 'general'}
                      onChange={(e, data) => this.handleChange(data.value)}
                      style={{ marginRight: '10px', fontSize: '13px' }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Radio
                      label="Currency"
                      name="type"
                      value="currency"
                      checked={numberFormat === 'currency'}
                      onChange={(e, data) => this.handleChange(data.value)}
                      style={{ marginRight: '10px', fontSize: '13px' }}
                    />
                  </Form.Field>

                  <Form.Field>
                    <Radio
                      label="Percent"
                      name="type"
                      value="percent"
                      checked={numberFormat === 'percent'}
                      onChange={(e, data) => this.handleChange(data.value)}
                      style={{ fontSize: '13px' }}
                    />
                  </Form.Field>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      // marginTop: '10px',
                      marginRight: '15px',
                    }}
                  >
                    <div style={{ marginBottom: '3px' }}>
                      <strong>000's seperator</strong>
                    </div>
                    <Dropdown
                      style={{ width: 'max-content', fontSize: '13px' }}
                      placeholder="None"
                      value={separator}
                      onChange={(e, data) => this.setState({ separator: data.value })}
                      floating
                      options={[
                        { key: '1', value: 'none', text: 'none' },
                        { key: '2', value: 'comma', text: ',' },
                      ]}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginRight: '15px',
                    }}
                  >
                    <div style={{ marginBottom: '3px' }}>
                      <strong># of decimals</strong>
                    </div>
                    <Dropdown
                      floating
                      upward
                      value={decimals}
                      style={{ fontSize: '13px' }}
                      onChange={(e, data) => this.setState({ decimals: data.value })}
                      scrolling
                      options={[
                        { key: '0.5', value: '0', text: '0' },
                        { key: '1', value: '1', text: '1' },
                        { key: '2', value: '2', text: '2' },
                        { key: '3', value: '3', text: '3' },
                        { key: '4', value: '4', text: '4' },
                        { key: '5', value: '5', text: '5' },
                        { key: '6', value: '6', text: '6' },
                        { key: '7', value: '7', text: '7' },
                        { key: '8', value: '8', text: '8' },
                        { key: '9', value: '9', text: '9' },
                      ]}
                    />
                  </div>
                  {numberFormat === 'currency' ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="subtext" style={{ marginBottom: '3px' }}>
                        <strong>Symbol</strong>
                      </div>
                      <Dropdown
                        style={{ fontSize: '11px' }}
                        value={symbol}
                        onChange={(e, data) => this.setState({ symbol: data.value })}
                        options={[
                          { key: '1', value: 'en-US', text: '$' },
                          { key: '2', value: 'en-GB', text: '£' },
                          { key: '3', value: 'de-DE', text: '€' },
                        ]}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
            {formatting==='3' ? (
              <div>
                 <GBSwitch
                  color={color}
                  text="Convert URL to button"
                  isChecked={useButtonDisplay}
                  Action={()=>this.setState({useButtonDisplay: !useButtonDisplay})}
                />
              </div>
            ):null}
            {formatting==='3' && useButtonDisplay ? (
             <>
             <div style={{ marginBottom: '3px',marginTop:'5px' }}>
             <strong>Button label</strong>
           </div>
            <Input
            size="small"
            onChange={(e, data) => this.setState({ buttonLabel: data.value })}
            value={buttonLabel}
            placeholder="Enter label for button"
          />
          </>
            ):null}

            <Callout callout={callout} updateCallout={this.CalloutHandler} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default Formula;
